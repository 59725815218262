





































































































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Recipe, RecipeTag, Tag, Library, Legislation } from '@/api';

import libraryModule from '@/store/Library';
import snackModule from '@/store/Snack';

import TagFilter from '@/components/common/TagFilter.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';
import LibrarySelect from '@/components/common/LibrarySelect.vue';

@Component({
  components: {
    TagFilter,
    LegislationSelect,
    LibrarySelect,
  },
})
export default class RecipeInformation extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  recipe = new Recipe(); // tmp value

  recipeOriginal = new Recipe();

  recipeTags: RecipeTag[] = [];

  tags: string[] = [];

  tagsOriginal: string[] = [];

  valid = false;

  fieldsDirty = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .not.empty()
        .test(v) || this.$t('recipes.validation.requiredPublish'),
  ];

  requiredObject = [
    (v: Legislation) => {
      if (!v) {
        return this.$t('common.validation.generalRequired');
      }

      return (
        v8n()
          .string()
          .not.empty()
          .test(v.id) || this.$t('common.validation.generalRequired')
      );
    },
  ];

  get validation() {
    let validation;
    if (this.isDirty) {
      validation = 'unsaved';
    } else if (!this.valid) {
      validation = 'invalid';
    } else {
      validation = 'complete';
    }
    return validation;
  }

  get editMode() {
    return this.$route.name === 'recipes-edit';
  }

  get library() {
    const id =
      (this.recipe && this.recipe.library && this.recipe.library.id) || '0';
    const library = libraryModule.libraries.find(lib => lib.id === id);
    return library || libraryModule.defaultLibrary;
  }

  set library(lib) {
    if (this.recipe) {
      this.recipe.library =
        lib === libraryModule.defaultLibrary ? null : (lib as Library);
    }
  }

  get libraryDirty() {
    const newLibId = this.library.id;
    const oldLibId =
      this.recipeOriginal && this.recipeOriginal.library
        ? this.recipeOriginal.library.id
        : '0';
    return newLibId !== oldLibId;
  }

  get referenceAmount() {
    return this.recipe && !this.recipe.isComponent
      ? this.recipe.referenceAmount
      : '–';
  }

  set referenceAmount(v: string) {
    this.recipe.referenceAmount = v;
  }

  get tagsToRemove() {
    return this.recipeTags.filter(
      recipeTag => !this.tags.includes(recipeTag.tag.id as string),
    );
  }

  get tagsToAdd() {
    return this.tags.filter(
      tag => !this.recipeTags.find(recipeTag => recipeTag.tag.id === tag),
    );
  }

  get tagsDirty() {
    return !!this.tagsToRemove.length || !!this.tagsToAdd.length;
  }

  get formFields() {
    return [
      this.recipe.legislation ? this.recipe.legislation.id : null,
      this.recipe.library ? this.recipe.library.id : null,
      this.recipe.name,
      this.recipe.referenceCode,
      this.recipe.referenceAmount,
      this.recipe.referenceAmountAssumptions,
      this.recipe.isComponent,
    ];
  }

  get isDirty() {
    return this.fieldsDirty || this.tagsDirty || this.libraryDirty;
  }

  cancel() {
    this.recipe = this.recipeOriginal.dup();
    this.tags = [...this.tagsOriginal];
  }

  async getTags() {
    this.recipeTags = this.recipe.id
      ? (
          await RecipeTag.where({ recipe: this.recipe.id })
            .includes('tag')
            .all()
        ).data
      : [];

    this.tags =
      (this.recipeTags.map(recipeTag => recipeTag.tag.id) as string[]) || [];
    this.tagsOriginal = [...this.tags];
  }

  async saveTags() {
    const { coreId } = this.recipe;

    // add and remove recipe tags that arent in tags
    await Promise.all([
      ...this.tagsToRemove.map(async recipeTag => recipeTag.destroy()),
      ...this.tagsToAdd.map(async tag =>
        new RecipeTag({
          recipeCoreId: coreId,
          tag: new Tag({ id: tag }),
        }).save({ with: 'tag.id' }),
      ),
    ]);
  }

  async save() {
    try {
      this.loading = true;
      if (this.recipe.isComponent) {
        this.recipe.referenceAmount = '100';
      }
      if (this.editMode) {
        await this.recipe.save({
          with: ['library.id'],
        });
      } else {
        await this.recipe.save({
          with: ['organisation.id', 'legislation.id', 'library.id'],
        });
      }
      await this.saveTags();
      this.fieldsDirty = false;
      this.$emit('updated', this.recipe);
      snackModule.setSuccess(this.$t('recipes.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.recipe = this.item.dup();
    this.recipeOriginal = this.item.dup();
    await this.getTags();
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }

  @Watch('formFields')
  dirtyChanged() {
    this.fieldsDirty = this.recipe.isDirty();
  }
}
