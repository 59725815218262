













































import { Component, Vue } from 'vue-property-decorator';

import FilterItem from '@/components/dashboard/FilterItem.vue';
import { StripeProduct } from '@/api';

@Component({ components: { FilterItem } })
export default class OrganisationFilters extends Vue {
  abProducts: StripeProduct[] = [];

  vitalProducts: StripeProduct[] = [];

  typeItems = [
    { label: this.$t('organisations.types.regular'), value: '0' },
    { label: this.$t('organisations.types.trainingProvider'), value: '1' },
    { label: this.$t('organisations.types.trainingSession'), value: '2' },
  ];

  accessItems = [
    { label: this.$t('organisations.access.labels.active'), value: 'true' },
    { label: this.$t('organisations.access.labels.inactive'), value: 'false' },
  ];

  manualAccessItems = [
    {
      label: this.$t('organisations.access.options.manualTrue'),
      value: 'true',
    },
    {
      label: this.$t('organisations.access.options.manualFalse'),
      value: 'false',
    },
  ];

  statusItems = [
    { label: this.$t('organisations.status.active'), value: 'active' },
    { label: this.$t('organisations.status.lapsed'), value: 'lapsed' },
    { label: this.$t('organisations.status.invalid'), value: 'invalid' },
    { label: this.$t('organisations.status.missing'), value: 'missing' },
  ];

  trainingItems = [
    { label: this.$t('organisations.status.activeTraining'), value: 'true' },
    { label: this.$t('organisations.status.inactiveTraining'), value: 'false' },
  ];

  couponItems = [
    { label: this.$t('organisations.labels.hasDiscount'), value: 'has' },
    { label: this.$t('organisations.labels.usedDiscount'), value: 'used' },
  ];

  get vitalItems() {
    const defaults = [
      { label: this.$t('organisations.labels.anySubscription'), value: 'any' },
      { label: this.$t('organisations.labels.noSubscription'), value: 'none' },
    ];
    const products = this.vitalProducts
      .map(product => {
        return product.prices.map(price => {
          return {
            label: `${product.name} ${this.formatSubInterval(price.interval)}`,
            value: price.id,
          };
        });
      })
      .flat();
    return [...defaults, ...products];
  }

  get abItems() {
    const defaults = [
      { label: this.$t('organisations.labels.anySubscription'), value: 'any' },
      { label: this.$t('organisations.labels.noSubscription'), value: 'none' },
    ];
    const products = this.abProducts
      .map(product => {
        return product.prices.map(price => {
          return {
            label: `${product.name} ${this.formatSubInterval(price.interval)}`,
            value: price.id,
          };
        });
      })
      .flat();
    return [...defaults, ...products];
  }

  formatSubInterval(value: string) {
    switch (value) {
      case 'month':
        return this.$t('subscriptions.labels.monthly');

      case 'year':
        return this.$t('subscriptions.labels.yearly');

      default:
        return value;
    }
  }

  async mounted() {
    const products = (await StripeProduct.all()).data;
    this.abProducts = products.filter(p =>
      p.name.startsWith('Allergen Bureau'),
    );
    this.vitalProducts = products
      .filter(p => !this.abProducts.includes(p))
      .sort((a, b) => a.fteMin - b.fteMin);
  }
}
