
































import { Component, Vue, Prop } from 'vue-property-decorator';

import localeModule from '@/store/Locale';

@Component({})
export default class LanguagePicker extends Vue {
  @Prop({ type: Boolean, default: false }) spacerL: boolean;

  @Prop({ type: Boolean, default: false }) spacerR: boolean;

  languages = [
    { text: 'English', value: 'en' },
    { text: 'French', value: 'fr' },
  ];

  setLanguage(locale: string) {
    localeModule.setLanguage(locale);
  }
}
