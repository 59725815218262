

























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import legislationModule from '@/store/Legislation';
import snackModule from '@/store/Snack';

import AllergenListItem from '@/components/common/AllergenListItem.vue';
import {
  ProcessingDispersionType,
  ProcessingDispersionTypeItems,
  ProcessingCrosscontact,
  Allergen,
  ProcessingSource,
  Legislation,
} from '@/api';
import { roundify } from '@/scenario-tester';

@Component({
  components: {
    AllergenListItem,
  },
})
export default class AllergenList extends Vue {
  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly processingSource: ProcessingSource;

  @Prop({ default: false, type: Boolean }) readonly readonly: boolean;

  crosscontacts: ProcessingCrosscontact[] = [];

  dialog = false;

  crosscontact: ProcessingCrosscontact | null = null;

  legislation: Legislation = new Legislation();

  roundify = roundify;

  loading = false;

  get sortedAllergens() {
    return this.legislation.sortedAllergens;
  }

  allergenById(id: string) {
    return this.legislation.allergens.find(allergen => allergen.id === id);
  }

  get ProcessingDispersionType() {
    return ProcessingDispersionType;
  }

  get dispersionTypeItems() {
    return ProcessingDispersionTypeItems;
  }

  get validation() {
    return this.crosscontacts.length ? 'complete' : 'incomplete';
  }

  editCrosscontact(
    allergen: Allergen,
    crosscontact: ProcessingCrosscontact | null,
  ) {
    this.crosscontact = crosscontact
      ? crosscontact.dup()
      : new ProcessingCrosscontact({
          allergen,
          source: this.processingSource,
          dispersionType: ProcessingDispersionType.Particulate,
        });
    this.dialog = true;
  }

  async saveCrosscontact() {
    if (!this.crosscontact) {
      throw new Error(
        this.$t('allergens.alerts.missingCrosscontact') as string,
      );
    }
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      await this.crosscontact.save({
        with: ['allergen.id', 'source.id'],
      });
      snackModule.setSuccess(
        this.$t('allergens.alerts.allergenSaved') as string,
      );
      await this.update();
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('allergens.alerts.allergenNotSaved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteCrosscontact() {
    if (!this.crosscontact) {
      throw new Error(
        this.$t('allergens.alerts.missingCrosscontact') as string,
      );
    }
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      if (this.crosscontact.isPersisted) {
        await this.crosscontact.destroy();
      }
      snackModule.setSuccess(
        this.$t('allergens.alerts.allergenRemoved') as string,
      );
      await this.update();
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('allergens.alerts.allergenNotRemoved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async update() {
    this.legislation = await legislationModule.getLegislation(
      this.legislationId,
    );
    this.crosscontacts = (
      await ProcessingCrosscontact.where({
        source: this.processingSource.id,
      }).all()
    ).data;
  }

  async mounted() {
    await this.update();
  }

  @Watch('processingSource')
  async processingSourceChanged() {
    await this.update();
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }
}
