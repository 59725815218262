




























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipe } from '@/api';

import dayjs from 'dayjs';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import LibraryUserAvatars from '@/components/common/LibraryUserAvatars.vue';
import ActionBanner from '@/components/dashboard/ActionBanner.vue';
import RecipesOverview from '@/components/dashboard/RecipesOverview.vue';
import RecipesEdit from '@/components/dashboard/RecipesEdit.vue';

import ConfirmDialog from '@/confirm-dialog';

@Component({
  components: {
    LibraryUserAvatars,
    ActionBanner,
    RecipesOverview,
    RecipesEdit,
  },
})
export default class RecipesSingle extends Vue {
  item: Recipe | null = null;

  tags: string[] = [];

  valid = false;

  loadingPublish = false;

  loadingRevision = false;

  loadingUpdate = false;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get isArchived() {
    return this.item && this.item.isArchived;
  }

  get editMode() {
    return this.$route.name === 'recipes-edit';
  }

  get revision() {
    return this.item && !this.item.isNewest;
  }

  get draft() {
    return this.item && this.item.status === 'draft';
  }

  get published() {
    return this.item && this.item.status === 'published';
  }

  get title() {
    return this.editMode
      ? this.$t('recipes.single.editTitle')
      : this.$t('recipes.single.createTitle');
  }

  get chipColor() {
    if (this.revision) {
      return 'orange';
    }
    if (this.published) {
      return 'green';
    }
    return 'pink';
  }

  get chipLabel() {
    if (this.revision) {
      return this.$t('common.revisionNumber', { number: this.itemId });
    }
    if (this.published) {
      return this.$t('common.labels.published');
    }
    return this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('MMMM DD, YYYY')} (${dayjs(val).fromNow()})`
      : '–';
  }

  setRoute() {
    // make sure the route is pointing at the correct id
    // id will change when creating a new one or creating a revision
    // maybe should re-render this component (by setting :key on the router-view)

    if (this.item && this.item.id) {
      this.$router
        .replace({
          name: 'recipes-edit',
          params: { itemId: this.item.id },
        })
        .catch(() => {
          // Stop duplicate route
        });
    } else {
      this.$router.replace({ name: 'recipes-new' }).catch(() => {
        // Stop duplicate route
      });
    }
  }

  setTitle() {
    if (this.item) {
      document.title = `${this.item.name} - VITAL Online`;
      authModule.trackPageview({
        gtag: this.$gtag,
        route: this.$route,
        pageTitle: this.item.name,
      });
    }
  }

  async toggleShared() {
    if (!this.item) {
      throw new Error(this.$t('recipes.alerts.missing') as string);
    }

    const r = await ConfirmDialog();
    if (r !== 'confirm') {
      return;
    }

    try {
      const recipe = new Recipe({ id: this.itemId });
      recipe.isPersisted = true;
      recipe.shared = !this.item.shared;
      await recipe.save();
      this.item = recipe;
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notShared') as string,
        errors: e.response.errors,
      });
    }
  }

  async publish() {
    if (!this.item) {
      throw new Error(this.$t('recipes.alerts.missing') as string);
    }
    if (this.item.status !== 'draft') {
      throw new Error(this.$t('recipes.alerts.isNotDraft') as string);
    }

    try {
      this.loadingPublish = true;
      const publishedItem = new Recipe({ id: this.item.id });
      publishedItem.isPersisted = true;
      publishedItem.status = 'published';
      await publishedItem.save();
      this.item = publishedItem;
      snackModule.setSuccess(this.$t('recipes.alerts.published') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notPublished') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingPublish = false;
    }
  }

  async update() {
    if (!this.item) {
      throw new Error(this.$t('recipes.alerts.missing') as string);
    }

    try {
      this.loadingUpdate = true;
      const dup = this.item.dup();
      dup.updateRelationships = true;
      await dup.save();
      dup.recipeTags = this.item.recipeTags;
      this.item = dup;
      snackModule.setSuccess(
        this.$t('ingredients.alerts.relsUpdated') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.relsNotUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingUpdate = false;
    }
  }

  async newRevision() {
    if (!this.item) {
      throw new Error(this.$t('recipes.alerts.missing') as string);
    }
    if (this.item.status !== 'published') {
      throw new Error(this.$t('recipes.alerts.isNotPublished') as string);
    }

    try {
      this.loadingRevision = true;
      const revision = new Recipe({
        coreId: this.item.coreId,
      });
      await revision.save();
      revision.recipeTags = this.item.recipeTags;
      this.item = revision;
      snackModule.setSuccess(
        this.$t('common.alerts.revisionCreated') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.revisionNotCreated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingRevision = false;
    }
  }

  async prepareItem() {
    if (this.editMode) {
      try {
        this.item = (await Recipe.find(this.itemId)).data;
        this.setTitle();
      } catch (e) {
        document.title = 'VITAL Online';
        throw e;
      }
    } else {
      this.item = new Recipe({
        organisation: authModule.activeOrganisation,
        waterGain: 100,
      });
    }
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('item')
  itemChanged() {
    this.setRoute();
  }

  @Watch('itemId')
  itemIdChanged() {
    this.setTitle();
    if (this.item && this.item.id !== this.itemId) {
      this.prepareItem();
    }
  }
}
