var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"edit-header"},[_c('div',[_c('div',{staticClass:"d-flex align-center form-horizontal-gap"},[_c('h1',[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.editMode)?_c('v-chip',{attrs:{"color":_vm.chipColor,"outlined":""}},[_vm._v(" "+_vm._s(_vm.chipLabel)+" ")]):_vm._e()],1)]),_c('v-spacer'),_c('div',[_c('div',{staticClass:"form-horizontal-gap"},[(!_vm.revision)?_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.editMode ||
              !_vm.draft ||
              !_vm.valid ||
              _vm.item.isRecipe ||
              _vm.loadingPublish ||
              _vm.isArchived,"depressed":"","large":"","color":"secondary","loading":_vm.loadingPublish},on:{"click":_vm.publish}},[_vm._v(" "+_vm._s(_vm.$t('common.publish'))+" ")]):_vm._e(),(!_vm.revision)?_c('v-btn',{attrs:{"disabled":!_vm.editMode ||
              !_vm.published ||
              _vm.item.isRecipe ||
              _vm.item.outdatedLegislation ||
              _vm.loadingRevision ||
              _vm.isArchived,"depressed":"","large":"","color":"warning","loading":_vm.loadingRevision},on:{"click":_vm.newRevision}},[_vm._v(" "+_vm._s(_vm.$t('common.newRevision'))+" ")]):_vm._e()],1)])],1),(_vm.item.isRecipe)?_c('action-banner',{attrs:{"button-text":_vm.$t('ingredients.actionBanner.recipeButton'),"readonly":!_vm.recipe},on:{"action":_vm.toRecipe}},[_c('h3',{staticClass:"action-banner__title"},[_vm._v(" "+_vm._s(_vm.$t('ingredients.actionBanner.recipeTitle'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('ingredients.actionBanner.recipeContent'))}})]):_vm._e(),(_vm.isArchived)?_c('action-banner',{attrs:{"hide-action":""}},[_c('h3',{staticClass:"action-banner__title"},[_vm._v(" You are viewing an archived item. ")]),_c('div',[_vm._v(" Information on outcomes may be outdated or incorrect. To make changes you first need to restore this item. ")])]):(_vm.editMode && _vm.item.outdated && _vm.item.isNewest)?_c('action-banner',{attrs:{"loading":_vm.loadingUpdate,"readonly":_vm.item.isRecipe},on:{"action":_vm.update}}):_vm._e(),(_vm.isArchived || _vm.isRecipe || (_vm.editMode && !_vm.draft))?_c('ingredients-overview',{key:_vm.item.id,model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}):_c('ingredients-edit',{key:("edit-" + (_vm.item.id)),on:{"valid":function($event){_vm.valid = $event}},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }