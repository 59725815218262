






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class FilterItem extends Vue {
  @Prop({ required: true }) readonly label: string;

  @Prop({ required: true }) readonly paramName: string;

  @Prop({ default: true }) readonly showAll: string;

  @Prop({ required: true }) readonly items: Array<{
    label: string;
    value: string;
  }>;

  @Prop({ default: false }) readonly alwaysChecked: boolean;

  hasQuery = false;

  get allItems() {
    return this.showAll
      ? [{ label: this.$t('common.labels.all'), value: '' }, ...this.items]
      : this.items;
  }

  get currentQuery() {
    return (this.$route.query[this.paramName] as string) || '';
  }

  set currentQuery(val: string) {
    if (val !== this.currentQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [this.paramName]: val || undefined,
        },
      });
    }
  }

  toggleHasQuery() {
    if (!this.alwaysChecked) {
      this.hasQuery = !this.hasQuery;
    }
  }

  mounted() {
    this.hasQuery = !!this.currentQuery;
  }

  @Watch('hasQuery')
  filterChanged() {
    if (!this.hasQuery) {
      this.currentQuery = '';
    }
  }
}
