



















































import { Component, Vue } from 'vue-property-decorator';
import { Legislation } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';

import dayjs from 'dayjs';

@Component({
  components: {
    AbstractTable,
  },
})
export default class Legislations extends Vue {
  get headers() {
    return Legislation.listHeaders();
  }

  get scopeFactory() {
    return () =>
      Legislation.includes([])
        .order({ name: 'asc' })
        .where({ is_newest: true });
  }

  formatDate(val: string) {
    return dayjs(val).fromNow();
  }

  formatEffectiveDate(val: string) {
    const dt = dayjs(val);
    return `${dt.format('MMMM D, YYYY')} (${dt.fromNow()})`;
  }

  chipColor(val: string) {
    return val === 'published' ? 'green' : 'pink';
  }

  chipLabel(val: string) {
    return val === 'published'
      ? this.$t('common.labels.published')
      : this.$t('common.labels.draft');
  }
}
