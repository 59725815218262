










































































import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { Library, LibraryUser } from '@/api';

import authModule from '@/store/Auth';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableActionButton from '@/components/common/TableActionButton.vue';
import BulkMove from '@/components/dashboard/BulkMove.vue';

@Component({
  components: {
    AbstractTable,
    TableActionButton,
    BulkMove,
  },
})
export default class Libraries extends Vue {
  selected: Library[] = [];

  moveDialog = false;

  get headers() {
    return Library.listHeaders();
  }

  get scopeFactory() {
    return Library.scopeFactory;
  }

  get singleSelected() {
    const [single] = this.selected;
    return single;
  }

  formatDate(val: string) {
    return dayjs(val).fromNow();
  }

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  activeOrgUsers(library: Library, perm: 'read' | 'write') {
    let libUsers: LibraryUser[];
    if (perm === 'read') {
      libUsers = library.libraryUsers.filter(
        libUser =>
          libUser.permission === 'read' || libUser.permission === 'write',
      );
    } else {
      libUsers = library.libraryUsers.filter(
        libUser => libUser.permission === 'write',
      );
    }

    const count = this.activeOrg.userRelationships.filter(
      orgUser =>
        orgUser.isManager ||
        libUsers.find(libUser => libUser.user.id === orgUser.user.id),
    ).length;

    return count;
  }

  getReadPerms(item: Library) {
    if (item.orgWidePermission !== 'none') {
      return this.$t('libraryUsers.visibilityShort.everyone');
    }
    const count = this.activeOrgUsers(item, 'read');
    if (count === 1) {
      return this.$t('libraryUsers.visibilityShort.you');
    }
    return this.$tc('libraryUsers.visibilityShort.count', count - 1, {
      count: count - 1,
    });
  }

  getWritePerms(item: Library) {
    if (item.orgWidePermission === 'write') {
      return this.$t('libraryUsers.visibilityShort.everyone');
    }
    const count = this.activeOrgUsers(item, 'write');
    if (count === 1) {
      return this.$t('libraryUsers.visibilityShort.you');
    }
    return this.$tc('libraryUsers.visibilityShort.count', count - 1, {
      count: count - 1,
    });
  }

  update() {
    (this.$refs.table as AbstractTable).update();
  }
}
