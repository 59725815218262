var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('legislations.list.title'))+" ")]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"}),_c('v-btn',{attrs:{"depressed":"","large":"","color":"secondary","to":{ name: 'legislations-new' }}},[_vm._v(" "+_vm._s(_vm.$t('legislations.new'))+" ")])],1)]),_c('abstract-table',{attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{
          name: 'legislations-edit',
          params: { itemId: item.id },
        },"ripple":false}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(value)+" ")])],1)]}},{key:"item.created",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.effectiveFrom",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatEffectiveDate(value))+" ")]}},{key:"item.isActionGridOnly",fn:function(ref){
        var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.chipColor(value)}},[_vm._v(" "+_vm._s(_vm.chipLabel(value))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }