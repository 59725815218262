




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/api';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import UsersEdit from '@/components/dashboard/users/UsersEdit.vue';
import UserOrganisations from '@/components/dashboard/users/UserOrganisations.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    UsersEdit,
    UserOrganisations,
  },
})
export default class UsersSingle extends Vue {
  item: User | null = null;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get editMode() {
    return this.$route.name === 'users-edit';
  }

  get title() {
    return this.editMode
      ? this.$t('users.single.editTitle')
      : this.$t('users.single.createTitle');
  }

  setRoute() {
    // make sure the route is pointing at the correct id
    // id will change when creating a new one or creating a revision
    // maybe should re-render this component (by setting :key on the router-view)

    if (this.item && this.item.id) {
      this.$router
        .replace({
          name: 'users-edit',
          params: { itemId: this.item.id },
        })
        .catch(() => {
          // Stop duplicate route
        });
    } else {
      this.$router.replace({ name: 'users-new' }).catch(() => {
        // Stop duplicate route
      });
    }
  }

  userUpdated(user: User) {
    this.item = user;
  }

  async prepareItem() {
    if (this.editMode) {
      this.item = (await User.find(this.itemId)).data;
    } else {
      this.item = new User();
    }
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('item')
  itemChanged() {
    this.setRoute();
  }

  @Watch('itemId')
  itemIdChanged() {
    if (this.item && this.item.id !== this.itemId) {
      this.prepareItem();
    }
  }
}
