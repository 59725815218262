var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('updateAll.compare.changeLog', { datetime: _vm.formatDate(_vm.item.created), }))+" ")]),_vm._l((_vm.item.comparison),function(report,legislationCoreId){return _c('collapsible',{key:legislationCoreId,staticClass:"mt-8",attrs:{"title":_vm.legislationName(legislationCoreId),"defaultValue":true}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getOutcomes(report),"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":item.afterId && item.beforeId
              ? {
                  name: 'compare',
                  params: { itemA: item.afterId, itemB: item.beforeId },
                }
              : '',"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"table-item__title"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.referenceCode)+" ")])],1)],1)]}},{key:"item.newRevision",fn:function(ref){
              var item = ref.item;
return [(item.afterId)?_c('v-chip',{staticClass:"ma-1",attrs:{"to":{ name: 'recipes-edit', params: { itemId: item.afterId } },"color":item.afterId !== item.beforeId ? 'primary' : 'grey',"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.afterId)+" ")]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-minus")])]}},{key:"item.oldRevision",fn:function(ref){
              var item = ref.item;
return [(item.beforeId)?_c('v-chip',{staticClass:"ma-1",attrs:{"to":{ name: 'recipes-edit', params: { itemId: item.beforeId } },"color":"grey","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.beforeId)+" ")]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-minus")])]}},{key:"item.published",fn:function(ref){
              var item = ref.item;
return [(item.published)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"green","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('common.labels.published'))+" ")]):_c('v-chip',{staticClass:"ma-1",attrs:{"color":"red","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('common.labels.draft'))+" ")])]}},{key:"item.outcome",fn:function(ref){
              var item = ref.item;
return [(item.outcomeChanged)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"orange","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('common.outcomeChanged'))+" ")]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-minus")])]}},{key:"item.label",fn:function(ref){
              var item = ref.item;
return [(item.labellingChanged)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"red","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('common.labellingChanged'))+" ")]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-minus")])]}},{key:"item.file",fn:function(ref){
              var item = ref.item;
return [(item.beforeId && item.afterId && item.published)?_c('table-action-button',{attrs:{"icon":"mdi-download","tooltip":_vm.$t('common.labels.comparisonReport'),"x-small":""},on:{"click":function($event){return _vm.generateReport(item)}}}):_vm._e()]}}],null,true)})],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }