







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe } from '@/api';
import Auth from '@/store/Auth';

@Component({
  components: {},
})
export default class YieldSummary extends Vue {
  @Prop({ required: true }) readonly recipe: Recipe;

  get isActive() {
    return Auth.isActive;
  }
}
