








































































































































import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { StripeInvoice } from '@/api';
import ConfirmDialog from '@/confirm-dialog';

import snackModule from '@/store/Snack';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import InvoiceDescriptionDialog from '@/components/dashboard/organisations/InvoiceDescriptionDialog.vue';
import InvoiceFilters from '@/components/dashboard/InvoiceFilters.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    InvoiceDescriptionDialog,
    InvoiceFilters,
  },
})
export default class Invoices extends Vue {
  selected: StripeInvoice[] = [];

  invoiceDialog = false;

  activeInvoice: StripeInvoice | null = null;

  openStatuses = [
    { text: this.$t('invoices.status.paid'), value: 'paid' },
    { text: this.$t('invoices.status.uncollectible'), value: 'uncollectible' },
    { text: this.$t('invoices.status.void'), value: 'void' },
  ];

  voidStatus = {
    text: this.$t('invoices.status.markAsStatus', {
      status: this.$t('invoices.status.void'),
    }),
    value: 'void',
  };

  finaliseStatus = {
    label: this.$t('invoices.status.finalise'),
    text: this.$t('invoices.status.open'),
    value: 'open',
  };

  finaliseStatusNoEmail = {
    label: this.$t('invoices.status.finaliseNoEmail'),
    text: this.$t('invoices.status.open'),
    value: 'open',
    sendEmail: false,
  };

  headers = [
    {
      text: this.$t('common.labels.id'),
      value: 'id',
    },
    {
      text: this.$t('common.labels.organisation'),
      value: 'subscription.organisation.name',
    },
    {
      text: this.$t('invoices.labels.subscription'),
      value: 'subscription',
    },
    {
      text: this.$t('invoices.labels.invoice'),
      value: 'hostedInvoiceUrl',
    },
    {
      text: this.$t('invoices.labels.amountDue'),
      value: 'amountDue',
    },
    {
      text: this.$t('common.labels.status'),
      value: 'status',
    },
    {
      text: this.$t('invoices.labels.dueDate'),
      value: 'dueDate',
    },
    {
      value: 'actions',
      width: 64,
    },
  ];

  get scopeFactory() {
    return () =>
      StripeInvoice.includes('subscription.organisation')
        .where(this.whereClause)
        .order({
          created: 'desc',
        });
  }

  get whereClause() {
    return {
      ...this.filters,
    };
  }

  get filters() {
    const filterObj: {
      status?: string;
    } = {};
    Object.entries(this.$route.query).map(item => {
      const [key, value] = item;

      if (value != null) {
        switch (key) {
          case 'status':
            filterObj.status = value as string;
            break;

          default:
            break;
        }
      }
      return item;
    });
    return filterObj;
  }

  get filtersActive() {
    return Object.keys(this.filters).length > 0;
  }

  formatDate(val: string) {
    return val
      ? this.$t('invoices.dateDueFromNowValues', {
          date: dayjs(val).format('MMMM DD, YYYY'),
          fromNow: dayjs(val).fromNow(),
        })
      : '–';
  }

  formatPrice(value: number, includeDecimal = false) {
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value);
  }

  formatSubInterval(value: string) {
    switch (value) {
      case 'month':
        return this.$t('subscriptions.labels.monthly');

      case 'year':
        return this.$t('subscriptions.labels.yearly');

      default:
        return value;
    }
  }

  updateDescription(invoice: StripeInvoice) {
    this.activeInvoice = invoice;
    this.invoiceDialog = true;
  }

  async setStatus(
    invoice: StripeInvoice,
    status: { text: string; value: string },
  ) {
    try {
      const r = await ConfirmDialog({
        description: this.$t('invoices.confirm.setStatusDescription', {
          status: status.text,
        }) as string,
      });
      if (r !== 'confirm') {
        return;
      }
      const tempInvoice = invoice.dup();
      tempInvoice.status = status.value;
      await tempInvoice.save();
      snackModule.setSuccess(this.$t('invoices.alerts.updated') as string);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('invoices.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    }
  }

  async resend(invoice: StripeInvoice) {
    try {
      const r = await ConfirmDialog({
        description: this.$t(
          'invoices.confirm.resendEmailDescription',
        ) as string,
      });
      if (r !== 'confirm') {
        return;
      }
      const tempInvoice = invoice.dup();
      tempInvoice.resendEmail = true;
      await tempInvoice.save();
      snackModule.setSuccess(this.$t('invoices.alerts.resent') as string);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('invoices.alerts.notResent') as string,
        errors: e.response.errors,
      });
    }
  }

  async duplicateInvoice(invoice: StripeInvoice) {
    try {
      const dup = invoice.dup();
      dup.status = 'draft';
      await dup.save();
      snackModule.setSuccess(this.$t('invoices.alerts.duplicated') as string);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('invoices.alerts.notDuplicated') as string,
        errors: e.response.errors,
      });
    }
  }

  update() {
    (this.$refs.table as AbstractTable).update();
  }

  @Watch('whereClause')
  filtersChanged() {
    this.update();
  }
}
