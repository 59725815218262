
































import { Component, Vue, VModel, Prop } from 'vue-property-decorator';
import { Legislation } from '@/api';

import legislationModule from '@/store/Legislation';
import Auth from '@/store/Auth';

@Component({})
export default class LegislationSelect extends Vue {
  @VModel({ required: true }) legislation: Legislation;

  @Prop({ default: false, type: Boolean }) readonly outlined: boolean;

  @Prop({ default: 'transparent' }) readonly backgroundColor: string;

  @Prop({ default: false, type: Boolean }) readonly disabled: boolean;

  @Prop({ default: false, type: Boolean }) readonly currentOnly: boolean;

  @Prop({ default: false, type: Boolean }) readonly includeActionGridOnly: boolean;

  @Prop({ required: false }) readonly rules: [];

  @Prop({ default: false, type: Boolean }) readonly solo: boolean;

  element: HTMLElement | null = null;

  get menuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      maxWidth: this.maxWidth,
      offsetY: true,
      offsetOverflow: true,
      transition: false,
    };
  }

  get maxWidth() {
    return this.element ? this.element.clientWidth : 'auto';
  }

  get filteredLegislations() {
    let items = legislationModule.allCachedLegislations;
    if (this.currentOnly) {
      items = items.filter(legislation => legislation.isCurrent);
    }
    if (!this.includeActionGridOnly) {
      items = items.filter(legislation => !legislation.isActionGridOnly);
    }
    return items.sort((a, b) => a.name.localeCompare(b.name));
  }

  setDefaultLegislation() {
    const ou = Auth.activeOrganisation;
    if (!ou) return;
    const defaultLegislation = legislationModule.getLegislationByCoreId(
      ou.organisation.legislationCoreId,
    );
    if (defaultLegislation) {
      this.legislation = defaultLegislation;
    }
  }

  async mounted() {
    this.$nextTick(() => {
      this.element = this.$el as HTMLElement;
    });
    await legislationModule.getActiveLegislations();
    if (this.legislation && this.legislation.id) {
      await legislationModule.getLegislation(this.legislation.id);
    } else {
      this.setDefaultLegislation();
    }
  }
}
