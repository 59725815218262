




















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class RecipeActions extends Vue {
  options = [
    {
      label: this.$t('common.delete'),
      icon: 'mdi-delete-outline',
      action: 'delete',
    },
    {
      label: this.$t('common.labels.move'),
      icon: 'mdi-format-horizontal-align-right',
      action: 'move',
    },
    {
      label: this.$t('common.labels.addTags'),
      icon: 'mdi-tag-multiple-outline',
      action: 'tag',
    },
  ];

  setAction(action: string) {
    this.$emit('do-action', action);
  }
}
