

















































































































import v8n from 'v8n';
import { debounce } from 'lodash';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { OrganisationUser, Organisation } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({
  components: {
    AbstractTable,
  },
})
export default class OrganisationUsers extends Vue {
  @Prop({ required: true }) readonly item: Organisation;

  search = '';

  searchQuery = '';

  debounceSearch: () => void;

  userDialog = false;

  isNewUser = false;

  user: OrganisationUser | null = null;

  canUpdateRole = true;

  sendWelcome = true;

  loading = false;

  valid = false;

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  get headers() {
    return OrganisationUser.listHeaders();
  }

  get scopeFactory() {
    return () => {
      return OrganisationUser.includes(['user']).where(this.whereClause);
    };
  }

  get whereClause() {
    return {
      organisation: this.item.id,
      search: this.searchQuery,
    };
  }

  get isCurrentUser() {
    if (!authModule.user || !this.user) {
      return false;
    }
    return authModule.user.id === this.user.user.id;
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  newUser() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    this.isNewUser = true;
    this.canUpdateRole = true;
    this.userDialog = true;
    this.user = new OrganisationUser({
      organisation: this.item,
    });
  }

  editUser(user: OrganisationUser) {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    this.isNewUser = false;
    if (
      // 1. you cannot change role of yourself
      // 2. you cannot change role of owner
      // 3. manager can only change role of non-owner
      authModule.activeOrganisation.id !== user.id &&
      (authModule.activeOrganisation.isOwner || !user.isOwner)
    ) {
      this.canUpdateRole = true;
    } else {
      this.canUpdateRole = false;
    }
    this.userDialog = true;
    this.user = user;
  }

  async deleteUser() {
    if (!this.user) {
      throw new Error(this.$t('users.alerts.missing') as string);
    }
    try {
      await this.user.destroy();
      snackModule.setSuccess(this.$t('users.alerts.deleted') as string);
      this.userDialog = false;
      this.table.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notDeleted') as string,
        errors: e.response.errors,
      });
    }
  }

  async saveUser() {
    if (!this.user) {
      throw new Error(this.$t('users.alerts.missing') as string);
    }
    try {
      this.loading = true;
      if (this.isNewUser) {
        this.user.suppressMail = !this.sendWelcome;
      }
      await this.user.save({ with: 'organisation.id' });
      snackModule.setSuccess(this.$t('users.alerts.updated') as string);
      this.userDialog = false;
      this.table.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);
  }

  @Watch('whereClause')
  @Watch('order')
  queryChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }
}
