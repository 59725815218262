




































































































































































import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organisation, OrganisationType } from '@/api';
import { SortDir } from 'spraypaint/lib-esm/scope';
import dayjs from 'dayjs';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import OrganisationFilters from '@/components/dashboard/OrganisationFilters.vue';
import OrganisationSort from '@/components/dashboard/OrganisationSort.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    AlertTooltip,
    OrganisationFilters,
    OrganisationSort,
  },
})
export default class Organisations extends Vue {
  search = '';

  searchQuery = '';

  sortValue: string | null = null;

  sortOrder = 'asc';

  debounceSearch: () => void;

  get headers() {
    return Organisation.listHeaders();
  }

  get order() {
    return this.sortValue
      ? ({ [this.sortValue]: this.sortOrder } as Record<string, SortDir>)
      : { created: 'desc' as SortDir };
  }

  get scopeFactory() {
    return () =>
      Organisation.includes([
        'owner',
        'vitalSubscription',
        'allergenBureauSubscription',
      ])
        .where(this.whereClause)
        .order(this.order);
  }

  get whereClause() {
    return {
      search: this.searchQuery,
      ...this.filters,
    };
  }

  get filters() {
    const filterObj: {
      type?: string;
      subscriptionStatus?: string;
      is_active?: string;
      manual_vitalonline_access?: string;
      training_active?: string;
      has_coupon?: string;
      used_coupon?: string;
      active_vital_price_id?: string;
      active_vital_price_id__isnull?: string;
      active_allergen_bureau_price_id?: string;
      active_allergen_bureau_price_id__isnull?: string;
    } = {};
    Object.entries(this.$route.query).map(item => {
      const [key, value] = item;

      if (value != null) {
        switch (key) {
          case 'type':
            filterObj.type = value as string;
            break;

          case 'status':
            filterObj.subscriptionStatus = value as string;
            break;

          case 'is_active':
            filterObj.is_active = value as string;
            break;

          case 'manual_vitalonline_access':
            filterObj.manual_vitalonline_access = value as string;
            break;

          case 'training':
            filterObj.training_active = value as string;
            break;

          case 'coupon':
            if (value === 'has') {
              filterObj.has_coupon = 'true';
            } else if (value === 'used') {
              filterObj.used_coupon = 'true';
            }
            break;

          case 'vital':
            if (value === 'any') {
              filterObj.active_vital_price_id__isnull = 'false';
            } else if (value === 'none') {
              filterObj.active_vital_price_id__isnull = 'true';
            } else {
              filterObj.active_vital_price_id = value as string;
            }
            break;

          case 'ab':
            if (value === 'any') {
              filterObj.active_allergen_bureau_price_id__isnull = 'false';
            } else if (value === 'none') {
              filterObj.active_allergen_bureau_price_id__isnull = 'true';
            } else {
              filterObj.active_allergen_bureau_price_id = value as string;
            }
            break;

          default:
            break;
        }
      }
      return item;
    });
    return filterObj;
  }

  get filtersActive() {
    return Object.keys(this.filters).length > 0;
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  sortChanged(sort: { value: string; order: string }) {
    this.sortValue = sort.value;
    this.sortOrder = sort.order;
  }

  titleCase(s: string) {
    return s
      .replace('_', ' ') // split snake_case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, (m: string) => {
        return m.toUpperCase();
      });
  }

  orgType(value: number) {
    switch (value) {
      case OrganisationType.Regular:
        return this.$t('organisations.types.regular');

      case OrganisationType.TrainingProvider:
        return this.$t('organisations.types.trainingProvider');

      case OrganisationType.TrainingSession:
        return this.$t('organisations.types.trainingSession');

      default:
        return '';
    }
  }

  statusColor(value: string) {
    switch (value) {
      case 'active':
      case 'training':
        return 'primary';

      case 'canceled':
        return 'error';

      default:
        return 'warning';
    }
  }

  statusIcon(value: string) {
    switch (value) {
      case 'active':
      case 'training':
        return 'mdi-check-circle';

      case 'canceled':
        return 'mdi-alert-circle';

      default:
        return 'mdi-alert-circle';
    }
  }

  trainingOrg(item: Organisation) {
    return item.type === OrganisationType.TrainingSession;
  }

  isTraining(item: Organisation) {
    return item.trainingActive;
  }

  vitalStatusIcon(item: Organisation) {
    if (item.manualVitalonlineAccess === true || item.isActive) {
      return 'mdi-check-circle';
    }
    return 'mdi-close-circle';
  }

  vitalStatusColor(item: Organisation) {
    if (item.manualVitalonlineAccess === true || item.isActive) {
      return 'primary';
    }
    return 'red';
  }

  createdDate(item: Organisation) {
    return this.$t('organisations.labels.created', {
      value: dayjs(item.created).format('DD MMM YYYY'),
    });
  }

  ownedBy(item: Organisation) {
    return this.$t('organisations.labels.ownedBy', {
      value: item.owner.fullName,
    });
  }

  formatTraining(item: Organisation) {
    if (item.type !== OrganisationType.Regular) {
      return '–';
    }
    if (item.trainingActive) {
      return `Ends ${dayjs(item.trainingExpires).format(
        'DD MMM YYYY, h:mm A',
      )}`;
    }
    return 'Training inactive';
  }

  formatDate(val: string) {
    const dt = dayjs(val);
    if (dt.isAfter(dayjs())) {
      return `Expires ${dt.format('DD MMM YYYY, h:mm A')}`;
    }
    return `Expired ${dt.format('DD MMM YYYY, h:mm A')}`;
  }

  formatSubInterval(value: string) {
    switch (value) {
      case 'month':
        return this.$t('subscriptions.labels.monthly');

      case 'year':
        return this.$t('subscriptions.labels.yearly');

      default:
        return value;
    }
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);
  }

  @Watch('whereClause')
  @Watch('order')
  queryChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }
}
