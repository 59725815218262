













































































































































































































































































































































import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  Recipe,
  Ingredient,
  Processing,
  OrganisationType,
  Legislation,
} from '@/api';

import wordpressModule from '@/store/Wordpress';
import authModule from '@/store/Auth';

import AlertTooltip from '@/components/common/AlertTooltip.vue';
import ActionLevelGridDialog from '@/components/common/ActionLevelGridDialog.vue';
import SubscribedOnlyButton from '@/components/common/SubscribedOnlyButton.vue';
import ReportBuilder from '@/reports/ReportBuilder';
import ScenarioTester from '@/scenario-tester';
import Big from 'big.js';

@Component({
  components: { AlertTooltip, ActionLevelGridDialog, SubscribedOnlyButton },
})
export default class Dashboard extends Vue {
  recentRecipes: Recipe[] = [];

  loadingRecipes = false;

  search = '';

  searchQuery = '';

  recipes: Recipe[] = [];

  ingredients: Ingredient[] = [];

  processings: Processing[] = [];

  loading = false;

  actionLevelGridDialog = false;

  debounceSearch: () => void;

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  get isRegular() {
    return this.activeOrg.type === OrganisationType.Regular;
  }

  get isProvider() {
    return this.activeOrg.type === OrganisationType.TrainingProvider;
  }

  get isTraining() {
    return this.activeOrg.type === OrganisationType.TrainingSession;
  }

  get validTraining() {
    if (!this.activeOrg || !this.isTraining) {
      return false;
    }
    return this.activeOrg.trainingActive;
  }

  get isTrainingProvider() {
    if (!this.activeOrg || !this.isTraining) {
      return false;
    }
    const provider = this.activeOrg.trainingProvider;
    return !!authModule.organisationUsers.find(
      user => user.organisation.id === provider.id,
    );
  }

  get wordpressLoading() {
    return wordpressModule.loading;
  }

  get latestPosts() {
    return wordpressModule.posts;
  }

  chipColor(val: string) {
    return val === 'published' ? 'green' : 'pink';
  }

  chipLabel(val: string) {
    return val === 'published'
      ? this.$t('common.labels.published')
      : this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return dayjs(val).format('D MMMM, YYYY');
  }

  formatDateTime(val: string) {
    return dayjs(val).format('D MMMM, YYYY h:mm a');
  }

  formatDateSince(val: string) {
    return dayjs(val).fromNow();
  }

  async generateSampleActionLevelGrid() {
    const scenario = new ScenarioTester(new Legislation(), new Recipe(), {
      useCalculatedOutcomes: false,
      sample: true,
    });
    const { data: legislation } = await Legislation.includes(['allergens'])
      .where({ isCurrent: true })
      .first();
    if (legislation == null) return;
    legislation.sortAllergens();
    new ReportBuilder(scenario, true)
      .actionLevelGrid(legislation, Big(100))
      .open();
  }

  async mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 750);

    this.$nextTick(() => {
      setTimeout(() => {
        const globalSearch = this.$refs.globalSearch as Vue;
        (globalSearch.$refs.input as HTMLElement).focus();
      });
    });

    try {
      this.loadingRecipes = true;
      this.recentRecipes = (
        await Recipe.where({
          is_newest: true,
          is_archived: false,
        })
          .order({ modified: 'desc' })
          .per(8)
          .all()
      ).data;
    } catch (e) {
      // Error
    } finally {
      this.loadingRecipes = false;
    }
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }

  @Watch('searchQuery')
  async queryChanged() {
    this.loading = true;
    try {
      this.recipes = (
        await Recipe.where({
          search: this.searchQuery,
          is_newest: true,
          is_archived: false,
        })
          .order({ modified: 'desc' })
          .per(5)
          .all()
      ).data;
      this.ingredients = (
        await Ingredient.where({
          search: this.searchQuery,
          is_newest: true,
          is_archived: false,
        })
          .order({ modified: 'desc' })
          .per(5)
          .all()
      ).data;
      this.processings = (
        await Processing.where({
          search: this.searchQuery,
          is_newest: true,
          is_archived: false,
        })
          .order({ modified: 'desc' })
          .per(5)
          .all()
      ).data;
    } catch (e) {
      // Error
    } finally {
      this.loading = false;
    }
  }
}
