

































































































































import v8n from 'v8n';
import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';
import { RecipeIngredient } from '@/api';

import snackModule from '@/store/Snack';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import IngredientSearch from '@/components/common/IngredientSearch.vue';
import IngredientAllergenList from '@/components/common/IngredientAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import DragAccordion from '@/components/common/DragAccordion.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    IngredientSearch,
    IngredientAllergenList,
    AlertTooltip,
    DragAccordion,
  },
})
export default class InlineIngredientEdit extends Vue {
  @VModel({ required: true }) ingredientDialog: boolean;

  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly recipeIngredient: RecipeIngredient;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  valid = false;

  allergenValid = 'incomplete';

  isDirty = false;

  loading = false;

  tabIndex = 0;

  get informationAlert() {
    if (!this.valid) {
      return {
        message: this.$t('common.tabs.missingInfo'),
        icon: 'mdi-alert-circle',
        color: 'error',
      };
    }
    if (this.isDirty) {
      return {
        message: this.$t('common.tabs.unsaved'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get allergenAlert() {
    if (this.allergenValid === 'incomplete') {
      return {
        message: this.$t('common.tabs.noAllergens'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get isNewIngredient() {
    return !this.recipeIngredient.ingredient;
  }

  get ingredientName() {
    if (this.recipeIngredient.ingredient) {
      return this.recipeIngredient.ingredient.name;
    }
    if (this.recipeIngredient.ingredientData) {
      return this.recipeIngredient.ingredientData.name;
    }
    throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
  }

  set ingredientName(val: string) {
    if (this.recipeIngredient.ingredient) {
      this.recipeIngredient.ingredient.name = val;
    } else if (this.recipeIngredient.ingredientData) {
      this.recipeIngredient.ingredientData.name = val;
    } else {
      throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
    }
  }

  get ingredientRefCode() {
    if (this.recipeIngredient.ingredient) {
      return this.recipeIngredient.ingredient.referenceCode;
    }
    if (this.recipeIngredient.ingredientData) {
      return this.recipeIngredient.ingredientData.reference_code;
    }
    throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
  }

  set ingredientRefCode(val: string) {
    if (this.recipeIngredient.ingredient) {
      this.recipeIngredient.ingredient.referenceCode = val;
    } else if (this.recipeIngredient.ingredientData) {
      this.recipeIngredient.ingredientData.reference_code = val;
    } else {
      throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
    }
  }

  get ingredientAssumptions() {
    if (this.recipeIngredient.ingredient) {
      return this.recipeIngredient.ingredient.assumptions;
    }
    if (this.recipeIngredient.ingredientData) {
      return this.recipeIngredient.ingredientData.assumptions;
    }
    throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
  }

  set ingredientAssumptions(val: string) {
    if (this.recipeIngredient.ingredient) {
      this.recipeIngredient.ingredient.assumptions = val;
    } else if (this.recipeIngredient.ingredientData) {
      this.recipeIngredient.ingredientData.assumptions = val;
    } else {
      throw new Error(this.$t('recipes.alerts.ingredientEmpty') as string);
    }
  }

  async fetchIngredient() {
    if (!this.recipeIngredient.id) {
      throw new Error(this.$t('recipes.alerts.ingredientMissingId') as string);
    }
    const recipeIngredient = (
      await RecipeIngredient.includes('ingredient').find(
        this.recipeIngredient.id as string,
      )
    ).data;
    this.recipeIngredient.ingredient = recipeIngredient.ingredient;
  }

  async deleteRecipeIngredient() {
    try {
      await this.recipeIngredient.destroy();
      snackModule.setSuccess(this.$t('ingredients.alerts.deleted') as string);
      this.ingredientDialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notDeleted') as string,
        errors: e.response.errors,
      });
    }
  }

  async saveRecipeIngredient() {
    this.loading = true;
    try {
      if (this.recipeIngredient.ingredient) {
        await this.recipeIngredient.ingredient.save();
      }
      await this.recipeIngredient.save({ with: ['recipe.id'] });
      await this.fetchIngredient();
      snackModule.setSuccess(this.$t('ingredients.alerts.saved') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notSaved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.checkIsDirty();
      this.loading = false;
    }
  }

  @Watch('ingredientName')
  @Watch('ingredientRefCode')
  @Watch('ingredientAssumptions')
  checkIsDirty() {
    if (this.recipeIngredient.ingredient) {
      this.isDirty = this.recipeIngredient.ingredient.isDirty();
    } else {
      this.isDirty = this.recipeIngredient.isDirty();
    }
  }

  @Watch('ingredientDialog')
  ingredientDialogChanged() {
    this.tabIndex = 0;
  }
}
