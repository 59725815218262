



















































































import { Component, Vue } from 'vue-property-decorator';
import { Legislation, Allergen } from '@/api';

import snackModule from '@/store/Snack';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import DatetimeSelect from '@/components/common/DatetimeSelect.vue';
import LegislationAllergenList from '@/components/dashboard/legislations/LegislationAllergenList.vue';

@Component({
  components: {
    AbstractTable,
    TabLayout,
    TabLayoutItem,
    DatetimeSelect,
    LegislationAllergenList,
  },
})
export default class LegislationsEdit extends Vue {
  item: Legislation | null = null;

  allergens: Allergen[] = [];

  actionGridOnly = false;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get editMode() {
    return this.$route.name === 'legislations-edit';
  }

  async save() {
    try {
      if (this.item) {
        this.item.actionGridOnly = this.actionGridOnly;
        await this.item.save();
        snackModule.setSuccess(this.$t('legislations.alerts.saved') as string);
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('legislations.alerts.notSaved') as string,
        errors: e.response.errors,
      });
    }
  }

  async mounted() {
    if (this.editMode) {
      this.item = (await Legislation.find(this.$route.params.itemId)).data;
      this.actionGridOnly = this.item.isActionGridOnly;
    } else {
      this.item = new Legislation();
    }
  }
}
