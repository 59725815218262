







































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { Library, Organisation, OrganisationUser } from '@/api';

import authModule from '@/store/Auth';

import LibraryUsersItem from '@/components/dashboard/LibraryUsersItem.vue';

@Component({
  components: { LibraryUsersItem },
})
export default class LibraryUsers extends Vue {
  @PropSync('library', { required: true, type: Library })
  readonly selectedLibrary!: Library;

  @Prop({ type: Boolean, default: false }) loading: boolean;

  organisation: Organisation | null = null;

  organisationUsers: OrganisationUser[] = [];

  permissions: { [key: string]: string } = {};

  get orgRead() {
    return (
      this.selectedLibrary.orgWidePermission === 'read' ||
      this.selectedLibrary.orgWidePermission === 'write'
    );
  }

  set orgRead(value: boolean) {
    const perm = value ? 'read' : 'none';
    this.selectedLibrary.orgWidePermission = perm;
  }

  get orgWrite() {
    return this.selectedLibrary.orgWidePermission === 'write';
  }

  set orgWrite(value: boolean) {
    const perm = value ? 'write' : 'read';
    this.selectedLibrary.orgWidePermission = perm;
  }

  permChanged(orgUser: OrganisationUser, perm: string) {
    if (!orgUser.user.id) {
      throw new Error(this.$t('users.alerts.missing') as string);
    }
    this.permissions[orgUser.user.id] = perm;
  }

  save() {
    this.selectedLibrary.libraryUserChanges = this.permissions;
    this.$emit('save');
  }

  async mounted() {
    if (!authModule.activeOrganisationId) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    this.organisation = (
      await Organisation.find(authModule.activeOrganisationId)
    ).data;
    this.organisationUsers = (
      await OrganisationUser.where({
        organisation: this.organisation.id,
      })
        .includes('user')
        .all()
    ).data;
  }
}
