var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cost-explorer",class:{ 'cost-explorer--readonly': _vm.readOnly }},[_vm._m(0),_c('div',{staticClass:"cost-explorer__grid",on:{"mouseleave":function($event){return _vm.setPlan(-1)}}},[_c('div',{staticClass:"cost-explorer__plan-select cost-explorer__plan-select--a",class:{ 'cost-explorer__plan-select--hover': _vm.hoverPlan === 0 }}),_c('div',{staticClass:"cost-explorer__plan-select cost-explorer__plan-select--b",class:{ 'cost-explorer__plan-select--hover': _vm.hoverPlan === 1 }}),_c('div',{staticClass:"cost-explorer__plan-select cost-explorer__plan-select--c",class:{
        'cost-explorer__plan-select--hover': _vm.hoverPlan > 1,
      }}),_c('div',{staticClass:"cost-explorer__plan-select cost-explorer__plan-select--d",class:{
        'cost-explorer__plan-select--hover': _vm.hoverPlan > 1,
      }}),_c('div',{staticClass:"cost-explorer__plan-flex"},[_c('div',{staticClass:"cost-explorer__plan-spacer"}),_c('div',{staticClass:"cost-explorer__plan-info",on:{"mouseenter":function($event){return _vm.setPlan(0)}}},[_c('v-icon',{attrs:{"color":_vm.hoverPlan === 0 ? 'white' : 'grey lighten-1'}},[_vm._v(" "+_vm._s(_vm.hoverPlan === 0 ? 'mdi-check-circle' : 'mdi-radiobox-blank')+" ")])],1),_c('div',{staticClass:"cost-explorer__plan-info",on:{"mouseenter":function($event){return _vm.setPlan(1)}}},[_c('v-icon',{attrs:{"color":_vm.hoverPlan === 1 ? 'white' : 'grey lighten-1'}},[_vm._v(" "+_vm._s(_vm.hoverPlan === 1 ? 'mdi-check-circle' : 'mdi-radiobox-blank')+" ")])],1),_c('div',{staticClass:"cost-explorer__plan-info",on:{"mouseenter":function($event){return _vm.setPlan(2)}}},[_c('v-icon',{attrs:{"color":_vm.hoverPlan > 1 ? 'white' : 'grey lighten-1'}},[_vm._v(" "+_vm._s(_vm.hoverPlan > 1 ? 'mdi-check-circle' : 'mdi-radiobox-blank')+" ")])],1),_c('div',{staticClass:"cost-explorer__plan-info",on:{"mouseenter":function($event){return _vm.setPlan(3)}}},[_c('v-icon',{attrs:{"color":_vm.hoverPlan > 1 ? 'white' : 'grey lighten-1'}},[_vm._v(" "+_vm._s(_vm.hoverPlan > 1 ? 'mdi-check-circle' : 'mdi-radiobox-blank')+" ")])],1)]),_c('div',{staticClass:"cost-explorer__plan-flex"},[_c('div',{staticClass:"cost-explorer__plan-spacer"}),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 0 },on:{"mouseenter":function($event){return _vm.setPlan(0)}}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('costExplorer.regular'))+" ")])]),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 1 },on:{"mouseenter":function($event){return _vm.setPlan(1)}}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('costExplorer.regular'))+" ")])]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(2)}}},[_c('h4',[_c('a',{class:{ 'cost-explorer__plan-link': _vm.hoverPlan > 1 },attrs:{"href":"https://www.canva.com/design/DAF8RNIdP3Y/qs0k8ymU45gpwCl1GSbbLA/view?utm_content=DAF8RNIdP3Y&utm_campaign=designshare&utm_medium=link&utm_source=editor","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('costExplorer.members'))+" ")])])]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(3)}}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('costExplorer.abMembers'))+" ")])])]),_c('div',{staticClass:"cost-explorer__plan-flex"},[_c('div',{staticClass:"cost-explorer__plan-spacer"}),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 0 },on:{"mouseenter":function($event){return _vm.setPlan(0)}}},[_c('div',{staticClass:"text--small",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.monthInterval'))}})]),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 1 },on:{"mouseenter":function($event){return _vm.setPlan(1)}}},[_c('div',{staticClass:"text--small"},[_vm._v(" "+_vm._s(_vm.$t('costExplorer.yearInterval'))+" ")])]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(2)}}},[_c('div',{staticClass:"text--small"},[_vm._v(" "+_vm._s(_vm.$t('costExplorer.yearInterval'))+" ")])]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(3)}}},[_c('div',{staticClass:"text--small"},[_vm._v(" "+_vm._s(_vm.$t('costExplorer.yearInterval'))+" ")]),_c('div',{staticClass:"text--small"},[_vm._v(" "+_vm._s(_vm.$t('costExplorer.prorataDate'))+" ")])])]),_vm._l((_vm.validProductBands),function(band,i){return _c('div',{key:i,staticClass:"cost-explorer__band"},[_c('div',{staticClass:"cost-explorer__band-title",on:{"mouseenter":function($event){return _vm.setPlan(-1)}}},[_c('div',{staticClass:"cost-explorer__band-title-inner"},[_c('v-icon',{staticClass:"cost-explorer__band-icon",attrs:{"color":"grey lighten-1","left":""}},[_vm._v(" mdi-radiobox-blank ")]),_c('v-icon',{staticClass:"cost-explorer__band-icon-checked",attrs:{"color":"primary","left":""}},[_vm._v(" mdi-check-circle ")]),_c('div',{domProps:{"innerHTML":_vm._s(band.name)}})],1)]),_c('div',{staticClass:"cost-explorer__band-item",class:{ 'cost-explorer__band-item--hover': _vm.hoverPlan === 0 },on:{"mouseenter":function($event){return _vm.setPlan(0)},"click":function($event){return _vm.choosePlan(band, 0)}}},[_c('div',{staticClass:"cost-explorer__band-item-inner"},[_c('span',{staticClass:"text--large"},[_vm._v(" "+_vm._s(band.monthly.regular.price)+" ")])])]),_c('div',{staticClass:"cost-explorer__band-item",class:{ 'cost-explorer__band-item--hover': _vm.hoverPlan === 1 },on:{"mouseenter":function($event){return _vm.setPlan(1)},"click":function($event){return _vm.choosePlan(band, 1)}}},[_c('div',{staticClass:"cost-explorer__band-item-inner"},[_c('span',{staticClass:"text--large"},[_vm._v(" "+_vm._s(band.yearly.regular.price)+" ")])])]),_c('div',{staticClass:"cost-explorer__band-item",class:{
          'cost-explorer__band-item--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(2)},"click":function($event){return _vm.choosePlan(band, 2)}}},[_c('div',{staticClass:"cost-explorer__band-item-inner"},[_c('span',{staticClass:"text--large"},[_vm._v(" "+_vm._s(band.yearly.member.price)+" ")])])]),_c('div',{staticClass:"cost-explorer__band-item-plus text--large",class:{ 'cost-explorer__band-item-plus--hover': _vm.hoverPlan > 1 }},[_c('span',[_vm._v("+")])]),_c('div',{staticClass:"cost-explorer__band-item",class:{
          'cost-explorer__band-item--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(3)},"click":function($event){return _vm.choosePlan(band, 3)}}},[_c('div',{staticClass:"cost-explorer__band-item-inner"},[_c('span',{staticClass:"text--large"},[_vm._v(" "+_vm._s(band.yearly.allergenBureau.price)+" ")])])])])}),_vm._l((_vm.dummyProductBands),function(band,j){return _c('div',{key:("dummy-" + j),staticClass:"cost-explorer__band"},[_c('div',{staticClass:"cost-explorer__band-title"},[_c('div',{staticClass:"cost-explorer__band-title-inner"},[_c('v-icon',{staticClass:"cost-explorer__band-icon",attrs:{"color":"grey lighten-1","left":""}},[_vm._v(" mdi-radiobox-blank ")]),_c('v-icon',{staticClass:"cost-explorer__band-icon-checked",attrs:{"color":"primary","left":""}},[_vm._v(" mdi-check-circle ")]),_c('div',{domProps:{"innerHTML":_vm._s(band.name)}})],1)]),_vm._l((4),function(x){return _c('div',{key:x,staticClass:"cost-explorer__band-item dummy-item"},[_vm._m(1,true)])}),_c('div',{staticClass:"contact",on:{"mouseenter":function($event){return _vm.setPlan(-1)},"click":function($event){return _vm.handleDummyitemClick(band)}}},[_vm._v(" Contact us ")])],2)}),_c('div',{staticClass:"cost-explorer__plan-flex"},[_c('div',{staticClass:"cost-explorer__plan-spacer"}),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 0 },on:{"mouseenter":function($event){return _vm.setPlan(0)}}},[_c('div',{staticClass:"text--small",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.regularDescription'))}})]),_c('div',{staticClass:"cost-explorer__plan-info",class:{ 'cost-explorer__plan-info--hover': _vm.hoverPlan === 1 },on:{"mouseenter":function($event){return _vm.setPlan(1)}}},[_c('div',{staticClass:"text--small",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.regularDescription'))}})]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(2)}}},[_c('div',{staticClass:"text--small",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.membersDescription'))}})]),_c('div',{staticClass:"cost-explorer__plan-info",class:{
          'cost-explorer__plan-info--hover': _vm.hoverPlan > 1,
        },on:{"mouseenter":function($event){return _vm.setPlan(3)}}},[_c('div',{staticClass:"text--small",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.abMembersDescription'))}})])])],2),_c('div',{staticClass:"cost-explorer__conditions",domProps:{"innerHTML":_vm._s(_vm.$t('costExplorer.conditions'))}}),(_vm.contactDialog)?_c('contact-dialog',{model:{value:(_vm.contactDialog),callback:function ($$v) {_vm.contactDialog=$$v},expression:"contactDialog"}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cost-explorer__heading text-right"},[_c('div',{staticClass:"cost-explorer__heading-divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cost-explorer__band-item-inner"},[_c('span',{staticClass:"text--large"},[_vm._v("-")])])}]

export { render, staticRenderFns }