







































import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import { OrganisationType } from '@/api';

@Component({})
export default class AlertBanner extends Vue {
  dismiss = false;

  get activeOrgUser() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation;
  }

  get trainingSession() {
    return this.activeOrgUser.organisation;
  }

  get ownerEmail() {
    return this.trainingSession.owner.email;
  }

  get isOwner() {
    return this.activeOrgUser.isOwner;
  }

  get isOwnerOfAny() {
    return authModule.isOwnerOfAnything;
  }

  get isTraining() {
    return (
      this.trainingSession &&
      this.trainingSession.type === OrganisationType.TrainingSession
    );
  }

  get isProvider() {
    if (!this.trainingSession || !this.isTraining) {
      return false;
    }
    const provider = this.trainingSession.trainingProvider;
    return !!authModule.organisationUsers.find(
      user => user.organisation.id === provider.id,
    );
  }

  get trialDate() {
    return this.trainingSession ? this.trainingSession.trainingExpires : '';
  }

  get startsIn() {
    return dayjs(this.trainingSession.trainingBegins).diff(dayjs(), 'day');
  }

  get endsIn() {
    return dayjs(this.trainingSession.trainingExpires).diff(dayjs(), 'day');
  }

  get alertTitle() {
    if (this.trainingSession.trainingActive) {
      return this.$tc('alertBanner.trainingDaysLeft', this.endsIn, {
        days: this.endsIn,
      });
    }
    if (this.startsIn >= 0) {
      return this.$t('trainingSessions.outdated.earlyMessage', {
        fromNow: dayjs(this.trainingSession.trainingBegins).fromNow(),
      });
    }
    return this.$t('trainingSessions.outdated.expiredTitle');
  }

  get alertDescription() {
    // Training active now or training coming soon
    if (this.trainingSession.trainingActive || this.startsIn >= 0) {
      if (this.isProvider) {
        return `${this.$t('alertBanner.isProvider')} ${this.$t(
          'alertBanner.isTraining',
        )}`;
      }
      return this.$t('alertBanner.isTraining');
    }
    // Training expired
    if (this.isOwnerOfAny) {
      return this.$t('trainingSessions.outdated.expiredMessageOrg');
    }
    return this.$t('trainingSessions.outdated.expiredMessageNoOrg');
  }
}
