

























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { Recipe, Ingredient, Processing } from '@/api';

import snackModule from '@/store/Snack';

@Component({ components: {} })
export default class Restore extends Vue {
  @PropSync('items', { required: true }) selectedItems!: (
    | Recipe
    | Ingredient
    | Processing
  )[];

  @Prop({ required: true }) readonly context: string;

  loading = false;

  get item() {
    const [item] = this.selectedItems;
    return item;
  }

  cancel() {
    this.$emit('cancel');
  }

  async restore() {
    try {
      this.loading = true;
      this.item.restore = true;
      await this.item.save();
      this.$emit('update');
      this.cancel();
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.restoreError') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
