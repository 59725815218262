import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class StripeSession extends ApplicationRecord {
  static jsonapiType = 'stripe-sessions';

  static scopeFactory() {
    return StripeSession.includes([]);
  }

  // post these things and use the returning id to call the stripe api

  @Attr() priceId: string;

  @Attr() organisationId: string;

  @Attr() payByInvoice: boolean; // input only

  @Attr() readonly sessionId: string;
}
