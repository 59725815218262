















































































































































































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Organisation, OrganisationUser, User, Legislation } from '@/api';

import snackModule from '@/store/Snack';

import AvatarUpload from '@/components/common/AvatarUpload.vue';
import VitalAccessSelect from '@/components/common/VitalAccessSelect.vue';
import CountrySelect from '@/components/common/CountrySelect.vue';
import RelAutocompleteAsync from '@/components/common/RelAutocompleteAsync.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';

@Component({
  components: {
    AvatarUpload,
    CountrySelect,
    LegislationSelect,
    VitalAccessSelect,
    RelAutocompleteAsync,
  },
})
export default class OrganisationsEdit extends Vue {
  @Prop({ required: true }) readonly item: Organisation;

  organisation = new Organisation(); // tmp value

  owner: User | OrganisationUser | null = null;

  legislation: Legislation | null = null;

  ownerLoading = true;

  valid = false;

  loading = false;

  typeItems = [
    { text: 'Regular', value: 0 },
    { text: 'Training Provider', value: 1 },
    { text: 'Training Session', value: 2, disabled: true },
  ];

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  requiredObject = [
    (v: User) => {
      if (!v) {
        return this.$t('common.validation.generalRequired');
      }

      return (
        v8n()
          .string()
          .not.empty()
          .test(v.id) || this.$t('common.validation.generalRequired')
      );
    },
  ];

  get editMode() {
    return this.$route.name === 'organisations-edit';
  }

  get userScopeFactory() {
    return () => {
      return this.editMode
        ? OrganisationUser.includes(['user']).where({
            organisation: this.organisation.id,
          })
        : User.includes([]);
    };
  }

  async save() {
    try {
      this.loading = true;
      if (!this.editMode && this.owner) {
        this.organisation.owner = this.owner as User;
      } else if (
        this.editMode &&
        this.owner &&
        this.organisation.owner.id !== (this.owner as OrganisationUser).user.id
      ) {
        this.organisation.owner = (this.owner as OrganisationUser).user;
      }
      if (this.legislation && this.legislation.id) {
        this.organisation.defaultLegislation = this.legislation.coreId;
      }
      await this.organisation.save({ with: 'owner.id' });
      this.$emit('updated', this.organisation);
      snackModule.setSuccess(this.$t('organisations.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('organisations.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.organisation = this.item.dup();
    if (this.organisation.owner) {
      const owners = (
        await OrganisationUser.includes('user')
          .where({
            user: this.organisation.owner.id,
            organisation: this.organisation.id,
          })
          .all()
      ).data;
      if (owners) {
        [this.owner] = owners;
        this.ownerLoading = false;
      }
    }
  }
}
