












import { Component, Vue, VModel, Prop } from 'vue-property-decorator';

@Component({})
export default class VitalAccessSelect extends Vue {
  @VModel({ required: true }) access: boolean | null;

  @Prop({ required: false }) readonly rules: [];

  get items() {
    return [
      {
        name: this.$t('organisations.access.options.automatic'),
        value: null,
      },
      {
        name: this.$t('organisations.access.options.manualTrue'),
        value: true,
      },
      {
        name: this.$t('organisations.access.options.manualFalse'),
        value: false,
      },
    ];
  }
}
