













import { Component, Vue, VModel, Prop, PropSync } from 'vue-property-decorator';
import { ApplicationRecord } from '@/api';

import BulkDelete from '@/components/dashboard/BulkDelete.vue';
import BulkAddTags from '@/components/dashboard/BulkAddTags.vue';
import BulkMove from '@/components/dashboard/BulkMove.vue';
import Duplicate from '@/components/dashboard/Duplicate.vue';
import Restore from '@/components/dashboard/Restore.vue';

@Component({})
export default class ActionsDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  @PropSync('items', { required: true }) selectedItems!: ApplicationRecord[];

  @Prop({ default: null }) readonly activeAction: string | null;

  @Prop({ required: true }) readonly context: string;

  get actionComponent() {
    switch (this.activeAction) {
      case 'delete':
        return BulkDelete;

      case 'tag':
        return BulkAddTags;

      case 'move':
        return BulkMove;

      case 'duplicate':
        return Duplicate;

      case 'restore':
        return Restore;

      default:
        return null;
    }
  }

  update() {
    this.$emit('update');
  }
}
