















































































import { Component, Vue, VModel, Watch } from 'vue-property-decorator';
import { Processing } from '@/api';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import ProcessingInformation from '@/components/dashboard/processings/ProcessingInformation.vue';
import ProcessingSourcesEdit from '@/components/dashboard/ProcessingSourcesEdit.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import ProcessingRevisionHistory from '@/components/dashboard/processings/ProcessingRevisionHistory.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    ProcessingInformation,
    ProcessingSourcesEdit,
    AlertTooltip,
    ProcessingRevisionHistory,
  },
})
export default class ProcessingProfilesEdit extends Vue {
  @VModel({ required: true }) processing: Processing;

  tabNumber = 0;

  processingInfoValid = 'invalid';

  processingSourcesValid = 'incomplete';

  get editMode() {
    return this.$route.name === 'processing-profiles-edit';
  }

  get processingValid() {
    return this.processingInfoValid === 'complete';
  }

  get infoAlertIcon() {
    switch (this.processingInfoValid) {
      case 'unsaved':
      case 'invalid':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get infoAlertColor() {
    switch (this.processingInfoValid) {
      case 'unsaved':
        return 'orange';

      case 'invalid':
        return 'error';

      default:
        return 'primary';
    }
  }

  get infoAlertMessage() {
    switch (this.processingInfoValid) {
      case 'unsaved':
        return this.$t('common.tabs.unsaved');

      case 'invalid':
        return this.$t('common.tabs.missingInfo');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  get sourcesAlertIcon() {
    switch (this.processingSourcesValid) {
      case 'incomplete':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get sourcesAlertColor() {
    switch (this.processingSourcesValid) {
      case 'incomplete':
        return 'orange';

      default:
        return 'primary';
    }
  }

  get sourcesAlertMessage() {
    switch (this.processingSourcesValid) {
      case 'incomplete':
        return this.$t('processings.tabs.noSources');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  processingUpdated(processing: Processing) {
    this.processing = processing;
  }

  setTab(index: number) {
    this.tabNumber = index;
  }

  @Watch('processingValid', { immediate: true })
  validChanged() {
    this.$emit('valid', this.processingValid);
  }
}
