




















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class IngredientActions extends Vue {
  options = [
    {
      label: this.$t('common.delete'),
      icon: 'mdi-delete-outline',
      action: 'delete',
    },
  ];

  setAction(action: string) {
    this.$emit('do-action', action);
  }
}
