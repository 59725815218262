













import { Component, Vue, VModel } from 'vue-property-decorator';

@Component({})
export default class TabLayout extends Vue {
  @VModel({ default: null }) tabNum: null | number;

  internalIndex = 0;

  get tabIndex() {
    // If the v-model isn't set use the internal index
    return this.tabNum === null ? this.internalIndex : this.tabNum;
  }

  set tabIndex(index: number) {
    this.tabNum = index;
    this.internalIndex = index;
  }
}
