










































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import ScenarioTester, { roundify } from '@/scenario-tester';
import { Allergen, ProcessingSource } from '@/api';

import Big from 'big.js';

@Component({
  components: {},
})
export default class ConcentrationChart extends Vue {
  @Prop({ required: true }) readonly scenario: ScenarioTester;

  @Prop({ required: true }) readonly allergen: Allergen;

  @Prop({ required: true }) readonly source: ProcessingSource;

  transitionPointInfo: TransitionPointInformation | null = null;

  roundify = roundify;

  get crosscontact() {
    return this.source.crosscontacts.find(
      item => item.allergen.id === this.allergen.id,
    );
  }

  get waterGain() {
    return this.transitionPointInfo
      ? this.transitionPointInfo.concentrationBreakdown.waterGain
      : Big('100');
  }

  get totalConcentration() {
    if (!this.transitionPointInfo) {
      return Big('0');
    }

    const sourceConcentration = ScenarioTester.calculateSourceConcentration(
      this.transitionPointInfo.concentrationBreakdown.sources,
      this.allergen,
      this.transitionPointInfo.concentrationBreakdown.waterGain,
    );
    return this.transitionPointInfo.ingredientConcentration.plus(
      sourceConcentration,
    );
  }

  get threshold() {
    if (!this.transitionPointInfo) {
      return '0';
    }
    return this.transitionPointInfo.concentrationBreakdown.threshold;
  }

  get concentrationBefore() {
    if (!this.transitionPointInfo) {
      return Big('0');
    }
    return this.transitionPointInfo.priorConcentration;
  }

  get concentrationAfter() {
    const ppm = this.source.crosscontactConcentration(
      this.allergen.id as string,
      this.waterGain,
    );
    return this.concentrationBefore.plus(ppm);
  }

  get transitionHangup() {
    if (!this.crosscontact || !this.transitionPointInfo) {
      return Big('0');
    }
    return Big(
      this.crosscontact.quantityResidualFromDesiredPPM(
        this.transitionPointInfo.transitionConcentration,
      ),
    ).times(this.waterGain.div('100'));
  }

  get transitionBatchSize() {
    if (!this.crosscontact || !this.transitionPointInfo) {
      return Big('0');
    }
    return this.crosscontact.amountExposedFromDesiredPPM(
      this.transitionPointInfo.transitionConcentration.times(
        this.waterGain.div('100'),
      ),
    );
  }

  get max() {
    if (!this.transitionPointInfo) {
      return 0;
    }
    return Math.max(
      Number(this.totalConcentration),
      Number(this.threshold) * 2,
    );
  }

  calcThresholdPos(ppm: string) {
    try {
      return `left:${Big(ppm)
        .times('100')
        .div(this.max)
        .toFixed(6)}%`;
    } catch (e) {
      return '';
    }
  }

  calcWidth(ppm: string) {
    try {
      return `width:${Big(ppm)
        .times('100')
        .div(this.max)
        .toFixed(6)}%`;
    } catch (e) {
      return '';
    }
  }

  async mounted() {
    this.transitionPointInfo = this.scenario.getTransitionPointInformation(
      this.source,
      this.allergen,
    );
  }
}
