




























































































































































































































































import { Component, Vue, VModel } from 'vue-property-decorator';
import { Legislation, Recipe } from '@/api';

import legislationModule from '@/store/Legislation';

import Collapsible from '@/components/common/Collapsible.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import SubscribedOnlyButton from '@/components/common/SubscribedOnlyButton.vue';

import RecipeRevisionHistory from '@/components/dashboard/recipes/RecipeRevisionHistory.vue';
import RecipeCompare from '@/components/dashboard/recipes/RecipeCompare.vue';

import ScenarioTesterSummary from '@/components/common/scenario-tester/ScenarioTesterSummary.vue';

import RecipeSummary from '@/components/common/tables/RecipeSummary.vue';
import YieldSummary from '@/components/common/tables/YieldSummary.vue';
import AssessmentSummary from '@/components/common/tables/AssessmentSummary.vue';
import RecipeBreakdownSummary from '@/components/common/tables/RecipeBreakdownSummary.vue';
import RecipeLegislationSummary from '@/components/common/tables/RecipeLegislationSummary.vue';
import RecipeIngredientsSummary from '@/components/common/tables/RecipeIngredientsSummary.vue';
import ConstituentAllergenSummary from '@/components/common/tables/ConstituentAllergenSummary.vue';
import ProcessingAssumptionsSummary from '@/components/common/tables/ProcessingAssumptionsSummary.vue';

import ScenarioOverview from '@/components/common/scenario-tester/ScenarioOverview.vue';

import ScenarioTester from '@/scenario-tester';
import ReportBuilder from '@/reports/ReportBuilder';

import Auth from '@/store/Auth';
import SubscribedOnlyWarning from '@/components/common/SubscribedOnlyWarning.vue';

@Component({
  components: {
    Collapsible,
    TabLayout,
    TabLayoutItem,
    RecipeRevisionHistory,
    RecipeCompare,
    RecipeSummary,
    AssessmentSummary,
    YieldSummary,
    RecipeBreakdownSummary,
    RecipeLegislationSummary,
    RecipeIngredientsSummary,
    ConstituentAllergenSummary,
    ProcessingAssumptionsSummary,
    ScenarioOverview,
    ScenarioTesterSummary,
    SubscribedOnlyButton,
    SubscribedOnlyWarning,
  },
})
export default class RecipesOverview extends Vue {
  @VModel({ required: true }) item: Recipe;

  legislation = new Legislation();

  recipe = new Recipe();

  rows: ScenarioRow[] = [];

  constituentRows: ConstituentRow[] = [];

  ready = false;

  error = false;

  get isDraft() {
    return this.recipe && this.recipe.status === 'draft';
  }

  generateShortReport(sample = false) {
    const scenario = new ScenarioTester(this.legislation, this.recipe, {
      useCalculatedOutcomes: true,
      sample,
    });
    const builder = new ReportBuilder(scenario, sample);
    builder
      .fullReportIntroduction()
      .assessmentSummary()
      .pageBreak()
      .fullSummary()
      .pageBreak()
      .fullReportFooter()
      .open();
  }

  generateFullReport(sample = false) {
    const scenario = new ScenarioTester(this.legislation, this.recipe, {
      useCalculatedOutcomes: true,
      sample,
    });
    const builder = new ReportBuilder(scenario, sample);
    builder
      .fullReportIntroduction()
      .assessmentSummary()
      .pageBreak()
      .fullSummary()
      .pageBreak()
      .referenceDoseSummary()
      .pageBreak()
      .rawMaterialsSummary()
      .pageBreak()
      .ingredientAllergenSummary(false)
      .pageBreak()
      .ingredientAllergenSummary(true)
      .pageBreak()
      .processingAssumptionsSummary()
      .pageBreak()
      .processingAllergenSummary(false)
      .pageBreak()
      .processingAllergenSummary(true)
      .pageBreak()
      .allAllergensSummary()
      .pageBreak()
      .yieldSummmary()
      .pageBreak()
      .fullReportFooter()
      .open();
  }

  viewExampleReport(report: 'short' | 'long'): string {
    const filePath = `${process.env.BASE_URL}pdfs/`;
    const filename =
      report === 'short'
        ? 'short-report-free-trialv0.4.pdf'
        : 'long-report-free-trialv0.4.pdf';
    return filePath + filename;
  }

  get isActive() {
    return Auth.isActive;
  }

  async mounted() {
    try {
      this.legislation = await legislationModule.getLegislation(
        this.item.legislation.id as string,
      );

      this.recipe = (
        await Recipe.includes([
          'recipeIngredients.ingredient.crosscontacts',
          'recipeProcessings.processing.processingSources.crosscontacts',
        ]).find(this.item.id as string)
      ).data;

      const scenario = new ScenarioTester(this.legislation, this.recipe, {
        useCalculatedOutcomes: true,
        sample: !this.isActive,
      });
      this.rows = scenario.getRows();
      this.constituentRows = scenario.getConstituentRows();
      this.ready = true;
    } catch (e) {
      console.log(e);
      this.error = true;
    }
  }
}
