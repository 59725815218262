




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe } from '@/api';

@Component({})
export default class SingleIngredientActions extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  @Prop({ default: false }) readonly archiveMode: boolean;

  get options() {
    let optionList = [
      {
        label: this.$t('common.delete'),
        icon: 'mdi-delete-outline',
        action: 'delete',
      },
      {
        label: this.$t('common.labels.duplicate'),
        icon: 'mdi-content-duplicate',
        action: 'duplicate',
      },
    ];
    if (this.archiveMode) {
      optionList = [
        {
          label: this.$t('common.restore'),
          icon: 'mdi-delete-restore',
          action: 'restore',
        },
      ];
    }
    return optionList;
  }

  setAction(action: string) {
    this.$emit('do-action', action);
  }
}
