






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import legislationModule from '@/store/Legislation';

import { Allergen } from '@/api';
import ScenarioTester from '@/scenario-tester';

@Component({
  components: {},
})
export default class RecipeTotalsSummary extends Vue {
  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly outcomes: Outcomes;

  rows: Array<{ allergen: Allergen; outcome: Outcome }> = [];

  @Watch('outcomes', { immediate: true })
  async outcomesChanged() {
    const legislation = await legislationModule.getLegislation(
      this.legislationId,
    );

    this.rows = ScenarioTester.flattenAllergens(legislation.sortedAllergens)
      .map(allergen => ({
        allergen,
        outcome: this.outcomes[allergen.coreId],
      }))
      .filter(row => !!row.outcome);
  }
}
