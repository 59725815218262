import { Model, Attr } from 'spraypaint';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class BulkDelete extends ApplicationRecord {
  static jsonapiType = 'bulk-deletes';

  static scopeFactory() {
    return BulkDelete.includes([]);
  }

  @Attr() resourceName: string;

  @Attr() ids: string[];

  @Attr() successes: string[];

  @Attr() failures: Array<{ itemId: string; errorCode: string }>;

  errorMessage(code: string) {
    switch (code) {
      case 'no-item':
        return i18n.t('bulkDelete.error.noItem');

      case 'no-perms':
        return i18n.t('bulkDelete.error.noPerms');

      case 'in-use':
        return i18n.t('bulkDelete.error.inUse');

      default:
        return i18n.t('bulkDelete.error.unknown');
    }
  }
}
