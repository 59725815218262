
































































































































import v8n from 'v8n';
import { Component, Vue } from 'vue-property-decorator';

import { User, Totp } from '@/api';
import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import AvatarUpload from '@/components/common/AvatarUpload.vue';
import ChangePasswordDialog from '@/components/auth/ChangePasswordDialog.vue';

import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    AvatarUpload,
    ChangePasswordDialog,
  },
})
export default class UserDetails extends Vue {
  valid = false;

  loading = false;

  pwDialog = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  get user() {
    return authModule.user as User;
  }

  async resetTotp() {
    const r = await confirmDialog({
      description: this.$t('common.twoFactor.userResetDescription') as string,
    });
    if (r !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      const totp = new Totp({ user: this.user, setTotp: false });
      await totp.save({ with: 'user.id' });
      await authModule.refreshUser();
      snackModule.setSuccess(this.$t('users.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async save() {
    if (!this.user) {
      throw new Error(this.$t('users.alerts.missing') as string);
    }
    try {
      this.loading = true;
      await this.user.save();
      snackModule.setSuccess(this.$t('users.alerts.detailsUpdated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.detailsNotUpdated') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
