






























































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Ingredient, Legislation } from '@/api';

import snackModule from '@/store/Snack';

import LegislationSelect from '@/components/common/LegislationSelect.vue';

@Component({
  components: {
    LegislationSelect,
  },
})
export default class IngredientInformation extends Vue {
  @Prop({ required: true }) readonly item: Ingredient;

  ingredient = new Ingredient(); // tmp value

  ingredientOriginal = new Ingredient();

  valid = false;

  isDirty = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('ingredients.validation.requiredPublish'),
  ];

  requiredObject = [
    (v: Legislation) => {
      if (!v) {
        return this.$t('common.validation.generalRequired');
      }

      return (
        v8n()
          .string()
          .not.empty()
          .test(v.id) || this.$t('common.validation.generalRequired')
      );
    },
  ];

  get editMode() {
    return this.$route.name === 'ingredients-edit';
  }

  get validation() {
    let validation;
    if (this.isDirty) {
      validation = 'unsaved';
    } else if (!this.valid) {
      validation = 'invalid';
    } else {
      validation = 'complete';
    }
    return validation;
  }

  get formFields() {
    return [
      this.ingredient.legislation ? this.ingredient.legislation.id : null,
      this.ingredient.name,
      this.ingredient.referenceCode,
      this.ingredient.assumptions,
    ];
  }

  cancel() {
    this.ingredient = this.ingredientOriginal.dup();
  }

  async save() {
    try {
      this.loading = true;
      await this.ingredient.save({
        with: ['legislation.id'],
      });
      this.isDirty = false;
      this.$emit('updated', this.ingredient);
      snackModule.setSuccess(this.$t('ingredients.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.ingredient = this.item.dup();
    this.ingredientOriginal = this.item.dup();
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }

  @Watch('formFields')
  dirtyChanged() {
    this.isDirty = this.ingredient.isDirty('legislation');
  }
}
