import { Model, Attr, HasOne } from 'spraypaint';
import { Organisation } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

export enum ChargeType {
  'Free' = 'free',
  'Prorated' = 'prorated',
  'Full' = 'full',
}

@Model()
export default class StripeSubscription extends ApplicationRecord {
  static jsonapiType = 'stripe-subscriptions';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
      },
      {
        text: i18n.t('common.labels.name'),
        value: 'metadata.membership_name',
      },
      {
        text: i18n.t('subscriptions.labels.currentStart'),
        value: 'currentPeriodStart',
      },
      {
        text: i18n.t('subscriptions.labels.currentEnd'),
        value: 'currentPeriodEnd',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
      },
    ];
  }

  static scopeFactory() {
    return StripeSubscription.includes([]);
  }

  @Attr() readonly customerId: string; // stripe version of org id

  @Attr() readonly status: string;

  @Attr() renew: boolean;

  @Attr() readonly currentPeriodStart: string;

  @Attr() readonly currentPeriodEnd: string;

  @Attr() readonly metadata: {
    fte_max: number;
    fte_min: number;
    price_id: string;
    price_iid: string;
    coupon_iid: string;
    price_value: number;
    coupon_value: number;
    price_currency: string;
    price_interval: string;
    coupon_currency: string;
    coupon_interval: string;
    membership_name: string;
    membership_type: string;
    requires_membership: string;
  };

  @HasOne() organisation: Organisation;

  // On patch only
  @Attr() payByInvoice: boolean;

  // On create only fields

  @Attr() chargeType: ChargeType;

  @Attr() priceId: string;

  @Attr() nextInvoiceDatetime: string;
}
