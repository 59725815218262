



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Processing, ProcessingSource } from '@/api';
import DragAccordion from '@/components/common/DragAccordion.vue';

import snackModule from '@/store/Snack';
import InlineSourceEdit from '@/components/dashboard/processings/InlineSourceEdit.vue';
import ProcessingAllergenList from '@/components/common/ProcessingAllergenList.vue';
import OutcomesSummary from '@/components/common/tables/OutcomesSummary.vue';

@Component({
  components: {
    DragAccordion,
    ProcessingAllergenList,
    InlineSourceEdit,
    OutcomesSummary,
  },
})
export default class ProcessingSourcesEdit extends Vue {
  @Prop({ required: true }) readonly processing: Processing;

  processingSources: ProcessingSource[] = [];

  processingSource: ProcessingSource | null = null;

  sourceDialog = false;

  loading = true;

  get editMode() {
    return this.$route.name === 'processing-profiles-edit';
  }

  get validation() {
    let validation;
    if (!this.processingSources.length) {
      validation = 'incomplete';
    } else {
      validation = 'complete';
    }
    return validation;
  }

  addNewSource() {
    this.processingSource = new ProcessingSource({
      processing: this.processing,
    });
    this.sourceDialog = true;
  }

  editProcessingSource(processingSource: ProcessingSource) {
    this.processingSource = processingSource.dup();
    this.sourceDialog = true;
  }

  async moveItem(
    element: ProcessingSource,
    parentId: string | null,
    newIndex: number,
  ) {
    try {
      element.order = newIndex;
      await element.save();
      snackModule.setSuccess(this.$t('common.alerts.itemMoved') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.itemNotMoved') as string,
        errors: [],
      });
    } finally {
      this.getProcessingSources();
    }
  }

  async getProcessingSources() {
    this.loading = true;
    try {
      const processingSources = (
        await ProcessingSource.where({
          processing: this.processing.id,
        })
          .order('order')
          .all()
      ).data;
      this.processingSources = processingSources;
      this.processingSource = null;
    } catch (e) {
      snackModule.setError({
        text: this.$t('processingSources.alerts.getError') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('sourceDialog')
  sourceDialogChanged() {
    if (!this.sourceDialog) {
      this.getProcessingSources();
    }
  }

  @Watch('processing.id', { immediate: true })
  processingChanged() {
    this.getProcessingSources();
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }
}
