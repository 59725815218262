













































































import { Component, Vue } from 'vue-property-decorator';
import { Library } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import ColorSelect from '@/components/common/ColorSelect.vue';
import LibraryUsers from '@/components/dashboard/LibraryUsers.vue';

@Component({
  components: {
    AbstractTable,
    TabLayout,
    TabLayoutItem,
    ColorSelect,
    LibraryUsers,
  },
})
export default class LibrariesEdit extends Vue {
  item: Library | null = null;

  loading = false;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get editMode() {
    return this.$route.name === 'libraries-edit';
  }

  get title() {
    return this.editMode
      ? this.$t('libraries.single.editTitle')
      : this.$t('libraries.single.createTitle');
  }

  setRoute() {
    if (this.item && this.item.id) {
      if (this.$route.params.itemId !== this.item.id) {
        this.$router.replace({
          name: 'libraries-edit',
          params: { itemId: this.item.id },
        });
      }
    } else {
      this.$router.replace({ name: 'libraries-new' });
    }
  }

  setTitle() {
    if (this.item) {
      document.title = `${this.item.name} - VITAL Online`;
      authModule.trackPageview({
        gtag: this.$gtag,
        route: this.$route,
        pageTitle: this.item.name,
      });
    }
  }

  async save() {
    if (!this.item) {
      throw new Error(this.$t('libraries.alerts.missing') as string);
    }
    try {
      this.loading = true;
      await this.item.save({ with: 'organisation.id' });
      snackModule.setSuccess(this.$t('libraries.alerts.saved') as string);
      this.setRoute();
      this.setTitle();
    } catch (e) {
      snackModule.setError({
        text: this.$t('libraries.alerts.notSaved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    if (this.editMode) {
      this.item = (
        await Library.includes('libraryUsers').find(this.$route.params.itemId)
      ).data;
      this.setTitle();
    } else {
      this.item = new Library({
        organisation: authModule.activeOrganisation,
        orgWidePermission: 'write',
        color: '',
      });
    }
  }
}
