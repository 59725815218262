




























































import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ContactForm } from '@/api';
import { SortDir } from 'spraypaint/lib-esm/scope';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import ContactSort from '@/components/dashboard/ContactSort.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    ContactSort,
  },
})
export default class ContactEntries extends Vue {
  search = '';

  searchQuery = '';

  sortValue: string | null = null;

  sortOrder = 'asc';

  debounceSearch: () => void;

  get headers() {
    return ContactForm.listHeaders();
  }

  get scopeFactory() {
    return () =>
      ContactForm.includes('createdBy')
        .where(this.whereClause)
        .order(this.order);
  }

  get whereClause() {
    return {
      search: this.searchQuery,
    };
  }

  get order() {
    return this.sortValue
      ? ({ [this.sortValue]: this.sortOrder } as Record<string, SortDir>)
      : { created: 'desc' as SortDir };
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  sortChanged(sort: { value: string; order: string }) {
    this.sortValue = sort.value;
    this.sortOrder = sort.order;
  }

  formatDate(val: string) {
    return dayjs(val).format('MMM D, YYYY h:mm a');
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);
  }

  @Watch('whereClause')
  @Watch('order')
  queryChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }
}
