
















































































































































import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SortDir } from 'spraypaint/lib-esm/scope';
import { Processing } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import ProcessingFilters from '@/components/dashboard/ProcessingFilters.vue';
import IngredientSort from '@/components/dashboard/IngredientSort.vue';
import IngredientActions from '@/components/dashboard/IngredientActions.vue';
import ActionsDialog from '@/components/dashboard/ActionsDialog.vue';
import SingleProcessingActions from '@/components/dashboard/SingleProcessingActions.vue';
import ActionBanner from '@/components/dashboard/ActionBanner.vue';

import dayjs from 'dayjs';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    AlertTooltip,
    ProcessingFilters,
    IngredientSort,
    IngredientActions,
    ActionsDialog,
    SingleProcessingActions,
    ActionBanner,
  },
})
export default class ProcessingProfiles extends Vue {
  search = '';

  searchQuery = '';

  debounceSearch: () => void;

  sortValue: string | null = null;

  sortOrder = 'asc';

  selected: Processing[] = [];

  actionsDialog = false;

  activeAction: string | null = null;

  get duplicateCCFilter() {
    return this.$route.query['duplicate-cc'] === 'true';
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get headers() {
    return Processing.listHeaders();
  }

  get archiveMode() {
    return this.$route.query.archived === 'true';
  }

  get scopeFactory() {
    return () => {
      return this.order
        ? Processing.includes([])
            .order(this.order)
            .where(this.whereClause)
        : Processing.includes([]).where(this.whereClause);
    };
  }

  get whereClause() {
    return {
      is_newest: true,
      shared: true,
      is_archived: this.archiveMode,
      search: this.searchQuery,
      ...this.filters,
    };
  }

  get order() {
    return this.sortValue
      ? ({ [this.sortValue]: this.sortOrder } as Record<string, SortDir>)
      : { modified: 'desc' as SortDir };
  }

  get filters() {
    const filterObj: {
      status?: string;
      outdated_legislation?: string;
      legislation__core?: string;
      duplicated_pcc?: string;
    } = {};
    Object.entries(this.$route.query).map(item => {
      const [key, value] = item;

      if (value != null) {
        switch (key) {
          case 'status':
            filterObj.status = value as string;
            break;

          case 'outdated':
            filterObj.outdated_legislation = value as string;
            break;

          case 'legislation':
            filterObj.legislation__core = value as string;
            break;

          case 'duplicate-cc':
            filterObj.duplicated_pcc = value as string;
            break;

          default:
            break;
        }
      }
      return item;
    });
    return filterObj;
  }

  get filtersActive() {
    return Object.keys(this.filters).length > 0;
  }

  formatDate(val: string) {
    return dayjs(val).fromNow();
  }

  chipColor(val: string) {
    return val === 'published' ? 'green' : 'pink';
  }

  chipLabel(val: string) {
    return val === 'published'
      ? this.$t('common.labels.published')
      : this.$t('common.labels.draft');
  }

  sortChanged(sort: { value: string; order: string }) {
    this.sortValue = sort.value;
    this.sortOrder = sort.order;
  }

  doAction(action: string) {
    this.activeAction = action;
    this.actionsDialog = true;
  }

  doSingleAction(action: string, item: Processing) {
    this.selected = [item];
    this.activeAction = action;
    this.actionsDialog = true;
  }

  update() {
    this.table.update();
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);

    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search as string;
      this.search = this.$route.query.search as string;
    }
  }

  @Watch('whereClause')
  @Watch('order')
  filtersChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    // Remove search query string on search change
    if (this.$route.query.search && this.search !== this.searchQuery) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: undefined,
        },
      });
    }

    this.debounceSearch();
  }
}
