


































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe } from '@/api';
import Auth from '@/store/Auth';

@Component({
  components: {},
})
export default class ConstituentAllergenSummary extends Vue {
  @Prop({ required: true }) readonly recipe: Recipe;

  @Prop({ required: true }) readonly constituentRows: ConstituentRow[];

  @Prop({ required: true }) readonly applyYield: boolean;

  @Prop({ required: true }) readonly showIngredients: boolean;

  get items() {
    return this.showIngredients
      ? this.recipe.recipeIngredients.map(
          recipeIngredient => recipeIngredient.ingredient.name,
        )
      : this.recipe.recipeProcessings.map(
          recipeProcessing => recipeProcessing.processing.name,
        );
  }

  get isActive() {
    return Auth.isActive;
  }
}
