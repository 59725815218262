

















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { Processing, User } from '@/api';

import dayjs from 'dayjs';

@Component({
  components: {},
})
export default class ProcessingRevisionHistory extends Vue {
  @Prop({ required: true }) readonly processingId: string;

  @Prop({ required: true }) readonly processingCoreId: string;

  revisions: Processing[] = [];

  formatDate(val: string) {
    return val
      ? dayjs(val).format('DD MMMM YYYY, h:mm:ss a')
      : this.$t('common.labels.unknownDate');
  }

  formatRelativeDate(val: string) {
    return val ? dayjs(val).fromNow() : '';
  }

  formatName(user: User | null) {
    return user ? user.fullName : this.$t('common.labels.unknownUser');
  }

  gotoRevision(revision: Processing) {
    if (this.processingId !== revision.id) {
      this.$router.push({
        name: 'processing-profiles-edit',
        params: { itemId: revision.id as string },
      });
    }
  }

  async mounted() {
    this.revisions = (
      await Processing.where({ coreId: this.processingCoreId })
        .includes(['createdBy', 'modifiedBy', 'publishedBy'])
        .order({ modified: 'desc' })
        .all()
    ).data;
  }
}
