














































































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Totp, User } from '@/api';

import snackModule from '@/store/Snack';

import AvatarUpload from '@/components/common/AvatarUpload.vue';
import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    AvatarUpload,
  },
})
export default class UsersEdit extends Vue {
  @Prop({ required: true }) readonly item: User;

  user = new User(); // tmp value

  valid = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  get editMode() {
    return this.$route.name === 'users-edit';
  }

  async resetTotp() {
    const r = await confirmDialog();
    if (r !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      const totp = new Totp({ user: this.user, setTotp: false });
      await totp.save({ with: 'user.id' });
      const user = (await User.find(this.user.id as string)).data;
      this.$emit('updated', user);
      snackModule.setSuccess(this.$t('users.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async save() {
    try {
      this.loading = true;
      await this.user.save();
      this.$emit('updated', this.user);
      snackModule.setSuccess(this.$t('users.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.user = this.item.dup();
  }
}
