import { Model, Attr, HasOne } from 'spraypaint';
import { Organisation } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class StripeBillingSession extends ApplicationRecord {
  static jsonapiType = 'stripe-billing-sessions';

  static scopeFactory() {
    return StripeBillingSession.includes([]);
  }

  @Attr() readonly url: string;

  // post to me to get the url for managing billing info
  @HasOne() organisation: Organisation;
}
