
























































import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';

import {
  Allergen,
  RecipeIngredient,
  DispersionType,
  DispersionTypeItems,
} from '@/api';

import ScenarioTester from '@/scenario-tester';

@Component({
  components: {},
})
export default class ScenarioTesterRecipeIngredientEdit extends Vue {
  @VModel() dialog: boolean;

  @Prop({ required: true }) readonly scenario: ScenarioTester;

  @Prop() readonly recipeIngredient: RecipeIngredient | null;

  @Prop() readonly allergen: Allergen | null;

  get DispersionType() {
    return DispersionType;
  }

  get dispersionTypeItems() {
    return DispersionTypeItems;
  }

  get crosscontact() {
    if (this.recipeIngredient && this.allergen) {
      return this.recipeIngredient.ingredient.crosscontacts.find(
        crosscontact =>
          !!this.allergen && crosscontact.allergen.id === this.allergen.id,
      );
    }
    return null;
  }

  revert() {
    if (this.crosscontact) {
      this.crosscontact.rollback();
    }
    if (this.recipeIngredient) {
      this.recipeIngredient.rollback();
    }
  }

  @Watch('dialog')
  dialogChanged() {
    if (this.recipeIngredient && this.crosscontact) {
      Vue.set(
        this.scenario.dirtyRecipeIngredients,
        `${this.recipeIngredient.id as string}`,
        this.recipeIngredient.isDirty(),
      );

      Vue.set(
        this.scenario.dirtyIngredients,
        `${this.recipeIngredient.id as string}:${
          this.crosscontact.allergen.id
        }`,
        this.crosscontact.isDirty(),
      );
    }
  }
}
