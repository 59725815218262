import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import {
  User,
  Organisation,
  Library,
  Legislation,
  RecipeTag,
  RecipeIngredient,
  RecipeProcessing,
  DispersionType,
  ProcessingDispersionType,
  Ingredient,
} from '@/api';
import i18n from '@/plugins/vue-i18n';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Recipe extends ApplicationRecord {
  static jsonapiType = 'recipes';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('recipes.labels.usedAsIngredient'),
        value: 'shared',
        width: 150,
        align: 'center',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
        width: 130,
        align: 'center',
      },
      {
        text: i18n.t('common.labels.modified'),
        value: 'modified',
        width: 180,
      },
      {
        value: 'actions',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return Recipe.includes([]);
  }

  @Attr() name: string;

  @Attr() created: string;

  @Attr() modified: string;

  @Attr() published: string;

  @HasOne() createdBy: User;

  @HasOne() modifiedBy: User;

  @HasOne() publishedBy: User;

  @Attr() coreId: string;

  // write only - set this and coreId to duplicate
  @Attr() asNew: boolean;

  @Attr() status: string;

  @Attr() waterGain: string;

  @Attr() waterGainAssumptions: string;

  @Attr() referenceCode: string;

  @Attr() referenceAmount: string;

  // whether or not reference amount is required and used
  // true is not required and not used
  // still need to complete this!
  @Attr() isComponent: boolean;

  @Attr() referenceAmountAssumptions: string;

  @Attr() outdated: boolean;

  @Attr() outdatedLegislation: boolean;

  @Attr() outdatedIngredients: number;

  @Attr() outdatedProcessings: number;

  @Attr() updateRelationships: boolean;

  @Attr() noProcessings: boolean;

  @Attr() noProcessingsAssumptions: string;

  @Attr() shared: boolean;

  @Attr() isLatest: boolean;

  @Attr() isNewest: boolean;

  @Attr() restore: boolean;

  @Attr() readonly isArchived: boolean;

  @Attr() revisionNotes: string;

  @Attr() readonly calculationVersion: number;

  @Attr() readonly recipesUsingCount: number;

  @Attr({ dirtyChecker: () => false }) readonly outcome: {
    ingredients: Outcomes;
    processings: Outcomes;
    total: Outcomes;
  };

  @Attr({ dirtyChecker: () => false })
  readonly labellingOutcome: LabellingOutcomes;

  @HasOne() frozenIngredient: Ingredient;

  @HasOne() organisation: Organisation;

  @HasOne() legislation: Legislation; // set on create. cant change

  @HasOne() library: Library | null;

  @HasMany() recipeTags: RecipeTag[];

  @HasMany() recipeIngredients: RecipeIngredient[];

  @HasMany() recipeProcessings: RecipeProcessing[];

  get totalPercentage() {
    if (!this.recipeIngredients) {
      return Big('0');
    }
    return this.recipeIngredients.reduce((a, b) => {
      return a.plus(b.percentage);
    }, Big('0'));
  }

  get sortedRecipeTags() {
    return [...this.recipeTags].sort((a, b) =>
      a.tag.name.localeCompare(b.tag.name),
    );
  }

  areRecipeIngredientsOfDispersionType(
    allergenId: string,
    dispersionType: DispersionType,
  ) {
    return this.recipeIngredients.some(
      recipeIngredient =>
        recipeIngredient.ingredient.crosscontactDispersionType(allergenId) ===
        dispersionType,
    );
  }

  areRecipeProcessingsOfDispersionType(
    allergenId: string,
    dispersionType: ProcessingDispersionType,
  ) {
    return this.recipeProcessings.some(recipeProcessing =>
      recipeProcessing.processing.areProcessingSourcesOfDispersionType(
        allergenId,
        dispersionType,
      ),
    );
  }

  recipeIngredientsDispersionType(allergenId: string, returnLabel = true) {
    // Intentional
    if (
      this.areRecipeIngredientsOfDispersionType(
        allergenId,
        DispersionType.Intentional,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.intentional')
        : DispersionType.Intentional;
    }
    // Particulate
    if (
      this.areRecipeIngredientsOfDispersionType(
        allergenId,
        DispersionType.Particulate,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.particulate')
        : DispersionType.Particulate;
    }
    // Dispersible
    if (
      this.areRecipeIngredientsOfDispersionType(
        allergenId,
        DispersionType.Dispersible,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.dispersible')
        : DispersionType.Dispersible;
    }
    return returnLabel ? 'N/A' : null;
  }

  recipeIngredientsCrosscontactConcentration(allergenId: string) {
    return this.recipeIngredients.reduce(
      (sum, recipeIngredient) =>
        sum.plus(
          Big(
            recipeIngredient.ingredient.crosscontactConcentration(allergenId),
          ).times(Big(recipeIngredient.percentage).div('100')),
        ),
      Big('0'),
    );
  }

  recipeProcessingsDispersionType(allergenId: string, returnLabel = true) {
    // Particulate
    if (
      this.areRecipeProcessingsOfDispersionType(
        allergenId,
        ProcessingDispersionType.Particulate,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.particulate')
        : ProcessingDispersionType.Particulate;
    }
    // Dispersible
    if (
      this.areRecipeProcessingsOfDispersionType(
        allergenId,
        ProcessingDispersionType.Dispersible,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.dispersible')
        : ProcessingDispersionType.Dispersible;
    }
    return returnLabel ? 'N/A' : null;
  }

  recipeProcessingsCrosscontactConcentration(allergenId: string) {
    return this.recipeProcessings.reduce(
      (sum, recipeProcessing) =>
        sum.plus(
          recipeProcessing.processing.processingSourcesCrosscontactConcentration(
            allergenId,
          ),
        ),
      Big('0'),
    );
  }
}
