










import { Component, Vue } from 'vue-property-decorator';

import FilterItem from '@/components/dashboard/FilterItem.vue';

@Component({ components: { FilterItem } })
export default class InvoiceFilters extends Vue {
  statusItems = [
    { label: this.$t('invoices.status.draft'), value: 'draft' },
    { label: this.$t('invoices.status.open'), value: 'open' },
    { label: this.$t('invoices.status.paid'), value: 'paid' },
    { label: this.$t('invoices.status.void'), value: 'void' },
    { label: this.$t('invoices.status.uncollectible'), value: 'uncollectible' },
  ];
}
