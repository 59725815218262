var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('libraries.list.title'))+" ")]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"},[_c('table-action-button',{attrs:{"tooltip":_vm.$t('libraries.labels.moveRecipes'),"icon":"mdi-format-horizontal-align-right","disabled":_vm.selected.length !== 1},on:{"click":function($event){$event.stopPropagation();_vm.moveDialog = true}}})],1),_c('v-btn',{attrs:{"depressed":"","large":"","color":"secondary","to":{ name: 'libraries-new' }}},[_vm._v(" "+_vm._s(_vm.$t('libraries.new'))+" ")])],1)]),_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{ name: 'libraries-edit', params: { itemId: item.id } },"ripple":false}},[_c('v-list-item-avatar',{attrs:{"size":"32","color":item.color || 'grey'}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-text-box-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(value)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$tc('libraries.recipeCount', item.recipeCoreCount, { count: item.recipeCoreCount, }))+" ")])],1)],1)]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadPerms(item))+" ")]}},{key:"item.write",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getWritePerms(item))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"max-width":"420"},model:{value:(_vm.moveDialog),callback:function ($$v) {_vm.moveDialog=$$v},expression:"moveDialog"}},[(_vm.moveDialog)?_c('v-card',[_c('bulk-move',{attrs:{"sourceLibrary":_vm.singleSelected},on:{"cancel":function($event){_vm.moveDialog = false},"update":_vm.update}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }