











































import { Component, Vue } from 'vue-property-decorator';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import ComparisonReports from '@/components/dashboard/reports/ComparisonReports.vue';
import RecipeReports from '@/components/dashboard/reports/RecipeReports.vue';
import IngredientReports from '@/components/dashboard/reports/IngredientReports.vue';

import authModule from '@/store/Auth';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    ComparisonReports,
    RecipeReports,
    IngredientReports,
  },
})
export default class Reports extends Vue {
  tabNumber = 0;

  get canAccessComparisons() {
    return authModule.isManager;
  }

  get canAccessRecipeReports() {
    return authModule.isManager;
  }

  get canAccessIngredientReports() {
    return true;
  }

  mounted() {
    if (this.canAccessComparisons) {
      this.tabNumber = 0;
      return;
    }
    if (this.canAccessRecipeReports) {
      this.tabNumber = 1;
      return;
    }
    if (this.canAccessIngredientReports) {
      this.tabNumber = 2;
    }
  }
}
