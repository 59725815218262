






















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ScenarioTesterRecipeIngredient from '@/components/common/scenario-tester/ScenarioTesterRecipeIngredient.vue';
import ScenarioTesterProcessingSource from '@/components/common/scenario-tester/ScenarioTesterProcessingSource.vue';
import ScenarioTesterRecipeIngredientEdit from '@/components/common/scenario-tester/ScenarioTesterRecipeIngredientEdit.vue';
import ScenarioTesterProcessingSourceEdit from '@/components/common/scenario-tester/ScenarioTesterProcessingSourceEdit.vue';
import ConcentrationChart from '@/components/common/scenario-tester/ConcentrationChart.vue';

import ScenarioTester from '@/scenario-tester';
import Big from 'big.js';

import { Allergen, ProcessingSource, RecipeIngredient } from '@/api';

@Component({
  components: {
    ScenarioTesterRecipeIngredient,
    ScenarioTesterProcessingSource,
    ScenarioTesterRecipeIngredientEdit,
    ScenarioTesterProcessingSourceEdit,
    ConcentrationChart,
  },
})
export default class ScenarioTesterTable extends Vue {
  @Prop({ required: true }) readonly scenario: ScenarioTester;

  activeProcessingSource: ProcessingSource | null = null;

  activeRecipeIngredient: RecipeIngredient | null = null;

  activeAllergen: Allergen | null = null;

  ingredientDialog = false;

  processingDialog = false;

  rows: ScenarioRow[] = [];

  get totalPercentage() {
    return this.scenario && this.scenario.recipe
      ? this.scenario.recipe.totalPercentage
      : Big('0');
  }

  editRecipeIngredient(allergen: Allergen, recipeIngredient: RecipeIngredient) {
    this.activeRecipeIngredient = recipeIngredient;
    this.activeAllergen = allergen;
    this.ingredientDialog = true;
  }

  editProcessingSource(allergen: Allergen, source: ProcessingSource) {
    this.activeProcessingSource = source;
    this.activeAllergen = allergen;
    this.processingDialog = true;
  }

  mounted() {
    this.rows = this.scenario.getRows();
  }
}
