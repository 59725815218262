















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { Legislation, Recipe, User } from '@/api';

import legislationModule from '@/store/Legislation';
import ScenarioTester from '@/scenario-tester';
import ReportBuilder from '@/reports/ReportBuilder';

import dayjs from 'dayjs';

import Collapsible from '@/components/common/Collapsible.vue';
import SortedAllergenSummary from '@/components/common/scenario-tester/SortedAllergenSummary.vue';
import ScenarioTesterSummary from '@/components/common/scenario-tester/ScenarioTesterSummary.vue';

@Component({
  components: {
    Collapsible,
    SortedAllergenSummary,
    ScenarioTesterSummary,
  },
})
export default class RecipeCompare extends Vue {
  @Prop({ required: true }) readonly recipeId: string;

  @Prop({ required: true }) readonly recipeCoreId: string;

  revisions: Recipe[] = [];

  revisionA: { id: string; title: string; revision: Recipe } | null = null;

  revisionB: { id: string; title: string; revision: Recipe } | null = null;

  legislationA: Legislation | null = null;

  legislationB: Legislation | null = null;

  rows: ScenarioRow[] = [];

  newRows: ScenarioRow[] = [];

  get allRevisions() {
    return this.revisions.map(revision => ({
      id: revision.id as string,
      title: `Revision ${revision.id} – ${this.formatName(
        revision.publishedBy,
      )} – ${this.formatDate(revision.published)} (${revision.status})`,
      revision,
    }));
  }

  get earlierRevisions() {
    const revision = this.revisionA;
    if (revision) {
      return this.allRevisions.filter(
        r =>
          dayjs(r.revision.published).diff(dayjs(revision.revision.published)) <
          0,
      );
    }
    return this.allRevisions;
  }

  get both() {
    return [this.revisionA, this.revisionB];
  }

  get bothSelected() {
    return this.revisionA && this.revisionB;
  }

  get showLabellingOutcome() {
    return (
      this.revisionA &&
      this.revisionB &&
      !(
        this.revisionA.revision.isComponent &&
        this.revisionB.revision.isComponent
      )
    );
  }

  get allergenRows() {
    if (this.legislationA && this.legislationB) {
      return {
        rows: ScenarioTester.flattenAllergens(
          this.legislationB.sortedAllergens,
        ),
        newRows: ScenarioTester.flattenAllergens(
          this.legislationA.sortedAllergens,
        ),
      };
    }
    return null;
  }

  get legislationRevChanged() {
    return (
      this.legislationA &&
      this.legislationB &&
      this.legislationA.id !== this.legislationB.id
    );
  }

  formatDate(val: string) {
    return val
      ? dayjs(val).format('DD MMMM YYYY, h:mm:ss a')
      : this.$t('common.labels.unknownDate');
  }

  formatRelativeDate(val: string) {
    return val ? dayjs(val).fromNow() : '';
  }

  formatName(user: User | null) {
    return user ? user.fullName : this.$t('common.labels.unknownUser');
  }

  gotoRevision(revision: Recipe) {
    this.$router.push({
      name: 'recipes-edit',
      params: { itemId: revision.id as string },
    });
  }

  async generateReport() {
    if (!this.revisionA || !this.revisionB) {
      this.rows = [];
      this.newRows = [];
      return;
    }
    this.legislationA = await legislationModule.getLegislation(
      this.revisionA.revision.legislation.id as string,
    );
    this.legislationB = await legislationModule.getLegislation(
      this.revisionB.revision.legislation.id as string,
    );
    const builder = new ReportBuilder(
      new ScenarioTester(this.legislationA, this.revisionA.revision, {
        useCalculatedOutcomes: true,
      }),
    );
    builder
      .comparisonSummary(
        new ScenarioTester(this.legislationB, this.revisionB.revision, {
          useCalculatedOutcomes: true,
        }),
        true,
      )
      .open();
  }

  async getComparison() {
    if (!this.revisionA || !this.revisionB) {
      this.rows = [];
      this.newRows = [];
      return;
    }
    this.legislationA = await legislationModule.getLegislation(
      this.revisionA.revision.legislation.id as string,
    );
    this.legislationB = await legislationModule.getLegislation(
      this.revisionB.revision.legislation.id as string,
    );

    this.rows = new ScenarioTester(this.legislationB, this.revisionB.revision, {
      useCalculatedOutcomes: true,
    }).getRows();

    this.newRows = new ScenarioTester(
      this.legislationA,
      this.revisionA.revision,
      {
        useCalculatedOutcomes: true,
      },
    ).getRows();
  }

  async mounted() {
    this.revisions = (
      await Recipe.where({ coreId: this.recipeCoreId })
        .includes([
          'createdBy',
          'modifiedBy',
          'publishedBy',
          'recipeIngredients.ingredient.crosscontacts',
          'recipeProcessings.processing.processingSources.crosscontacts',
        ])
        .order({ modified: 'desc' })
        .all()
    ).data;
    this.revisionA = this.allRevisions[0] || null;
    this.revisionB = this.allRevisions[1] || null;
  }

  @Watch('revisionA')
  revisionAChanged() {
    if (this.revisionB && !this.earlierRevisions.includes(this.revisionB)) {
      this.revisionB = null;
    }
  }

  @Watch('both')
  bothChanged() {
    this.getComparison();
  }
}
