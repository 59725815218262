







































import { Component, Vue } from 'vue-property-decorator';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import OrganisationReports from '@/components/dashboard/admin-reports/OrganisationReports.vue';
import InvoiceReports from '@/components/dashboard/admin-reports/InvoiceReports.vue';
import UserReports from '@/components/dashboard/admin-reports/UserReports.vue';
import ComparisonReports from '@/components/dashboard/reports/ComparisonReports.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    OrganisationReports,
    InvoiceReports,
    UserReports,
    ComparisonReports,
  },
})
export default class AdminReports extends Vue {
  tabNumber = 0;
}
