







































































import dayjs from 'dayjs';
import { Component, Vue, VModel } from 'vue-property-decorator';
import { Organisation, OrgwideUpdate, UpdateStatus } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

@Component({})
export default class UpdatingDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  updateComplete = false;

  get isOwnerUpdate() {
    return authModule.ownerUpdate;
  }

  get ownerUpdateitem() {
    return authModule.updateItem;
  }

  formatDate(val: string) {
    return val ? dayjs(val).format('DD/MM/YYYY hh:mm:ss a') : '-';
  }

  formatStatus(val: number) {
    switch (val) {
      case 0:
        return this.$t('updateAll.status.pending');
      case 1:
        return this.$t('updateAll.status.started');
      case 3:
        return this.$t('updateAll.status.completed');
      case 4:
        return this.$t('updateAll.status.failed');

      default:
        return '';
    }
  }

  close() {
    authModule.setUpdatingDialog(false);
    authModule.bumpDashboardRouteKey();
    authModule.setOwnerUpdate(false);
    authModule.setUpdateItem(null);
  }

  viewReports() {
    this.close();
    this.$router.push({ name: 'reports' });
  }

  async checkUpdating() {
    if (!authModule.activeOrganisationId) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    try {
      if (
        this.isOwnerUpdate &&
        this.ownerUpdateitem &&
        this.ownerUpdateitem.id
      ) {
        const org = (await OrgwideUpdate.find(this.ownerUpdateitem.id)).data;
        authModule.setUpdateItem(org);

        if (
          org.status === UpdateStatus.Started ||
          org.status === UpdateStatus.Pending
        ) {
          setTimeout(this.checkUpdating, 15000);
        } else {
          this.updateComplete = true;
        }
      } else {
        const org = (await Organisation.find(authModule.activeOrganisationId))
          .data;

        if (org.isUpdating) {
          setTimeout(this.checkUpdating, 15000);
        } else {
          this.updateComplete = true;
          this.close();
        }
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('organisations.alerts.getError') as string,
        errors: e.response.errors,
      });
      setTimeout(this.checkUpdating, 15000);
    }
  }

  mounted() {
    setTimeout(this.checkUpdating, 15000);
  }
}
