





















import { Component, Vue, Prop } from 'vue-property-decorator';

import { ProcessingSource } from '@/api';

@Component({
  components: {},
})
export default class ScenarioTesterProcessingSource extends Vue {
  @Prop({ required: true }) readonly allergenId: string;

  @Prop({ required: true }) readonly source: ProcessingSource;

  @Prop({ default: false }) readonly dirty: boolean;

  get crosscontact() {
    return this.source.crosscontacts.find(
      crosscontact => crosscontact.allergen.id === this.allergenId,
    );
  }
}
