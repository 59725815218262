import { Model, Attr, HasOne } from 'spraypaint';
import { Recipe, Ingredient } from '@/api';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class RecipeIngredient extends ApplicationRecord {
  static jsonapiType = 'recipe-ingredients';

  static scopeFactory() {
    return RecipeIngredient.includes([]);
  }

  // write to "ingredientData" if you wanna
  // create a recipe-specific ingredient
  // leave ingredient blank when you do this
  // get the recipe back with an include

  @Attr() ingredientData: {
    name: string;
    reference_code: string;
    assumptions: string;
  } | null;

  @Attr() percentage: string;

  @HasOne() recipe: Recipe;

  @HasOne() ingredient: Ingredient;

  crosscontactPPM(allergenId: string, waterGain = Big('100')) {
    return this.ingredient
      .crosscontactConcentration(allergenId)
      .times(Big(this.percentage).div('100'))
      .div(waterGain.div('100'));
  }
}
