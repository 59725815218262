var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('contactForm.list.title'))+" ")]),_c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"search-bar__item search-bar__item--last"},[_c('v-text-field',{attrs:{"solo":"","flat":"","prepend-inner-icon":"mdi-magnify","background-color":"grey lighten-4","placeholder":_vm.$t('contactForm.list.search'),"clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"},[_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.sort'),"icon":"mdi-swap-vertical","menu-dense":false,"menu-width":"300"}},[_c('contact-sort',{on:{"change":_vm.sortChanged}})],1)],1)])]),_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{
          name: 'contact-entries-view',
          params: { itemId: item.id },
        },"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"table-item__title text-wrap"},[_vm._v(" "+_vm._s(value.email)+" ")]),(value.fullName !== value.email)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(value.fullName)+" ")]):_vm._e()],1)],1)]}},{key:"item.created",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }