import { Model, Attr, HasOne } from 'spraypaint';
import { User, Organisation } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class OrganisationUser extends ApplicationRecord {
  static jsonapiType = 'organisation-users';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.email'),
        value: 'user.email',
      },
      {
        text: i18n.t('users.labels.role'),
        value: 'role',
      },
      {
        text: i18n.t('users.labels.fullName'),
        value: 'user.fullName',
      },
    ];
  }

  static scopeFactory() {
    return OrganisationUser.includes([]);
  }

  @Attr() isOwner: boolean;

  @Attr() isManager: boolean;

  @Attr() isDefault: boolean;

  @Attr() status: string;

  @Attr() created: string;

  @Attr() changed: string;

  @Attr() email: string;

  @Attr() suppressMail: boolean;

  @HasOne() user: User;

  @HasOne() organisation: Organisation;
}
