


















































import v8n from 'v8n';
import { Component, Vue, VModel } from 'vue-property-decorator';
import { ContactForm } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

@Component({})
export default class ActionsDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  contactForm: ContactForm | null = null;

  valid = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  get user() {
    return authModule.user;
  }

  async save() {
    if (!this.contactForm) {
      throw new Error(this.$t('contactForm.alerts.missing') as string);
    }
    try {
      this.loading = true;
      await this.contactForm.save();
      snackModule.setSuccess(this.$t('contactForm.alerts.sent') as string);
      this.$emit('sent');
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('contactForm.alerts.notSent') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.contactForm = new ContactForm();
  }
}
