






















import { Component, Vue } from 'vue-property-decorator';
import authModule from '@/store/Auth';

import OrganisationSwitcher from '@/components/dashboard/OrganisationSwitcher.vue';

@Component({ components: { OrganisationSwitcher } })
export default class OrganisationMenu extends Vue {
  get organisations() {
    return authModule.organisationUsers;
  }

  get userEmail() {
    return authModule.user ? authModule.user.email : '';
  }

  get activeOrganisation() {
    return authModule.activeOrganisation;
  }
}
