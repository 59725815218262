var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editMode && _vm.processing)?_c('div',[_c('div',{staticClass:"brand-accordion"},[_c('div',{staticClass:"brand-accordion__header"},[_c('div',{staticClass:"brand-accordion__title section-title"},[_vm._v(" "+_vm._s(_vm.$t('processingSources.title'))+" ")]),_c('div',{staticClass:"brand-accordion__button"},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addNewSource($event)}}},[_vm._v(" "+_vm._s(_vm.$t('processingSources.new'))+" ")])],1)]),(_vm.loading)?_c('div',{staticClass:"text-center ma-4"},[_c('v-progress-circular',{attrs:{"color":"primary","width":"2","size":"44","indeterminate":""}})],1):_c('drag-accordion',{attrs:{"items":_vm.processingSources},on:{"move":_vm.moveItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editProcessingSource(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle-edit-outline")])],1)]}}],null,true)},[_vm._v(" Edit processing source ")])]}},{key:"content",fn:function(ref){
var item = ref.item;
return [_c('outcomes-summary',{attrs:{"legislationId":_vm.processing.legislation.id,"outcomes":item.outcome}})]}}],null,false,3529970389)})],1),(_vm.processingSource)?_c('inline-source-edit',{attrs:{"legislationId":_vm.processing.legislation.id,"processingSource":_vm.processingSource},model:{value:(_vm.sourceDialog),callback:function ($$v) {_vm.sourceDialog=$$v},expression:"sourceDialog"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }