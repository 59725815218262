import { Model, Attr } from 'spraypaint';
import { StripePrice } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class StripeProduct extends ApplicationRecord {
  static jsonapiType = 'stripe-products';

  static scopeFactory() {
    return StripeProduct.includes([]);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() fteMin: number;

  @Attr() fteMax: number;

  @Attr() prices: StripePrice[];

  @Attr() isDummy?: boolean;
}
