





























































































































import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';

import {
  Allergen,
  ProcessingSource,
  ProcessingDispersionType,
  ProcessingDispersionTypeItems,
} from '@/api';

import ScenarioTester, { roundify } from '@/scenario-tester';

import ConcentrationChart from '@/components/common/scenario-tester/ConcentrationChart.vue';

@Component({
  components: {
    ConcentrationChart,
  },
})
export default class ScenarioTesterProcessingSourceEdit extends Vue {
  @VModel() dialog: boolean;

  @Prop({ required: true }) readonly scenario: ScenarioTester;

  @Prop() readonly processingSource: ProcessingSource | null;

  @Prop() readonly allergen: Allergen | null;

  roundify = roundify;

  get ProcessingDispersionType() {
    return ProcessingDispersionType;
  }

  get dispersionTypeItems() {
    return ProcessingDispersionTypeItems;
  }

  get crosscontact() {
    if (this.processingSource && this.allergen) {
      return this.processingSource.crosscontacts.find(
        crosscontact =>
          !!this.allergen && crosscontact.allergen.id === this.allergen.id,
      );
    }
    return null;
  }

  revert() {
    if (this.crosscontact) {
      this.crosscontact.rollback();
    }
  }

  @Watch('dialog')
  dialogChanged() {
    if (this.processingSource && this.crosscontact) {
      Vue.set(
        this.scenario.dirtySources,
        `${this.processingSource.id as string}:${
          this.crosscontact.allergen.id
        }`,
        this.crosscontact.isDirty(),
      );
    }
  }
}
