import { Model, Attr, HasOne } from 'spraypaint';
import { User, Library } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class LibraryUser extends ApplicationRecord {
  static jsonapiType = 'library-users';

  static scopeFactory() {
    return LibraryUser.includes([]);
  }

  @Attr() permission: 'none' | 'read' | 'write';

  @HasOne() user: User;

  @HasOne() library: Library;
}
