



























































import { Component, Vue, VModel } from 'vue-property-decorator';
import { Legislation, Ingredient } from '@/api';

import legislationModule from '@/store/Legislation';

import Collapsible from '@/components/common/Collapsible.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import IngredientRecipes from '@/components/dashboard/ingredients/IngredientRecipes.vue';
import IngredientRevisionHistory from '@/components/dashboard/ingredients/IngredientRevisionHistory.vue';

import IngredientSummary from '@/components/common/tables/IngredientSummary.vue';
import LegislationSummary from '@/components/common/tables/LegislationSummary.vue';
import OutcomesSummary from '@/components/common/tables/OutcomesSummary.vue';

@Component({
  components: {
    Collapsible,
    TabLayout,
    TabLayoutItem,
    IngredientRecipes,
    IngredientRevisionHistory,
    IngredientSummary,
    LegislationSummary,
    OutcomesSummary,
  },
})
export default class IngredientsOverview extends Vue {
  @VModel({ required: true }) item: Ingredient;

  legislation = new Legislation();

  ready = false;

  items: Array<{ title: string; component: string; props: object }> = [];

  get isDraftAndFrozen() {
    return this.item.status === 'draft' && this.item.isRecipe;
  }

  async mounted() {
    this.legislation = await legislationModule.getLegislation(
      this.item.legislation.id as string,
    );

    this.ready = true;
  }
}
