


















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Legislation } from '@/api';

import dayjs from 'dayjs';

import snackModule from '@/store/Snack';

import LegislationsOverview from '@/components/dashboard/LegislationsOverview.vue';
import LegislationsEdit from '@/components/dashboard/LegislationsEdit.vue';

@Component({
  components: {
    LegislationsOverview,
    LegislationsEdit,
  },
})
export default class LegislationsSingle extends Vue {
  item: Legislation | null = null;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get editMode() {
    return this.$route.name === 'legislations-edit';
  }

  get revision() {
    return this.item && !this.item.isNewest;
  }

  get draft() {
    return this.item && this.item.status === 'draft';
  }

  get published() {
    return this.item && this.item.status === 'published';
  }

  get title() {
    return this.editMode
      ? this.$t('legislations.single.editTitle')
      : this.$t('legislations.single.createTitle');
  }

  get chipColor() {
    if (this.revision) {
      return 'orange';
    }
    if (this.published) {
      return 'green';
    }
    return 'pink';
  }

  get chipLabel() {
    if (this.revision) {
      return this.$t('common.revisionNumber', { number: this.itemId });
    }
    if (this.published) {
      return this.$t('common.labels.published');
    }
    return this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('MMMM DD, YYYY')} (${dayjs(val).fromNow()})`
      : '–';
  }

  setRoute() {
    // make sure the route is pointing at the correct id
    // id will change when creating a new one or creating a revision
    // maybe should re-render this component (by setting :key on the router-view)

    if (this.item && this.item.id) {
      this.$router
        .replace({
          name: 'legislations-edit',
          params: { itemId: this.item.id },
        })
        .catch(() => {
          // Stop duplicate route
        });
    } else {
      this.$router.replace({ name: 'legislations-new' }).catch(() => {
        // Stop duplicate route
      });
    }
  }

  async publish() {
    if (!this.item) {
      throw new Error(this.$t('legislations.alerts.missing') as string);
    }
    if (this.item.status !== 'draft') {
      throw new Error(this.$t('legislations.alerts.isNotDraft') as string);
    }

    try {
      const dup = this.item.dup();
      dup.status = 'published';
      await dup.save();
      this.item = dup;
      snackModule.setSuccess(
        this.$t('legislations.alerts.published') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('legislations.alerts.notPublished') as string,
        errors: e.response.errors,
      });
    }
  }

  async newRevision() {
    if (!this.item) {
      throw new Error(this.$t('legislations.alerts.missing') as string);
    }
    if (this.item.status !== 'published') {
      throw new Error(this.$t('legislations.alerts.isNotPublished') as string);
    }

    try {
      const revision = new Legislation({
        coreId: this.item.coreId,
      });
      await revision.save();
      this.item = revision;
      snackModule.setSuccess(
        this.$t('common.alerts.revisionCreated') as string,
      );
      this.setRoute();
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.revisionNotCreated') as string,
        errors: e.response.errors,
      });
    }
  }

  async prepareItem() {
    if (this.editMode) {
      this.item = (await Legislation.find(this.itemId)).data;
    } else {
      this.item = new Legislation();
    }
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('item')
  itemChanged() {
    this.setRoute();
  }

  @Watch('itemId')
  itemIdChanged() {
    if (this.item && this.item.id !== this.itemId) {
      this.prepareItem();
    }
  }
}
