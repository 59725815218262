
































































































import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organisation } from '@/api';

import authModule from '@/store/Auth';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import TableActionButton from '@/components/common/TableActionButton.vue';
import TrainingSessionSort from '@/components/dashboard/TrainingSessionSort.vue';
import TrainingSessionsCreate from '@/components/dashboard/TrainingSessionsCreate.vue';

import dayjs from 'dayjs';
import { SortDir } from 'spraypaint/lib-esm/scope';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    TableActionButton,
    TrainingSessionSort,
    TrainingSessionsCreate,
  },
})
export default class TrainingSessions extends Vue {
  search = '';

  searchQuery = '';

  debounceSearch: () => void;

  sortValue: string | null = 'created';

  sortOrder = 'desc';

  createDialog = false;

  selected: Organisation[] = [];

  clone: Organisation | null = null;

  get headers() {
    return Organisation.trainingListHeaders();
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  get scopeFactory() {
    return () => {
      return this.order
        ? Organisation.includes('owner')
            .order(this.order)
            .where(this.whereClause)
        : Organisation.includes('owner').where(this.whereClause);
    };
  }

  get whereClause() {
    return {
      search: this.searchQuery,
      trainingProvider: this.activeOrg.id,
    };
  }

  get order() {
    return this.sortValue
      ? ({ [this.sortValue]: this.sortOrder } as Record<string, SortDir>)
      : '';
  }

  newSession(item: Organisation | null) {
    this.clone = item;
    this.createDialog = true;
  }

  formatDate(org: Organisation) {
    if (dayjs().diff(dayjs(org.trainingBegins)) < 0) {
      // future
      return `${dayjs(org.trainingBegins).format('MMMM DD, YYYY')} – ${dayjs(
        org.trainingExpires,
      ).format('MMMM DD, YYYY')} (Starts ${dayjs(
        org.trainingBegins,
      ).fromNow()})`;
    }
    if (dayjs().diff(dayjs(org.trainingExpires)) < 0) {
      return `${dayjs(org.trainingBegins).format('MMMM DD, YYYY')} – ${dayjs(
        org.trainingExpires,
      ).format('MMMM DD, YYYY')} (Ends ${dayjs(
        org.trainingExpires,
      ).fromNow()})`;
    }
    return `${dayjs(org.trainingBegins).format('MMMM DD, YYYY')} – ${dayjs(
      org.trainingExpires,
    ).format('MMMM DD, YYYY')} (Ended ${dayjs(org.trainingExpires).fromNow()})`;
  }

  formatCreatedDate(val: string) {
    return val ? dayjs(val).fromNow() : '–';
  }

  chipColor(val: string) {
    if (val === 'upcoming') {
      return 'blue';
    }
    if (val === 'active') {
      return 'green';
    }
    return 'red';
  }

  chipLabel(val: string) {
    if (val === 'upcoming') {
      return this.$t('common.labels.upcoming');
    }
    if (val === 'active') {
      return this.$t('common.labels.active');
    }
    return this.$t('common.labels.expired');
  }

  orgUser(org: Organisation) {
    return authModule.organisationUsers.find(
      user => user.organisation.id === org.id,
    );
  }

  setOrg(org: Organisation) {
    const orgUser = this.orgUser(org);
    if (orgUser) {
      authModule.setActiveOrganisationCookies(orgUser);
      window.location.href = process.env.BASE_URL;
    }
  }

  sortChanged(sort: { value: string; order: string }) {
    this.sortValue = sort.value;
    this.sortOrder = sort.order;
  }

  update() {
    this.table.update();
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);
  }

  @Watch('whereClause')
  @Watch('order')
  tagsChanged() {
    this.update();
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }

  @Watch('createDialog')
  createDialogChanged() {
    if (!this.createDialog) {
      this.update();
    }
  }
}
