






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe } from '@/api';

import snackModule from '@/store/Snack';

@Component({})
export default class SingleRecipeActions extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  @Prop({ default: false }) readonly archiveMode: boolean;

  get options() {
    return this.archiveMode
      ? [
          {
            label: this.$t('common.restore'),
            icon: 'mdi-delete-restore',
            action: 'restore',
          },
        ]
      : [
          {
            label: this.$t('common.delete'),
            icon: 'mdi-delete-outline',
            action: 'delete',
          },
          {
            label: this.$t('common.labels.move'),
            icon: 'mdi-format-horizontal-align-right',
            action: 'move',
          },
          {
            label: this.$t('common.labels.addTags'),
            icon: 'mdi-tag-multiple-outline',
            action: 'tag',
          },
          {
            label: this.$t('common.labels.duplicate'),
            icon: 'mdi-content-duplicate',
            action: 'duplicate',
          },
        ];
  }

  setAction(action: string) {
    this.$emit('do-action', action);
  }

  sharedIcon(val: boolean, item?: Recipe) {
    let icon;
    if (val && item && item.recipesUsingCount > 0) {
      icon = 'mdi-check-circle';
    } else {
      icon = val ? 'mdi-check-circle-outline' : 'mdi-circle-off-outline';
    }
    return icon;
  }

  sharedActionLabel(val: boolean) {
    return val
      ? this.$t('recipes.labels.stopUsingAsAnIngredient')
      : this.$t('recipes.labels.useAsAnIngredient');
  }

  async toggleIngredient(item: Recipe) {
    try {
      item.shared = !item.shared;
      await item.save();
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notShared') as string,
        errors: e.response.errors,
      });
      item.shared = !item.shared;
    }
  }
}
