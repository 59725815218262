
































import { Component, Prop, Vue } from 'vue-property-decorator';

import Cookies from 'js-cookie';

import VueDropzone from 'vue2-dropzone';
import { Organisation, User } from '@/api';

const previewTemplate = `
  <div class="dz-preview">
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  </div>
`;

@Component({
  components: {
    VueDropzone,
  },
})
export default class extends Vue {
  @Prop({ required: true }) readonly item: Organisation | User;

  @Prop({ default: '128px' }) readonly size: string;

  @Prop() readonly label: string;

  @Prop() readonly placeholder: string;

  get url() {
    return `${this.item.klass.url(this.item.id)}picture/`;
  }

  get options() {
    const headers =
      process.env.VUE_APP_USE_COOKIES === 'true'
        ? {
            'Accept': 'application/vnd.api+json',
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          }
        : {
            'Accept': 'application/vnd.api+json',
            'Authorization': `Token ${Cookies.get('token')}`,
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          };

    return {
      url: this.url,
      previewTemplate,
      maxFiles: 1,
      resizeWidth: 400,
      resizeHeight: 400,
      resizeMethod: 'crop',
      paramName: 'picture',
      method: 'PATCH',
      headers,
    };
  }

  get style() {
    let picture = '';
    if (this.item.picture) {
      picture = this.item.picture;
    } else if (this.placeholder) {
      picture = this.placeholder;
    }
    return `background-image: url(${picture}); width: ${this.size}; height: ${this.size}`;
  }

  get dropzone() {
    return (this.$refs.dropzone as unknown) as {
      removeFile: (file: unknown) => void;
    };
  }

  fileAdded() {
    this.$emit('fileAdded');
  }

  filesAdded() {
    this.$emit('filesAdded');
  }

  fileSending() {
    this.$emit('fileSending');
  }

  fileUploaded(file: unknown, response: unknown) {
    this.$emit('fileUploaded', { file, response });
    this.dropzone.removeFile(file);
    this.updateItem();
  }

  fileRemoved() {
    this.$emit('fileRemoved');
  }

  queueComplete() {
    this.$emit('queueComplete');
  }

  async updateItem() {
    // replace the picture attribute of the item
    if (!this.item || !this.item.id) {
      throw new Error('no item');
    }
    const item = (await this.item.klass.find(this.item.id)).data as
      | Organisation
      | User;

    if (item) {
      this.item.picture = item.picture;
    }
  }
}
