

































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import {
  ApplicationRecord,
  BulkDelete,
  Recipe,
  Ingredient,
  Processing,
} from '@/api';

import libraryModule from '@/store/Library';
import snackModule from '@/store/Snack';

@Component({})
export default class BulkDeleteAction extends Vue {
  @PropSync('items', { required: true }) selectedItems!: ApplicationRecord[];

  @Prop({ required: true }) readonly context: string;

  loading = false;

  complete = false;

  bulkDelete: BulkDelete | null = null;

  get itemIds() {
    return (this.selectedItems as (Recipe | Ingredient | Processing)[]).map(
      item => item.coreId,
    );
  }

  get hasErrors() {
    return this.bulkDelete && this.bulkDelete.failures.length;
  }

  itemName(id: string) {
    const selectedItem = (this.selectedItems as (
      | Recipe
      | Ingredient
      | Processing
    )[]).find(item => String(item.coreId) === id);
    return selectedItem ? selectedItem.name : '';
  }

  cancel() {
    this.$emit('cancel');
  }

  async deleteItems() {
    this.bulkDelete = new BulkDelete({
      resourceName: this.context,
      ids: this.itemIds,
    });
    try {
      this.loading = true;
      await this.bulkDelete.save();
      if (this.bulkDelete.failures.length) {
        throw new Error(this.$t('bulkDelete.alerts.error') as string);
      }
      libraryModule.getLibraries(true);
      this.cancel();
      snackModule.setSuccess(this.$t('bulkDelete.alerts.success') as string);
    } catch (e) {
      // Error
    } finally {
      if (this.bulkDelete.successes.length) {
        this.selectedItems = (this.selectedItems as (
          | Recipe
          | Ingredient
          | Processing
        )[]).filter(item => {
          if (!this.bulkDelete) {
            throw new Error(this.$t('bulkDelete.alerts.missing') as string);
          }
          return !this.bulkDelete.successes.includes(String(item.coreId));
        });
      }
      this.loading = false;
      this.complete = true;
      this.$emit('update');
    }
  }
}
