var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('abstract-table',{attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.organisation.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{
          name: 'organisations-edit',
          params: { itemId: item.organisation.id },
        }}},[_c('v-list-item-avatar',{attrs:{"size":"32","color":"grey lighten-2"}},[_c('v-img',{attrs:{"src":item.organisation.avatar,"alt":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(value)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.orgType(item.organisation.type))+" ")])],1)],1)]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [(item.isOwner)?[_vm._v(" Owner ")]:(item.isManager)?[_vm._v(" Manager ")]:[_vm._v(" User ")]]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }