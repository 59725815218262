











































import { Component, Vue } from 'vue-property-decorator';
import authModule from '@/store/Auth';

@Component({
  components: {},
})
export default class SubscribedOnlyWarning extends Vue {
  get activeOrgUser() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation;
  }

  get activeOrganisation() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return this.activeOrgUser.organisation;
  }

  get ownerEmail() {
    return this.activeOrganisation.owner.email;
  }

  get isOwner() {
    return this.activeOrgUser.isOwner;
  }

  get isActive() {
    return (
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.isActive
    );
  }
}
