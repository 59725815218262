import { Model, Attr, HasOne } from 'spraypaint';
import { Library } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class BulkMoveLibrary extends ApplicationRecord {
  static jsonapiType = 'bulk-move-librarys';

  static scopeFactory() {
    return BulkMoveLibrary.includes([]);
  }

  @Attr() recipeCoreIds: string[];

  @Attr() successes: string[];

  @Attr() failures: Array<{ itemId: string; errorCode: string }>;

  @HasOne() sourceLibrary: Library;

  @HasOne() destinationLibrary: Library;

  errorMessage(code: string) {
    switch (code) {
      case 'no-item':
        return i18n.t('bulkMoveLibrary.error.noItem');

      case 'no-perms':
        return i18n.t('bulkMoveLibrary.error.noPerms');

      case 'in-use':
        return i18n.t('bulkMoveLibrary.error.inUse');

      default:
        return i18n.t('bulkMoveLibrary.error.unknown');
    }
  }
}
