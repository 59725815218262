































































































































































































































































import dayjs from 'dayjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Organisation, StripeSubscription, StripeBillingSession } from '@/api';
import ConfirmDialog from '@/confirm-dialog';

import snackModule from '@/store/Snack';

import ContactDialog from '@/components/dashboard/ContactDialog.vue';

@Component({
  components: { ContactDialog },
})
export default class OrganisationsSubscriptions extends Vue {
  @Prop({ required: true }) readonly item: Organisation;

  organisation = new Organisation(); // tmp value

  subType = 'vital';

  vitalLoading = false;

  abLoading = false;

  contactDialog = false;

  get vitalSubscription() {
    return this.organisation.vitalSubscription;
  }

  get abSubscription() {
    return this.organisation.allergenBureauSubscription;
  }

  get vitalValue() {
    const originalValue = this.vitalSubscription.metadata.price_value / 100;
    const value = this.organisation.hasCoupon
      ? originalValue - this.vitalSubscription.metadata.coupon_value / 100
      : originalValue;
    return this.formatPrice(value, true);
  }

  get vitalAutoPay() {
    if (!this.vitalSubscription) {
      return false;
    }
    return !this.vitalSubscription.payByInvoice;
  }

  set vitalAutoPay(value: boolean) {
    if (this.vitalSubscription) {
      this.vitalSubscription.payByInvoice = !value;
    }
  }

  get abAutoPay() {
    if (!this.abSubscription) {
      return false;
    }
    return !this.abSubscription.payByInvoice;
  }

  set abAutoPay(value: boolean) {
    if (this.abSubscription) {
      this.abSubscription.payByInvoice = !value;
    }
  }

  get hasDefaultPaymentMethod() {
    return this.organisation.hasDefaultPaymentMethod;
  }

  update() {
    this.$emit('update');
  }

  showNewSubscription(subscription: StripeSubscription | null) {
    if (!subscription) {
      return true;
    }
    return subscription.status === 'canceled';
  }

  isValidSubscription(subscription: StripeSubscription) {
    return dayjs().isBefore(subscription.currentPeriodEnd, 'minute');
  }

  renewColor(subscription: StripeSubscription) {
    switch (subscription.renew) {
      case true:
        return 'error';

      default:
        return 'primary';
    }
  }

  renewText(subscription: StripeSubscription) {
    switch (subscription.renew) {
      case true:
        return this.$t('subscriptions.cancel');

      default:
        return this.$t('subscriptions.renew');
    }
  }

  formatDate(value: string) {
    return dayjs(value).format('DD/MM/YYYY');
  }

  formatPrice(value: number, includeDecimal = false) {
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value);
  }

  titleCase(s: string) {
    return s
      .replace('_', ' ') // split snake_case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, (m: string) => {
        return m.toUpperCase();
      });
  }

  async manageStripe() {
    const session = new StripeBillingSession({
      organisation: this.item,
    });
    try {
      await session.save({ with: ['organisation.id'] });
      if (session.url) {
        window.location.href = session.url;
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.sessionError') as string,
        errors: e.response.errors,
      });
    }
  }

  async toggleRenew(subscription: StripeSubscription) {
    switch (subscription.metadata.membership_type) {
      case 'vital':
        this.vitalLoading = true;
        break;

      case 'allergen_bureau':
        this.abLoading = true;
        break;

      default:
        break;
    }
    const r = await ConfirmDialog({
      description: !subscription.renew
        ? (this.$t('subscriptions.confirm.renewDescription') as string)
        : (this.$t('subscriptions.confirm.dontRenewDescription') as string),
    });
    if (r !== 'confirm') {
      this.vitalLoading = false;
      this.abLoading = false;
      return;
    }

    try {
      subscription.renew = !subscription.renew;
      await subscription.save();
      snackModule.setSuccess(this.$t('subscriptions.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
      subscription.renew = !subscription.renew;
    } finally {
      this.vitalLoading = false;
      this.abLoading = false;
    }
  }

  async toggleInvoice(subscription: StripeSubscription, value: boolean) {
    const r = await ConfirmDialog({
      description: value
        ? (this.$t('subscriptions.confirm.invoiceDescription') as string)
        : (this.$t('subscriptions.confirm.notInvoiceDescription') as string),
    });
    if (r !== 'confirm') {
      subscription.payByInvoice = value;
      return;
    }

    try {
      await subscription.save();
      snackModule.setSuccess(this.$t('subscriptions.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
      subscription.payByInvoice = value;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.organisation = this.item.dup();
  }
}
