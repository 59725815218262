var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-vertical-gap"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('recipeIngredients.addTitle'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('recipeIngredients.addDescription'))}})]),_c('div',{staticClass:"rel-search"},[_c('ingredient-search',{ref:"ingredientSearch",attrs:{"recipeId":_vm.item.id},on:{"select":_vm.addIngredient}}),_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rel-search__add",attrs:{"fab":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.addNewIngredient($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('recipeIngredients.addInline'))+" ")])],1),_c('div',{staticClass:"brand-accordion__header"},[_c('div',{staticClass:"brand-accordion__title section-title"},[_vm._v(" "+_vm._s(_vm.$t('recipeIngredients.currentTitle'))+" ")]),(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"color":"primary","width":"2","size":"44","indeterminate":""}})],1):_c('div',[_c('div',{staticClass:"brand-accordion__percent",class:{
          'brand-accordion__percent--fail': !_vm.totalPercentage.eq('100'),
        }},[_c('div',{staticClass:"brand-accordion__percent-title"},[_vm._v(" "+_vm._s(_vm.$t('recipes.labels.allocated'))+" ")]),_vm._v(" "+_vm._s(_vm.totalPercentage + '%')+" ")])])]),(!_vm.loading)?_c('drag-accordion',{attrs:{"items":_vm.recipeIngredients,"disableDrag":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var recipeIngredientItem = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(recipeIngredientItem.ingredient.name)+" ")]),(
          recipeIngredientItem.ingredient.shared &&
            !recipeIngredientItem.ingredient.isLatest
        )?_c('alert-tooltip',{attrs:{"right":"","small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('recipes.alerts.ingredientOutdated'))+" ")]):_vm._e(),(!recipeIngredientItem.ingredient.assumptions)?_c('alert-tooltip',{attrs:{"right":"","small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('common.validation.assumptionsMissing'))+" ")]):_vm._e(),(!parseFloat(recipeIngredientItem.percentage))?_c('alert-tooltip',{attrs:{"right":"","small":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('recipes.alerts.zeroPercentageIngredient'))+" ")]):_vm._e(),_c('v-spacer'),(!recipeIngredientItem.ingredient.shared)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editRecipeIngredient(recipeIngredientItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle-edit-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('recipeIngredients.editInline'))+" ")]):_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteRecipeIngredient(recipeIngredientItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('ingredients.remove'))+" ")]),_c('percent-edit',{attrs:{"recipeIngredient":recipeIngredientItem}})]}},{key:"content",fn:function(ref){
        var recipeIngredientItem = ref.item;
return [_c('v-simple-table',{staticClass:"alt-summary-table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('common.labels.referenceCode')))]),_c('td',[_vm._v(" "+_vm._s(recipeIngredientItem.ingredient.referenceCode)+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('common.labels.assumptions')))]),_c('td',{staticClass:"text--pre"},[_vm._v(" "+_vm._s(recipeIngredientItem.ingredient.assumptions)+" ")])])])]),_c('outcomes-summary',{attrs:{"legislationId":_vm.item.legislation.id,"outcomes":recipeIngredientItem.ingredient.outcome}})]}}],null,false,2248283063)}):_vm._e(),(_vm.recipeIngredient)?_c('inline-ingredient-edit',{attrs:{"legislationId":_vm.item.legislation.id,"recipeIngredient":_vm.recipeIngredient},model:{value:(_vm.inlineEditDialog),callback:function ($$v) {_vm.inlineEditDialog=$$v},expression:"inlineEditDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }