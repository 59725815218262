






























import { Component, Vue, Prop } from 'vue-property-decorator';
import NestedDrag from '@/components/common/NestedDrag.vue';

@Component({
  components: {
    NestedDrag,
  },
})
export default class DragAccordion extends Vue {
  @Prop({ default: () => [] }) readonly items: object[];

  @Prop({ default: false }) readonly disableDrag: boolean;

  selected: object | null = null;

  moveItem(movedItem: object, parentId: string | null, newIndex: number) {
    this.$emit('move', movedItem, parentId, newIndex);
  }

  setAccordion(item: object) {
    this.selected = this.selected === item ? null : item;
  }
}
