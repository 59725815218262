

















































import v8n from 'v8n';
import { Component, Vue, VModel } from 'vue-property-decorator';

import LegislationSelect from '@/components/common/LegislationSelect.vue';
import { Legislation, Recipe } from '@/api';

import ScenarioTester from '@/scenario-tester';
import ReportBuilder from '@/reports/ReportBuilder';

import Big from 'big.js';

@Component({ components: { LegislationSelect } })
export default class ActionLevelGridDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  loading = false;

  valid = false;

  referenceAmount = '100';

  legislation: Legislation | null = null;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  generate() {
    if (!this.legislation) {
      throw new Error('no legislation selected');
    }
    const scenario = new ScenarioTester(new Legislation(), new Recipe(), {
      useCalculatedOutcomes: false,
    });
    new ReportBuilder(scenario)
      .actionLevelGrid(this.legislation, Big(this.referenceAmount))
      .open();
  }
}
