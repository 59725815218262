






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { RecipeIngredient } from '@/api';
import Big from 'big.js';

@Component({
  components: {},
})
export default class RecipeIngredientsSummary extends Vue {
  @Prop({ required: true }) readonly recipeIngredients: RecipeIngredient[];

  getPercentage(percentage: string) {
    return Big(percentage).toString();
  }
}
