

































































































import { Component, Vue, VModel, Prop, Watch } from 'vue-property-decorator';
import { Tag } from '@/api';
import i18n from '@/plugins/vue-i18n';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import ColorSelect from '@/components/common/ColorSelect.vue';

@Component({
  components: {
    ColorSelect,
  },
})
export default class TagFilter extends Vue {
  @VModel({ required: true }) selected: Tag[];

  @Prop({ default: i18n.t('tags.defaultTitle') }) readonly title: string;

  @Prop({ default: false, type: Boolean }) readonly editMode: boolean;

  @Prop({ default: null }) readonly libraryId: string | null;

  tags: Tag[] = [];

  search = '';

  showSearch = false;

  showAll = false;

  dialog = false;

  tag: Tag | null = null;

  get filteredTags() {
    const tags = this.tags.filter(tag =>
      tag.name.toLowerCase().startsWith(this.search.toLowerCase()),
    );

    return this.showAll || this.search !== '' ? tags : tags.slice(0, 10);
  }

  get dialogTitle() {
    return this.tag && this.tag.id
      ? this.$t('tags.editTitle')
      : this.$t('tags.createTitle');
  }

  editTag(tag: Tag) {
    this.tag = tag.dup();
    this.dialog = true;
  }

  async deleteTag() {
    if (!this.tag) {
      throw new Error(this.$t('tags.alerts.missingTag') as string);
    }
    try {
      await this.tag.destroy();
      snackModule.setSuccess(this.$t('tags.alerts.tagDeleted') as string);
      await this.updateTags();
      this.tag = null;
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('tags.alerts.tagNotDeleted') as string,
        errors: e.response.errors,
      });
    }
  }

  async saveTag() {
    if (!this.tag) {
      throw new Error(this.$t('tags.alerts.missingTag') as string);
    }
    try {
      await this.tag.save({ with: 'organisation.id' });
      snackModule.setSuccess(this.$t('tags.alerts.tagSaved') as string);
      await this.updateTags();
      this.tag = null;
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('tags.alerts.tagNotSaved') as string,
        errors: e.response.errors,
      });
    }
  }

  newTag() {
    this.tag = new Tag({
      color: '',
      organisation: authModule.activeOrganisation,
    });
    this.dialog = true;
  }

  async updateTags() {
    const id = this.libraryId === '-1' ? null : this.libraryId;
    try {
      this.tags = (
        await Tag.where({
          used_in_library: !this.editMode ? id : null,
        })
          .extraParams({ library_context: id })
          .all()
      ).data;
    } catch (e) {
      // Error
    }
  }

  async mounted() {
    await this.updateTags();
  }

  @Watch('libraryId')
  libraryIdChanged() {
    this.updateTags();
  }
}
