






















import { Component, Vue, VModel } from 'vue-property-decorator';

@Component({})
export default class ColorSelect extends Vue {
  @VModel({ type: String }) color: string;
}
