






































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Auth from '@/store/Auth';

@Component({
  components: {},
})
export default class RecipeTotalCrosscontacts extends Vue {
  @Prop({ required: true }) readonly constituentRows: ConstituentRow[];

  get isActive() {
    return Auth.isActive;
  }
}
