

































































import { Component, Prop, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';

@Component({})
export default class AlertBanner extends Vue {
  @Prop() subscriptionSunset: boolean;

  get ownerEmail() {
    return (
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.owner.email
    );
  }

  get subscriptionExpired() {
    return (
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.subscriptionStatus === 'lapsed'
    );
  }

  get isOwner() {
    return authModule.isOwner;
  }

  get alertTitle() {
    if (this.subscriptionSunset) {
      return this.$t('subscriptionSunset.title');
    }
    if (this.subscriptionExpired) {
      return this.$t('subscriptionExpired.title');
    }
    return this.$t('subscriptionRequired.hiddenContentTitle');
  }
}
