



























































































import { Component, VModel, Vue, Prop } from 'vue-property-decorator';
import { Organisation, OrganisationType } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import TabLayout from '@/components/common/TabLayout.vue';
import DatetimeSelect from '@/components/common/DatetimeSelect.vue';
import RelAutocompleteAsync from '@/components/common/RelAutocompleteAsync.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    TabLayout,
    DatetimeSelect,
    RelAutocompleteAsync,
  },
})
export default class TrainingSessionsCreate extends Vue {
  @VModel() dialog: boolean;

  @Prop({ default: null }) readonly clone: Organisation;

  item: Organisation | null = null;

  loading = false;

  startImmediately = true;

  sendWelcomeImmediately = true;

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  get cloneScopeFactory() {
    return () => {
      return Organisation.where({
        trainingProvider: this.activeOrg.id,
      });
    };
  }

  async save() {
    if (!this.item) {
      throw new Error(this.$t('organisations.alerts.missing') as string);
    }

    // set appropriate dates
    const now = dayjs().toISOString();
    if (this.startImmediately) {
      this.item.trainingBegins = now;
    }
    if (this.sendWelcomeImmediately) {
      this.item.sendWelcomeAt = now;
    }
    this.item.trainingExpires = dayjs(this.item.trainingBegins)
      .add(6, 'week')
      .toISOString();

    if (
      !this.item.sendWelcomeAt ||
      dayjs(this.item.sendWelcomeAt).diff(this.item.trainingBegins) > 0
    ) {
      snackModule.setError({
        text: this.$t('trainingSessions.alerts.invalidWelcomeEmail') as string,
        errors: [],
      });
      return;
    }

    try {
      this.loading = true;
      await this.item.save({
        with: ['trainingProvider.id', 'owner.id', 'cloneSession.id'],
      });
      snackModule.setSuccess(
        this.$t('trainingSessions.alerts.created') as string,
      );
      if (authModule.user) {
        await authModule.setUserOrganisations(authModule.user);
      }
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('trainingSessions.alerts.failed') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.item = new Organisation({
      type: OrganisationType.TrainingSession,
      trainingProvider: this.activeOrg,
      owner: authModule.user,
      cloneSession: this.clone,
      trainingBegins: dayjs()
        .add(1, 'week')
        .toISOString(),
    });
  }
}
