































































import v8n from 'v8n';
import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Organisation, StripeProduct, StripeSubscription } from '@/api';

import snackModule from '@/store/Snack';

@Component({
  components: {},
})
export default class SubscriptionNew extends Vue {
  @Prop({ required: true }) readonly organisation: Organisation;

  @Prop({ required: true }) readonly subscriptionType: string;

  subscription = new StripeSubscription({
    organisation: this.organisation,
    renew: false,
  });

  abProducts: StripeProduct[] = [];

  vitalProducts: StripeProduct[] = [];

  dateMenu = false;

  invoiceDate: string | null = null;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  chargeTypeItems = [
    { text: this.$t('subscriptions.chargeTypes.free'), value: 'free' },
    { text: this.$t('subscriptions.chargeTypes.prorated'), value: 'prorated' },
    { text: this.$t('subscriptions.chargeTypes.full'), value: 'full' },
  ];

  get planItems() {
    const products =
      this.subscriptionType === 'ab' ? this.abProducts : this.vitalProducts;
    return products
      .map(product => {
        return product.prices.map(price => {
          let value;
          if (
            this.subscriptionType === 'vital' &&
            price.interval === 'year' &&
            this.organisation.hasCoupon &&
            this.organisation.allergenBureauSubscription.metadata.fte_min ===
              product.fteMin
          ) {
            value = price.discounted_value;
          } else {
            value = price.value;
          }
          const cost = this.formatPrice(value);
          return {
            text: this.$t('subscriptions.nameCostInterval', {
              name: product.name,
              cost,
              interval: price.interval,
            }),
            value: price.id,
          };
        });
      })
      .flat();
  }

  get formattedInvoiceDate() {
    return this.invoiceDate ? dayjs(this.invoiceDate).format('DD/MM/YYYY') : '';
  }

  formatPrice(value: number, includeDecimal = false) {
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value / 100);
  }

  cancel() {
    this.$emit('cancel');
  }

  update() {
    this.$emit('update');
  }

  async save() {
    if (!this.subscription) {
      throw new Error(
        this.$t('subscriptions.alerts.missingSubscription') as string,
      );
    }
    try {
      this.loading = true;
      if (this.invoiceDate) {
        this.subscription.nextInvoiceDatetime = dayjs(
          this.invoiceDate,
        ).toISOString();
      }
      await this.subscription.save({ with: 'organisation.id' });
      snackModule.setSuccess(this.$t('subscriptions.alerts.created') as string);
      this.update();
      this.cancel();
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notCreated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    const products = (await StripeProduct.all()).data;
    this.abProducts = products.filter(p =>
      p.name.startsWith('Allergen Bureau'),
    );
    this.vitalProducts = products
      .filter(p => !this.abProducts.includes(p))
      .sort((a, b) => a.fteMin - b.fteMin);
  }
}
