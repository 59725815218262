




























































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import dayjs from 'dayjs';
import TableActionButton from '@/components/common/TableActionButton.vue';
import Collapsible from '@/components/common/Collapsible.vue';

import legislationModule from '@/store/Legislation';
import snackModule from '@/store/Snack';
import ScenarioTester from '@/scenario-tester';
import ReportBuilder from '@/reports/ReportBuilder';
import { OrgwideUpdate, Recipe } from '@/api';

@Component({
  components: {
    TableActionButton,
    Collapsible,
  },
})
export default class ComparisonReportsSingle extends Vue {
  item: OrgwideUpdate | null = null;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get headers() {
    return [
      {
        text: this.$t('common.labels.name'),
        value: 'name',
      },
      {
        text: this.$t('common.newRevision'),
        value: 'newRevision',
        align: 'center',
      },
      {
        text: this.$t('common.oldRevision'),
        value: 'oldRevision',
        align: 'center',
      },
      {
        text: this.$t('common.labels.status'),
        value: 'published',
        align: 'center',
      },
      {
        text: this.$t('common.outcomeChanged'),
        value: 'outcome',
        align: 'center',
      },
      {
        text: this.$t('common.labellingChanged'),
        value: 'label',
        align: 'center',
      },
      {
        value: 'file',
        align: 'right',
      },
    ];
  }

  get comparisonReports() {
    return this.item
      ? this.item.comparisonReports.filter(
          report =>
            Object.values(report.outcomes).filter(
              outcome => outcome.beforeId !== outcome.afterId,
            ).length,
        )
      : [];
  }

  async generateReport(outcome: ComparisonReportOutcome) {
    const items = (
      await Recipe.where({ id__in: [outcome.beforeId, outcome.afterId] })
        .includes([
          'recipeIngredients.ingredient.crosscontacts',
          'recipeProcessings.processing.processingSources.crosscontacts',
        ])
        .all()
    ).data;

    const recipeA = items.find(
      recipe => recipe.id === outcome.afterId.toString(),
    );
    const recipeB = items.find(
      recipe => recipe.id === outcome.beforeId.toString(),
    );

    if (!recipeA || !recipeB) {
      snackModule.setError({
        text: 'Error fetching recipe revisions',
        errors: [],
      });
      return;
    }
    const legislationA = await legislationModule.getLegislation(
      recipeA.legislation.id as string,
    );
    const legislationB = await legislationModule.getLegislation(
      recipeB.legislation.id as string,
    );
    const builder = new ReportBuilder(
      new ScenarioTester(legislationA, recipeA, {
        useCalculatedOutcomes: true,
      }),
    );
    builder
      .comparisonSummary(
        new ScenarioTester(legislationB, recipeB, {
          useCalculatedOutcomes: true,
        }),
        true,
      )
      .open();
  }

  getOutcomes(outcomes: RecipeComparison[]) {
    return outcomes
      .filter(outcome => outcome.beforeId !== outcome.afterId)
      .sort((a, b) => {
        const valA = (a.outcomeChanged ? 1 : 0) + (a.labellingChanged ? 2 : 0);
        const valB = (b.outcomeChanged ? 1 : 0) + (b.labellingChanged ? 2 : 0);
        return valB - valA;
      });
  }

  legislationName(legislationCoreId: string) {
    const legislation = legislationModule.allCachedLegislations.find(
      // eslint-disable-next-line eqeqeq
      item => item.coreId == legislationCoreId && item.isCurrent,
    );
    if (legislation) {
      return legislation.name;
    }
    return 'unkonwn';
  }

  async prepareItem() {
    this.item = (await OrgwideUpdate.find(this.itemId)).data;
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('DD MMMM YYYY, h:mm:ss a')} (${dayjs(
          val,
        ).fromNow()})`
      : '–';
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('itemId')
  itemIdChanged() {
    this.prepareItem();
  }
}
