























































import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Scope } from 'spraypaint';
import { Recipe } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';

@Component({
  components: { AbstractTable, AlertTooltip },
})
export default class RecipesTable extends Vue {
  @Prop({ required: true }) readonly scopeFactory: () => Scope<Recipe>;

  headers = [
    {
      text: this.$t('common.labels.name'),
      value: 'name',
    },
    {
      text: this.$t('common.labels.status'),
      value: 'status',
      width: 130,
      align: 'center',
    },
    {
      text: this.$t('common.labels.modified'),
      value: 'modified',
      width: 180,
    },
  ];

  chipColor(val: string) {
    return val === 'published' ? 'green' : 'pink';
  }

  chipLabel(val: string) {
    return val === 'published'
      ? this.$t('common.labels.published')
      : this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return dayjs(val).fromNow();
  }
}
