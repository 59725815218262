import { Model, Attr, HasOne } from 'spraypaint';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';
import User from './User';

@Model()
export default class Totp extends ApplicationRecord {
  static jsonapiType = 'totps';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('common.labels.email'),
        value: 'email',
      },
      {
        text: i18n.t('users.labels.fullName'),
        value: 'fullName',
      },
    ];
  }

  static scopeFactory() {
    return Totp.includes([]);
  }

  // always post with user and either setTotp or verifyOtp
  @HasOne() user: User;

  @Attr() setTotp: boolean;

  @Attr() verifyOtp: string;

  // whether or not totp has been verified
  @Attr() readonly verified: boolean;

  // store this to recover
  @Attr() readonly unhashedRecovery: string;

  // add this to totp provider
  @Attr() readonly totpLink: string;

  get totpCode() {
    const url = new URL(this.totpLink);
    return url.searchParams.get('secret') || '';
  }
}
