import { Model, Attr, HasOne } from 'spraypaint';
import { OrganisationalRecipeReport } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

export enum ReportStatus {
  'Pending' = 1,
  'Failed' = 2,
  'Successful' = 3,
}

@Model()
export default class ComparisonReport extends ApplicationRecord {
  static jsonapiType = 'comparison-reports';

  static listHeaders() {
    return [
      {
        text: i18n.t('reports.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('common.labels.created'),
        value: 'created',
      },
      {
        value: 'file',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return ComparisonReport.includes([]);
  }

  @Attr() created: string;

  @Attr() status: ReportStatus;

  @Attr() file: string;

  @Attr() legislationCoreId: string;

  @Attr() outcomes: ComparisonReportOutcomes;

  // before
  @HasOne() organisationalRecipeReportA: OrganisationalRecipeReport;

  // after
  @HasOne() organisationalRecipeReportB: OrganisationalRecipeReport;
}
