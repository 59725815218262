


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class AlertTooltip extends Vue {
  @Prop({ default: '' }) readonly color: string;

  @Prop({ default: 'mdi-alert-circle' }) readonly icon: string;

  @Prop({ default: false }) readonly left: boolean;

  @Prop({ default: false }) readonly right: boolean;

  @Prop({ default: false }) readonly small: boolean;
}
