var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('users.list.title'))+" ")]),_c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"search-bar__item search-bar__item--last"},[_c('v-text-field',{attrs:{"solo":"","flat":"","prepend-inner-icon":"mdi-magnify","background-color":"grey lighten-4","placeholder":_vm.$t('users.list.search'),"clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"}),_c('v-btn',{attrs:{"depressed":"","large":"","color":"secondary","to":{ name: 'users-new' }}},[_vm._v(" "+_vm._s(_vm.$t('users.new'))+" ")])],1)]),_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{
          name: 'users-edit',
          params: { itemId: item.id },
        },"ripple":false}},[_c('v-list-item-avatar',{attrs:{"size":"32","color":"grey lighten-2"}},[_c('v-img',{attrs:{"src":item.avatar,"alt":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"table-item__title text-wrap"},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])])],1)],1)]}},{key:"item.fullName",fn:function(ref){
        var value = ref.value;
        var item = ref.item;
return [_vm._v(" "+_vm._s(value === item.email ? '-' : value)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }