










































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe, Legislation } from '@/api';

import dayjs from 'dayjs';
import Big from 'big.js';

import Auth from '@/store/Auth';

@Component({
  components: {},
})
export default class AssessmentSummary extends Vue {
  @Prop({ required: true }) readonly recipe: Recipe;

  @Prop({ required: true }) readonly legislation: Legislation;

  @Prop({ required: true }) readonly rows: ScenarioRow[];

  intentional: object[] = [];

  al2: object[] = [];

  al1: object[] = [];

  unknown: object[] = [];

  formatDate(val: string) {
    return dayjs(val).format('DD MMMM YYYY, h:mm:ss a');
  }

  get referenceAmount() {
    return this.recipe.isComponent
      ? `${this.$t('common.labels.isComponent')} (${this.$t(
          'common.labels.isComponentTooltip',
        )})`
      : `${Big(this.recipe.referenceAmount).toString()} g`;
  }

  get isActive() {
    return Auth.isActive;
  }

  mounted() {
    this.intentional = this.rows.filter(
      row => !row.isGroup && row.labels.intentional,
    );
    this.al2 = this.rows.filter(row => !row.isGroup && row.labels.actionLevel2);
    this.al1 = this.rows.filter(row => !row.isGroup && row.labels.actionLevel1);
    this.unknown = this.rows.filter(row => !row.isGroup && row.labels.unknown);
  }
}
