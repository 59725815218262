



















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { Legislation, User } from '@/api';

import dayjs from 'dayjs';

@Component({
  components: {},
})
export default class LegislationRevisionHistory extends Vue {
  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly legislationCoreId: string;

  revisions: Legislation[] = [];

  formatDate(val: string) {
    return val
      ? dayjs(val).format('DD MMMM YYYY, h:mm:ss a')
      : this.$t('common.labels.unknownDate');
  }

  formatRelativeDate(val: string) {
    return val ? dayjs(val).fromNow() : '';
  }

  formatName(user: User | null) {
    return user ? user.fullName : this.$t('common.labels.unknownUser');
  }

  gotoRevision(revision: Legislation) {
    if (this.legislationId !== revision.id) {
      this.$router.push({
        name: 'legislations-edit',
        params: { itemId: revision.id as string },
      });
    }
  }

  async mounted() {
    this.revisions = (
      await Legislation.where({ coreId: this.legislationCoreId })
        .includes(['createdBy', 'modifiedBy', 'publishedBy'])
        .order({ created: 'desc' })
        .all()
    ).data;
  }
}
