var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.ready)?_c('v-app',[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"accent-bar"}),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"56"}})],1)]):_c('v-app',{staticClass:"app-container",class:{ 'app-container--full-width': _vm.showAppBar }},[(_vm.showAppBar)?_c('v-navigation-drawer',{staticClass:"side-nav",attrs:{"app":"","absolute":"","clipped":"","permanent":"","color":"grey lighten-4","mobile-breakpoint":"0","touchless":""}},[_c('div',{staticClass:"side-nav__inner"},[_c('organisation-menu'),_c('v-list',{staticClass:"pt-0",attrs:{"nav":""}},[_vm._l((_vm.filteredMenu),function(item){return _c('v-list-item',{key:item.to.name,attrs:{"to":item.to,"color":"primary"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),(_vm.isManager)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","depressed":"","block":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateAll($event)}}},[_vm._v(" "+_vm._s(_vm.$t('organisationSwitcher.update'))+" ")]):_vm._e(),_c('v-divider',{staticClass:"mb-2"}),_vm._l((_vm.filteredAdminMenu),function(item){return _c('v-list-item',{key:item.to.name,attrs:{"to":item.to,"color":"primary"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('organisation-switcher')],2)],1)]):_vm._e(),(_vm.showAppBar)?_c('v-app-bar',{attrs:{"app":"","color":"primary","clipped-left":"","flat":"","dark":""}},[_c('router-link',{staticClass:"app-bar-logo",attrs:{"to":{ name: 'dashboard' },"title":"VITAL Online"}},[_c('img',{attrs:{"src":require("@/assets/images/vital-white.svg"),"alt":"VITAL Online"}})]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","left":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-avatar',{attrs:{"size":"40","color":"beige"}},[_c('v-img',{attrs:{"src":_vm.userAvatar,"alt":""}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('users.labels.settings')))])])]}}],null,false,3650250733)},[_c('v-list',{staticClass:"user-menu",attrs:{"dense":"","min-width":"200px"}},[_c('v-list-item',{attrs:{"to":{ name: 'user-details' }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('common.menuItems.userDetails'))+" ")])],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('common.logout'))+" ")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"href":_vm.$t('common.supportUrl')}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('common.supportText'))+" ")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"href":(_vm.wpBase + "/versions/")}},[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"left":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.version)+" ")])]}}],null,false,245083557)},[_vm._v(" "+_vm._s(_vm.hash)+" ")])],1)],1),_c('v-divider')],1)],1)],1):_vm._e(),_c('v-main',{key:_vm.locale},[_c('router-view')],1),_c('snackbar')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }