












































































































































import { Component, Vue, VModel, Watch } from 'vue-property-decorator';
import { Recipe } from '@/api';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import RecipeInformation from '@/components/dashboard/recipes/RecipeInformation.vue';
import RecipeYield from '@/components/dashboard/recipes/RecipeYield.vue';
import RecipeIngredientsEdit from '@/components/dashboard/recipes/RecipeIngredientsEdit.vue';
import RecipeProcessingsEdit from '@/components/dashboard/recipes/RecipeProcessingsEdit.vue';
import RecipeRevisionHistory from '@/components/dashboard/recipes/RecipeRevisionHistory.vue';

import AlertTooltip from '@/components/common/AlertTooltip.vue';

@Component({
  components: {
    AlertTooltip,
    TabLayout,
    TabLayoutItem,
    RecipeInformation,
    RecipeYield,
    RecipeIngredientsEdit,
    RecipeProcessingsEdit,
    RecipeRevisionHistory,
  },
})
export default class RecipesEdit extends Vue {
  @VModel({ required: true }) recipe: Recipe;

  tabNumber = 0;

  recipeInfoValid = 'invalid';

  recipeYieldValid = 'invalid';

  recipeIngredientsValid = false;

  zeroPercentageIngredient = false;

  recipeProcessingsValid = 'invalid';

  get editMode() {
    return this.$route.name === 'recipes-edit';
  }

  get recipeValid() {
    return (
      this.recipeInfoValid === 'complete' &&
      this.recipeYieldValid === 'complete' &&
      this.recipeIngredientsValid &&
      !this.recipe.outdatedIngredients &&
      this.recipeProcessingsValid === 'complete' &&
      !this.recipe.outdatedProcessings
    );
  }

  get infoAlertIcon() {
    switch (this.recipeInfoValid) {
      case 'unsaved':
      case 'invalid':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get infoAlertColor() {
    switch (this.recipeInfoValid) {
      case 'unsaved':
        return 'orange';

      case 'invalid':
        return 'error';

      default:
        return 'primary';
    }
  }

  get infoAlertMessage() {
    switch (this.recipeInfoValid) {
      case 'unsaved':
        return this.$t('common.tabs.unsaved');

      case 'invalid':
        return this.$t('common.tabs.missingInfo');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  get amountsAlertIcon() {
    switch (this.recipeYieldValid) {
      case 'unsaved':
      case 'invalid':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get amountsAlertColor() {
    switch (this.recipeYieldValid) {
      case 'unsaved':
        return 'orange';

      case 'invalid':
        return 'error';

      default:
        return 'primary';
    }
  }

  get amountsAlertMessage() {
    switch (this.recipeYieldValid) {
      case 'unsaved':
        return this.$t('common.tabs.unsaved');

      case 'invalid':
        return this.$t('common.tabs.missingInfo');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  get ingredientsAlertIcon() {
    let icon;
    if (this.recipe.outdatedIngredients || !this.recipeIngredientsValid) {
      icon = 'mdi-alert-circle';
    } else {
      icon = 'mdi-check-circle';
    }
    return icon;
  }

  get ingredientsAlertColor() {
    let color;
    if (this.recipe.outdatedIngredients || !this.recipeIngredientsValid) {
      color = 'error';
    } else {
      color = 'primary';
    }
    return color;
  }

  get ingredientsAlertMessage() {
    let message;
    if (this.recipe.outdatedIngredients) {
      message = this.$t('recipes.tabs.ingredientsOutdated');
    } else if (this.zeroPercentageIngredient) {
      message = this.$t('recipes.tabs.zeroPercentageIngredient');
    } else if (!this.recipeIngredientsValid) {
      message = this.$t('common.tabs.invalidInfo');
    } else {
      message = this.$t('common.tabs.complete');
    }
    return message;
  }

  get processingsAlertIcon() {
    let icon;
    if (this.recipe.outdatedProcessings) {
      icon = 'mdi-alert-circle';
    } else {
      switch (this.recipeProcessingsValid) {
        case 'unsaved':
        case 'invalid':
          icon = 'mdi-alert-circle';
          break;

        default:
          icon = 'mdi-check-circle';
          break;
      }
    }
    return icon;
  }

  get processingsAlertColor() {
    let color;
    if (this.recipe.outdatedProcessings) {
      color = 'error';
    } else {
      switch (this.recipeProcessingsValid) {
        case 'unsaved':
          color = 'orange';
          break;

        case 'invalid':
          color = 'error';
          break;

        default:
          color = 'primary';
          break;
      }
    }
    return color;
  }

  get processingsAlertMessage() {
    let message;
    if (this.recipe.outdatedProcessings) {
      message = this.$t('recipes.tabs.processingsOutdated');
    } else {
      switch (this.recipeProcessingsValid) {
        case 'unsaved':
          message = this.$t('common.tabs.unsaved');
          break;

        case 'invalid':
          message = this.$t('common.tabs.missingInfo');
          break;

        default:
          message = this.$t('common.tabs.complete');
          break;
      }
    }
    return message;
  }

  recipeUpdated(recipe: Recipe) {
    this.recipe = recipe;
  }

  setTab(index: number) {
    this.tabNumber = index;
  }

  @Watch('recipeValid', { immediate: true })
  validChanged() {
    this.$emit('valid', this.recipeValid);
  }
}
