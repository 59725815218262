








































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Recipe, RecipeIngredient, Ingredient } from '@/api';

import snackModule from '@/store/Snack';

import IngredientSearch from '@/components/common/IngredientSearch.vue';
import IngredientAllergenList from '@/components/common/IngredientAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import DragAccordion from '@/components/common/DragAccordion.vue';
import OutcomesSummary from '@/components/common/tables/OutcomesSummary.vue';
import InlineIngredientEdit from '@/components/dashboard/recipes/InlineIngredientEdit.vue';
import PercentEdit from '@/components/dashboard/recipes/PercentEdit.vue';

import Big from 'big.js';

@Component({
  components: {
    IngredientSearch,
    IngredientAllergenList,
    AlertTooltip,
    DragAccordion,
    OutcomesSummary,
    InlineIngredientEdit,
    PercentEdit,
  },
})
export default class RecipeIngredientsEdit extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  recipeIngredients: RecipeIngredient[] = [];

  recipeIngredient: RecipeIngredient | null = null;

  addIngredientLoading = false;

  loading = false;

  inlineEditDialog = false;

  get missingIngredientAssumptions() {
    return (
      this.recipeIngredients.length &&
      this.recipeIngredients.some(
        recipeIngredient => !recipeIngredient.ingredient.assumptions,
      )
    );
  }

  get zeroPercentageIngredient() {
    return this.recipeIngredients.some(
      recipeIngredient => !parseFloat(recipeIngredient.percentage),
    );
  }

  get valid() {
    return (
      !this.missingIngredientAssumptions &&
      !this.zeroPercentageIngredient &&
      this.totalPercentage.eq('100')
    );
  }

  get totalPercentage() {
    if (!this.recipeIngredients) {
      return Big('0');
    }
    return this.recipeIngredients.reduce((a, b) => {
      return a.plus(b.percentage || '0');
    }, Big('0'));
  }

  addNewIngredient() {
    this.recipeIngredient = new RecipeIngredient({
      recipe: this.item,
      ingredientData: {},
    });
    this.inlineEditDialog = true;
  }

  editRecipeIngredient(recipeIngredient: RecipeIngredient) {
    this.recipeIngredient = recipeIngredient.dup();
    this.recipeIngredient.ingredient = recipeIngredient.ingredient.dup();
    this.inlineEditDialog = true;
  }

  async addIngredient(ingredient: Ingredient) {
    this.loading = true;
    try {
      const recipeIngredient = new RecipeIngredient({
        recipe: this.item,
        ingredient,
      });
      await recipeIngredient.save({ with: ['recipe.id', 'ingredient.id'] });
      await this.getRecipeIngredients();
      snackModule.setSuccess(this.$t('ingredients.alerts.added') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notAdded') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteRecipeIngredient(recipeIngredient: RecipeIngredient) {
    this.loading = true;
    try {
      await recipeIngredient.destroy();
      await this.getRecipeIngredients();
      snackModule.setSuccess(this.$t('ingredients.alerts.removed') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notRemoved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async getRecipeIngredients() {
    try {
      const recipeIngredients = (
        await RecipeIngredient.includes(['ingredient'])
          .where({
            recipe: this.item.id,
          })
          .all()
      ).data;
      this.recipeIngredients = recipeIngredients;
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.getError') as string,
        errors: e.response.errors,
      });
    }
  }

  @Watch('item', { immediate: true })
  async recipeChanged() {
    this.loading = true;
    await this.getRecipeIngredients();
    this.loading = false;
  }

  @Watch('inlineEditDialog')
  async inlineEditDialogChanged() {
    if (!this.inlineEditDialog) {
      this.loading = true;
      await this.getRecipeIngredients();
      this.loading = false;
    }
  }

  @Watch('valid', { immediate: true })
  validChanged() {
    this.$emit('valid', this.valid);
  }

  @Watch('zeroPercentageIngredient', { immediate: true })
  zeroPercentageIngredientChanged() {
    this.$emit('zeroPercentageIngredient', this.zeroPercentageIngredient);
  }
}
