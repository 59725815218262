






















import { Component, Vue } from 'vue-property-decorator';

import AuthSlider from '@/components/auth/AuthSlider.vue';
import LanguagePicker from '@/components/common/LanguagePicker.vue';

@Component({
  components: {
    AuthSlider,
    LanguagePicker,
  },
})
export default class AuthView extends Vue {}
