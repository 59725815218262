












































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import { Scope } from 'spraypaint';
import { ApplicationRecord } from '@/api';

@Component({
  components: {},
})
export default class AbstractTable extends Vue {
  // eg [{ value: 'id', text: 'ID' }]
  @Prop({ required: true }) readonly headers: object[];

  @Prop({ default: false }) readonly singleSelect: boolean;

  @Prop({ default: true }) readonly showSelect: boolean;

  // eg () => User.includes(['someFields']).where({ project: 1 });
  @Prop({ required: true }) readonly scopeFactory: () => Scope<
    ApplicationRecord
  >;

  @VModel() selected: ApplicationRecord[];

  loading = false;

  total = 0;

  items: ApplicationRecord[] = [];

  options = {
    page: 1,
    itemsPerPage: 20,
  };

  get pageCount() {
    return Math.ceil(this.total / this.options.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 +
      (this.options.page - 1) * this.options.itemsPerPage} - ${Math.min(
      this.options.page * this.options.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  itemClicked(item: ApplicationRecord) {
    this.$emit('item-clicked', item);
  }

  async update() {
    this.loading = true;
    try {
      // initial scope + page + pageSize
      const scope = this.scopeFactory()
        .page(this.options.page)
        .per(this.options.itemsPerPage);

      // get result
      const result = await scope.all();
      this.items = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.items = [];
    } finally {
      this.loading = false;
    }
  }

  @Watch('options', { deep: true })
  optionsChanged() {
    this.update();
  }
}
