





























import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { ReportStatus, OrganisationReport } from '@/api';

import snackModule from '@/store/Snack';
import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import TableActionButton from '@/components/common/TableActionButton.vue';
import ReportFilters from '@/components/dashboard/ReportFilters.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';
import ActionLevelGridDialog from '@/components/common/ActionLevelGridDialog.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    TableActionButton,
    ReportFilters,
    LegislationSelect,
    ActionLevelGridDialog,
  },
})
export default class OrganisationReports extends Vue {
  loading = false;

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get headers() {
    return OrganisationReport.listHeaders();
  }

  get scopeFactory() {
    return () => OrganisationReport.order({ created: 'desc' });
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('DD MMMM YYYY, h:mm:ss a')} (${dayjs(
          val,
        ).fromNow()})`
      : '–';
  }

  downloadTooltip(item: OrganisationReport) {
    switch (item.status) {
      case ReportStatus.Pending:
        return this.$t('reports.downloadTooltip.pending');
      case ReportStatus.Failed:
        return this.$t('reports.downloadTooltip.failed');
      case ReportStatus.Successful:
        return this.$t('reports.downloadTooltip.success');

      default:
        return '';
    }
  }

  downloadDisabled(item: OrganisationReport) {
    switch (item.status) {
      case ReportStatus.Pending:
      case ReportStatus.Failed:
        return true;

      default:
        return false;
    }
  }

  downloadReport(val: string) {
    if (val) {
      window.open(val);
    }
  }

  update() {
    this.table.update();
  }

  async newReport() {
    try {
      this.loading = true;
      const report = new OrganisationReport();
      await report.save();
      snackModule.setSuccess(this.$t('reports.alerts.created') as string);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('reports.alerts.notCreated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
