













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Legislation, Allergen } from '@/api';
import ScenarioTester, { roundify } from '@/scenario-tester';

@Component({
  components: {},
})
export default class RecipeLegislationSummary extends Vue {
  @Prop({ required: true }) readonly legislation: Legislation;

  allergenName(allergen: Allergen) {
    return allergen.children.length
      ? `${allergen.name} ${this.$t('recipes.accordion.totalsSuffix')}`
      : allergen.name;
  }

  get flattened() {
    return ScenarioTester.flattenAllergens(this.legislation.sortedAllergens);
  }

  roundify = roundify;
}
