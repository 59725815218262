











































































import { Component, Vue } from 'vue-property-decorator';
import { Organisation } from '@/api';

import authModule from '@/store/Auth';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import OrganisationDetails from '@/components/dashboard/OrganisationDetails.vue';
import OrganisationUsers from '@/components/dashboard/OrganisationUsers.vue';
import Subscriptions from '@/components/dashboard/Subscriptions.vue';
import OrganisationInvoices from '@/components/dashboard/organisations/OrganisationInvoices.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    OrganisationDetails,
    OrganisationUsers,
    Subscriptions,
    OrganisationInvoices,
  },
})
export default class ManageOrganisation extends Vue {
  item: Organisation | null = null;

  get activeOrg() {
    return authModule.activeOrganisation
      ? authModule.activeOrganisation.organisation
      : null;
  }

  get isManager() {
    return authModule.activeOrganisation
      ? authModule.activeOrganisation.isManager
      : false;
  }

  get isOwner() {
    return authModule.activeOrganisation
      ? authModule.activeOrganisation.isOwner
      : false;
  }

  organisationUpdated(organisation: Organisation) {
    this.item = organisation;
  }

  async prepareItem() {
    if (!this.activeOrg || !this.activeOrg.id) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    this.item = (
      await Organisation.includes([
        'vitalSubscription',
        'allergenBureauSubscription',
      ]).find(this.activeOrg.id)
    ).data;
  }

  async mounted() {
    await this.prepareItem();
  }
}
