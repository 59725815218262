import { Model, Attr, HasOne } from 'spraypaint';
import { User } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

export enum ReportStatus {
  'Pending' = 1,
  'Failed' = 2,
  'Successful' = 3,
}

@Model()
export default class UserReport extends ApplicationRecord {
  static jsonapiType = 'user-reports';

  static listHeaders() {
    return [
      {
        text: i18n.t('reports.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('common.labels.created'),
        value: 'created',
      },
      {
        value: 'file',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return UserReport.includes([]);
  }

  @Attr() created: string;

  @Attr() modified: string;

  @Attr() status: ReportStatus;

  @Attr() file: string;

  @Attr() notes: string;

  @HasOne() createdBy: User;

  @HasOne() modifiedBy: User;
}
