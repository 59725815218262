











































import { Component, Vue, Prop } from 'vue-property-decorator';
import SortedAllergenRow from '@/components/common/scenario-tester/SortedAllergenRow.vue';

import ScenarioTester from '@/scenario-tester';
import { Allergen } from '@/api';

@Component({
  components: {
    SortedAllergenRow,
  },
})
export default class SortedAllergenSummary extends Vue {
  @Prop({ required: true }) readonly rows: Allergen[];

  @Prop({ default: null }) readonly newRows: Allergen[];

  get comparison() {
    return ScenarioTester.compareSortedAllergens(this.newRows, this.rows);
  }

  get allEqual() {
    return !this.comparison || !this.comparison.some(row => !row.rowsAreEqual);
  }

  get equalComparator() {
    return this.allEqual ? 'none' : 'equal';
  }

  getComparatorRowA(row: {
    revA?: Allergen;
    revB?: Allergen;
    rowsAreEqual: boolean;
  }) {
    if (!row.rowsAreEqual) {
      if (row.revB) {
        return 'in';
      }
      return 'added';
    }
    return this.equalComparator;
  }

  getComparatorRowB(row: {
    revA?: Allergen;
    revB?: Allergen;
    rowsAreEqual: boolean;
  }) {
    if (!row.rowsAreEqual) {
      if (row.revA) {
        return 'out';
      }
      return 'deleted';
    }
    return this.equalComparator;
  }
}
