var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('abstract-table',{attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory,"show-select":false},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{ name: 'recipes-edit', params: { itemId: item.id } },"ripple":false}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"table-item__title"},[_c('span',[_vm._v(" "+_vm._s(value)+" ")]),(item.outdated)?_c('alert-tooltip',{attrs:{"color":"error","small":"","right":""}},[_vm._v(" "+_vm._s(_vm.$t('recipes.isOutdated'))+" ")]):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.referenceCode)+" ")]),(item.recipeTags.length)?_c('v-list-item-subtitle',{staticClass:"table-item__tags"},_vm._l((item.sortedRecipeTags),function(recipeTag){return _c('div',{key:recipeTag.id,staticClass:"table-item__tag"},[_c('v-icon',{attrs:{"color":recipeTag.tag.color,"left":"","x-small":""}},[_vm._v(" mdi-brightness-1 ")]),_c('span',[_vm._v(" "+_vm._s(recipeTag.tag.name)+" ")])],1)}),0):_vm._e()],1)],1)]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.chipColor(value)}},[_vm._v(" "+_vm._s(_vm.chipLabel(value))+" ")])]}},{key:"item.modified",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }