var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('organisations.list.title'))+" ")]),_c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"search-bar__item search-bar__item--last"},[_c('v-text-field',{attrs:{"solo":"","flat":"","prepend-inner-icon":"mdi-magnify","background-color":"grey lighten-4","placeholder":_vm.$t('organisations.list.search'),"clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"},[_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.filter'),"icon":"mdi-filter-outline","menu-dense":false,"menu-width":"300","is-active":_vm.filtersActive}},[_c('organisation-filters')],1),_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.sort'),"icon":"mdi-swap-vertical","menu-dense":false,"menu-width":"300","is-active":!!_vm.sortValue}},[_c('organisation-sort',{on:{"change":_vm.sortChanged}})],1)],1),_c('v-btn',{attrs:{"depressed":"","large":"","color":"secondary","to":{ name: 'organisations-new' }}},[_vm._v(" "+_vm._s(_vm.$t('organisations.new'))+" ")])],1)]),_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-list-item',{staticClass:"table-item",attrs:{"to":{
          name: 'organisations-edit',
          params: { itemId: item.id },
        },"ripple":false}},[_c('v-list-item-avatar',{attrs:{"size":"32","color":"grey lighten-2"}},[_c('v-img',{attrs:{"src":item.avatar,"alt":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"table-item__title text-wrap"},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])]),_c('v-list-item-subtitle',[_c('div',[_vm._v(_vm._s(_vm.createdDate(item)))]),_c('div',[_vm._v(_vm._s(_vm.ownedBy(item)))])])],1)],1)]}},{key:"item.type",fn:function(ref){
        var value = ref.value;
return [_c('span',{staticClass:"d-flex text-no-wrap"},[_vm._v(" "+_vm._s(_vm.orgType(value))+" ")])]}},{key:"item.trainingEnds",fn:function(ref){
        var item = ref.item;
return [(_vm.trainingOrg(item))?_c('div',{staticClass:"d-flex text-no-wrap"},[(_vm.isTraining(item))?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" Training Active ")]),_c('alert-tooltip',{attrs:{"icon":"mdi-clock","color":"green","small":"","right":""}},[_vm._v(" "+_vm._s(_vm.$t('organisations.labels.activeTraining'))+" ")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.trainingExpires))+" ")])]):_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" Training Expired ")]),_c('alert-tooltip',{attrs:{"icon":"mdi-clock","color":"orange","small":"","right":""}},[_vm._v(" "+_vm._s(_vm.$t('organisations.labels.activeTraining'))+" ")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.trainingExpires))+" ")])])]):_c('div',[_vm._v(" – ")])]}},{key:"item.vitalStatus",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('span',{staticClass:"d-flex text-no-wrap"},[_c('span',[_vm._v(" "+_vm._s(value)+" ")]),_c('v-icon',{attrs:{"small":"","right":"","color":_vm.vitalStatusColor(item)}},[_vm._v(" "+_vm._s(_vm.vitalStatusIcon(item))+" ")])],1)]}},{key:"item.vitalSubscription",fn:function(ref){
        var value = ref.value;
return [(value)?_c('div',{staticClass:"d-flex text-no-wrap"},[_vm._v(" "+_vm._s(value.metadata.membership_name)+" "+_vm._s(_vm.formatSubInterval(value.metadata.price_interval))+" "),_c('alert-tooltip',{attrs:{"color":_vm.statusColor(value.status),"icon":_vm.statusIcon(value.status),"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.titleCase(value.status))+" ")])],1):_vm._e(),(value)?_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDate(value.currentPeriodEnd))+" ")]):_vm._e()]}},{key:"item.allergenBureauSubscription",fn:function(ref){
        var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex text-no-wrap"},[_vm._v(" "+_vm._s(value.metadata.membership_name)+" "+_vm._s(_vm.formatSubInterval(value.metadata.price_interval))+" "),_c('alert-tooltip',{attrs:{"color":_vm.statusColor(value.status),"icon":_vm.statusIcon(value.status),"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.titleCase(value.status))+" ")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }