
























































































































































































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import AvatarUpload from '@/components/common/AvatarUpload.vue';
import CountrySelect from '@/components/common/CountrySelect.vue';
import RelAutocompleteAsync from '@/components/common/RelAutocompleteAsync.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';
import { OrganisationUser, Organisation, Legislation } from '@/api';

@Component({
  components: {
    AvatarUpload,
    CountrySelect,
    RelAutocompleteAsync,
    LegislationSelect,
  },
})
export default class OrganisationDetails extends Vue {
  @Prop({ required: true }) readonly item: Organisation;

  @Prop({ type: Boolean, default: false }) readonly canEdit: boolean;

  organisation = new Organisation(); // tmp value

  valid = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  owner: OrganisationUser | null = null;

  ownerLoading = true;

  legislation: Legislation | null = null;

  get activeOrg() {
    return authModule.activeOrganisation
      ? authModule.activeOrganisation.organisation
      : null;
  }

  get isOwner() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.isOwner;
  }

  get userScopeFactory() {
    return () => {
      if (!authModule.activeOrganisation) {
        throw new Error(
          this.$t('common.alerts.missingActiveOrganisation') as string,
        );
      }
      return OrganisationUser.includes(['user']).where({
        organisation: authModule.activeOrganisation.organisation.id,
      });
    };
  }

  async save() {
    if (!this.organisation.id) {
      throw new Error(this.$t('organisations.alerts.missing') as string);
    }
    try {
      this.loading = true;
      if (this.owner && this.organisation.owner.id !== this.owner.user.id) {
        this.organisation.owner = this.owner.user;
      }
      if (this.legislation && this.legislation.id) {
        this.organisation.defaultLegislation = this.legislation.coreId;
      }
      if (authModule.isOwner) {
        await this.organisation.save({ with: 'owner.id' });
      } else {
        await this.organisation.save();
      }
      window.location.reload();
      snackModule.setSuccess(this.$t('organisations.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('organisations.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.organisation = this.item.dup();
    if (this.organisation.owner) {
      try {
        const owners = (
          await OrganisationUser.includes('user')
            .where({
              user: this.organisation.owner.id,
              organisation: this.organisation.id,
            })
            .all()
        ).data;
        if (owners) {
          [this.owner] = owners;
        }
      } catch (e) {
        snackModule.setError({
          text: this.$t('organisations.alerts.ownerError') as string,
          errors: e.response.errors,
        });
      } finally {
        this.ownerLoading = false;
      }
    }
  }
}
