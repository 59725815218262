import { Model, Attr, HasOne } from 'spraypaint';
import { Allergen, Ingredient } from '@/api';
import i18n from '@/plugins/vue-i18n';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

export enum DispersionType {
  'Intentional' = 0,
  'Particulate' = 1,
  'Dispersible' = 2,
}

export const DispersionTypeItems = [
  {
    text: i18n.t('common.dispersionTypes.intentional'),
    value: 0,
  },
  {
    text: i18n.t('common.dispersionTypes.particulate'),
    value: 1,
  },
  {
    text: i18n.t('common.dispersionTypes.dispersible'),
    value: 2,
  },
];

@Model()
export default class IngredientCrosscontact extends ApplicationRecord {
  static jsonapiType = 'ingredient-crosscontacts';

  static scopeFactory() {
    return IngredientCrosscontact.includes([]);
  }

  @Attr() created: string;

  @Attr() dispersionType: DispersionType;

  @Attr() concentration: string; // this is ppm

  @HasOne() allergen: Allergen;

  @HasOne() ingredient: Ingredient;

  get label() {
    switch (this.dispersionType) {
      case DispersionType.Intentional:
        return i18n.t('common.dispersionTypes.intentionalLabel');
      case DispersionType.Particulate:
        return i18n.t('common.dispersionTypes.particulateLabel');
      case DispersionType.Dispersible:
        return i18n.t('common.dispersionTypes.dispersibleLabel', {
          amount: Big(this.concentration).toString(),
        });
      default:
        return '';
    }
  }
}
