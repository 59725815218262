
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RecipeIngredient } from '@/api';

import snackModule from '@/store/Snack';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import IngredientSearch from '@/components/common/IngredientSearch.vue';
import IngredientAllergenList from '@/components/common/IngredientAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import DragAccordion from '@/components/common/DragAccordion.vue';

import Big from 'big.js';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    IngredientSearch,
    IngredientAllergenList,
    AlertTooltip,
    DragAccordion,
  },
})
export default class RecipeIngredientsEdit extends Vue {
  @Prop({ required: true }) readonly recipeIngredient: RecipeIngredient;

  dialog = false;

  async saveItem() {
    try {
      await this.recipeIngredient.save();
      snackModule.setSuccess(
        this.$t('recipes.alerts.percentageUpdated') as string,
      );
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.percentageNotUpdated') as string,
        errors: e.response.errors,
      });
    }
  }

  get bigPercent() {
    return Big(this.recipeIngredient.percentage || '0');
  }

  get color() {
    if (this.bigPercent.gt('100') || this.bigPercent.lt('0')) {
      return 'red';
    }
    return 'green';
  }

  handleKeyup(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.saveItem();
    }
  }

  @Watch('dialog', { immediate: true })
  dialogChanged() {
    this.recipeIngredient.rollback();
    this.$nextTick(() => {
      if (this.$refs.txt && this.dialog) {
        ((this.$refs.txt as Vue).$refs.input as HTMLInputElement).select();
      }
    });
  }
}
