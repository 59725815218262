







































import { Component, Vue, VModel } from 'vue-property-decorator';
import { Legislation } from '@/api';
import legislationModule from '@/store/Legislation';

import Collapsible from '@/components/common/Collapsible.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import LegislationRevisionHistory from '@/components/dashboard/legislations/LegislationRevisionHistory.vue';

import LegislationSummary from '@/components/common/tables/LegislationSummary.vue';
import RecipeLegislationSummary from '@/components/common/tables/RecipeLegislationSummary.vue';

@Component({
  components: {
    Collapsible,
    TabLayout,
    TabLayoutItem,
    LegislationRevisionHistory,
    LegislationSummary,
    RecipeLegislationSummary,
  },
})
export default class LegislationsOverview extends Vue {
  @VModel({ required: true }) item: Legislation;

  ready = false;

  legislation: Legislation | null = null;

  items: Array<{ title: string; component: string; props: object }> = [];

  async mounted() {
    this.legislation = await legislationModule.getLegislation(
      this.item.id as string,
      true,
    );
    this.ready = true;
  }
}
