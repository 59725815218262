import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import {
  Legislation,
  IngredientCrosscontact,
  DispersionType,
  User,
} from '@/api';
import i18n from '@/plugins/vue-i18n';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Ingredient extends ApplicationRecord {
  static jsonapiType = 'ingredients';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
        width: 130,
        align: 'center',
      },
      {
        text: i18n.t('common.labels.modified'),
        value: 'modified',
        width: 180,
      },
      {
        value: 'actions',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return Ingredient.includes([]);
  }

  @Attr() coreId: string;

  // write only - set this and coreId to duplicate
  @Attr() asNew: boolean;

  @Attr() created: string;

  @Attr() modified: string;

  @Attr() published: string;

  @HasOne() createdBy: User;

  @HasOne() modifiedBy: User;

  @HasOne() publishedBy: User;

  @Attr() name: string;

  @Attr() shared: boolean;

  @Attr() status: 'draft' | 'published';

  @Attr() referenceCode: string;

  @Attr() assumptions: string;

  @Attr() outdated: boolean;

  @Attr() outdatedLegislation: boolean;

  @Attr() outdatedCalculationVersion: boolean;

  @Attr() outdatedBaseRecipe: boolean;

  @Attr() updateRelationships: boolean;

  @Attr() isLatest: boolean;

  @Attr() isNewest: boolean;

  @Attr() isRecipe: boolean;

  // waterGain from the original recipe or 100...
  @Attr() readonly waterGain: string;

  @Attr() readonly isArchived: boolean;

  @Attr() readonly fromRecipe: number;

  @Attr() readonly calculationVersion: number;

  @Attr() restore: boolean;

  @Attr() recipeCount: number;

  @Attr({ dirtyChecker: () => false }) readonly outcome: Outcomes;

  @HasOne() legislation: Legislation;

  @HasMany() crosscontacts: IngredientCrosscontact[];

  get searchableName() {
    return `${this.name} ${this.referenceCode}`;
  }

  crosscontactDispersionType(allergenId: string) {
    const crosscontact = this.crosscontacts.find(
      item => item.allergen.id === allergenId,
    );
    return crosscontact ? crosscontact.dispersionType : null;
  }

  crosscontactConcentration(allergenId: string) {
    const crosscontact = this.crosscontacts.find(
      item => item.allergen.id === allergenId,
    );
    if (
      crosscontact &&
      crosscontact.dispersionType === DispersionType.Dispersible
    ) {
      return Big(crosscontact.concentration);
    }

    return Big('0');
  }
}
