import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import { User, OrganisationUser, StripeSubscription } from '@/api';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-jdenticon-sprites';
import i18n from '@/plugins/vue-i18n';
import dayjs from 'dayjs';
import ApplicationRecord from '../ApplicationRecord';

export enum OrganisationType {
  'Regular' = 0,
  'TrainingProvider' = 1,
  'TrainingSession' = 2,
}

@Model()
export default class Organisation extends ApplicationRecord {
  static jsonapiType = 'organisations';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('organisations.labels.type'),
        value: 'type',
      },
      {
        text: i18n.t('organisations.access.labels.title'),
        value: 'vitalStatus',
      },
      {
        text: i18n.t('organisations.labels.training'),
        value: 'trainingEnds',
      },
      {
        text: i18n.t('organisations.labels.vital'),
        value: 'vitalSubscription',
      },
      {
        text: i18n.t('organisations.labels.allergenBureau'),
        value: 'allergenBureauSubscription',
      },
    ];
  }

  static trainingListHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('organisations.labels.owner'),
        value: 'owner.fullName',
      },
      { text: i18n.t('common.labels.status'), value: 'trainingSessionStatus' },
      {
        text: i18n.t('organisations.labels.sessionBegins'),
        value: 'trainingBegins',
      },
      {
        text: i18n.t('common.labels.created'),
        value: 'created',
      },
      {
        value: 'actions',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return Organisation.includes([]);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() picture: string;

  @Attr() domainName: string;

  @Attr() companyName: string;

  @Attr() addressLine1: string;

  @Attr() addressLine2: string;

  @Attr() city: string;

  @Attr() state: string;

  @Attr() country: string;

  @Attr() postcode: string;

  @Attr() billingAddressLine1: string;

  @Attr() billingAddressLine2: string;

  @Attr() billingCity: string;

  @Attr() billingState: string;

  @Attr() billingCountry: string;

  @Attr() billingPostcode: string;

  @Attr() billingEmail: string;

  @Attr() billingContactFirstName: string;

  @Attr() billingContactLastName: string;

  @Attr() billingAddressMatchesShippingAddress: boolean;

  @Attr() created: string;

  @Attr() changed: string;

  @Attr() type: OrganisationType; // Regular / Training Provider / Training Session

  @Attr() trainingBegins: string;

  @Attr() trainingExpires: string;

  @Attr() trainingActive: boolean;

  @Attr() sendWelcomeAt: string;

  @Attr() subscriptionStatus: 'active' | 'lapsed' | 'invalid' | 'missing';

  @Attr() isActive: boolean;

  @Attr() alwaysActive: boolean;

  @Attr() manualVitalonlineAccess: boolean | null;

  @Attr() userCap: number;

  @Attr() hasCoupon: boolean;

  @Attr() usedCoupon: boolean;

  @Attr() autoFinalizeVital: boolean;

  @Attr() autoFinalizeAllergenBureau: boolean;

  @HasOne() owner: User;

  @HasOne() trainingProvider: Organisation;

  @Attr() isUpdating: boolean;

  @Attr() hasDefaultPaymentMethod: boolean;

  @Attr() chargeGst: boolean;

  @Attr() alwaysApplyCoupon: boolean;

  @Attr() notes: string;

  // these are org users for the organisation
  @HasMany() userRelationships: OrganisationUser[];

  @HasOne() vitalSubscription: StripeSubscription;

  @HasOne() allergenBureauSubscription: StripeSubscription;

  @Attr() readonly voPriceArchived: boolean;

  @Attr() readonly abPriceArchived: boolean;

  @Attr() readonly legislationCoreId: string;

  @Attr() defaultLegislation: string; // write only

  // Training session cloning - write only
  @HasOne() cloneSession: Organisation;

  get validSubscription() {
    return this.trainingActive || this.subscriptionStatus === 'active';
  }

  get avatar() {
    let avatar;
    if (this.picture) {
      avatar = this.picture;
    } else {
      const avatars = new Avatars(sprites, {
        dataUri: true,
        background: '#FFF',
      });
      avatar = avatars.create(`org${this.id}`);
    }
    return avatar;
  }

  get trainingSessionStatus() {
    if (dayjs().diff(dayjs(this.trainingBegins)) < 0) {
      return 'upcoming';
    }
    if (dayjs().diff(dayjs(this.trainingExpires)) < 0) {
      return 'active';
    }
    return 'expired';
  }

  get vitalStatus() {
    if (this.manualVitalonlineAccess === true) {
      return i18n.t('organisations.access.labels.activeManual');
    }
    if (this.manualVitalonlineAccess === false) {
      return i18n.t('organisations.access.labels.inactiveManual');
    }
    if (this.isActive) {
      return i18n.t('organisations.access.labels.active');
    }
    return i18n.t('organisations.access.labels.inactive');
  }
}
