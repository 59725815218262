import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

import { Wordpress } from '@/api';

@Module({ dynamic: true, namespaced: true, name: 'wordpress', store })
class WordpressModule extends VuexModule {
  loading = true;

  posts: WordpressPost[] = [];

  @Mutation
  setPosts(posts: WordpressPost[]) {
    this.posts = posts;
  }

  @Mutation
  setLoading(val: boolean) {
    this.loading = val;
  }

  @Action({ rawError: true })
  async getPosts() {
    try {
      this.setLoading(true);
      const posts = await Wordpress.getLatestPosts();
      this.setPosts(posts);
    } catch (e) {
      // Error
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(WordpressModule, store);
