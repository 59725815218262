
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipe } from '@/api';
import legislationModule from '@/store/Legislation';

import TableMenuButton from '@/components/common/TableMenuButton.vue';
import ScenarioTesterSummary from '@/components/common/scenario-tester/ScenarioTesterSummary.vue';
import ScenarioTester from '@/scenario-tester';
import ReportBuilder from '@/reports/ReportBuilder';

@Component({
  components: { TableMenuButton, ScenarioTesterSummary },
})
export default class CompareRecipe extends Vue {
  rows: ScenarioRow[] = [];

  newRows: ScenarioRow[] = [];

  ready = false;

  showLabellingOutcome = true;

  recipeA: Recipe | null = null;

  recipeB: Recipe | null = null;

  recipeNewest: Recipe | null = null;

  notFound = false;

  get idA() {
    return this.$route.params.itemA.toString();
  }

  get idB() {
    return this.$route.params.itemB.toString();
  }

  get itemIds() {
    return [this.idA, this.idB];
  }

  async generateReport() {
    if (!this.recipeA || !this.recipeB) {
      return;
    }
    const legislationA = await legislationModule.getLegislation(
      this.recipeA.legislation.id as string,
    );
    const legislationB = await legislationModule.getLegislation(
      this.recipeB.legislation.id as string,
    );
    const builder = new ReportBuilder(
      new ScenarioTester(legislationA, this.recipeA, {
        useCalculatedOutcomes: true,
      }),
    );
    builder
      .comparisonSummary(
        new ScenarioTester(legislationB, this.recipeB, {
          useCalculatedOutcomes: true,
        }),
        true,
      )
      .open();
  }

  async getNewest(coreId: string) {
    return (await Recipe.where({ coreId, is_newest: true }).first()).data;
  }

  async getItems() {
    this.ready = false;
    this.notFound = false;
    this.recipeA = null;
    this.recipeB = null;
    this.recipeNewest = null;

    const items = (
      await Recipe.where({ id__in: this.itemIds })
        .includes([
          'recipeIngredients.ingredient.crosscontacts',
          'recipeProcessings.processing.processingSources.crosscontacts',
        ])
        .all()
    ).data;

    const recipeA = items.find(recipe => recipe.id === this.idA);
    const recipeB = items.find(recipe => recipe.id === this.idB);

    if (!recipeA || !recipeB) {
      this.notFound = true;
      return;
    }

    const legislationA = await legislationModule.getLegislation(
      recipeA.legislation.id as string,
    );
    const legislationB = await legislationModule.getLegislation(
      recipeB.legislation.id as string,
    );

    this.rows = new ScenarioTester(legislationB, recipeB, {
      useCalculatedOutcomes: true,
    }).getRows();

    this.newRows = new ScenarioTester(legislationA, recipeA, {
      useCalculatedOutcomes: true,
    }).getRows();

    this.recipeA = recipeA;
    this.recipeB = recipeB;
    this.recipeNewest = await this.getNewest(recipeA.coreId);

    this.ready = true;
  }

  mounted() {
    this.getItems();
  }

  @Watch('itemIds')
  idChanged() {
    this.getItems();
  }
}
