


















































































































































































































import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '@/config';
import {
  StripeSession,
  StripeSubscription,
  Organisation,
  OrganisationUser,
} from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import CostExplorer from '@/components/common/CostExplorer.vue';
import TextSwitch from '@/components/common/TextSwitch.vue';
import ContactDialog from '@/components/dashboard/ContactDialog.vue';
import ActionBanner from '@/components/dashboard/ActionBanner.vue';

interface PriceSelection {
  price: string;
  priceId: string;
  vitalLineItems: Array<{ name: string; price: number }>;
  abLineItems: Array<{ name: string; price: number }>;
}

interface PriceBand {
  name: string;
  tooltip: string;
  monthly: {
    regular: PriceSelection;
  };
  yearly: {
    regular: PriceSelection;
    member: PriceSelection;
    allergenBureau: PriceSelection;
  };
}

@Component({
  components: {
    TextSwitch,
    CostExplorer,
    ContactDialog,
    ActionBanner,
  },
})
export default class Checkout extends Vue {
  paymentType = 0;

  paymentTypes = [
    { text: this.$t('costExplorer.payCreditCard'), value: false },
    { text: this.$t('costExplorer.payInvoice'), value: true },
  ];

  priceId = '';

  abLineItems: Array<{ name: string; price: number }> = [];

  vitalLineItems: Array<{ name: string; price: number }> = [];

  vitalSubscription: StripeSubscription | null = null;

  abSubscription: StripeSubscription | null = null;

  contactDialog = false;

  loading = false;

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }

    return authModule.activeOrganisation.organisation;
  }

  get activeOrgUser() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }

    return authModule.activeOrganisation;
  }

  get chargeGst() {
    return this.activeOrg.chargeGst;
  }

  select(selection: PriceSelection | null) {
    if (!selection) {
      this.abLineItems = [];
      this.vitalLineItems = [];
      this.priceId = '';
      return;
    }

    const abLineItems = [...selection.abLineItems];
    const vitalLineItems = [...selection.vitalLineItems];

    if (this.chargeGst) {
      abLineItems.push({
        name: this.$t('costExplorer.labels.gst') as string,
        price:
          0.1 *
          selection.abLineItems.reduce((acc, item) => acc + item.price, 0),
      });
      vitalLineItems.push({
        name: this.$t('costExplorer.labels.gst') as string,
        price:
          0.1 *
          selection.vitalLineItems.reduce((acc, item) => acc + item.price, 0),
      });
    }

    abLineItems.push({
      name: this.$t('costExplorer.labels.total') as string,
      price: abLineItems.reduce((acc, item) => acc + item.price, 0),
    });
    vitalLineItems.push({
      name: this.$t('costExplorer.labels.total') as string,
      price: vitalLineItems.reduce((acc, item) => acc + item.price, 0),
    });

    this.abLineItems = selection.abLineItems.length ? abLineItems : [];
    this.vitalLineItems = selection.vitalLineItems.length ? vitalLineItems : [];

    this.priceId = selection.priceId;

    this.$nextTick(() => {
      (this.$refs.nextEl as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    });
  }

  formatPrice(value: number, includeDecimal = false) {
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value / 100);
  }

  formatDate(value: string) {
    return dayjs(value).format('DD/MM/YYYY');
  }

  titleCase(s: string) {
    return s
      .replace('_', ' ') // split snake_case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, (m: string) => {
        return m.toUpperCase();
      });
  }

  async checkout() {
    if (!authModule.activeOrganisationId) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }

    try {
      this.loading = true;
      const stripe = await loadStripe(stripeKey);
      const stripeSession = new StripeSession({
        organisationId: authModule.activeOrganisationId,
        priceId: this.priceId,
        payByInvoice: this.paymentType,
      });
      const result = await stripeSession.save();

      if (result && stripeSession.sessionId && stripe) {
        stripe.redirectToCheckout({ sessionId: stripeSession.sessionId });
      } else if (this.paymentType) {
        if (this.activeOrgUser.id) {
          const orgUser = (
            await OrganisationUser.includes('organisation').find(
              this.activeOrgUser.id,
            )
          ).data;
          authModule.setActiveOrganisation(orgUser);
        }
        this.$router.push({ name: 'checkout-success' });
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('costExplorer.alerts.checkoutError') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    if (!this.activeOrg || !this.activeOrgUser) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }

    if (this.activeOrg.id && this.activeOrgUser.isOwner) {
      const org = (
        await Organisation.includes([
          'vitalSubscription',
          'allergenBureauSubscription',
        ]).find(this.activeOrg.id)
      ).data;

      this.vitalSubscription = org.vitalSubscription;
      this.abSubscription = org.allergenBureauSubscription;
    }
  }
}
