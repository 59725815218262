






























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import authModule from '@/store/Auth';

@Component({
  components: {},
})
export default class LoginForm extends Vue {
  email = '';

  password = '';

  otp = '';

  recoveryCode = '';

  error = '';

  loading = false;

  valid = false;

  mode: 'login' | 'otp' | 'recovery' = 'login';

  get isLoggedIn(): boolean {
    return authModule.isLoggedIn;
  }

  get nextQuery(): string {
    return typeof this.$route.query.next === 'string'
      ? this.$route.query.next
      : '';
  }

  async login() {
    this.loading = true;
    this.error = '';
    try {
      if (this.mode === 'otp') {
        await authModule.login({
          email: this.email,
          password: this.password,
          otp: this.otp,
        });
      } else if (this.mode === 'recovery') {
        await authModule.login({
          email: this.email,
          password: this.password,
          recoveryCode: this.recoveryCode,
        });
      } else {
        await authModule.login({ email: this.email, password: this.password });
      }
    } catch (e) {
      if ((e as ErrorResponse).response.errors[0].code === 'otp_requried') {
        this.mode = 'otp';
      } else {
        this.error = (e as ErrorResponse).response.errors[0].detail || '';
      }
    } finally {
      this.loading = false;
    }
  }

  forgotPassword() {
    this.$router.push({ name: 'forgot-password' });
  }

  resetForm() {
    this.email = '';
    this.password = '';
    this.otp = '';
    this.error = '';
    this.mode = 'login';
  }

  @Watch('isLoggedIn', { immediate: true })
  isLoggedInChange() {
    if (this.isLoggedIn) {
      const location = this.nextQuery || { name: 'dashboard' };
      this.$router.replace(location).catch(() => {
        // catching in case next query is overriden in global hook
      });
    }
  }
}
