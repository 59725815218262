import { Model, Attr, HasOne } from 'spraypaint';
import { Tag } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class RecipeTag extends ApplicationRecord {
  static jsonapiType = 'recipe-tags';

  static scopeFactory() {
    return RecipeTag.includes([]);
  }

  @Attr() recipeCoreId: string;

  @HasOne() tag: Tag;
}
