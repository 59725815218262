







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, OrganisationUser, OrganisationType } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({
  components: {
    AbstractTable,
  },
})
export default class UserOrganisations extends Vue {
  @Prop({ required: true }) readonly item: User;

  headers = [
    {
      text: this.$t('common.labels.name'),
      value: 'organisation.name',
    },
    {
      text: this.$t('users.labels.role'),
      value: 'role',
    },
  ];

  get scopeFactory() {
    return () =>
      OrganisationUser.includes('organisation').where({
        user: this.item.id,
      });
  }

  orgType(value: number) {
    switch (value) {
      case OrganisationType.Regular:
        return this.$t('organisations.types.regular');

      case OrganisationType.TrainingProvider:
        return this.$t('organisations.types.trainingProvider');

      case OrganisationType.TrainingSession:
        return this.$t('organisations.types.trainingSession');

      default:
        return '';
    }
  }
}
