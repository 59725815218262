













import { Component, Vue } from 'vue-property-decorator';

import SortItem from '@/components/dashboard/SortItem.vue';

@Component({ components: { SortItem } })
export default class RecipeSort extends Vue {
  selectedFilter: string | null = null;

  sortItems = [
    { label: this.$t('common.labels.name'), value: 'name' },
    { label: this.$t('common.labels.referenceCode'), value: 'referenceCode' },
    { label: this.$t('common.labels.status'), value: 'is_draft' },
    { label: this.$t('common.labels.outdated'), value: '_is_outdated' },
    { label: this.$t('common.labels.legislation'), value: 'legislation__core' },
    { label: this.$t('common.labels.createdDate'), value: 'created' },
    { label: this.$t('common.labels.publishedDate'), value: 'published' },
    { label: this.$t('common.labels.modifiedDate'), value: 'modified' },
  ];

  sortChanged(order: string) {
    this.$emit('change', { value: this.selectedFilter, order });
  }
}
