































































































































































































import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SortDir } from 'spraypaint/lib-esm/scope';
import { Recipe } from '@/api';

import libraryModule from '@/store/Library';

import ActionBanner from '@/components/dashboard/ActionBanner.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import LibrarySelect from '@/components/common/LibrarySelect.vue';
import LibraryUserAvatars from '@/components/common/LibraryUserAvatars.vue';
import TagFilter from '@/components/common/TagFilter.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import RecipeFilters from '@/components/dashboard/RecipeFilters.vue';
import RecipeSort from '@/components/dashboard/RecipeSort.vue';
import RecipeActions from '@/components/dashboard/RecipeActions.vue';
import ActionsDialog from '@/components/dashboard/ActionsDialog.vue';
import SingleRecipeActions from '@/components/dashboard/SingleRecipeActions.vue';

import dayjs from 'dayjs';

@Component({
  components: {
    ActionBanner,
    AlertTooltip,
    AbstractTable,
    TableMenuButton,
    LibrarySelect,
    LibraryUserAvatars,
    TagFilter,
    RecipeFilters,
    RecipeSort,
    RecipeActions,
    ActionsDialog,
    SingleRecipeActions,
  },
})
export default class Recipes extends Vue {
  activeLibrary = libraryModule.activeLibrary;

  search = '';

  searchQuery = '';

  debounceSearch: () => void;

  sortValue: string | null = null;

  sortOrder = 'asc';

  selected: Recipe[] = [];

  actionsDialog = false;

  activeAction: string | null = null;

  get tags() {
    return this.$route.query.tags
      ? (this.$route.query.tags as string).split(',')
      : [];
  }

  set tags(tags) {
    this.$router.push({
      query: {
        ...this.$route.query,
        tags: tags.length ? tags.join(',') : undefined,
      },
    });
  }

  get headers() {
    return Recipe.listHeaders();
  }

  get libraryId() {
    return libraryModule.activeLibrary.id !== '0'
      ? libraryModule.activeLibrary.id
      : null;
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get scopeFactory() {
    return () => {
      return this.order
        ? Recipe.includes(['recipeTags.tag'])
            .order(this.order)
            .where(this.whereClause)
        : Recipe.includes(['recipeTags.tag']).where(this.whereClause);
    };
  }

  get archiveMode() {
    return this.$route.query.archived === 'true';
  }

  get whereClause() {
    const whereObj: Record<string, unknown> = {
      tags__in: this.$route.query.tags,
      is_newest: true,
      is_archived: this.archiveMode,
      search: this.searchQuery,
      ...this.filters,
    };

    if (this.libraryId) {
      if (Number(this.libraryId) > 0) {
        whereObj.library = this.libraryId;
      }
    } else {
      whereObj.library__isnull = true;
    }
    return whereObj;
  }

  get order() {
    return this.sortValue
      ? ({ [this.sortValue]: this.sortOrder } as Record<string, SortDir>)
      : { modified: 'desc' as SortDir };
  }

  get filters() {
    const filterObj: {
      status?: string;
      outdated?: string;
      legislation__core?: string;
      shared?: string;
      recipes_using_count__gte?: number;
    } = {};
    Object.entries(this.$route.query).map(item => {
      const [key, value] = item;

      if (value != null) {
        switch (key) {
          case 'status':
            filterObj.status = value as string;
            break;

          case 'outdated':
            filterObj.outdated = value as string;
            break;

          case 'legislation':
            filterObj.legislation__core = value as string;
            break;

          case 'ingredient':
            if (value === 'used') {
              filterObj.recipes_using_count__gte = 1;
            } else {
              filterObj.shared = value as string;
            }
            break;

          default:
            break;
        }
      }
      return item;
    });
    return filterObj;
  }

  get filtersActive() {
    return Object.keys(this.filters).length > 0;
  }

  chipColor(val: string) {
    return val === 'published' ? 'green' : 'pink';
  }

  chipLabel(val: string) {
    return val === 'published'
      ? this.$t('common.labels.published')
      : this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return dayjs(val).fromNow();
  }

  sharedIconColor(val: boolean) {
    return val ? 'success' : 'grey lighten-1';
  }

  sharedIcon(val: boolean, item?: Recipe) {
    let icon;
    if (val && item && item.recipesUsingCount > 0) {
      icon = 'mdi-check-circle';
    } else {
      icon = val ? 'mdi-check-circle-outline' : 'mdi-circle-off-outline';
    }
    return icon;
  }

  sharedTooltip(val: boolean, item?: Recipe) {
    let tooltip;
    if (val && item && item.recipesUsingCount > 0) {
      tooltip = this.$tc('recipes.usedCount', item.recipesUsingCount, {
        count: item.recipesUsingCount,
      });
    } else {
      tooltip = val
        ? this.$t('recipes.labels.availableAsAnIngregient')
        : this.$t('recipes.labels.notAvailableAsAnIngregient');
    }
    return tooltip;
  }

  sortChanged(sort: { value: string; order: string }) {
    this.sortValue = sort.value;
    this.sortOrder = sort.order;
  }

  doAction(action: string) {
    this.activeAction = action;
    this.actionsDialog = true;
  }

  doSingleAction(action: string, item: Recipe) {
    this.selected = [item];
    this.activeAction = action;
    this.actionsDialog = true;
  }

  update() {
    this.table.update();
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);

    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search as string;
      this.search = this.$route.query.search as string;
    }
  }

  @Watch('libraryId')
  libraryChanged() {
    if (this.tags.length) {
      this.tags = [];
    }
  }

  @Watch('whereClause')
  @Watch('order')
  queryChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    // Remove search query string on search change
    if (this.$route.query.search && this.search !== this.searchQuery) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: undefined,
        },
      });
    }

    this.debounceSearch();
  }
}
