var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"title-underlined"},[_vm._v(" "+_vm._s(_vm.$t('trainingSessions.list.title'))+" ")]),_c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"search-bar__item search-bar__item--last"},[_c('v-text-field',{attrs:{"solo":"","flat":"","prepend-inner-icon":"mdi-magnify","background-color":"grey lighten-4","placeholder":_vm.$t('trainingSessions.list.search'),"clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"actions-bar"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"},[_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.sort'),"icon":"mdi-swap-vertical","menu-dense":false,"menu-width":"300"}},[_c('training-session-sort',{on:{"change":_vm.sortChanged}})],1)],1),_c('v-btn',{attrs:{"depressed":"","large":"","color":"secondary"},on:{"click":function($event){return _vm.newSession(null)}}},[_vm._v(" "+_vm._s(_vm.$t('trainingSessions.new'))+" ")])],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.actions'),"icon":"mdi-dots-horizontal","x-small":""}},[(_vm.orgUser(item))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.setOrg(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-swap-horizontal ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('trainingSessions.labels.switch'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.newSession(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-content-duplicate ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('trainingSessions.labels.clone'))+" ")])],1)],1)]}},{key:"item.trainingSessionStatus",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.chipColor(value)}},[_vm._v(" "+_vm._s(_vm.chipLabel(value))+" ")])]}},{key:"item.trainingBegins",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item))+" ")]}},{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCreatedDate(value))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),(_vm.createDialog)?_c('training-sessions-create',{attrs:{"clone":_vm.clone},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }