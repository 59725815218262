var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allergen-list"},[_vm._l((_vm.sortedAllergens),function(allergen){return _c('div',{key:allergen.id,class:{
      'allergen-item--readonly': _vm.readonly,
      'allergen-group': allergen.children.length,
      'allergen-item': !allergen.children.length,
    }},[(allergen.children.length)?_c('div',{staticClass:"allergen-group__label"},[_vm._v(" "+_vm._s(allergen.name)+" ")]):_c('allergen-list-item',{attrs:{"allergen":allergen,"crosscontacts":_vm.crosscontacts,"readonly":_vm.readonly},on:{"editCrosscontact":_vm.editCrosscontact}}),_vm._l((allergen.children),function(child){return _c('div',{key:child.id,staticClass:"allergen-item allergen-item--child",class:{ 'allergen-item--readonly': _vm.readonly }},[_c('allergen-list-item',{attrs:{"allergen":child,"crosscontacts":_vm.crosscontacts,"readonly":_vm.readonly},on:{"editCrosscontact":_vm.editCrosscontact}})],1)})],2)}),(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.allergenById(_vm.crosscontact.allergen.id).name)+" ")])]),_c('v-card-text',[_c('div',{staticClass:"form-vertical-gap"},[_c('p',[_vm._v(" "+_vm._s(_vm.allergenById(_vm.crosscontact.allergen.id).definition)+" ")]),_c('v-select',{attrs:{"label":_vm.$t('allergens.labels.dispersionType'),"items":_vm.dispersionTypeItems,"hide-details":""},model:{value:(_vm.crosscontact.dispersionType),callback:function ($$v) {_vm.$set(_vm.crosscontact, "dispersionType", $$v)},expression:"crosscontact.dispersionType"}}),(
              _vm.crosscontact &&
                _vm.crosscontact.dispersionType ===
                  _vm.ProcessingDispersionType.Particulate
            )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('allergens.helpText.particulate'))}}):_vm._e(),(
              _vm.crosscontact.dispersionType ===
                _vm.ProcessingDispersionType.Dispersible
            )?_c('div',{staticClass:"form-vertical-gap"},[_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.hangUpQuantity'),"type":"number","hide-details":"","suffix":"g"},model:{value:(_vm.crosscontact.quantityResidual),callback:function ($$v) {_vm.$set(_vm.crosscontact, "quantityResidual", $$v)},expression:"crosscontact.quantityResidual"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenHangUpPercent', {
                  allergen: _vm.allergenById(_vm.crosscontact.allergen.id).name,
                }),"type":"number","hide-details":"","suffix":"%"},model:{value:(_vm.crosscontact.percentOfPrior),callback:function ($$v) {_vm.$set(_vm.crosscontact, "percentOfPrior", $$v)},expression:"crosscontact.percentOfPrior"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenProteinPercent', {
                  allergen: _vm.allergenById(_vm.crosscontact.allergen.id).name,
                }),"type":"number","hide-details":"","suffix":"%"},model:{value:(_vm.crosscontact.percentResidual),callback:function ($$v) {_vm.$set(_vm.crosscontact, "percentResidual", $$v)},expression:"crosscontact.percentResidual"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.proteinAmount'),"type":"number","value":_vm.crosscontact.calculatedAmountOfProtein.toFixed(6),"hide-details":"","suffix":"mg","disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.batchSizeExposed'),"type":"number","hide-details":"","suffix":"kg"},model:{value:(_vm.crosscontact.amountExposed),callback:function ($$v) {_vm.$set(_vm.crosscontact, "amountExposed", $$v)},expression:"crosscontact.amountExposed"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenProteinAmount'),"type":"number","value":_vm.crosscontact.calculatedPPM.toFixed(6),"hide-details":"","suffix":"ppm","disabled":""}})],1):_vm._e()],1)]),_c('v-card-actions',[(_vm.crosscontact.isPersisted)?_c('v-btn',{attrs:{"color":"red darken-1","text":"","disabled":_vm.loading},on:{"click":_vm.deleteCrosscontact}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","depressed":"","disabled":_vm.loading},on:{"click":_vm.saveCrosscontact}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }