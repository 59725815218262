






















































































import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import authModule from '@/store/Auth';
import { OrganisationType, OrganisationUser } from '@/api';

@Component({})
export default class OrganisationSwitcher extends Vue {
  dialog = false;

  get activeOrganisation() {
    return authModule.activeOrganisation;
  }

  get organisations() {
    return authModule.organisationUsers;
  }

  get filteredOrganisations() {
    return this.organisations.filter(org => {
      if (!org.organisation) {
        return false;
      }
      if (org.organisation.type === 2) {
        return dayjs().isBetween(
          dayjs(org.organisation.trainingBegins),
          dayjs(org.organisation.trainingExpires),
        );
      }
      return true;
    });
  }

  userType(org: OrganisationUser) {
    let userType;
    if (org.isOwner) {
      userType = this.$t('organisationSwitcher.userType.owner');
    } else if (org.isManager) {
      userType = this.$t('organisationSwitcher.userType.manager');
    } else {
      userType = this.$t('organisationSwitcher.userType.regular');
    }
    return userType;
  }

  orgType(org: OrganisationUser) {
    if (org.organisation.type === OrganisationType.TrainingProvider) {
      return ` – ${this.$t('organisations.types.trainingProvider')}`;
    }
    if (org.organisation.type === OrganisationType.TrainingSession) {
      return ` – ${this.$t('organisations.types.trainingSession')}`;
    }
    return '';
  }

  setOrg(org: OrganisationUser) {
    if (!this.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    if (this.activeOrganisation.id !== org.id) {
      authModule.setActiveOrganisationCookies(org);
      this.dialog = false;
      window.location.href = process.env.BASE_URL;
    }
  }

  async setDefault(org: OrganisationUser) {
    await authModule.setDefaultOrganisation(org);
  }

  isActive(org: OrganisationUser) {
    if (!this.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return this.activeOrganisation.id === org.id;
  }
}
