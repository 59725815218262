













import { Component, Vue } from 'vue-property-decorator';

import SortItem from '@/components/dashboard/SortItem.vue';

@Component({ components: { SortItem } })
export default class OrganisationSort extends Vue {
  selectedFilter: string | null = null;

  sortItems = [
    { label: this.$t('organisations.labels.name'), value: 'name' },
    { label: this.$t('common.labels.createdDate'), value: 'created' },
    {
      label: this.$t('organisations.labels.trainingExpires'),
      value: 'training_expires',
    },
    {
      label: this.$t('organisations.access.labels.title'),
      value: 'is_active',
    },
  ];

  sortChanged(order: string) {
    this.$emit('change', { value: this.selectedFilter, order });
  }
}
