













import { Component, Vue } from 'vue-property-decorator';

import SortItem from '@/components/dashboard/SortItem.vue';

@Component({ components: { SortItem } })
export default class TrainingSessionSort extends Vue {
  selectedFilter: string | null = null;

  sortItems = [
    { label: this.$t('common.labels.name'), value: 'name' },
    {
      label: this.$t('organisations.labels.sessionBegins'),
      value: 'training_begins',
    },
    {
      label: this.$t('organisations.labels.sessionExpires'),
      value: 'training_expires',
    },
    {
      label: this.$t('common.labels.created'),
      value: 'created',
    },
  ];

  sortChanged(order: string) {
    this.$emit('change', { value: this.selectedFilter, order });
  }
}
