





















































import v8n from 'v8n';
import { Component, Vue, VModel } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

@Component({})
export default class ChangePasswordDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  currentPassword = '';

  password1 = '';

  password2 = '';

  loading = false;

  valid = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  passwordRules = [
    (v: string) =>
      v8n()
        .optional(
          v8n()
            .string()
            .minLength(8),
          true,
        )
        .test(v) || this.$t('users.validation.passwordLength'),
    (v: string) =>
      v8n()
        .optional(
          v8n()
            .not.numeric()
            .minLength(8),
          true,
        )
        .test(v) || this.$t('users.validation.passwordNonNumeric'),
  ];

  get passwordRules2() {
    return [
      (v: string) =>
        this.password1 === v || this.$t('users.validation.passwordMatch'),
    ];
  }

  get user() {
    return authModule.user;
  }

  async save() {
    if (!this.user) {
      throw new Error(this.$t('users.alerts.missing') as string);
    }
    try {
      this.loading = true;
      if (this.password1 && this.password1 === this.password2) {
        this.user.password = this.password1;
        this.user.currentPassword = this.currentPassword;
      }
      await this.user.save();
      snackModule.setSuccess(this.$t('users.alerts.detailsUpdated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.detailsNotUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
