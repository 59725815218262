











import { Component, Vue } from 'vue-property-decorator';

import CostExplorer from '@/components/common/CostExplorer.vue';

@Component({
  components: { CostExplorer },
})
export default class PublicExplorer extends Vue {}
