

































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Library } from '@/api';

import authModule from '@/store/Auth';
import libraryModule from '@/store/Library';

@Component({})
export default class LibraryUserAvatars extends Vue {
  @Prop({ required: true }) readonly libraryId: string;

  libraries: Partial<Library>[] = [];

  get library() {
    return this.libraries.find(lib => lib.id === this.libraryId);
  }

  get isOrgRead() {
    return this.library && this.library.orgWidePermission !== 'none';
  }

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  get activeOrgUsers() {
    return this.activeOrg.userRelationships.filter(
      orgUser =>
        orgUser.isManager ||
        (this.library &&
          this.library.libraryUsers &&
          this.library.libraryUsers.find(
            libUser => libUser.user.id === orgUser.user.id,
          )),
    );
  }

  get avatarUsers() {
    return this.activeOrgUsers.map(orgUser => orgUser.user).slice(0, 4);
  }

  get userCount() {
    if (this.isOrgRead) {
      return 0;
    }
    return this.activeOrgUsers.length;
  }

  get text() {
    if (this.isOrgRead) {
      return this.$t('libraryUsers.visibility.everyone');
    }
    if (this.userCount === 1) {
      return this.$t('libraryUsers.visibility.you');
    }
    const count = this.userCount - 1;
    return this.$tc('libraryUsers.visibility.count', count, { count });
  }

  async mounted() {
    let libraries: Library[] = [];
    try {
      libraries = await libraryModule.getLibraries(false);
    } catch (e) {
      // Error
    } finally {
      this.libraries = [
        libraryModule.allLibraries,
        libraryModule.defaultLibrary,
        ...libraries,
      ];
    }
  }
}
