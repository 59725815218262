






















































































import { Component, Vue, Prop } from 'vue-property-decorator';

import snackModule from '@/store/Snack';
import { Allergen, Legislation } from '@/api';

import NestedDrag from '@/components/common/NestedDrag.vue';

@Component({
  components: {
    NestedDrag,
  },
})
export default class LegislationAllergenList extends Vue {
  @Prop({ required: true }) readonly legislationId: string;

  legislation: Legislation | null = null;

  allergen: Allergen | null = null;

  dialog = false;

  checkMove(item: Allergen, parentId: string | null) {
    return parentId === null || !item.children.length;
  }

  async moveItem(
    allergen: Allergen,
    parentId: string | null,
    newIndex: number,
  ) {
    try {
      allergen.parent = parentId ? new Allergen({ id: parentId }) : null;
      allergen.order = newIndex;
      await allergen.save({ with: 'parent.id' });
      snackModule.setSuccess(this.$t('common.alerts.itemMoved') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.itemNotMoved') as string,
        errors: [],
      });
    } finally {
      await this.getAllergens();
    }
  }

  async getAllergens() {
    // do I want to use the store here?
    const legislation = (
      await Legislation.includes('allergens').find(this.legislationId)
    ).data;
    legislation.sortAllergens();
    this.legislation = legislation;
  }

  editAllergen(allergen: Allergen | null) {
    this.allergen = allergen
      ? allergen.dup()
      : new Allergen({ legislation: this.legislation });
    this.dialog = true;
  }

  async saveAllergen() {
    if (!this.allergen) {
      throw new Error(this.$t('allergens.alerts.missingAllergen') as string);
    }
    try {
      if (this.allergen.isPersisted) {
        await this.allergen.save();
      } else {
        await this.allergen.save({ with: ['legislation.id'] });
      }
      snackModule.setSuccess(
        this.$t('allergens.alerts.allergenSaved') as string,
      );
      await this.getAllergens();
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('allergens.alerts.allergenNotSaved') as string,
        errors: e.response.errors,
      });
    }
  }

  async deleteAllergen() {
    if (!this.allergen) {
      throw new Error(this.$t('allergens.alerts.missingAllergen') as string);
    }
    if (this.allergen.isPersisted) {
      await this.allergen.destroy();
    }
    await this.getAllergens();
    this.dialog = false;
  }

  async mounted() {
    await this.getAllergens();
  }
}
