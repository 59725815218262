















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Organisation } from '@/api';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import OrganisationsEdit from '@/components/dashboard/organisations/OrganisationsEdit.vue';
import OrganisationUsers from '@/components/dashboard/OrganisationUsers.vue';
import OrganisationSubscriptions from '@/components/dashboard/organisations/OrganisationSubscriptions.vue';
import OrganisationInvoices from '@/components/dashboard/organisations/OrganisationInvoices.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    OrganisationsEdit,
    OrganisationUsers,
    OrganisationSubscriptions,
    OrganisationInvoices,
  },
})
export default class OrganisationsSingle extends Vue {
  item: Organisation | null = null;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get editMode() {
    return this.$route.name === 'organisations-edit';
  }

  get title() {
    return this.editMode
      ? this.$t('organisations.single.editTitle')
      : this.$t('organisations.single.createTitle');
  }

  setRoute() {
    // make sure the route is pointing at the correct id
    // id will change when creating a new one or creating a revision
    // maybe should re-render this component (by setting :key on the router-view)

    if (this.item && this.item.id) {
      this.$router
        .replace({
          name: 'organisations-edit',
          params: { itemId: this.item.id },
        })
        .catch(() => {
          // Stop duplicate route
        });
    } else {
      this.$router.replace({ name: 'organisations-new' }).catch(() => {
        // Stop duplicate route
      });
    }
  }

  organisationUpdated(organisation: Organisation) {
    this.item = organisation;
  }

  async prepareItem() {
    if (this.editMode) {
      this.item = (
        await Organisation.includes([
          'vitalSubscription',
          'allergenBureauSubscription',
        ]).find(this.itemId)
      ).data;
    } else {
      this.item = new Organisation({ type: 0 });
    }
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('item')
  itemChanged() {
    this.setRoute();
  }

  @Watch('itemId')
  itemIdChanged() {
    if (this.item && this.item.id !== this.itemId) {
      this.prepareItem();
    }
  }
}
