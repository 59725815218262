import { Vue } from 'vue-property-decorator';
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import { Legislation } from '@/api';
import store from '@/store';

@Module({ dynamic: true, namespaced: true, name: 'legislation', store })
class LegislationModule extends VuexModule {
  ready = false;

  activeLegislations: Legislation[] = [];

  cachedLegislationMap: { [key: string]: Legislation } = {};

  get allCachedLegislations() {
    return Object.values(this.cachedLegislationMap);
  }

  get activeLegislationCores() {
    // current legislations
    // exclude action grid only legislations
    // map to { label: name, value: coreId }
    return this.activeLegislations
      .filter(legislation => !legislation.isActionGridOnly)
      .map(legislation => ({
        label: legislation.name,
        value: String(legislation.coreId),
      }));
  }

  get getLegislationByCoreId() {
    return (legislationCoreId: string) => {
      return (
        this.activeLegislations.find(
          legislation => legislation.coreId === legislationCoreId,
        ) || null
      );
    };
  }

  @Mutation
  setReady() {
    this.ready = true;
  }

  @Mutation
  setActiveLegislations(legislations: Legislation[]) {
    this.activeLegislations = legislations;
  }

  @Mutation
  addCachedLegislation(legislation: Legislation) {
    Vue.set(this.cachedLegislationMap, legislation.id as string, legislation);
  }

  @Action({ rawError: true })
  async getActiveLegislations() {
    if (!this.ready) {
      const legislations = (
        await Legislation.where({ is_current: true })
          .includes('allergens')
          .order({ name: 'asc' })
          .all()
      ).data;
      legislations.forEach(legislation => {
        legislation.sortAllergens();
        this.addCachedLegislation(legislation);
      });
      this.setActiveLegislations(legislations);
      this.setReady();
    }
  }

  @Action({ rawError: true })
  async getLegislation(legislationId: string, forceFetch = false) {
    if (!this.cachedLegislationMap[legislationId] || forceFetch) {
      const legislation = (
        await Legislation.includes('allergens').find(legislationId)
      ).data;
      legislation.sortAllergens();
      this.addCachedLegislation(legislation);
    }
    return this.cachedLegislationMap[legislationId];
  }
}

export default getModule(LegislationModule, store);
