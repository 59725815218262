import { Model, Attr, HasOne } from 'spraypaint';
import { User } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class ContactForm extends ApplicationRecord {
  static jsonapiType = 'contact-forms';

  static listHeaders() {
    return [
      {
        text: i18n.t('users.labels.fullName'),
        value: 'createdBy',
      },
      {
        text: i18n.t('contactForm.labels.subject'),
        value: 'subject',
      },
      {
        text: i18n.t('users.labels.sent'),
        value: 'created',
        width: 180,
      },
    ];
  }

  static scopeFactory() {
    return ContactForm.includes(['createdBy']);
  }

  @Attr() subject: string;

  @Attr() message: string;

  @Attr() readonly created: string;

  @Attr() readonly modified: string;

  @HasOne() readonly createdBy: User;

  @HasOne() readonly modifiedBy: User;
}
