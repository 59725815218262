










import { Component, Vue } from 'vue-property-decorator';

import legislationModule from '@/store/Legislation';

import FilterItem from '@/components/dashboard/FilterItem.vue';

@Component({ components: { FilterItem } })
export default class IngredientFilters extends Vue {
  get legislationItems() {
    return legislationModule.activeLegislationCores;
  }
}
