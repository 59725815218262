
































































































































import v8n from 'v8n';
import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';
import { RecipeProcessing } from '@/api';

import snackModule from '@/store/Snack';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import ProcessingAllergenList from '@/components/common/ProcessingAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    ProcessingAllergenList,
    AlertTooltip,
  },
})
export default class InlineProcessingEdit extends Vue {
  @VModel({ required: true }) dialog: boolean;

  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly recipeProcessing: RecipeProcessing;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  valid = false;

  allergenValid = 'incomplete';

  isDirty = false;

  loading = false;

  tabIndex = 0;

  get informationAlert() {
    if (!this.valid) {
      return {
        message: this.$t('common.tabs.missingInfo'),
        icon: 'mdi-alert-circle',
        color: 'error',
      };
    }
    if (this.isDirty) {
      return {
        message: this.$t('common.tabs.unsaved'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get allergenAlert() {
    if (this.allergenValid === 'incomplete') {
      return {
        message: this.$t('common.tabs.noAllergens'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get isNewProcessing() {
    return !this.recipeProcessing.processing;
  }

  get processingName() {
    if (this.recipeProcessing.processing) {
      return this.recipeProcessing.processing.name;
    }
    if (this.recipeProcessing.processingData) {
      return this.recipeProcessing.processingData.name;
    }
    throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
  }

  set processingName(val: string) {
    if (this.recipeProcessing.processing) {
      this.recipeProcessing.processing.name = val;
    } else if (this.recipeProcessing.processingData) {
      this.recipeProcessing.processingData.name = val;
    } else {
      throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
    }
  }

  get processingRefCode() {
    if (this.recipeProcessing.processing) {
      return this.recipeProcessing.processing.referenceCode;
    }
    if (this.recipeProcessing.processingData) {
      return this.recipeProcessing.processingData.reference_code;
    }
    throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
  }

  set processingRefCode(val: string) {
    if (this.recipeProcessing.processing) {
      this.recipeProcessing.processing.referenceCode = val;
    } else if (this.recipeProcessing.processingData) {
      this.recipeProcessing.processingData.reference_code = val;
    } else {
      throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
    }
  }

  get processingAssumptions() {
    if (
      this.recipeProcessing.processing &&
      this.recipeProcessing.processing.processingSources[0]
    ) {
      return this.recipeProcessing.processing.processingSources[0].assumptions;
    }
    if (this.recipeProcessing.processingData) {
      return this.recipeProcessing.processingData.assumptions;
    }
    throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
  }

  set processingAssumptions(val: string) {
    if (
      this.recipeProcessing.processing &&
      this.recipeProcessing.processing.processingSources[0]
    ) {
      this.recipeProcessing.processing.processingSources[0].assumptions = val;
    } else if (this.recipeProcessing.processingData) {
      this.recipeProcessing.processingData.assumptions = val;
    } else {
      throw new Error(this.$t('recipes.alerts.processingEmpty') as string);
    }
  }

  async fetchProcessing() {
    if (!this.recipeProcessing.id) {
      throw new Error(this.$t('recipes.alerts.processingMissingId') as string);
    }
    const recipeProcessing = (
      await RecipeProcessing.includes('processing.processingSources').find(
        this.recipeProcessing.id as string,
      )
    ).data;
    this.recipeProcessing.processing = recipeProcessing.processing;
  }

  async deleteRecipeProcessing() {
    try {
      await this.recipeProcessing.destroy();
      snackModule.setSuccess(this.$t('processings.alerts.deleted') as string);
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('processings.alerts.notDeleted') as string,
        errors: e.response.errors,
      });
    }
  }

  async saveRecipeProcessing() {
    this.loading = true;
    try {
      if (this.recipeProcessing.processing) {
        if (this.recipeProcessing.processing.processingSources[0]) {
          await this.recipeProcessing.processing.processingSources[0].save();
        }
        await this.recipeProcessing.processing.save();
      } else {
        await this.recipeProcessing.save({ with: ['recipe.id'] });
      }
      await this.fetchProcessing();
      snackModule.setSuccess(this.$t('processings.alerts.saved') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('processings.alerts.notSaved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.checkIsDirty();
      this.loading = false;
    }
  }

  @Watch('processingName')
  @Watch('processingRefCode')
  @Watch('processingAssumptions')
  checkIsDirty() {
    if (this.recipeProcessing.processing) {
      this.isDirty = this.recipeProcessing.processing.isDirty();
    } else {
      this.isDirty = this.recipeProcessing.isDirty();
    }
  }

  @Watch('dialog')
  dialogChanged() {
    this.tabIndex = 0;
  }
}
