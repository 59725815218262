















































































import v8n from 'v8n';
import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  OrganisationalIngredientReport,
  ReportStatus,
  Legislation,
} from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';
import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import TableActionButton from '@/components/common/TableActionButton.vue';
import ReportFilters from '@/components/dashboard/ReportFilters.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    TableActionButton,
    ReportFilters,
    LegislationSelect,
  },
})
export default class Reports extends Vue {
  actionLevelGridDialog = false;

  newDialog = false;

  valid = false;

  loading = false;

  reportLegislation: Legislation | null = null;

  requiredObject = [
    (v: Legislation) => {
      if (!v) {
        return this.$t('common.validation.generalRequired');
      }

      return (
        v8n()
          .string()
          .not.empty()
          .test(v.id) || this.$t('common.validation.generalRequired')
      );
    },
  ];

  get activeOrg() {
    if (!authModule.activeOrganisation) {
      throw new Error(
        this.$t('common.alerts.missingActiveOrganisation') as string,
      );
    }
    return authModule.activeOrganisation.organisation;
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get headers() {
    return OrganisationalIngredientReport.listHeaders();
  }

  get scopeFactory() {
    return () =>
      OrganisationalIngredientReport.includes('legislation')
        .order({ created: 'desc' })
        .where(this.whereClause);
  }

  get whereClause() {
    return {
      organisation: this.activeOrg.id,
      ...this.filters,
    };
  }

  get filters() {
    const filterObj: {
      legislation__core?: string;
    } = {};
    Object.entries(this.$route.query).map(item => {
      const [key, value] = item;

      if (value != null) {
        switch (key) {
          case 'legislation':
            filterObj.legislation__core = value as string;
            break;

          default:
            break;
        }
      }
      return item;
    });
    return filterObj;
  }

  get filtersActive() {
    return Object.keys(this.filters).length > 0;
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('DD MMMM YYYY, h:mm:ss a')} (${dayjs(
          val,
        ).fromNow()})`
      : '–';
  }

  downloadTooltip(item: OrganisationalIngredientReport) {
    switch (item.status) {
      case ReportStatus.Pending:
        return this.$t('reports.downloadTooltip.pending');
      case ReportStatus.Failed:
        return this.$t('reports.downloadTooltip.failed');
      case ReportStatus.Successful:
        return this.$t('reports.downloadTooltip.success');

      default:
        return '';
    }
  }

  downloadDisabled(item: OrganisationalIngredientReport) {
    switch (item.status) {
      case ReportStatus.Pending:
      case ReportStatus.Failed:
        return true;

      default:
        return false;
    }
  }

  downloadReport(val: string) {
    if (val) {
      window.open(val);
    }
  }

  update() {
    this.table.update();
  }

  async saveReport() {
    try {
      this.loading = true;
      const report = new OrganisationalIngredientReport({
        organisation: this.activeOrg,
        legislation: this.reportLegislation,
      });
      await report.save({ with: ['organisation.id', 'legislation.id'] });
      snackModule.setSuccess(this.$t('reports.alerts.created') as string);
      this.newDialog = false;
      this.update();
    } catch (e) {
      snackModule.setError({
        text: this.$t('reports.alerts.notCreated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('whereClause')
  filtersChanged() {
    this.table.update();
  }
}
