var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"actions-bar mt-0"},[_c('div',{staticClass:"actions-bar__item actions-bar__item--last page-actions"},[_c('div',{staticClass:"form-horizontal-gap-small"},[_c('table-menu-button',{attrs:{"tooltip":_vm.$t('common.labels.filter'),"icon":"mdi-filter-outline","menu-dense":false,"menu-width":"300","is-active":_vm.filtersActive}},[_c('report-filters')],1)],1),_c('SubscribedOnlyButton',{attrs:{"depressed":"","large":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.actionLevelGridDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('reports.labels.actionLevelGrid'))+" ")]),_c('SubscribedOnlyButton',{attrs:{"depressed":"","large":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.newDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('reports.newRecipeReport'))+" ")])],1)]),_c('abstract-table',{ref:"table",attrs:{"headers":_vm.headers,"scopeFactory":_vm.scopeFactory},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.legislation",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.name)+" ")]}},{key:"item.effectiveFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.legislation.effectiveFrom))+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('table-action-button',{attrs:{"tooltip":_vm.downloadTooltip(item),"icon":"mdi-download","x-small":"","disable":_vm.downloadDisabled(item)},on:{"click":function($event){$event.stopPropagation();return _vm.downloadReport(value)}}})]}}],null,true)}),_c('action-level-grid-dialog',{model:{value:(_vm.actionLevelGridDialog),callback:function ($$v) {_vm.actionLevelGridDialog=$$v},expression:"actionLevelGridDialog"}}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}},[(_vm.newDialog)?_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-truncate headline"},[_vm._v(" "+_vm._s(_vm.$t('reports.newRecipeReport'))+" ")])]),_c('v-card-text',[_c('v-form',{staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('legislation-select',{attrs:{"label":_vm.$t('common.labels.legislation'),"outlined":"","currentOnly":"","rules":_vm.requiredObject},model:{value:(_vm.reportLegislation),callback:function ($$v) {_vm.reportLegislation=$$v},expression:"reportLegislation"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.newDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"secondary","loading":_vm.loading,"disabled":!_vm.valid || _vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.saveReport($event)}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }