var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-truncate title-underlined flex-grow-1"},[_vm._v(" "+_vm._s(_vm.processingSource.processing.name)+" – "+_vm._s(_vm.processingSource.name)+" ")])]),_c('v-card-text',[_c('div',{staticClass:"form-vertical-gap"},[_c('div',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('scenarioTester.crosscontactValue', { value: _vm.allergen.name }))+" ")]),_c('div',{staticClass:"text--pre"},[_vm._v(" "+_vm._s(_vm.allergen.definition)+" ")]),_c('v-select',{attrs:{"label":_vm.$t('allergens.labels.dispersionType'),"items":_vm.dispersionTypeItems,"hide-details":""},model:{value:(_vm.crosscontact.dispersionType),callback:function ($$v) {_vm.$set(_vm.crosscontact, "dispersionType", $$v)},expression:"crosscontact.dispersionType"}}),(
            _vm.crosscontact.dispersionType ===
              _vm.ProcessingDispersionType.Dispersible
          )?_c('div',{staticClass:"form-vertical-gap"},[_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.hangUpQuantity'),"type":"number","min":"0","hide-details":"","suffix":"g"},model:{value:(_vm.crosscontact.quantityResidual),callback:function ($$v) {_vm.$set(_vm.crosscontact, "quantityResidual", $$v)},expression:"crosscontact.quantityResidual"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenHangUpPercent', {
                allergen: _vm.allergen.name,
              }),"type":"number","min":"0","hide-details":"","suffix":"%"},model:{value:(_vm.crosscontact.percentOfPrior),callback:function ($$v) {_vm.$set(_vm.crosscontact, "percentOfPrior", $$v)},expression:"crosscontact.percentOfPrior"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenProteinPercent', {
                allergen: _vm.allergen.name,
              }),"type":"number","min":"0","hide-details":"","suffix":"%"},model:{value:(_vm.crosscontact.percentResidual),callback:function ($$v) {_vm.$set(_vm.crosscontact, "percentResidual", $$v)},expression:"crosscontact.percentResidual"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.proteinAmount'),"type":"number","min":"0","value":_vm.crosscontact.calculatedAmountOfProtein.toFixed(6),"hide-details":"","suffix":"mg","disabled":""}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.batchSizeExposed'),"type":"number","min":"0","hide-details":"","suffix":"kg"},model:{value:(_vm.crosscontact.amountExposed),callback:function ($$v) {_vm.$set(_vm.crosscontact, "amountExposed", $$v)},expression:"crosscontact.amountExposed"}}),_c('v-text-field',{attrs:{"label":_vm.$t('allergens.labels.allergenProteinAmount'),"type":"number","min":"0","value":_vm.crosscontact.calculatedPPM.toFixed(6),"hide-details":"","suffix":"ppm","disabled":""}}),(!_vm.scenario.recipe.isComponent)?_c('div',[_c('div',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.$t('allergens.scenarioTestingValue', { value: _vm.allergen.name }))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t('scenarioTester.descriptionValue', {
                  value: _vm.allergen.name,
                })
              )}}),_c('concentration-chart',{attrs:{"scenario":_vm.scenario,"allergen":_vm.allergen,"source":_vm.processingSource}})],1):_vm._e()],1):_vm._e()],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.revert}},[_vm._v(" "+_vm._s(_vm.$t('common.revert'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.done'))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }