import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import store from '@/store';
import Cookies from 'js-cookie';
import i18n from '@/plugins/vue-i18n';

@Module({ dynamic: true, namespaced: true, name: 'locale', store })
class LocaleModule extends VuexModule {
  ready = false;

  locale = 'en';

  @Mutation
  setReady() {
    this.ready = true;
  }

  @Mutation
  setLocale(locale: string) {
    this.locale = locale;
  }

  @Action({ rawError: true })
  async setLanguage(locale: string) {
    i18n.locale = locale;
    this.setLocale(locale);
    Cookies.set('app-locale', locale);
  }

  @Action({ rawError: true })
  async init() {
    const defaultLang = Cookies.get('app-locale');
    if (defaultLang) {
      i18n.locale = defaultLang;
      this.setLocale(defaultLang);
    }
    this.setReady();
  }
}

export default getModule(LocaleModule, store);
