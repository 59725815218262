




































































import Cookies from 'js-cookie';
import { Component, Vue, Watch } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import wordpressModule from '@/store/Wordpress';

import TrainingBanner from '@/components/dashboard/TrainingBanner.vue';
import SubscriptionBanner from '@/components/dashboard/SubscriptionBanner.vue';
import SubscriptionRequiredDialog from '@/components/common/SubscriptionRequiredDialog.vue';
import UpdatingDialog from '@/components/dashboard/UpdatingDialog.vue';
import EnableTotpDialog from '@/components/auth/EnableTotpDialog.vue';

import { OrganisationType } from '@/api';

@Component({
  components: {
    TrainingBanner,
    SubscriptionBanner,
    SubscriptionRequiredDialog,
    UpdatingDialog,
    EnableTotpDialog,
  },
})
export default class DashboardView extends Vue {
  welcomeDialog = false;

  setWelcomeCookie = false;

  get totpRequired() {
    return authModule.totpRequired;
  }

  get canAccess() {
    if (this.isTrainingSession) {
      return this.isActive || authModule.isManager;
    }
    return authModule.canAccessRoute;
  }

  get activeOrg() {
    return authModule.activeOrganisation;
  }

  get subscriptionSunset() {
    if (!this.activeOrg || !this.activeOrg.organisation) return false;
    return this.activeOrg.organisation.voPriceArchived;
  }

  get validSubscription() {
    return authModule.validSubscription;
  }

  get isRegular() {
    return (
      !!this.activeOrg &&
      this.activeOrg.organisation.type === OrganisationType.Regular
    );
  }

  get isTrainingSession() {
    return (
      !!this.activeOrg &&
      this.activeOrg.organisation.type === OrganisationType.TrainingSession
    );
  }

  get isActive() {
    return this.activeOrg && this.activeOrg.organisation.isActive;
  }

  get alwaysActive() {
    return (
      this.activeOrg &&
      this.activeOrg.organisation.manualVitalonlineAccess === true
    );
  }

  get subscriptionStatus() {
    return this.activeOrg && this.activeOrg.organisation.subscriptionStatus;
  }

  get showSubscriptionBanner() {
    return this.isRegular && (!this.isActive || this.subscriptionSunset);
  }

  get routeKey() {
    return authModule.dashboardRouteKey;
  }

  get updatingDialog() {
    return authModule.updatingDialog;
  }

  get postsLoading() {
    return wordpressModule.loading;
  }

  get latestPost() {
    const [post] = wordpressModule.posts;
    return post || null;
  }

  dialogContent(post: WordpressPost) {
    if (!post) {
      return '';
    }

    let content: string | unknown = '';
    if (post.acf && post.acf.dialog_content) {
      content = post.acf.dialog_content;
    } else {
      content = post.excerpt.rendered;
    }

    return content;
  }

  dismissWelcome() {
    if (this.setWelcomeCookie) {
      Cookies.set('latest-post-date', this.latestPost.date, {
        expires: 400,
      });
    }
    this.welcomeDialog = false;
  }

  checkPosts() {
    const postDate = Cookies.get('latest-post-date');
    if (!postDate || (postDate && postDate !== this.latestPost.date)) {
      this.welcomeDialog = true;
    }
  }

  @Watch('postsLoading', { immediate: true })
  postsLoadingChanged(val: boolean) {
    if (val === false) {
      this.checkPosts();
    }
  }
}
