










































































































































import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OrgwideUpdate } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';
import legislationModule from '@/store/Legislation';
import localeModule from '@/store/Locale';

import Snackbar from '@/components/common/Snackbar.vue';
import OrganisationMenu from '@/components/dashboard/OrganisationMenu.vue';
import OrganisationSwitcher from '@/components/dashboard/OrganisationSwitcher.vue';
import LanguagePicker from '@/components/common/LanguagePicker.vue';
import ConfirmDialog from '@/confirm-dialog';
import { Route } from 'vue-router';

@Component({
  components: {
    Snackbar,
    OrganisationMenu,
    OrganisationSwitcher,
    LanguagePicker,
  },
})
export default class App extends Vue {
  menu = [
    {
      title: this.$t('common.menuItems.dashboard'),
      to: {
        name: 'dashboard',
      },
      show: () => true,
    },
    {
      title: this.$t('common.menuItems.ingredients'),
      to: {
        name: 'ingredients',
      },
      show: () => true,
    },
    {
      title: this.$t('common.menuItems.processingProfiles'),
      to: {
        name: 'processing-profiles',
      },
      show: () => true,
    },
    {
      title: this.$t('common.menuItems.recipes'),
      to: {
        name: 'recipes',
      },
      show: () => true,
    },
    {
      title: this.$t('common.menuItems.libraries'),
      to: {
        name: 'libraries',
      },
      show: () => {
        if (!authModule.activeOrganisation) {
          throw new Error(
            this.$t('common.alerts.missingActiveOrganisation') as string,
          );
        }
        return authModule.activeOrganisation.isManager;
      },
    },
    {
      title: this.$t('common.menuItems.reports'),
      to: {
        name: 'reports',
      },
      show: () => true,
    },
  ];

  adminMenu = [
    {
      title: this.$t('common.menuItems.legislations'),
      to: {
        name: 'legislations',
      },
      show: () => {
        return authModule.isLegislationManager;
      },
    },
    {
      title: this.$t('common.menuItems.organisations'),
      to: {
        name: 'organisations',
      },
      show: () => {
        return authModule.isOrganisationManager;
      },
    },
    {
      title: this.$t('common.menuItems.users'),
      to: {
        name: 'users',
      },
      show: () => {
        return authModule.isOrganisationManager;
      },
    },
    {
      title: this.$t('common.menuItems.invoices'),
      to: {
        name: 'invoices',
      },
      show: () => {
        return authModule.isOrganisationManager;
      },
    },
    {
      title: this.$t('common.menuItems.contactEntries'),
      to: {
        name: 'contact-entries',
      },
      show: () => {
        return authModule.isOrganisationManager;
      },
    },
    {
      title: this.$t('common.menuItems.adminReports'),
      to: {
        name: 'admin-reports',
      },
      show: () => {
        return authModule.isOrganisationManager;
      },
    },
    {
      title: this.$t('common.menuItems.trainingSessions'),
      to: {
        name: 'training-sessions',
      },
      show: () => {
        return authModule.isProvider;
      },
    },
  ];

  get filteredMenu() {
    return this.menu.filter(item => item.show());
  }

  get filteredAdminMenu() {
    return this.adminMenu.filter(item => item.show());
  }

  get ready(): boolean {
    return authModule.isLoggedIn
      ? authModule.ready && legislationModule.ready
      : authModule.ready;
  }

  get userAvatar() {
    if (authModule.user) {
      return authModule.user.avatar;
    }
    return '';
  }

  get showAppBar() {
    return (
      this.$route.matched &&
      this.$route.matched[0] &&
      this.$route.matched[0].meta &&
      !!this.$route.matched[0].meta.showAppBar
    );
  }

  get locale() {
    return localeModule.locale;
  }

  get isManager() {
    return authModule.isOwner || authModule.isManager;
  }

  get version() {
    return `Version: ${process.env.VUE_APP_VERSION}`;
  }

  get hash() {
    return `Commit: ${process.env.VUE_APP_COMMIT}`;
  }

  get wpBase() {
    return process.env.VUE_APP_WP_API_BASE;
  }

  async updateAll() {
    try {
      const r = await ConfirmDialog({
        description: this.$t('updateAll.confirm.updateDescription') as string,
      });
      if (r !== 'confirm') {
        return;
      }

      const update = new OrgwideUpdate();
      await update.save();
      if (update.id) {
        authModule.setUpdateItem(update);
      }
      authModule.setOwnerUpdate(true);
      authModule.setUpdatingDialog(true);
    } catch (e) {
      snackModule.setError({
        text: this.$t('updateAll.alerts.error') as string,
        errors: e.response.errors,
      });
    }
  }

  logout() {
    authModule.logout();
  }

  @Watch('locale')
  localeChanged() {
    dayjs.locale(this.locale);
  }

  @Watch('$route', { immediate: true })
  titleChange(to: Route, from: Route) {
    if (
      (!from || from.name !== to.name) &&
      to.name !== 'ingredients-edit' &&
      to.name !== 'processing-profiles-edit' &&
      to.name !== 'recipes-edit' &&
      to.name !== 'libraries-edit'
    ) {
      if (to.meta.title) {
        document.title = `${to.meta.title} - VITAL Online`;

        authModule.trackPageview({
          gtag: this.$gtag,
          route: to,
          pageTitle: to.meta.title,
        });
      } else {
        document.title = 'VITAL Online';
      }
    }
  }
}
