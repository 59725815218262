










































import { Component, Vue } from 'vue-property-decorator';
import { ContactForm } from '@/api';

@Component({
  components: {},
})
export default class ContactEntriesSingle extends Vue {
  item: ContactForm | null = null;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  async mounted() {
    this.item = (
      await ContactForm.includes('createdBy').find(this.itemId)
    ).data;
  }
}
