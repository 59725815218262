































































import { Component, Vue, VModel } from 'vue-property-decorator';
import { Legislation, Processing, ProcessingSource } from '@/api';

import legislationModule from '@/store/Legislation';

import Collapsible from '@/components/common/Collapsible.vue';
import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';

import ProcessingRecipes from '@/components/dashboard/processings/ProcessingRecipes.vue';
import ProcessingRevisionHistory from '@/components/dashboard/processings/ProcessingRevisionHistory.vue';

import ProcessingSummary from '@/components/common/tables/ProcessingSummary.vue';
import ProcessingSourceAssumptions from '@/components/common/tables/ProcessingSourceAssumptions.vue';
import LegislationSummary from '@/components/common/tables/LegislationSummary.vue';
import OutcomesSummary from '@/components/common/tables/OutcomesSummary.vue';

import dayjs from 'dayjs';

@Component({
  components: {
    Collapsible,
    TabLayout,
    TabLayoutItem,
    ProcessingRecipes,
    ProcessingSourceAssumptions,
    ProcessingRevisionHistory,
    ProcessingSummary,
    LegislationSummary,
    OutcomesSummary,
  },
})
export default class ProcessingProfilesOverview extends Vue {
  @VModel({ required: true }) item: Processing;

  legislation = new Legislation();

  sources: ProcessingSource[] = [];

  ready = false;

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('MMMM DD, YYYY')} (${dayjs(val).fromNow()})`
      : '–';
  }

  async mounted() {
    this.legislation = await legislationModule.getLegislation(
      this.item.legislation.id as string,
    );

    this.sources = (
      await ProcessingSource.where({
        processing: this.item.id,
      }).all()
    ).data;

    this.ready = true;
  }
}
