



















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { Recipe, User } from '@/api';

import dayjs from 'dayjs';

@Component({
  components: {},
})
export default class RecipeRevisionHistory extends Vue {
  @Prop({ required: true }) readonly recipeId: string;

  @Prop({ required: true }) readonly recipeCoreId: string;

  revisions: Recipe[] = [];

  formatDate(val: string) {
    return val
      ? dayjs(val).format('DD MMMM YYYY, h:mm:ss a')
      : this.$t('common.labels.unknownDate');
  }

  formatRelativeDate(val: string) {
    return val ? dayjs(val).fromNow() : '';
  }

  formatName(user: User | null) {
    return user ? user.fullName : this.$t('common.labels.unknownUser');
  }

  gotoRevision(revision: Recipe) {
    if (this.recipeId !== revision.id) {
      this.$router.push({
        name: 'recipes-edit',
        params: { itemId: revision.id as string },
      });
    }
  }

  async mounted() {
    this.revisions = (
      await Recipe.where({ coreId: this.recipeCoreId })
        .includes(['createdBy', 'modifiedBy', 'publishedBy'])
        .order({ modified: 'desc' })
        .all()
    ).data;
  }
}
