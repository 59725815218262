



























import { Component, Vue, PropSync } from 'vue-property-decorator';
import { ApplicationRecord, Recipe, BulkAddRecipeTag } from '@/api';

import snackModule from '@/store/Snack';

import TagFilter from '@/components/common/TagFilter.vue';

@Component({ components: { TagFilter } })
export default class BulkAddTags extends Vue {
  @PropSync('items', { required: true }) selectedItems!: ApplicationRecord[];

  tags: string[] = [];

  get itemIds() {
    return (this.selectedItems as Recipe[]).map(item => item.coreId);
  }

  cancel() {
    this.$emit('cancel');
  }

  async save() {
    try {
      const addTags = new BulkAddRecipeTag({
        recipeCoreIds: this.itemIds,
        tags: this.tags,
      });
      await addTags.save();
      this.cancel();
      this.$emit('update');
      snackModule.setSuccess(this.$t('BulkAddTags.alerts.added') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('BulkAddTags.alerts.notAdded') as string,
        errors: e.response.errors,
      });
    }
  }
}
