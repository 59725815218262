import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authModule from '@/store/Auth';
import localeModule from '@/store/Locale';

import nextQuery from '@/nextQuery';

// dashboard
import DashboardView from '@/views/DashboardView.vue';

import Dashboard from '@/components/dashboard/Dashboard.vue';
import ManageOrganisation from '@/components/dashboard/ManageOrganisation.vue';
import UserDetails from '@/components/dashboard/UserDetails.vue';

import Recipes from '@/components/dashboard/Recipes.vue';
import RecipesSingle from '@/components/dashboard/RecipesSingle.vue';

import Ingredients from '@/components/dashboard/Ingredients.vue';
import IngredientsSingle from '@/components/dashboard/IngredientsSingle.vue';

import Libraries from '@/components/dashboard/Libraries.vue';
import LibrariesEdit from '@/components/dashboard/LibrariesEdit.vue';

import ProcessingProfiles from '@/components/dashboard/ProcessingProfiles.vue';
import ProcessingProfilesSingle from '@/components/dashboard/ProcessingProfilesSingle.vue';

import Legislations from '@/components/dashboard/Legislations.vue';
import LegislationsSingle from '@/components/dashboard/LegislationsSingle.vue';

import Organisations from '@/components/dashboard/Organisations.vue';
import OrganisationsSingle from '@/components/dashboard/OrganisationsSingle.vue';

import Users from '@/components/dashboard/Users.vue';
import UsersSingle from '@/components/dashboard/UsersSingle.vue';

import Invoices from '@/components/dashboard/Invoices.vue';

import ContactEntries from '@/components/dashboard/ContactEntries.vue';
import ContactEntriesSingle from '@/components/dashboard/ContactEntriesSingle.vue';

import TrainingSessions from '@/components/dashboard/TrainingSessions.vue';

import Reports from '@/components/dashboard/reports/Reports.vue';
import AdminReports from '@/components/dashboard/admin-reports/AdminReports.vue';

import ComparisonReportsSingle from '@/components/dashboard/reports/ComparisonReportsSingle.vue';
import CompareRecipe from '@/components/dashboard/reports/CompareRecipe.vue';

// checkout
import CheckoutView from '@/views/CheckoutView.vue';

import Checkout from '@/components/checkout/Checkout.vue';
import CheckoutSuccess from '@/components/checkout/CheckoutSuccess.vue';
import CheckoutCancelled from '@/components/checkout/CheckoutCancelled.vue';
import PublicExplorer from '@/components/checkout/PublicExplorer.vue';

// auth
import AuthView from '@/views/AuthView.vue';

import Register from '@/components/auth/Register.vue';
import Login from '@/components/auth/Login.vue';
import PasswordResetForm from '@/components/auth/PasswordResetForm.vue';
import PasswordResetConfirmForm from '@/components/auth/PasswordResetConfirmForm.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: '/',
  //   component: HomeView,
  //   children: [{ path: '', name: 'home' }],
  // },
  {
    path: '',
    component: DashboardView,
    meta: { showAppBar: true, auth: true },
    redirect: '/home/',
    children: [
      {
        path: 'home',
        name: 'dashboard',
        component: Dashboard,
        meta: { title: 'Home' },
      },
      {
        path: 'recipes',
        name: 'recipes',
        component: Recipes,
        meta: { title: 'Recipes' },
      },
      {
        path: 'recipes',
        component: RecipesSingle,
        children: [
          {
            path: 'new',
            name: 'recipes-new',
            meta: { title: 'Create a Recipe' },
          },
          {
            path: ':itemId',
            name: 'recipes-edit',
            meta: { title: 'Edit a Recipe' },
          },
        ],
      },
      {
        path: 'ingredients',
        name: 'ingredients',
        component: Ingredients,
        meta: { title: 'Ingredients' },
      },
      {
        path: 'ingredients',
        component: IngredientsSingle,
        children: [
          {
            path: 'new',
            name: 'ingredients-new',
            meta: { title: 'Create an Ingredient' },
          },
          {
            path: ':itemId',
            name: 'ingredients-edit',
            meta: { title: 'Edit an Ingredient' },
          },
        ],
      },
      {
        path: 'processing-profiles',
        name: 'processing-profiles',
        component: ProcessingProfiles,
        meta: { title: 'Processing Profiles' },
      },
      {
        path: 'processing-profiles',
        component: ProcessingProfilesSingle,
        children: [
          {
            path: 'new',
            name: 'processing-profiles-new',
            meta: { title: 'Create a Processing Profile' },
          },
          {
            path: ':itemId',
            name: 'processing-profiles-edit',
            meta: { title: 'Edit a Processing Profile' },
          },
        ],
      },
      {
        path: 'libraries',
        name: 'libraries',
        component: Libraries,
        meta: { orgManager: true, title: 'Libraries' },
      },
      {
        path: 'libraries',
        component: LibrariesEdit,
        meta: { orgManager: true },
        children: [
          {
            path: 'new',
            name: 'libraries-new',
            meta: { title: 'Create a Library' },
          },
          {
            path: ':itemId',
            name: 'libraries-edit',
            meta: { title: 'Edit a Library' },
          },
        ],
      },
      {
        path: 'jurisdictions',
        name: 'legislations',
        component: Legislations,
        meta: {
          roles: ['legislation_managers'],

          title: 'Jurisdiction',
        },
      },
      {
        path: 'jurisdictions',
        component: LegislationsSingle,
        meta: { roles: ['legislation_managers'] },
        children: [
          {
            path: 'new',
            name: 'legislations-new',
            meta: { title: 'Create a Jurisdiction' },
          },
          {
            path: ':itemId',
            name: 'legislations-edit',
            meta: { title: 'Edit a Jurisdiction' },
          },
        ],
      },
      {
        path: 'organisations',
        name: 'organisations',
        component: Organisations,
        meta: {
          roles: ['organisation_managers'],

          title: 'Organisations',
        },
      },
      {
        path: 'organisations',
        component: OrganisationsSingle,
        meta: { roles: ['organisation_managers'] },
        children: [
          {
            path: 'new',
            name: 'organisations-new',
            meta: { title: 'Create an Organisation' },
          },
          {
            path: ':itemId',
            name: 'organisations-edit',
            meta: { title: 'Edit an Organisation' },
          },
        ],
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: {
          roles: ['organisation_managers'],

          title: 'Users',
        },
      },
      {
        path: 'users',
        component: UsersSingle,
        meta: { roles: ['organisation_managers'] },
        children: [
          {
            path: 'new',
            name: 'users-new',
            meta: { title: 'Create a User' },
          },
          {
            path: ':itemId',
            name: 'users-edit',
            meta: { title: 'Edit a User' },
          },
        ],
      },
      {
        path: 'invoices',
        name: 'invoices',
        component: Invoices,
        meta: {
          roles: ['organisation_managers'],

          title: 'Invoices',
        },
      },
      {
        path: 'contact-entries',
        name: 'contact-entries',
        component: ContactEntries,
        meta: {
          roles: ['organisation_managers'],

          title: 'Contact Entries',
        },
      },
      {
        path: 'contact-entries',
        component: ContactEntriesSingle,
        meta: { roles: ['organisation_managers'] },
        children: [
          {
            path: ':itemId',
            name: 'contact-entries-view',
            meta: { title: 'View Contact Entry' },
          },
        ],
      },
      {
        path: 'admin-reports',
        name: 'admin-reports',
        component: AdminReports,
        meta: { roles: ['organisation_managers'] },
      },
      {
        path: 'manage-organisation',
        name: 'manage-organisation',
        component: ManageOrganisation,
        meta: { title: 'Manage Organisation' },
      },
      {
        path: 'user-details',
        name: 'user-details',
        component: UserDetails,
        meta: { title: 'User Details' },
      },
      {
        path: 'training-sessions',
        name: 'training-sessions',
        component: TrainingSessions,
        meta: { trainingProvider: true, title: 'Training Sessions' },
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        meta: { title: 'Reports' },
      },
      {
        path: 'change-log/compare/:itemA/:itemB',
        name: 'compare',
        component: CompareRecipe,
        meta: {
          orgManager: true,
          title: 'Compare Recipe',
        },
      },
      {
        path: 'change-log',
        component: ComparisonReportsSingle,
        meta: {
          orgManager: true,
        },
        children: [
          {
            path: ':itemId',
            name: 'change-log-view',
            meta: { title: 'View Change Log' },
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: CheckoutView,

    children: [
      {
        path: 'checkout',
        name: 'checkout',
        component: Checkout,
        meta: { auth: true, title: 'Checkout' },
      },
      {
        path: 'checkout-success',
        name: 'checkout-success',
        component: CheckoutSuccess,
        meta: { title: 'Checkout Success' },
      },
      {
        path: 'checkout-cancelled',
        name: 'checkout-cancelled',
        component: CheckoutCancelled,
        meta: { title: 'Checkout Cancelled' },
      },
      {
        path: 'cost-explorer',
        name: 'public-explorer',
        component: PublicExplorer,
        meta: { title: 'Cost Explorer' },
      },
    ],
  },
  {
    path: '',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { title: 'Login' },
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: { title: 'Register' },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: PasswordResetForm,
        meta: { title: 'Forgot Password' },
      },
      {
        path: 'set-password',
        name: 'set-password',
        component: PasswordResetConfirmForm,
        meta: { title: 'Set Password' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // init once
  if (!authModule.ready) {
    await authModule.init();
  }
  if (!localeModule.ready) {
    await localeModule.init();
  }

  // if no active org need to register
  if (
    authModule.isLoggedIn &&
    !authModule.activeOrganisation &&
    to.name !== 'register'
  ) {
    next({ name: 'register', query: { org: 'false' } });
    return;
  }

  // if active org exists move on from register
  if (
    authModule.isLoggedIn &&
    authModule.isOwnerOfAnything &&
    to.name === 'register'
  ) {
    next({ name: 'dashboard' });
    return;
  }

  if (authModule.isLoggedIn) {
    let canAccess = true;

    const ownerOnly = to.matched.some(route => !!route.meta.orgOwner);

    const managerOnly = to.matched.some(route => !!route.meta.orgManager);

    const providerOnly = to.matched.some(
      route => !!route.meta.trainingProvider,
    );

    // If orgOwner meta is present only the Organisation Owner can access
    if (ownerOnly) {
      canAccess = canAccess && authModule.isOwner;
    }

    // If orgManager meta is present only Organisation Managers can access
    if (managerOnly) {
      canAccess = canAccess && authModule.isManager;
    }

    // If providerOnly meta is present only Training Providers can access
    if (providerOnly) {
      canAccess = canAccess && authModule.isProvider;
    }

    // check user roles
    let roles;
    if (to.meta.roles) {
      roles = to.meta.roles;
    } else {
      roles = to.matched.reduce(
        (combined, route) => [...combined, ...((route.meta.roles as []) || [])],
        [],
      );
    }
    canAccess = canAccess && authModule.canAccess(roles);

    // Allow or deny access to current route
    authModule.setCanAccessRoute(canAccess);
  }

  // need to handle authed routes
  const requiresAuth = to.matched.some(match => match.meta.auth);
  if (requiresAuth && !authModule.isLoggedIn) {
    next(`/login?next=${nextQuery()}`);
    return;
  }

  next();
});

export default router;
