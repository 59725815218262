























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProcessingSource } from '@/api';

@Component({
  components: {},
})
export default class ProcessingSourceAssumptions extends Vue {
  @Prop({ required: true }) readonly sources: ProcessingSource[];
}
