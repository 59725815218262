




















































































































































































































































































































































import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StripeProduct } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import TextSwitch from '@/components/common/TextSwitch.vue';
import ContactDialog from '@/components/dashboard/ContactDialog.vue';

interface PriceSelection {
  price: string;
  priceId: string;
  vitalLineItems: Array<{ name: string; price: number }>;
  abLineItems: Array<{ name: string; price: number }>;
}

interface PriceBand {
  name: string;
  tooltip: string;
  isDummy?: boolean;
  monthly: {
    regular: PriceSelection;
  };
  yearly: {
    regular: PriceSelection;
    member: PriceSelection;
    allergenBureau: PriceSelection;
  };
}

@Component({
  components: {
    TextSwitch,
    ContactDialog,
  },
})
export default class CostExplorer extends Vue {
  @Prop({ default: false, type: Boolean }) readOnly: boolean;

  products: StripeProduct[] = [];

  bands: PriceBand[] = [];

  hoverPlan = -1;

  index = 0;

  billingCycles = [
    { text: this.$t('costExplorer.interval.yearly'), value: 'yearly' },
    { text: this.$t('costExplorer.interval.monthly'), value: 'monthly' },
  ];

  lineItems: Array<{ name: string; price: number }> = [];

  priceId = '';

  payByInvoice = false;

  isDummyHovered = false;

  contactDialog = false;

  get user() {
    return authModule.user;
  }

  handleDummyitemClick(band: PriceBand) {
    this.setPlan(-1);
    this.choosePlan(band, -1);
    if (this.user) {
      this.contactDialog = true;
    }
  }

  setPlan(i: number) {
    this.hoverPlan = i;
  }

  choosePlan(band: PriceBand, i: number) {
    switch (i) {
      case 0:
        this.setLineItems(band.monthly.regular);
        break;
      case 1:
        this.setLineItems(band.yearly.regular);
        break;
      case 2:
      case 3:
        this.setLineItems(band.yearly.member);
        break;
      default:
        this.setLineItems(null);
    }
  }

  setLineItems(selection: PriceSelection | null) {
    this.$emit('select', selection);
  }

  formatPrice(value: number, includeDecimal = false) {
    if (!value) {
      return '-';
    }
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value / 100);
  }

  proRataAdjustment() {
    const calcDate = dayjs();
    let anchorDate = dayjs(new Date(calcDate.year(), 3, 1));
    if (calcDate.isBefore(anchorDate)) {
      anchorDate = anchorDate.subtract(1, 'year');
    }
    return calcDate.diff(anchorDate, 'day') / 365;
  }

  generateDummyProduct(name: string) {
    return new StripeProduct({
      name,
      prices: [
        { interval: 'month', value: 0 },
        { interval: 'year', value: 0 },
      ],
      fteMax: -1,
      fteMin: 1,
      isDummy: true,
    });
  }

  get dummyProductBands() {
    return this.bands.filter(p => p.isDummy);
  }

  get validProductBands() {
    return this.bands.filter(p => !p.isDummy);
  }

  async mounted() {
    try {
      this.products = (await StripeProduct.all()).data;
    } catch (e) {
      snackModule.setError({
        text: this.$t('costExplorer.alerts.getError') as string,
        errors: e.response.errors,
      });
    } finally {
      let abProducts = this.products.filter(p =>
        p.name.startsWith('Allergen Bureau'),
      );
      abProducts = [
        ...abProducts,
        this.generateDummyProduct('AB Multi'),
        this.generateDummyProduct('AB Global'),
      ];

      let vitalProducts = this.products
        .filter(p => !abProducts.includes(p))
        .sort((a, b) => a.fteMin - b.fteMin);
      vitalProducts = [
        ...vitalProducts,
        this.generateDummyProduct('Multi Region'),
        this.generateDummyProduct('Global Region'),
      ];

      this.bands = vitalProducts.map(vitalProduct => {
        const abProduct = abProducts.find(
          p =>
            p.fteMin === vitalProduct.fteMin &&
            p.fteMax === vitalProduct.fteMax,
        );
        if (!abProduct) {
          throw new Error(
            this.$t('costExplorer.alerts.abBandMissing') as string,
          );
        }
        const monthlyPrice = vitalProduct.prices.find(
          price => price.interval === 'month',
        );
        if (!monthlyPrice) {
          throw new Error(
            this.$t('costExplorer.alerts.vitalMonthlyMissing') as string,
          );
        }
        const yearlyPrice = vitalProduct.prices.find(
          price => price.interval === 'year',
        );
        if (!yearlyPrice) {
          throw new Error(
            this.$t('costExplorer.alerts.vitalYearlyMissing') as string,
          );
        }
        const yearlyABPrice = abProduct.prices.find(
          price => price.interval === 'year',
        );
        if (!yearlyABPrice) {
          throw new Error(
            this.$t('costExplorer.alerts.abYearlyMissing') as string,
          );
        }

        let tooltip = this.$t('costExplorer.tooltips.range', {
          min: abProduct.fteMin,
          max: abProduct.fteMax,
        }) as string;
        if (abProduct.fteMax === -1) {
          tooltip = this.$t('costExplorer.tooltips.more', {
            value: abProduct.fteMin - 1,
          }) as string;
        }
        if (vitalProduct.isDummy) {
          tooltip = this.$t('costExplorer.tooltips.contact') as string;
        }

        let vitalProductName: string;
        if (vitalProduct.isDummy) {
          vitalProductName = `${vitalProduct.name}<sup>1</sup>`;
        } else if (vitalProduct.fteMax !== -1) {
          vitalProductName = `${vitalProduct.name} (${vitalProduct.fteMin}-${vitalProduct.fteMax} FTE)<sup>*</sup>`;
        } else {
          vitalProductName = `${vitalProduct.name} (>${vitalProduct.fteMin - 1} FTE)<sup>*</sup>`;
        }

        return {
          name: vitalProductName,
          tooltip,
          isDummy: vitalProduct.isDummy,
          monthly: {
            regular: {
              price: this.formatPrice(monthlyPrice.value),
              priceId: monthlyPrice.id,
              vitalLineItems: [
                {
                  name: vitalProduct.name,
                  price: monthlyPrice.value,
                },
              ],
              abLineItems: [],
            },
          },
          yearly: {
            regular: {
              price: this.formatPrice(yearlyPrice.value),
              priceId: yearlyPrice.id,
              vitalLineItems: [
                {
                  name: vitalProduct.name,
                  price: yearlyPrice.value,
                },
              ],
              abLineItems: [],
            },
            member: {
              price: this.formatPrice(yearlyPrice.discounted_value),
              priceId: yearlyABPrice.id,
              vitalLineItems: [
                {
                  name: vitalProduct.name,
                  price: yearlyPrice.value,
                },
                {
                  name: this.$t('costExplorer.labels.memberDiscount') as string,
                  price: yearlyPrice.discounted_value - yearlyPrice.value,
                },
              ],
              abLineItems: [
                {
                  name: abProduct.name,
                  price: yearlyABPrice.value,
                },
                {
                  name: this.$t('costExplorer.labels.proRata') as string,
                  price: -this.proRataAdjustment() * yearlyABPrice.value,
                },
              ],
            },
            allergenBureau: {
              price: this.formatPrice(yearlyABPrice.value),
              priceId: yearlyABPrice.id,
              vitalLineItems: [
                {
                  name: vitalProduct.name,
                  price: yearlyPrice.value,
                },
                {
                  name: this.$t('costExplorer.labels.memberDiscount') as string,
                  price: yearlyPrice.discounted_value - yearlyPrice.value,
                },
              ],
              abLineItems: [
                {
                  name: abProduct.name,
                  price: yearlyABPrice.value,
                },
                {
                  name: this.$t('costExplorer.labels.proRata') as string,
                  price: -this.proRataAdjustment() * yearlyABPrice.value,
                },
              ],
            },
          },
        };
      });
    }
  }
}
