import axios from 'axios';

export default class Wordpress {
  static apiBase(): string {
    return process.env.VUE_APP_WP_API_BASE;
  }

  static async getLatestPosts(): Promise<WordpressPost[]> {
    const path = `${this.apiBase()}/wp-json/wp/v2/posts/?per_page=3`;
    const response = await axios.get(path);
    return response.data;
  }
}
