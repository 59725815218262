import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import {
  Processing,
  ProcessingCrosscontact,
  ProcessingDispersionType,
} from '@/api';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class ProcessingSource extends ApplicationRecord {
  static jsonapiType = 'processing-sources';

  static scopeFactory() {
    return ProcessingSource.includes([]);
  }

  @Attr() name: string;

  @Attr() order: number;

  @Attr() isPlaceholder: boolean; // whats this

  @Attr() assumptions: string;

  @Attr({ dirtyChecker: () => false }) outcome: Outcomes;

  @HasOne() processing: Processing;

  @HasMany() crosscontacts: ProcessingCrosscontact[];

  crosscontactDispersionType(allergenId: string) {
    const crosscontact = this.crosscontacts.find(
      item => item.allergen.id === allergenId,
    );
    return crosscontact ? crosscontact.dispersionType : null;
  }

  crosscontactConcentration(allergenId: string, waterGain: Big = Big('100')) {
    const crosscontact = this.crosscontacts.find(
      item => item.allergen.id === allergenId,
    );
    return crosscontact &&
      crosscontact.dispersionType === ProcessingDispersionType.Dispersible
      ? Big(crosscontact.calculatedPPM).div(waterGain.div('100'))
      : Big('0');
  }
}
