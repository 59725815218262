



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Ingredient, Legislation } from '@/api';

import dayjs from 'dayjs';

@Component({
  components: {},
})
export default class IngredientSummary extends Vue {
  @Prop({ required: true }) readonly ingredient: Ingredient;

  @Prop({ required: true }) readonly legislation: Legislation;

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('DD MMMM YYYY, h:mm:ss a')} (${dayjs(
          val,
        ).fromNow()})`
      : '–';
  }
}
