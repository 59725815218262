







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { roundify } from '@/scenario-tester';
import { Allergen } from '@/api';

@Component({
  components: {},
})
export default class ScenarionTesterRow extends Vue {
  @Prop({ required: true }) readonly row: Allergen;

  @Prop({ default: 'none' }) readonly compare:
    | 'in'
    | 'out'
    | 'deleted'
    | 'added'
    | 'equal'
    | 'none';

  roundify = roundify;
}
