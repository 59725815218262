













import { Component, Vue } from 'vue-property-decorator';

import SortItem from '@/components/dashboard/SortItem.vue';

@Component({ components: { SortItem } })
export default class ContactSort extends Vue {
  selectedFilter: string | null = null;

  sortItems = [
    { label: this.$t('users.labels.fullName'), value: 'createdBy.firstName' },
    { label: this.$t('common.labels.email'), value: 'createdBy.email' },
    { label: this.$t('contactForm.labels.subject'), value: 'subject' },
    { label: this.$t('contactForm.labels.sendDate'), value: 'created' },
  ];

  sortChanged(order: string) {
    this.$emit('change', { value: this.selectedFilter, order });
  }
}
