



















































































import { Component, Vue, VModel, Watch } from 'vue-property-decorator';
import { Ingredient } from '@/api';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import IngredientInformation from '@/components/dashboard/ingredients/IngredientInformation.vue';
import IngredientAllergenList from '@/components/common/IngredientAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import IngredientRevisionHistory from '@/components/dashboard/ingredients/IngredientRevisionHistory.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    IngredientInformation,
    IngredientAllergenList,
    AlertTooltip,
    IngredientRevisionHistory,
  },
})
export default class IngredientsEdit extends Vue {
  @VModel({ required: true }) ingredient: Ingredient;

  tabNumber = 0;

  ingredientInfoValid = 'invalid';

  ingredientAllergenValid = 'invalid';

  get editMode() {
    return this.$route.name === 'ingredients-edit';
  }

  get ingredientValid() {
    return this.ingredientInfoValid === 'complete';
  }

  get infoAlertIcon() {
    switch (this.ingredientInfoValid) {
      case 'unsaved':
      case 'invalid':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get infoAlertColor() {
    switch (this.ingredientInfoValid) {
      case 'unsaved':
        return 'orange';

      case 'invalid':
        return 'error';

      default:
        return 'primary';
    }
  }

  get infoAlertMessage() {
    switch (this.ingredientInfoValid) {
      case 'unsaved':
        return this.$t('common.tabs.unsaved');

      case 'invalid':
        return this.$t('common.tabs.missingInfo');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  get allergenAlertIcon() {
    switch (this.ingredientAllergenValid) {
      case 'incomplete':
        return 'mdi-alert-circle';

      default:
        return 'mdi-check-circle';
    }
  }

  get allergenAlertColor() {
    switch (this.ingredientAllergenValid) {
      case 'incomplete':
        return 'orange';

      default:
        return 'primary';
    }
  }

  get allergenAlertMessage() {
    switch (this.ingredientAllergenValid) {
      case 'incomplete':
        return this.$t('common.tabs.noAllergens');

      default:
        return this.$t('common.tabs.complete');
    }
  }

  ingredientUpdated(ingredient: Ingredient) {
    this.ingredient = ingredient;
  }

  setTab(index: number) {
    this.tabNumber = index;
  }

  @Watch('ingredientValid', { immediate: true })
  validChanged() {
    this.$emit('valid', this.ingredientValid);
  }
}
