



































































import v8n from 'v8n';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { PasswordResetConfirmation } from '@/api';

interface VuetifyForm extends Vue {
  validate: () => boolean;
}

@Component
export default class PasswordResetConfirmForm extends Vue {
  valid = false;

  loading = false;

  success = false;

  firstName = '';

  lastName = '';

  password1 = '';

  password2 = '';

  error = '';

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  passwordRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.passwordRequired'),
    (v: string) =>
      v8n()
        .string()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordLength'),
    (v: string) =>
      v8n()
        .not.numeric()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordNonNumeric'),
  ];

  get passwordRules2() {
    return [
      (v: string) =>
        this.password1 === v || this.$t('users.validation.passwordMatch'),
    ];
  }

  get uid() {
    return typeof this.$route.query.uid === 'string'
      ? this.$route.query.uid
      : '';
  }

  get token() {
    return typeof this.$route.query.token === 'string'
      ? this.$route.query.token
      : '';
  }

  get email() {
    return typeof this.$route.query.email === 'string'
      ? atob(this.$route.query.email)
      : '';
  }

  get welcome() {
    return (
      typeof this.$route.query.welcome === 'string' &&
      this.$route.query.welcome === 'true'
    );
  }

  get queryOk() {
    return !!this.uid && !!this.token && !!this.email;
  }

  async setPassword() {
    this.loading = true;
    try {
      const passwordObj: {
        uid: string;
        token: string;
        newPassword1: string;
        newPassword2: string;
        firstName?: string;
        lastName?: string;
      } = {
        uid: this.uid,
        token: this.token,
        newPassword1: this.password1,
        newPassword2: this.password2,
      };
      if (this.firstName) {
        passwordObj.firstName = this.firstName;
      }
      if (this.lastName) {
        passwordObj.lastName = this.lastName;
      }

      await PasswordResetConfirmation.setPassword(passwordObj);
      this.error = '';
      this.success = true;
    } catch (e) {
      this.error =
        (e as ErrorResponse).response.errors[0].detail || 'Unknown Error';
    } finally {
      this.loading = false;
    }
  }

  home() {
    this.$router.push({ name: 'login' });
  }

  @Watch('password1')
  @Watch('password2')
  passwordChanged() {
    (this.$refs.form as VuetifyForm).validate();
  }
}
