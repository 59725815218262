import { Model, Attr, HasOne } from 'spraypaint';
import { Organisation } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Tag extends ApplicationRecord {
  static jsonapiType = 'tags';

  static scopeFactory() {
    return Tag.includes([]);
  }

  @Attr() name: string;

  @Attr() color: string;

  @Attr() readonly recipeCoreCount: number;

  @HasOne() organisation: Organisation;
}
