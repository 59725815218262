
































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ScenarioTesterRow from '@/components/common/scenario-tester/ScenarioTesterRow.vue';
import Auth from '@/store/Auth';

import ScenarioTester from '@/scenario-tester';

@Component({
  components: {
    ScenarioTesterRow,
  },
})
export default class ScenarioTesterSummary extends Vue {
  @Prop({ required: true }) readonly showOutcome: boolean;

  @Prop({ required: true }) readonly rows: ScenarioRow[];

  @Prop({ default: null }) readonly newRows: ScenarioRow[] | null;

  get comparison() {
    if (this.rows && this.newRows) {
      return ScenarioTester.compareRows(this.newRows, this.rows);
    }
    return null;
  }

  get allEqual() {
    return !this.comparison || !this.comparison.some(row => !row.rowsAreEqual);
  }

  get equalComparator() {
    return this.allEqual ? 'none' : 'equal';
  }

  get unknownRows() {
    return this.rows.filter(row => row.labels.unknown);
  }

  get summedRows() {
    return this.rows.filter(row => row.labels.requiresAL2Footnote);
  }

  get isActive() {
    return Auth.isActive;
  }

  getComparatorRowA(row: {
    revA?: ScenarioRow;
    revB?: ScenarioRow;
    rowsAreEqual: boolean;
  }) {
    if (!row.rowsAreEqual) {
      if (row.revB) {
        return 'in';
      }
      return 'added';
    }
    return this.equalComparator;
  }

  getComparatorRowB(row: {
    revA?: ScenarioRow;
    revB?: ScenarioRow;
    rowsAreEqual: boolean;
  }) {
    if (!row.rowsAreEqual) {
      if (row.revA) {
        return 'out';
      }
      return 'deleted';
    }
    return this.equalComparator;
  }
}
