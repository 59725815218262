

























import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { OrgwideUpdate } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import TableActionButton from '@/components/common/TableActionButton.vue';
import ReportFilters from '@/components/dashboard/ReportFilters.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';
import ActionLevelGridDialog from '@/components/common/ActionLevelGridDialog.vue';

@Component({
  components: {
    AbstractTable,
    TableMenuButton,
    TableActionButton,
    ReportFilters,
    LegislationSelect,
    ActionLevelGridDialog,
  },
})
export default class ComparisonReports extends Vue {
  loading = false;

  get table() {
    return this.$refs.table as AbstractTable;
  }

  get headers() {
    return OrgwideUpdate.listHeaders();
  }

  get scopeFactory() {
    return () => OrgwideUpdate.order({ created: 'desc' });
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('DD MMMM YYYY, h:mm:ss a')} (${dayjs(
          val,
        ).fromNow()})`
      : '–';
  }

  update() {
    this.table.update();
  }
}
