










































import { Component, Vue, Watch, VModel, Prop } from 'vue-property-decorator';
import { Library } from '@/api';

import libraryModule from '@/store/Library';

@Component({})
export default class LibrarySelect extends Vue {
  @VModel({ required: true }) library: Partial<Library>;

  @Prop({ default: false, type: Boolean }) readonly outlined: boolean;

  @Prop({ default: true, type: Boolean }) readonly showRecipeCount: boolean;

  @Prop({ default: false, type: Boolean }) readonly showAll: boolean;

  @Prop({ default: true, type: Boolean }) readonly setActive: boolean;

  @Prop({ required: false }) readonly rules: [];

  get libraries() {
    return this.showAll
      ? [
          libraryModule.allLibraries,
          libraryModule.defaultLibrary,
          ...libraryModule.libraries,
        ]
      : [libraryModule.defaultLibrary, ...libraryModule.libraries];
  }

  mounted() {
    libraryModule.getLibraries(true);
  }

  @Watch('library')
  libraryChanged() {
    if (this.setActive) {
      libraryModule.setActiveLibrary(this.library);
    }
  }
}
