




















































































































































































































































import dayjs from 'dayjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Organisation, StripeSubscription } from '@/api';
import ConfirmDialog from '@/confirm-dialog';

import snackModule from '@/store/Snack';
import SubscriptionNew from '@/components/dashboard/organisations/SubscriptionNew.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({
  components: {
    SubscriptionNew,
    AbstractTable,
  },
})
export default class OrganisationsSubscriptions extends Vue {
  @Prop({ required: true }) readonly item: Organisation;

  organisation = new Organisation(); // tmp value

  subDialog = false;

  subType = 'vital';

  showSubList = false;

  get vitalSubscription() {
    return this.organisation.vitalSubscription;
  }

  get abSubscription() {
    return this.organisation.allergenBureauSubscription;
  }

  get vitalValue() {
    const originalValue = this.vitalSubscription.metadata.price_value / 100;
    const value = this.organisation.hasCoupon
      ? originalValue - this.vitalSubscription.metadata.coupon_value / 100
      : originalValue;
    return this.formatPrice(value, true);
  }

  get headers() {
    return StripeSubscription.listHeaders();
  }

  get scopeFactory() {
    return () =>
      StripeSubscription.where({
        organisation: this.organisation.id,
      }).order({ currentPeriodStart: 'desc' });
  }

  update() {
    this.$emit('update');
    (this.$refs.table as AbstractTable).update();
  }

  showNewSubscription(subscription: StripeSubscription | null) {
    if (!subscription) {
      return true;
    }
    return subscription.status === 'canceled';
  }

  newSubscription(type: string) {
    this.subType = type;
    this.subDialog = true;
  }

  formatDate(value: string) {
    return dayjs(value).format('DD/MM/YYYY');
  }

  formatPrice(value: number, includeDecimal = false) {
    const formatter = new Intl.NumberFormat('au-EN', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });
    return formatter.format(value);
  }

  formatTableDate(val: string) {
    return val ? `${dayjs(val).format('MMMM DD, YYYY')}` : '–';
  }

  titleCase(s: string) {
    return s
      .replace('_', ' ') // split snake_case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, (m: string) => {
        return m.toUpperCase();
      });
  }

  toggleSubList() {
    this.showSubList = !this.showSubList;
  }

  async cancelSubscription(subscription: StripeSubscription) {
    const r = await ConfirmDialog({
      description: this.$t('subscriptions.confirm.cancelDescription') as string,
    });
    if (r !== 'confirm') {
      return;
    }

    try {
      await subscription.destroy();
      this.update();
      snackModule.setSuccess(
        this.$t('subscriptions.alerts.cancelled') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notCancelled') as string,
        errors: e.response.errors,
      });
    }
  }

  async toggleRenew(subscription: StripeSubscription, value: boolean) {
    const r = await ConfirmDialog({
      description: value
        ? (this.$t('subscriptions.confirm.renewDescription') as string)
        : (this.$t('subscriptions.confirm.dontRenewDescription') as string),
    });
    if (r !== 'confirm') {
      subscription.renew = !value;
      return;
    }

    try {
      await subscription.save();
      snackModule.setSuccess(this.$t('subscriptions.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
      subscription.renew = !value;
    }
  }

  async toggleAlwaysApplyCoupon(organisation: Organisation, value: boolean) {
    const r = await ConfirmDialog({
      description: value
        ? (this.$t('subscriptions.confirm.alwaysApplyCoupon') as string)
        : (this.$t('subscriptions.confirm.dontAlwaysApplyCoupon') as string),
    });
    if (r !== 'confirm') {
      organisation.alwaysApplyCoupon = !value;
      return;
    }

    try {
      await organisation.save();
      snackModule.setSuccess(this.$t('organisations.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('organisations.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
      organisation.alwaysApplyCoupon = !value;
    }
  }

  async toggleFinalise(value: boolean) {
    const r = await ConfirmDialog({
      description: value
        ? (this.$t('subscriptions.confirm.finaliseDescription') as string)
        : (this.$t('subscriptions.confirm.dontFinaliseDescription') as string),
    });
    if (r !== 'confirm') {
      this.organisation.rollback();
      return;
    }

    try {
      await this.organisation.save();
      snackModule.setSuccess(this.$t('subscriptions.alerts.updated') as string);
      this.$emit('updated', this.organisation);
    } catch (e) {
      snackModule.setError({
        text: this.$t('subscriptions.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
      this.organisation.rollback();
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.organisation = this.item.dup();
  }
}
