


















































































































































































import v8n from 'v8n';
import { Component, Vue, Watch } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import CountrySelect from '@/components/common/CountrySelect.vue';

@Component({ components: { CountrySelect } })
export default class LoginForm extends Vue {
  firstName = '';

  lastName = '';

  email = '';

  password = '';

  password2 = '';

  organisationName = '';

  companyName = '';

  addressLine1 = '';

  addressLine2 = '';

  city = '';

  state = '';

  country = '';

  postcode = '';

  readTerms = false;

  loading = false;

  valid = false;

  error = '';

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  passwordRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.passwordRequired'),
    (v: string) =>
      v8n()
        .string()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordLength'),
    (v: string) =>
      v8n()
        .not.numeric()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordNonNumeric'),
  ];

  requiredBoolean = [
    (v: boolean) =>
      v8n()
        .boolean()
        .exact(true)
        .test(v) || this.$t('register.validation.generalRequired'),
  ];

  get passwordRules2() {
    return [
      (v: string) =>
        this.password === v || this.$t('users.validation.passwordMatch'),
    ];
  }

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  get isLoggedIn() {
    return authModule.isLoggedIn;
  }

  get missingOrg() {
    return this.$route.query && this.$route.query.org === 'false';
  }

  logout() {
    authModule.logout();
  }

  async register() {
    this.loading = true;
    try {
      await authModule.registerUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
      });
      snackModule.setSuccess(this.$t('register.alerts.success') as string);
    } catch (e) {
      this.error = e.response.errors[0].detail;
    } finally {
      this.loading = false;
    }
  }

  async registerOrg() {
    this.loading = true;
    try {
      const newOrg = await authModule.registerOrganisation({
        organisationName: this.organisationName,
        companyName: this.companyName,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        country: this.country,
        postcode: this.postcode,
      });
      const orgUser = authModule.organisationUsers.find(
        item => item.organisation && item.organisation.id === newOrg.id,
      );
      if (orgUser) {
        authModule.setActiveOrganisationCookies(orgUser);
        window.location.href = process.env.BASE_URL;
      }
    } catch (e) {
      this.error = e.response.errors[0].detail;
    } finally {
      this.loading = false;
    }
  }

  @Watch('password')
  @Watch('password2')
  passwordChanged() {
    (this.$refs.form as HTMLFormElement).validate();
  }
}
