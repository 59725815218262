import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import { Library, Recipe } from '@/api';
import store from '@/store';
import i18n from '@/plugins/vue-i18n';

@Module({ dynamic: true, namespaced: true, name: 'library', store })
class LibraryModule extends VuexModule {
  ready = false;

  libraries: Library[] = [];

  defaultLibrary: Partial<Library> = {
    id: '0',
    name: i18n.t('libraries.labels.default') as string,
    color: 'primary',
    recipeCoreCount: 0,
    libraryUsers: [],
  };

  allLibraries: Partial<Library> = {
    id: '-1',
    name: i18n.t('libraries.labels.all') as string,
    color: 'secondary',
    recipeCoreCount: 0,
    libraryUsers: [],
  };

  activeLibrary = this.allLibraries;

  @Mutation
  setLibraries(libraries: Library[]) {
    this.libraries = libraries;
  }

  @Mutation
  setActiveLibrary(library: Partial<Library>) {
    this.activeLibrary = library;
  }

  @Mutation
  setDefaultLibraryCount(count: number) {
    this.defaultLibrary.recipeCoreCount = count;
  }

  @Mutation
  setTotalCount(count: number) {
    this.allLibraries.recipeCoreCount = count;
  }

  @Mutation
  setReady() {
    this.ready = true;
  }

  @Action({ rawError: true })
  getTotalCount() {
    const libraries = [this.defaultLibrary, ...this.libraries];
    const totalCount = libraries.reduce((acc, item) => {
      const count = item.recipeCoreCount || 0;
      return acc + count;
    }, 0);
    this.setTotalCount(totalCount);
  }

  @Action({ rawError: true })
  async getLibraries(forceFetch = false) {
    if (!this.ready || forceFetch) {
      const libraries = (await Library.includes('libraryUsers.user').all())
        .data;
      const recipeMeta = (
        await Recipe.where({
          library__isnull: true,
          is_newest: true,
          is_archived: false,
        })
          .per(1)
          .all()
      ).meta;
      this.setLibraries(libraries);
      this.setDefaultLibraryCount(recipeMeta.pagination.count);
      this.getTotalCount();
      this.setReady();
    }
    return this.libraries;
  }
}

export default getModule(LibraryModule, store);
