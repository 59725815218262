






import { Component, Vue, Prop } from 'vue-property-decorator';
import { Processing, Recipe } from '@/api';

import RecipesTable from '@/components/dashboard/RecipesTable.vue';

@Component({
  components: { RecipesTable },
})
export default class ProcessingRecipes extends Vue {
  @Prop({ required: true }) readonly item: Processing;

  get scopeFactory() {
    return () =>
      Recipe.includes('recipeTags.tag')
        .order({ modified: 'desc' })
        .where({
          is_newest: true,
          uses_processing: this.item.coreId,
        });
  }
}
