




















































































































































































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Recipe, RecipeProcessing, Processing } from '@/api';

import snackModule from '@/store/Snack';

import ProcessingSearch from '@/components/common/ProcessingSearch.vue';
import ProcessingAllergenList from '@/components/common/ProcessingAllergenList.vue';
import DragAccordion from '@/components/common/DragAccordion.vue';
import OutcomesSummary from '@/components/common/tables/OutcomesSummary.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';
import InlineProcessingEdit from '@/components/dashboard/recipes/InlineProcessingEdit.vue';

@Component({
  components: {
    ProcessingSearch,
    ProcessingAllergenList,
    DragAccordion,
    OutcomesSummary,
    AlertTooltip,
    InlineProcessingEdit,
  },
})
export default class RecipeProcessingsEdit extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  recipe = new Recipe(); // tmp value

  recipeProcessings: RecipeProcessing[] = [];

  recipeProcessing: RecipeProcessing | null = null;

  dialog = false;

  validAssumptions = false;

  isDirty = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('recipes.validation.requiredPublish'),
  ];

  requiredBoolean = [
    (v: boolean) =>
      v8n()
        .boolean()
        .exact(true)
        .test(v) || this.$t('recipes.validation.requiredPublish'),
  ];

  get valid() {
    return (
      this.validAssumptions ||
      (this.recipeProcessings.length && this.validAssumptions)
    );
  }

  get validation() {
    let validation;
    if (this.isDirty) {
      validation = 'unsaved';
    } else if (!this.valid) {
      validation = 'invalid';
    } else {
      validation = 'complete';
    }
    return validation;
  }

  get formFields() {
    return [this.recipe.noProcessings, this.recipe.noProcessingsAssumptions];
  }

  addNewProcessing() {
    this.recipeProcessing = new RecipeProcessing({
      recipe: this.item,
      processingData: {},
    });
    this.dialog = true;
  }

  editRecipeProcessing(recipeProcessing: RecipeProcessing) {
    this.recipeProcessing = recipeProcessing.dup();
    this.recipeProcessing.processing = recipeProcessing.processing.dup();
    this.dialog = true;
  }

  cancel() {
    this.recipe = this.item.dup();
  }

  async save() {
    try {
      this.loading = true;
      await this.recipe.save();
      this.isDirty = false;
      this.$emit('updated', this.recipe);
      snackModule.setSuccess(this.$t('recipes.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async moveItem(
    recipeProcessing: RecipeProcessing,
    parentId: string | null,
    newIndex: number,
  ) {
    try {
      recipeProcessing.order = newIndex;
      await recipeProcessing.save();
      snackModule.setSuccess(this.$t('common.alerts.itemMoved') as string);
    } catch (e) {
      await this.getRecipeProcessings();
      snackModule.setError({
        text: this.$t('common.alerts.itemNotMoved') as string,
        errors: [],
      });
    }
  }

  async addProcessing(processing: Processing) {
    this.loading = true;
    try {
      const recipeProcessing = new RecipeProcessing({
        recipe: this.recipe,
        processing,
      });
      await recipeProcessing.save({ with: ['recipe.id', 'processing.id'] });
      await this.getRecipeProcessings();
      snackModule.setSuccess(this.$t('processings.alerts.added') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('processings.alerts.notAdded') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteRecipeProcessing(recipeProcessing: RecipeProcessing) {
    this.loading = true;
    try {
      if (recipeProcessing.isPersisted) {
        await recipeProcessing.destroy();
      }
      await this.getRecipeProcessings();
      snackModule.setSuccess(this.$t('processings.alerts.removed') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('processings.alerts.notRemoved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async getRecipeProcessings() {
    try {
      const recipeProcessings = (
        await RecipeProcessing.includes(['processing.processingSources'])
          .where({
            recipe: this.recipe.id,
          })
          .order('order')
          .all()
      ).data;
      this.recipeProcessings = recipeProcessings;
    } catch (e) {
      snackModule.setError({
        text: this.$t('processings.alerts.getError') as string,
        errors: e.response.errors,
      });
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.loading = true;
    this.recipe = this.item.dup();
    await this.getRecipeProcessings();
    this.loading = false;
  }

  @Watch('dialog')
  async dialogChanged() {
    if (!this.dialog) {
      this.loading = true;
      await this.getRecipeProcessings();
      this.loading = false;
    }
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }

  @Watch('formFields')
  dirtyChanged() {
    this.isDirty = this.recipe.isDirty();
  }
}
