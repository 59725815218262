




























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class TableMenuButton extends Vue {
  @Prop({ required: true }) icon: string;

  @Prop({ required: true }) tooltip: string;

  @Prop({ type: Boolean, default: false }) readonly xSmall: boolean;

  @Prop({ type: Boolean, default: true }) readonly menuDense: boolean;

  @Prop({ type: Boolean, default: false }) readonly disabled: boolean;

  @Prop({ type: Boolean, default: false }) readonly isActive: boolean;

  @Prop() readonly menuWidth: number;

  get iconColour() {
    return this.isActive ? 'primary' : 'grey darken-1';
  }
}
