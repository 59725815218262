


















































import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

@Component({
  name: 'NestedDrag',
  components: {
    Draggable,
  },
})
export default class NestedDrag extends Vue {
  // items require children and id to work
  @Prop({ default: () => [] }) readonly items: object[];

  @Prop({ default: 0 }) readonly depth: number;

  @Prop({ default: 0 }) readonly maxDepth: number;

  @Prop({ default: null }) readonly parentId: string | null;

  @Prop({ default: false }) readonly disabled: boolean;

  @Prop({ default: null }) readonly checkHandler: (
    item: object,
    parentId: string | null,
    newIndex: number,
  ) => boolean | null;

  internalDragging = false;

  get dragging() {
    return this.internalDragging;
  }

  set dragging(d: boolean) {
    this.internalDragging = d;
    this.$emit('update:dragging', d);
  }

  checkMove(
    event: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
  ) {
    if (this.checkHandler) {
      return this.checkHandler(
        event.draggedContext.element,
        event.relatedContext.component.$parent.parentId,
        event.draggedContext.futureIndex,
      );
    }
    return true;
  }

  moveItem(movedItem: object, parentId: string | null, newIndex: number) {
    this.$emit('move', movedItem, parentId, newIndex);
  }

  async change(
    event: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
  ) {
    if (event.added) {
      const movedItem = event.added.element as object;
      const { newIndex } = event.added;
      this.$emit('move', movedItem, this.parentId, newIndex);
    } else if (event.moved) {
      const movedItem = event.moved.element as object;
      const { newIndex } = event.moved;
      this.$emit('move', movedItem, this.parentId, newIndex);
    }
  }
}
