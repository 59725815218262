import { Model, Attr, HasMany } from 'spraypaint';
import { ComparisonReport } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

export enum UpdateStatus {
  'Pending' = 0,
  'Started' = 1,
  'Completed' = 3,
  'Failed' = 4,
}

@Model()
export default class OrgwideUpdate extends ApplicationRecord {
  static jsonapiType = 'orgwide-updates';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.created'),
        value: 'created',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'prettyStatus',
      },
    ];
  }

  static scopeFactory() {
    return OrgwideUpdate.includes([]);
  }

  @Attr() created: string;

  @Attr() changed: string;

  @Attr() updateAllStarted: string;

  @Attr() lastRecipeUpdated: string;

  @Attr() completed: string;

  @Attr() status: UpdateStatus;

  @Attr() comparison: Comparison;

  // one per legislation
  @HasMany() comparisonReports: ComparisonReport[];

  get prettyStatus() {
    switch (this.status) {
      case UpdateStatus.Pending:
        return 'Pending';
      case UpdateStatus.Started:
        return 'Started';
      case UpdateStatus.Completed:
        return 'Completed';
      case UpdateStatus.Failed:
        return 'Failed';
      default:
        return 'Unknown';
    }
  }
}
