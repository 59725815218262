



































import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';

import LanguagePicker from '@/components/common/LanguagePicker.vue';

@Component({
  components: { LanguagePicker },
})
export default class CheckoutView extends Vue {
  get isLoggedIn() {
    return authModule.isLoggedIn;
  }
}
