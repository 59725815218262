







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/vue-i18n';

import dayjs from 'dayjs';

@Component({})
export default class DatetimeSelect extends Vue {
  @Prop({ default: i18n.t('common.labels.date') }) readonly label: string;

  @Prop({ required: true }) readonly value: string;

  @Prop({ default: false }) readonly hideDetails: boolean;

  dateMenu = false;

  timeMenu = false;

  date = '';

  time = '';

  get datetime() {
    if (this.date && this.time) {
      const [year, month, day] = this.date.split('-');
      const [hour, minute] = this.time.split(':');
      const dt = dayjs(
        new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10),
          parseInt(hour, 10),
          parseInt(minute, 10),
        ),
      );
      return dt.toISOString();
    }
    return '';
  }

  get formattedDate() {
    return this.date ? dayjs(this.date).format('MMMM D, YYYY') : '';
  }

  mounted() {
    if (this.value) {
      const dt = dayjs(this.value);
      if (dt.isValid()) {
        this.date = dt.format('YYYY-MM-DD');
        this.time = dt.format('HH:mm');
      }
    }
  }

  @Watch('value', { immediate: true })
  valueChanged() {
    if (this.value) {
      const dt = dayjs(this.value);
      if (dt.isValid()) {
        this.date = dt.format('YYYY-MM-DD');
        this.time = dt.format('HH:mm');
      }
    }
  }

  @Watch('datetime')
  datetimeChanged() {
    this.$emit('input', this.datetime);
  }
}
