






























































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { Recipe, BulkMoveLibrary, Library } from '@/api';

import libraryModule from '@/store/Library';
import snackModule from '@/store/Snack';

import LibrarySelect from '@/components/common/LibrarySelect.vue';

@Component({ components: { LibrarySelect } })
export default class BulkMove extends Vue {
  @PropSync('items') selectedItems!: Recipe[];

  @Prop() sourceLibrary: Library;

  loading = false;

  complete = false;

  destinationLibrary: Library | Partial<Library> = libraryModule.defaultLibrary;

  bulkMove: BulkMoveLibrary | null = null;

  get itemIds() {
    return this.selectedItems.map(item => item.coreId);
  }

  get hasErrors() {
    return this.bulkMove && this.bulkMove.failures.length;
  }

  itemName(id: string) {
    const selectedItem = this.selectedItems.find(
      item => String(item.coreId) === id,
    );
    return selectedItem ? selectedItem.name : '';
  }

  cancel() {
    this.$emit('cancel');
  }

  async moveItems() {
    let moveObj;
    if (this.sourceLibrary) {
      moveObj = {
        sourceLibrary: this.sourceLibrary,
      };
    } else {
      moveObj = {
        recipeCoreIds: this.itemIds,
      };
    }
    this.bulkMove = new BulkMoveLibrary(moveObj);
    if (this.destinationLibrary && Number(this.destinationLibrary.id) > 0) {
      this.bulkMove.destinationLibrary = this.destinationLibrary as Library;
    }
    try {
      this.loading = true;
      await this.bulkMove.save({
        with: ['sourceLibrary.id', 'destinationLibrary.id'],
      });
      if (this.bulkMove.failures.length) {
        throw new Error(this.$t('bulkMove.alerts.error') as string);
      }
      libraryModule.getLibraries(true);
      this.cancel();
      snackModule.setSuccess(this.$t('bulkMove.alerts.success') as string);
    } catch (e) {
      // Error
    } finally {
      if (!this.sourceLibrary && this.bulkMove.successes.length) {
        this.selectedItems = this.selectedItems.filter(item => {
          if (!this.bulkMove) {
            throw new Error(this.$t('bulkMove.alerts.missing') as string);
          }
          return !this.bulkMove.successes.includes(String(item.coreId));
        });
      }
      this.loading = false;
      this.complete = true;
      this.$emit('update');
    }
  }
}
