



































import v8n from 'v8n';
import { Component, Vue, VModel, Prop } from 'vue-property-decorator';
import { StripeInvoice } from '@/api';

import snackModule from '@/store/Snack';

@Component({})
export default class InvoiceDescriptionDialog extends Vue {
  @VModel({ type: Boolean }) dialog: boolean;

  @Prop({ required: true }) invoice: StripeInvoice;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  async save() {
    try {
      this.loading = true;
      await this.invoice.save();
      snackModule.setSuccess(this.$t('invoices.alerts.updated') as string);
      this.$emit('updated');
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('invoices.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
