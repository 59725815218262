

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipe, Ingredient } from '@/api';

import dayjs from 'dayjs';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import ActionBanner from '@/components/dashboard/ActionBanner.vue';
import IngredientsOverview from '@/components/dashboard/IngredientsOverview.vue';
import IngredientsEdit from '@/components/dashboard/IngredientsEdit.vue';

@Component({
  components: {
    ActionBanner,
    IngredientsOverview,
    IngredientsEdit,
  },
})
export default class IngredientsSingle extends Vue {
  item: Ingredient | null = null;

  recipe: Recipe | null = null;

  valid = false;

  loadingPublish = false;

  loadingRevision = false;

  loadingUpdate = false;

  get itemId() {
    return this.$route.params.itemId || -1;
  }

  get isArchived() {
    return this.item && this.item.isArchived;
  }

  get editMode() {
    return this.$route.name === 'ingredients-edit';
  }

  get revision() {
    return this.item && !this.item.isNewest;
  }

  get draft() {
    return this.item && this.item.status === 'draft';
  }

  get isRecipe() {
    return this.item && this.item.isRecipe;
  }

  get published() {
    return this.item && this.item.status === 'published';
  }

  get title() {
    return this.editMode
      ? this.$t('ingredients.single.editTitle')
      : this.$t('ingredients.single.createTitle');
  }

  get chipColor() {
    if (this.revision) {
      return 'orange';
    }
    if (this.published) {
      return 'green';
    }
    return 'pink';
  }

  get chipLabel() {
    if (this.revision) {
      return this.$t('common.revisionNumber', { number: this.itemId });
    }
    if (this.published) {
      return this.$t('common.labels.published');
    }
    return this.$t('common.labels.draft');
  }

  formatDate(val: string) {
    return val
      ? `${dayjs(val).format('MMMM DD, YYYY')} (${dayjs(val).fromNow()})`
      : '–';
  }

  setRoute() {
    // make sure the route is pointing at the correct id
    // id will change when creating a new one or creating a revision
    // maybe should re-render this component (by setting :key on the router-view)

    if (this.item && this.item.id) {
      this.$router
        .replace({
          name: 'ingredients-edit',
          params: { itemId: this.item.id },
        })
        .catch(() => {
          // Stop duplicate route
        });
    } else {
      this.$router.replace({ name: 'ingredients-new' }).catch(() => {
        // Stop duplicate route
      });
    }
  }

  setTitle() {
    if (this.item) {
      document.title = `${this.item.name} - VITAL Online`;
      authModule.trackPageview({
        gtag: this.$gtag,
        route: this.$route,
        pageTitle: this.item.name,
      });
    }
  }

  toRecipe() {
    if (!this.recipe || !this.recipe.id) {
      throw new Error(this.$t('recipes.alerts.missing') as string);
    }
    this.$router.push({
      name: 'recipes-edit',
      params: { itemId: this.recipe.id },
    });
  }

  async publish() {
    if (!this.item) {
      throw new Error(this.$t('ingredients.alerts.missing') as string);
    }
    if (this.item.status !== 'draft') {
      throw new Error(this.$t('ingredients.alerts.isNotDraft') as string);
    }

    try {
      this.loadingPublish = true;
      const dup = this.item.dup();
      dup.status = 'published';
      await dup.save();
      this.item = dup;
      snackModule.setSuccess(this.$t('ingredients.alerts.published') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.notPublished') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingPublish = false;
    }
  }

  async update() {
    if (!this.item) {
      throw new Error(this.$t('ingredients.alerts.missing') as string);
    }

    try {
      this.loadingUpdate = true;
      const dup = this.item.dup();
      dup.updateRelationships = true;
      await dup.save();
      this.item = dup;
      snackModule.setSuccess(
        this.$t('ingredients.alerts.relsUpdated') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('ingredients.alerts.relsNotUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingUpdate = false;
    }
  }

  async newRevision() {
    if (!this.item) {
      throw new Error(this.$t('ingredients.alerts.missing') as string);
    }
    if (this.item.status !== 'published') {
      throw new Error(this.$t('ingredients.alerts.isNotPublished') as string);
    }

    try {
      this.loadingRevision = true;
      const revision = new Ingredient({
        coreId: this.item.coreId,
      });
      await revision.save();
      this.item = revision;
      snackModule.setSuccess(
        this.$t('common.alerts.revisionCreated') as string,
      );
      this.setRoute();
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.revisionNotCreated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loadingRevision = false;
    }
  }

  async prepareItem() {
    if (this.editMode) {
      try {
        this.item = (await Ingredient.find(this.$route.params.itemId)).data;
        this.setTitle();

        if (this.item.isRecipe && this.item.fromRecipe) {
          this.recipe = (await Recipe.find(this.item.fromRecipe)).data;
        }
      } catch (e) {
        document.title = 'VITAL Online';
        throw e;
      }
    } else {
      this.item = new Ingredient();
    }
  }

  async mounted() {
    await this.prepareItem();
  }

  @Watch('item')
  itemChanged() {
    this.setRoute();
  }

  @Watch('itemId')
  itemIdChanged() {
    this.setTitle();
    if (this.item && this.item.id !== this.itemId) {
      this.prepareItem();
    }
  }
}
