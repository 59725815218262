






























import { Component, Vue, Prop } from 'vue-property-decorator';
import i18n from '@/plugins/vue-i18n';

@Component({})
export default class ActionBanner extends Vue {
  @Prop({ default: i18n.t('common.update') }) readonly buttonText: string;

  @Prop({ type: Boolean, default: false }) readonly readonly: boolean;

  @Prop({ type: Boolean, default: false }) readonly loading: boolean;

  @Prop({ type: Boolean, default: false }) readonly hideAction: boolean;

  action() {
    this.$emit('action');
  }
}
