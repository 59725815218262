



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Library, OrganisationUser, LibraryUser } from '@/api';

@Component({
  components: {},
})
export default class LibraryUsersItem extends Vue {
  @Prop({ required: true }) readonly library: Library;

  @Prop({ required: true }) readonly orgUser: OrganisationUser;

  @Prop({ required: false, default: null })
  readonly libraryUser: LibraryUser | null;

  internalRead = false;

  internalWrite = false;

  get readDisabled() {
    return this.orgUser.isManager || this.library.orgWidePermission !== 'none';
  }

  get writeDisabled() {
    return this.orgUser.isManager || this.library.orgWidePermission === 'write';
  }

  get readChecked() {
    return (
      this.orgUser.isManager ||
      this.library.orgWidePermission !== 'none' ||
      this.internalRead === true ||
      this.internalWrite === true
    );
  }

  set readChecked(val: boolean) {
    const perm = val ? 'read' : 'none';
    this.internalRead = val;
    this.internalWrite = val ? this.internalWrite : false;
    this.$emit('changed', this.orgUser, perm);
  }

  get writeChecked() {
    return (
      this.orgUser.isManager ||
      this.library.orgWidePermission === 'write' ||
      this.internalWrite === true
    );
  }

  set writeChecked(val: boolean) {
    const readPerm = this.internalRead ? 'read' : 'none';
    const perm = val ? 'write' : readPerm;
    this.internalWrite = val;
    this.$emit('changed', this.orgUser, perm);
  }

  mounted() {
    const libraryUser = this.library.libraryUsers.find(e => {
      return e.user.id === this.orgUser.user.id;
    });
    if (libraryUser) {
      if (libraryUser.permission === 'read') {
        this.internalRead = true;
      } else if (libraryUser.permission === 'write') {
        this.internalWrite = true;
      }
    }
  }
}
