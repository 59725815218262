import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import { Organisation, LibraryUser } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Library extends ApplicationRecord {
  static jsonapiType = 'libraries';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('libraries.labels.read'),
        value: 'read',
      },
      {
        text: i18n.t('libraries.labels.write'),
        value: 'write',
      },
    ];
  }

  static scopeFactory() {
    return Library.includes(['libraryUsers.user']);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() color: string;

  @Attr() orgWidePermission: 'none' | 'read' | 'write';

  @Attr() libraryUserChanges: { [key: string]: string };

  @Attr() recipeCoreCount: number;

  @HasOne() organisation: Organisation;

  @HasMany() libraryUsers: LibraryUser[];
}
