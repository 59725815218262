import { Model, Attr, HasOne } from 'spraypaint';
import { Recipe, Processing } from '@/api';
import { isEqual } from 'lodash';
import ApplicationRecord from '../ApplicationRecord';

interface ProcessingData {
  name: string;
  reference_code: string;
  assumptions: string;
}

@Model()
export default class RecipeProcessing extends ApplicationRecord {
  static jsonapiType = 'recipe-processings';

  static scopeFactory() {
    return RecipeProcessing.includes([]);
  }

  // write to "processingData" if you wanna
  // create a recipe-specific processing
  // leave processing blank when you do this
  // get the recipe back with an include

  @Attr({
    dirtyChecker: (oldData: ProcessingData, newData: ProcessingData) =>
      isEqual(oldData, newData),
  })
  processingData: ProcessingData;

  @Attr() order: number;

  @HasOne() processing: Processing;

  @HasOne() recipe: Recipe;
}
