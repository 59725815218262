import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import {
  Legislation,
  ProcessingSource,
  ProcessingDispersionType,
  User,
} from '@/api';
import i18n from '@/plugins/vue-i18n';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Processing extends ApplicationRecord {
  static jsonapiType = 'processings';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
        width: 130,
        align: 'center',
      },
      {
        text: i18n.t('common.labels.modified'),
        value: 'modified',
        width: 180,
      },
      {
        value: 'actions',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return Processing.includes([]);
  }

  @Attr() coreId: string;

  // write only - set this and coreId to duplicate
  @Attr() asNew: boolean;

  @Attr() created: string;

  @Attr() modified: string;

  @Attr() published: string;

  @HasOne() createdBy: User;

  @HasOne() modifiedBy: User;

  @HasOne() publishedBy: User;

  @Attr() name: string;

  @Attr() shared: boolean;

  @Attr() status: 'draft' | 'published';

  @Attr() referenceCode: string;

  @Attr() outdated: boolean;

  @Attr() outdatedLegislation: boolean;

  @Attr() updateRelationships: boolean;

  @Attr() isLatest: boolean;

  @Attr() isNewest: boolean;

  @Attr() readonly isArchived: boolean;

  @Attr() restore: boolean;

  @Attr() recipeCount: number;

  @Attr({ dirtyChecker: () => false }) readonly outcome: Outcomes;

  @HasOne() legislation: Legislation;

  @HasMany() processingSources: ProcessingSource[];

  @Attr() readonly calculationVersion: number;

  get searchableName() {
    return `${this.name} ${this.referenceCode}`;
  }

  areProcessingSourcesOfDispersionType(
    allergenId: string,
    dispersionType: ProcessingDispersionType,
  ) {
    return this.processingSources.some(
      processingSource =>
        processingSource.crosscontactDispersionType(allergenId) ===
        dispersionType,
    );
  }

  processingSourcesDispersionType(allergenId: string, returnLabel = true) {
    // Particulate
    if (
      this.areProcessingSourcesOfDispersionType(
        allergenId,
        ProcessingDispersionType.Particulate,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.particulate')
        : ProcessingDispersionType.Particulate;
    }
    // Dispersible
    if (
      this.areProcessingSourcesOfDispersionType(
        allergenId,
        ProcessingDispersionType.Dispersible,
      )
    ) {
      return returnLabel
        ? i18n.t('common.dispersionTypes.dispersible')
        : ProcessingDispersionType.Dispersible;
    }
    return returnLabel ? 'N/A' : null;
  }

  processingSourcesCrosscontactConcentration(allergenId: string) {
    return this.processingSources.reduce(
      (sum, processingSource) =>
        sum.plus(processingSource.crosscontactConcentration(allergenId)),
      Big('0'),
    );
  }
}
