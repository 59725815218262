





























































































































































import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import { Totp } from '@/api';

import QRious from 'qrious';

@Component({})
export default class EnableTotpDialog extends Vue {
  loading = false;

  showCode = false;

  verifyCode = '';

  qr = '';

  totp: Totp | null = null;

  get user() {
    return authModule.user;
  }

  async copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    snackModule.setSuccess(this.$t('common.clipboard') as string);
  }

  async verifyTotp() {
    try {
      this.loading = true;
      const totp = new Totp({ user: this.user, verifyOtp: this.verifyCode });
      await totp.save({ with: 'user.id' });
      await authModule.refreshUser();
      snackModule.setSuccess(
        this.$t('common.twoFactor.verifySuccess') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.detailsNotUpdated') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async enableTotp() {
    try {
      this.loading = true;
      const totp = new Totp({ user: this.user, setTotp: true });
      await totp.save({ with: 'user.id' });
      this.totp = totp;
      const qr = new QRious({
        value: this.totp.totpLink,
        size: 256,
        padding: 0,
      });
      this.qr = qr.toDataURL();
    } catch (e) {
      this.totp = null;
      snackModule.setError({
        text: this.$t('users.alerts.detailsNotUpdated') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  logout() {
    authModule.logout();
  }
}
