import { Model, Attr, HasOne } from 'spraypaint';
import { StripeSubscription } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class StripeInvoice extends ApplicationRecord {
  static jsonapiType = 'stripe-invoices';

  static listHeaders() {
    return [
      {
        text: i18n.t('invoices.labels.subscription'),
        value: 'subscription',
      },
      {
        text: i18n.t('invoices.labels.subscriptionId'),
        value: 'subscriptionId',
      },
      {
        text: i18n.t('invoices.labels.invoice'),
        value: 'hostedInvoiceUrl',
      },
      {
        text: i18n.t('invoices.labels.amountDue'),
        value: 'amountDue',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
      },
      {
        text: i18n.t('invoices.labels.dueDate'),
        value: 'dueDate',
      },
    ];
  }

  static adminListHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
      },
      {
        text: i18n.t('invoices.labels.subscription'),
        value: 'subscription',
      },
      {
        text: i18n.t('invoices.labels.subscriptionId'),
        value: 'subscriptionId',
      },
      {
        text: i18n.t('invoices.labels.invoice'),
        value: 'hostedInvoiceUrl',
      },
      {
        text: i18n.t('invoices.labels.amountDue'),
        value: 'amountDue',
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
      },
      {
        text: i18n.t('invoices.labels.dueDate'),
        value: 'dueDate',
      },
      {
        value: 'actions',
        width: 64,
      },
    ];
  }

  static scopeFactory() {
    return StripeInvoice.includes([]);
  }

  @Attr() customerId: string;

  @Attr() subscriptionId: string;

  @Attr() productId: string;

  @Attr() status: string;

  @Attr() amountDue: string;

  @Attr() amountPaid: string;

  @Attr() amountRemaining: string;

  @Attr() currency: string;

  @Attr() dueDate: string;

  @Attr() nextPaymentAttempt: string;

  @Attr() hostedInvoiceUrl: string;

  @Attr() invoicePdf: string;

  @Attr() description: string;

  @Attr() readonly canReissue: boolean;

  @Attr() readonly created: boolean;

  // Write only
  @Attr() sendEmail: boolean;

  // Write only
  @Attr() resendEmail: boolean;

  @HasOne() subscription: StripeSubscription;
}
