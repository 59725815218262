
































import v8n from 'v8n';
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { Recipe, Ingredient, Processing } from '@/api';

import snackModule from '@/store/Snack';

@Component({ components: {} })
export default class Duplicate extends Vue {
  @PropSync('items', { required: true }) selectedItems!: (
    | Recipe
    | Ingredient
    | Processing
  )[];

  @Prop({ required: true }) readonly context: string;

  loading = false;

  itemName = '';

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  get item() {
    const [item] = this.selectedItems;
    return item;
  }

  cancel() {
    this.$emit('cancel');
  }

  async save() {
    try {
      this.loading = true;
      switch (this.context) {
        case 'recipes': {
          const recipe = new Recipe({
            coreId: this.item.coreId,
            asNew: true,
            name: this.itemName,
          });
          await recipe.save();
          if (recipe.id) {
            this.$router.push({
              name: 'recipes-edit',
              params: { itemId: recipe.id },
            });
          }
          break;
        }
        case 'ingredients': {
          const ingredient = new Ingredient({
            coreId: this.item.coreId,
            asNew: true,
            name: this.itemName,
          });
          await ingredient.save();
          if (ingredient.id) {
            this.$router.push({
              name: 'ingredients-edit',
              params: { itemId: ingredient.id },
            });
          }
          break;
        }
        case 'processings': {
          const processing = new Processing({
            coreId: this.item.coreId,
            asNew: true,
            name: this.itemName,
          });
          await processing.save();
          if (processing.id) {
            this.$router.push({
              name: 'processing-profiles-edit',
              params: { itemId: processing.id },
            });
          }
          break;
        }

        default:
          break;
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('common.alerts.duplicateError') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = true;
    }
  }

  mounted() {
    this.itemName = `${this.item.name} (copy)`;
  }
}
