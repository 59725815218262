import {
  Legislation,
  Recipe,
  Allergen,
  DispersionType,
  ProcessingDispersionType,
  ProcessingSource,
  RecipeIngredient,
} from '@/api';
import i18n from '@/plugins/vue-i18n';

import Big from 'big.js';
import { flatten, isEqual } from 'lodash';

export function roundify(val: string | Big) {
  try {
    if (Big(val).gte(10)) {
      return Big(val).toFixed(0);
    }
    return Big(val).toPrecision(2);
  } catch (e) {
    return Number.NaN;
  }
}

export default class ScenarioTester {
  legislation: Legislation;

  recipe: Recipe;

  useCalculatedOutcomes = false;

  dirtyRecipeIngredients: { [key: string]: boolean } = {};

  dirtyIngredients: { [key: string]: boolean } = {};

  dirtySources: { [key: string]: boolean } = {};

  sample: boolean;

  constructor(
    legislation: Legislation,
    recipe: Recipe,
    options: { useCalculatedOutcomes?: boolean; sample?: boolean } = {},
  ) {
    this.legislation = legislation;
    this.recipe = recipe;

    // options
    this.useCalculatedOutcomes = options.useCalculatedOutcomes || false;
    this.sample = options.sample || false;
  }

  private getBasicAllergenOutcome(allergen: Allergen): Outcome {
    if (!this.recipe) {
      throw new Error(i18n.t('scenarioTester.alerts.missingRecipe') as string);
    }
    const dispersible =
      this.recipe.areRecipeIngredientsOfDispersionType(
        allergen.id as string,
        DispersionType.Dispersible,
      ) ||
      this.recipe.areRecipeProcessingsOfDispersionType(
        allergen.id as string,
        ProcessingDispersionType.Dispersible,
      );
    const particulate =
      this.recipe.areRecipeIngredientsOfDispersionType(
        allergen.id as string,
        DispersionType.Particulate,
      ) ||
      this.recipe.areRecipeProcessingsOfDispersionType(
        allergen.id as string,
        ProcessingDispersionType.Particulate,
      );
    const intentional = this.recipe.areRecipeIngredientsOfDispersionType(
      allergen.id as string,
      DispersionType.Intentional,
    );
    const ingredientPPM = this.recipe.recipeIngredientsCrosscontactConcentration(
      allergen.id as string,
    );
    const processingPPM = this.recipe.recipeProcessingsCrosscontactConcentration(
      allergen.id as string,
    );
    return {
      allergen_revision: allergen.id as string,
      ppm: ingredientPPM.plus(processingPPM).toFixed(6),
      ppm_self_water_gain: Big(ingredientPPM.plus(processingPPM))
        .div(Big(this.recipe.waterGain).div(100))
        .toFixed(6),
      dispersible,
      particulate,
      intentional,
    };
  }

  private getAllergenRecipeIngredients(allergen: Allergen) {
    if (!this.recipe) {
      throw new Error(i18n.t('scenarioTester.alerts.missingRecipe') as string);
    }
    const allergenIds = [
      allergen.id,
      ...allergen.children.map(child => child.id),
    ];
    const recipeIngredients = this.recipe.recipeIngredients.filter(
      recipeIngredient =>
        recipeIngredient.ingredient.crosscontacts.find(crosscontact =>
          allergenIds.includes(crosscontact.allergen.id),
        ),
    );
    return recipeIngredients;
  }

  private getAllergenRecipeProcessings(allergen: Allergen) {
    if (!this.recipe) {
      throw new Error(i18n.t('scenarioTester.alerts.missingRecipe') as string);
    }
    const allergenIds = [
      allergen.id,
      ...allergen.children.map(child => child.id),
    ];
    const recipeProcessings = this.recipe.recipeProcessings.filter(
      recipeProcessing =>
        recipeProcessing.processing.processingSources.filter(source =>
          source.crosscontacts.find(crosscontact =>
            allergenIds.includes(crosscontact.allergen.id),
          ),
        ).length,
    );
    return recipeProcessings;
  }

  private getAllergenRecipeProcessingSources(allergen: Allergen) {
    if (!this.recipe) {
      throw new Error(i18n.t('scenarioTester.alerts.missingRecipe') as string);
    }
    const allergenIds = [
      allergen.id,
      ...allergen.children.map(child => child.id),
    ];
    const processingSources = flatten(
      this.recipe.recipeProcessings
        .map(recipeProcessing =>
          recipeProcessing.processing.processingSources.filter(source =>
            source.crosscontacts.find(crosscontact =>
              allergenIds.includes(crosscontact.allergen.id),
            ),
          ),
        )
        .filter(source => source.length),
    );
    return processingSources;
  }

  getAllergenLabels(
    allergen: Allergen,
    threshold: Big | null,
    outcomes: { [coreId: string]: Outcome },
  ): LabellingOutcome {
    if (this.recipe.isComponent) {
      return {
        intentional: false,
        actionLevel2: false,
        actionLevel1: false,
        unknown: false,
        requiresAL2Footnote: false,
      };
    }

    const outcome = outcomes[allergen.coreId];

    const ppmParentOutcome = Big(
      allergen.parent ? outcomes[allergen.parent.coreId].ppm : outcome.ppm,
    );

    const ppmOutcomeAfterWaterGain = Big(outcome.ppm).div(
      Big(this.recipe.waterGain).div('100'),
    );

    const ppmParentOutcomeAfterWaterGain = ppmParentOutcome.div(
      Big(this.recipe.waterGain).div('100'),
    );

    const isGroup = !!allergen.children.length;

    const unknown =
      threshold === null &&
      !outcome.intentional &&
      !outcome.particulate &&
      !!outcome.dispersible;

    const thresholdReached =
      threshold !== null && ppmOutcomeAfterWaterGain.gte(threshold);

    const groupThresholdReached =
      threshold !== null && ppmParentOutcomeAfterWaterGain.gte(threshold);

    const requiresAL2Footnote =
      !outcome.particulate &&
      !!outcome.dispersible &&
      threshold !== null &&
      groupThresholdReached &&
      !thresholdReached;

    const intentional = !!outcome.intentional;

    let actionLevel2 =
      !!outcome.particulate || (!!outcome.dispersible && groupThresholdReached);

    let actionLevel1 = !actionLevel2 && !unknown && !!outcome.dispersible;

    actionLevel2 = actionLevel2 && (!intentional || isGroup);
    actionLevel1 = actionLevel1 && ((!intentional && !actionLevel2) || isGroup);

    return {
      intentional,
      actionLevel2,
      actionLevel1,
      unknown,
      requiresAL2Footnote,
    };
  }

  getAllergenActionLevelThreshold(allergen: Allergen) {
    if (this.sample) return Big(15);
    if (!allergen.referenceDose || this.recipe.isComponent) {
      return null;
    }
    const threshold = Big(allergen.referenceDose).times(
      Big('1000').div(this.recipe.referenceAmount),
    );
    if (allergen.maxActionTrans && Big(allergen.maxActionTrans).lt(threshold)) {
      return Big(allergen.maxActionTrans);
    }
    return threshold;
  }

  // get the outcomes by either calculating manually or from the server

  // NOTE: we can't always get from the server because the
  // scenario tester works with unsaved recipe revisions
  getOutcomes() {
    const outcomes: { [key: string]: Outcome } = {};

    if (this.useCalculatedOutcomes) {
      this.legislation.allergens.forEach(allergen => {
        outcomes[allergen.coreId] =
          this.recipe.outcome.total[allergen.coreId] ||
          ScenarioTester.emptyOutcome(allergen.id as string);
      });
      return outcomes;
    }

    this.legislation.sortedAllergens.forEach(allergen => {
      const isGroup = allergen.children.length;
      if (isGroup) {
        const groupOutcome: Outcome = allergen.children.reduce(
          (outcome, child) => {
            const childOutcome = this.getBasicAllergenOutcome(child);
            outcomes[child.coreId] = childOutcome;
            return {
              allergen_revision: child.id as string,
              ppm: Big(outcome.ppm)
                .plus(childOutcome.ppm)
                .toString(),
              ppm_self_water_gain: Big(outcome.ppm_self_water_gain)
                .plus(childOutcome.ppm_self_water_gain)
                .toString(),
              dispersible: outcome.dispersible || childOutcome.dispersible,
              particulate: outcome.particulate || childOutcome.particulate,
              intentional: outcome.intentional || childOutcome.intentional,
            };
          },
          ScenarioTester.emptyOutcome(allergen.id as string),
        );
        outcomes[allergen.coreId] = {
          ...groupOutcome,
          ppm: Big(groupOutcome.ppm).toFixed(6),
          ppm_self_water_gain: Big(groupOutcome.ppm_self_water_gain).toFixed(6),
        };
      } else {
        outcomes[allergen.coreId] = this.getBasicAllergenOutcome(allergen);
      }
    });
    return outcomes;
  }

  getConstituentRows(): ConstituentRow[] {
    const flattened = ScenarioTester.flattenAllergens(
      this.legislation.sortedAllergens,
    );

    return flattened.map(allergen => {
      // looks like this always uses calculated outcomes off server
      const ingredientOutcome =
        this.recipe.outcome.ingredients[allergen.coreId] ||
        ScenarioTester.emptyOutcome(allergen.id as string);
      const processingOutcome =
        this.recipe.outcome.processings[allergen.coreId] ||
        ScenarioTester.emptyOutcome(allergen.id as string);
      const totalOutcome =
        this.recipe.outcome.total[allergen.coreId] ||
        ScenarioTester.emptyOutcome(allergen.id as string);

      const ppmAfterWaterGainTotal = Big(totalOutcome.ppm).div(
        Big(this.recipe.waterGain).div('100'),
      );

      const isGroup = !!allergen.children.length;
      const allergenName = isGroup
        ? `${allergen.name} ${i18n.t('recipes.accordion.totalsSuffix')}`
        : allergen.name;

      return {
        allergenCoreId: allergen.coreId,
        allergen,
        allergenName,
        isGroup,
        isChild: !!allergen.parent,
        ingredientOutcomes: this.recipe.recipeIngredients.map(
          recipeIngredient => {
            const outcome =
              recipeIngredient.ingredient.outcome[allergen.coreId] ||
              ScenarioTester.emptyOutcome(allergen.id as string);
            const ppmBeforeWaterGain = Big(outcome.ppm).times(
              Big(recipeIngredient.percentage).div('100'),
            );
            const ppmAfterWaterGain = Big(ppmBeforeWaterGain).div(
              Big(this.recipe.waterGain).div('100'),
            );
            return {
              outcome: this.sample
                ? ScenarioTester.emptyOutcome(allergen.id as string)
                : outcome,
              ppmBeforeWaterGain: ppmBeforeWaterGain.toFixed(6),
              ppmAfterWaterGain: ppmAfterWaterGain.toFixed(6),
            };
          },
        ),
        processingOutcomes: this.recipe.recipeProcessings.map(
          recipeProcessing => {
            const outcome =
              recipeProcessing.processing.outcome[allergen.coreId] ||
              ScenarioTester.emptyOutcome(allergen.id as string);
            const ppmBeforeWaterGain = Big(outcome.ppm);
            const ppmAfterWaterGain = Big(ppmBeforeWaterGain).div(
              Big(this.recipe.waterGain).div('100'),
            );
            return {
              outcome: this.sample
                ? ScenarioTester.emptyOutcome(allergen.id as string)
                : outcome,
              ppmBeforeWaterGain: ppmBeforeWaterGain.toFixed(6),
              ppmAfterWaterGain: ppmAfterWaterGain.toFixed(6),
            };
          },
        ),
        totalOutcomes: {
          ingredientOutcome: {
            ...ingredientOutcome,
            ppm: Big(ingredientOutcome.ppm).toFixed(6),
          },
          processingOutcome: {
            ...processingOutcome,
            ppm: Big(processingOutcome.ppm).toFixed(6),
          },
          totalOutcome: {
            ...totalOutcome,
            ppm: Big(totalOutcome.ppm).toFixed(6),
          },
          ppmAfterWaterGain: ppmAfterWaterGainTotal.toFixed(6),
        },
      };
    });
  }

  getRows(): ScenarioRow[] {
    const outcomes = this.getOutcomes();
    const flattened = ScenarioTester.flattenAllergens(
      this.legislation.sortedAllergens,
    );

    return flattened.map(allergen => {
      const threshold = this.getAllergenActionLevelThreshold(allergen);
      const outcome = outcomes[allergen.coreId];

      const ppmAfterWaterGain = Big(outcome.ppm).div(
        Big(this.recipe.waterGain).div('100'),
      );

      const isGroup = !!allergen.children.length;
      const allergenName = isGroup
        ? `${allergen.name} ${i18n.t('recipes.accordion.totalsSuffix')}`
        : allergen.name;

      return {
        allergenCoreId: allergen.coreId,
        allergen,
        allergenName,
        isGroup,
        isChild: !!allergen.parent,
        threshold,
        recipeIngredients: this.useCalculatedOutcomes
          ? []
          : this.getAllergenRecipeIngredients(allergen),
        recipeProcessings: this.useCalculatedOutcomes
          ? []
          : this.getAllergenRecipeProcessings(allergen),
        recipeProcessingSources: this.useCalculatedOutcomes
          ? []
          : this.getAllergenRecipeProcessingSources(allergen),
        outcome,
        ppmAfterWaterGain: ppmAfterWaterGain.toFixed(6),
        labels: this.getAllergenLabels(allergen, threshold, outcomes),
      };
    });
  }

  getAllergenConcentrationBreakdown(
    allergen: Allergen,
  ): ConcentrationBreakdown {
    const ingredients = this.getAllergenRecipeIngredients(allergen).filter(
      recipeIngredient =>
        recipeIngredient.ingredient.crosscontactDispersionType(
          allergen.id as string,
        ) === DispersionType.Dispersible,
    );
    const sources = this.getAllergenRecipeProcessingSources(allergen).filter(
      processingSource =>
        processingSource.crosscontactDispersionType(allergen.id as string) ===
        ProcessingDispersionType.Dispersible,
    );
    return {
      ingredients,
      sources,
      threshold: this.getAllergenActionLevelThreshold(allergen),
      waterGain: Big(this.recipe.waterGain),
    };
  }

  // used for concentration chart
  getTransitionPointInformation(
    source: ProcessingSource,
    allergen: Allergen,
  ): TransitionPointInformation {
    const concentrationBreakdown = this.getAllergenConcentrationBreakdown(
      allergen,
    );

    const sourceIndex = concentrationBreakdown.sources.indexOf(source);
    if (sourceIndex === -1) {
      throw new Error(i18n.t('scenarioTester.alerts.invalidSource') as string);
    }
    const crosscontact = source.crosscontacts.find(
      item => item.allergen.id === allergen.id,
    );
    if (!crosscontact) {
      throw new Error(
        i18n.t('scenarioTester.alerts.allergenNotFound') as string,
      );
    }

    if (concentrationBreakdown.threshold === null) {
      throw new Error('threshold is null');
    }

    const ingredientConcentration = ScenarioTester.calculateIngredientConcentration(
      concentrationBreakdown.ingredients,
      allergen,
      concentrationBreakdown.waterGain,
    );

    const priorSourceConcentration = ScenarioTester.calculateSourceConcentration(
      concentrationBreakdown.sources.slice(0, sourceIndex),
      allergen,
      concentrationBreakdown.waterGain,
    );

    const sourceConcentration = ScenarioTester.calculateSourceConcentration(
      concentrationBreakdown.sources,
      allergen,
      concentrationBreakdown.waterGain,
    );

    const priorConcentration = ingredientConcentration.plus(
      priorSourceConcentration,
    );

    const totalConcentration = ingredientConcentration.plus(
      sourceConcentration,
    );

    const transitionConcentration = concentrationBreakdown.threshold.minus(
      priorConcentration,
    );

    const transitionHangup = crosscontact.quantityResidualFromDesiredPPM(
      transitionConcentration,
    );
    const transitionBatchSize = crosscontact.amountExposedFromDesiredPPM(
      transitionConcentration,
    );

    return {
      ingredientConcentration,
      priorConcentration,
      totalConcentration,
      transitionConcentration,
      transitionHangup,
      transitionBatchSize,
      concentrationBreakdown,
    };
  }

  static calculateIngredientConcentration(
    recipeIngredients: RecipeIngredient[],
    allergen: Allergen,
    waterGain: Big,
  ) {
    return recipeIngredients.reduce((sum, item) => {
      const val = item
        .crosscontactPPM(allergen.id as string)
        .div(waterGain.div('100'));
      return sum.plus(val);
    }, Big('0'));
  }

  static calculateSourceConcentration(
    sources: ProcessingSource[],
    allergen: Allergen,
    waterGain: Big,
  ) {
    return sources.reduce((sum, item) => {
      const val = item
        .crosscontactConcentration(allergen.id as string)
        .div(waterGain.div('100'));
      return sum.plus(val);
    }, Big('0'));
  }

  static allergenRowsAreEqual(
    a: Allergen | undefined,
    b: Allergen | undefined,
  ) {
    if (a && b) {
      const parentsEqual =
        !!(!a.parent && !b.parent) ||
        !!(a.parent && b.parent && a.parent.coreId === b.parent.coreId);
      return (
        a.name === b.name &&
        a.referenceDose === b.referenceDose &&
        a.maxActionTrans === b.maxActionTrans &&
        parentsEqual
      );
    }
    return false;
  }

  static rowsAreEqual(a: ScenarioRow | undefined, b: ScenarioRow | undefined) {
    if (a && b) {
      return (
        a.allergen.name === b.allergen.name &&
        a.ppmAfterWaterGain === b.ppmAfterWaterGain &&
        isEqual(
          // a.outcome,
          // b.outcome,
          { ...a.outcome, allergen_revision: undefined },
          { ...b.outcome, allergen_revision: undefined },
        ) &&
        isEqual(a.labels, b.labels)
      );
    }
    return false;
  }

  static compareSortedAllergens(newRows: Allergen[], oldRows: Allergen[]) {
    const oldRowsCopy = [...oldRows];

    // match existing
    const merged: (Allergen | undefined)[][] = newRows.map(newRow => {
      const oldIndex = oldRowsCopy.findIndex(
        oldRow => oldRow.coreId === newRow.coreId,
      );
      if (oldIndex === -1) {
        return [newRow, undefined];
      }
      return [newRow, oldRowsCopy.splice(oldIndex, 1)[0]];
    });

    // add no longer existing
    merged.push(...oldRowsCopy.map(oldRow => [undefined, oldRow]));

    return merged.map(row => {
      const revA = row[0];
      const revB = row[1];
      const rowsAreEqual = this.allergenRowsAreEqual(revA, revB);
      return {
        revA,
        revB,
        rowsAreEqual,
      };
    });
  }

  static compareRows(newRows: ScenarioRow[], oldRows: ScenarioRow[]) {
    const oldRowsCopy = [...oldRows];

    // match existing
    const merged: (ScenarioRow | undefined)[][] = newRows.map(newRow => {
      const oldIndex = oldRowsCopy.findIndex(
        oldRow => oldRow.allergenCoreId === newRow.allergenCoreId,
      );
      if (oldIndex === -1) {
        return [newRow, undefined];
      }
      return [newRow, oldRowsCopy.splice(oldIndex, 1)[0]];
    });

    // add no longer existing
    merged.push(...oldRowsCopy.map(oldRow => [undefined, oldRow]));

    return merged.map(row => {
      const revA = row[0];
      const revB = row[1];
      const rowsAreEqual = this.rowsAreEqual(revA, revB);
      return {
        revA,
        revB,
        rowsAreEqual,
      };
    });
  }

  static emptyOutcome(allergenRevisionId: string): Outcome {
    return {
      allergen_revision: allergenRevisionId,
      ppm: (0).toFixed(6),
      ppm_self_water_gain: (0).toFixed(6),
      dispersible: false,
      particulate: false,
      intentional: false,
    };
  }

  static flattenAllergens(sortedAllergens: Allergen[]) {
    return flatten(
      sortedAllergens.map(allergen => [allergen, ...allergen.children]),
    );
  }

  static actionLevelThreshold(
    allergen: Allergen,
    referenceAmount: Big,
    sample: boolean,
  ) {
    if (sample) return Big(15);
    if (!allergen.referenceDose) {
      return null;
    }
    const threshold = Big(allergen.referenceDose).times(
      Big('1000').div(referenceAmount),
    );
    if (allergen.maxActionTrans && Big(allergen.maxActionTrans).lt(threshold)) {
      return Big(allergen.maxActionTrans);
    }
    return threshold;
  }

  static actionLevelGrid(
    legislation: Legislation,
    referenceAmount: Big,
    sample: boolean,
  ) {
    const flattened = ScenarioTester.flattenAllergens(
      legislation.sortedAllergens,
    );
    return flattened.map(allergen => {
      const threshold = ScenarioTester.actionLevelThreshold(
        allergen,
        referenceAmount,
        sample,
      );
      const isGroup = !!allergen.children.length;
      const allergenName = isGroup
        ? `${allergen.name} ${i18n.t('recipes.accordion.totalsSuffix')}`
        : allergen.name;

      return {
        allergenCoreId: allergen.coreId,
        allergen,
        allergenName,
        isGroup,
        isChild: !!allergen.parent,
        threshold,
      };
    });
  }
}
