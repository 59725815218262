




































import { Component, Vue, Prop } from 'vue-property-decorator';

import { Allergen, IngredientCrosscontact } from '@/api';

@Component({})
export default class AllergenListItem extends Vue {
  @Prop({ required: true }) readonly allergen: Allergen;

  @Prop({ required: true }) readonly crosscontacts: IngredientCrosscontact[];

  @Prop({ default: true }) readonly readonly: boolean;

  // the "first" cross contact
  // the reason the duplicate cross contact bug was not so evident
  get crosscontact() {
    return this.crosscontacts.find(
      crosscontact => crosscontact.allergen.id === this.allergen.id,
    );
  }

  get selected() {
    return !!this.crosscontact;
  }

  // all cross contacts for an allergen
  // now showing as part of the UI changes for the duplicate cross contact bug
  get allergenCrosscontacts() {
    return this.crosscontacts.filter(
      crosscontact => crosscontact.allergen.id === this.allergen.id,
    );
  }

  // duplicate cross contacts for a given allergen should not exist!
  // this IS the duplicate cross contact bug
  get duplicateCCBug() {
    return this.allergenCrosscontacts.length > 1;
  }

  editCrosscontact() {
    if (this.readonly) {
      return;
    }
    this.$emit('editCrosscontact', this.allergen, this.crosscontact || null);
  }
}
