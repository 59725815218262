
























































import { debounce } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/api';

import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({
  components: {
    AbstractTable,
  },
})
export default class Users extends Vue {
  search = '';

  searchQuery = '';

  debounceSearch: () => void;

  get headers() {
    return User.listHeaders();
  }

  get scopeFactory() {
    return () => User.where(this.whereClause).order({ id: 'desc' });
  }

  get whereClause() {
    return {
      search: this.searchQuery,
    };
  }

  get table() {
    return this.$refs.table as AbstractTable;
  }

  mounted() {
    this.debounceSearch = debounce(() => {
      this.searchQuery = this.search;
    }, 500);
  }

  @Watch('whereClause')
  queryChanged() {
    this.table.update();
  }

  @Watch('search')
  searchChanged() {
    this.debounceSearch();
  }
}
