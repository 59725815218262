import { Model, Attr, BelongsTo } from 'spraypaint';
import { Legislation } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Allergen extends ApplicationRecord {
  static jsonapiType = 'allergens';

  static scopeFactory() {
    return Allergen.includes([]);
  }

  @Attr() coreId: string;

  @Attr() created: string;

  @Attr() name: string;

  @Attr() alternativeLabel: string;

  @Attr() definition: string;

  @Attr() status: 'draft' | 'published';

  @Attr() referenceDose: string; // this is in weight (mg)

  @Attr() maxActionTrans: string | null; // this is in ppm

  @Attr() isEmergent: boolean; // da fuck is this?

  @Attr() order: number;

  @BelongsTo() parent: Allergen | null;

  @BelongsTo() legislation: Legislation;

  children: Allergen[] = [];
}
