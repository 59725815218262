






























import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

@Component({
  components: {
    Draggable,
  },
})
export default class Collapsible extends Vue {
  @Prop({ default: false }) readonly defaultValue: boolean;

  @Prop({ default: '' }) readonly title: string;

  visible = false;

  created() {
    this.visible = this.defaultValue;
  }
}
