import { Model, Attr, HasMany, HasOne } from 'spraypaint';
import { Allergen, User } from '@/api';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Legislation extends ApplicationRecord {
  static jsonapiType = 'legislations';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.name'),
        value: 'name',
      },
      {
        text: i18n.t('legislations.labels.effectiveFrom'),
        value: 'effectiveFrom',
      },
      {
        text: i18n.t('legislations.labels.actionGridOnly'),
        value: 'isActionGridOnly',
        width: 130,
      },
      {
        text: i18n.t('common.labels.status'),
        value: 'status',
        width: 130,
        align: 'center',
      },
      {
        text: i18n.t('common.labels.created'),
        value: 'created',
        width: 180,
      },
    ];
  }

  static scopeFactory() {
    return Legislation.includes(['allergens']);
  }

  sortAllergens() {
    const parentAllergens = this.allergens
      .sort((a, b) => a.order - b.order)
      .filter(allergen => !allergen.parent);
    parentAllergens.forEach(parent => {
      parent.children = this.allergens
        .sort((a, b) => a.order - b.order)
        .filter(
          allergen => allergen.parent && allergen.parent.id === parent.id,
        );
    });
    this.sortedAllergens = parentAllergens;
  }

  @Attr() coreId: string;

  @Attr() created: string;

  @Attr() modified: string;

  @Attr() published: string;

  @HasOne() createdBy: User;

  @HasOne() modifiedBy: User;

  @HasOne() publishedBy: User;

  @Attr() name: string;

  @Attr() description: string;

  @Attr() effectiveFrom: string;

  @Attr() isCurrent: boolean;

  @Attr() isNewest: boolean;

  @Attr() status: 'draft' | 'published';

  @HasMany() allergens: Allergen[];

  @Attr() readonly isActionGridOnly: boolean;

  @Attr() actionGridOnly: boolean; // write only

  sortedAllergens: Allergen[] = [];
}
