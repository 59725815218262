











import { Component, Vue } from 'vue-property-decorator';
import authModule from '@/store/Auth';
import { v4 as uuid } from 'uuid';

@Component({
  components: {},
})
export default class SubscribedOnlyButton extends Vue {
  id = uuid();

  get isLocked() {
    return !(
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.isActive
    );
  }

  get isDialogActive() {
    return authModule.showSubscriptionRequiredDialog === this.id;
  }

  click(ev: MouseEvent) {
    if (this.isLocked) {
      authModule.setShowSubscriptionRequiredDialog(this.id);
    } else {
      this.$emit('click', ev);
    }
  }
}
