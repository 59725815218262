








































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import ScenarioTesterTable from '@/components/common/scenario-tester/ScenarioTesterTable.vue';
import ScenarioTesterSummary from '@/components/common/scenario-tester/ScenarioTesterSummary.vue';

import legislationModule from '@/store/Legislation';
import ScenarioTester from '@/scenario-tester';

import { Recipe } from '@/api';
import { JsonapiResource } from 'spraypaint';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

import Big from 'big.js';

@Component({
  components: {
    ScenarioTesterTable,
    ScenarioTesterSummary,
  },
})
export default class ScenarioOverview extends Vue {
  @Prop({ required: true }) readonly recipeId: string;

  outcomes = true;

  scenario: ScenarioTester | null = null;

  rows: ScenarioRow[] = [];

  newRows: ScenarioRow[] | null = null;

  recipeProxy: RecordProxy<Recipe> | null = null;

  isDirty = false;

  get totalPercentage() {
    return this.scenario && this.scenario.recipe
      ? this.scenario.recipe.totalPercentage
      : Big('0');
  }

  get allowReset() {
    return (
      !!this.scenario &&
      (!!Object.values(this.scenario.dirtyRecipeIngredients).filter(
        item => item,
      ).length ||
        !!Object.values(this.scenario.dirtyIngredients).filter(item => item)
          .length ||
        !!Object.values(this.scenario.dirtySources).filter(item => item)
          .length ||
        this.isDirty)
    );
  }

  get formFields() {
    return this.scenario
      ? [this.scenario.recipe.referenceAmount, this.scenario.recipe.waterGain]
      : [];
  }

  get showLabellingOutcome() {
    return this.scenario && !this.scenario.recipe.isComponent;
  }

  recalculate() {
    if (!this.scenario) {
      throw new Error(
        this.$t('scenarioTester.alerts.missingScenario') as string,
      );
    }

    this.outcomes = !this.outcomes;
    if (this.outcomes) {
      this.newRows = this.scenario.getRows();
    }
  }

  async createScenario() {
    if (!this.recipeProxy) {
      throw new Error(
        this.$t('scenarioTester.alerts.missingRecipeProxy') as string,
      );
    }

    const r1 = Recipe.fromJsonapi(
      this.recipeProxy.raw.data as JsonapiResource,
      this.recipeProxy.raw,
    ) as Recipe;

    const legislation = await legislationModule.getLegislation(
      r1.legislation.id as string,
    );

    const scenario = new ScenarioTester(legislation, r1, {
      useCalculatedOutcomes: false,
    });
    this.rows = scenario.getRows();
    this.newRows = null;
    this.scenario = scenario;
  }

  async mounted() {
    this.recipeProxy = await Recipe.includes([
      'recipeIngredients.ingredient.crosscontacts',
      'recipeProcessings.processing.processingSources.crosscontacts',
    ]).find(this.recipeId as string);

    await this.createScenario();
  }

  @Watch('formFields')
  formFieldsChanged() {
    this.isDirty =
      !!this.scenario &&
      !!this.scenario.recipe &&
      this.scenario.recipe.isDirty();
  }
}
