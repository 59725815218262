import { Model, Attr, HasMany } from 'spraypaint';
import { RecipeTag } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class BulkAddRecipeTag extends ApplicationRecord {
  static jsonapiType = 'bulk-add-recipe-tags';

  static scopeFactory() {
    return BulkAddRecipeTag.includes([]);
  }

  @Attr() recipeCoreIds: string[];

  @Attr() tags: string[];

  @HasMany() recipeTags: RecipeTag[];
}
