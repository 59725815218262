import { Model, Attr, HasOne } from 'spraypaint';
import { Allergen, ProcessingSource } from '@/api';
import i18n from '@/plugins/vue-i18n';
import Big from 'big.js';
import ApplicationRecord from '../ApplicationRecord';

export enum ProcessingDispersionType {
  'Particulate' = 0,
  'Dispersible' = 1,
}

export const ProcessingDispersionTypeItems = [
  {
    text: i18n.t('common.dispersionTypes.particulate'),
    value: 0,
  },
  {
    text: i18n.t('common.dispersionTypes.dispersible'),
    value: 1,
  },
];

@Model()
export default class ProcessingCrosscontact extends ApplicationRecord {
  static jsonapiType = 'processing-crosscontacts';

  static scopeFactory() {
    return ProcessingCrosscontact.includes([]);
  }

  @Attr() created: string;

  @Attr() dispersionType: ProcessingDispersionType;

  // this one can be "tweaked" in scenario testing
  @Attr() quantityResidual: string; // hang up quantity

  @Attr() percentOfPrior: string; // % "allergen" in hang up (crosscontact)

  @Attr() percentResidual: string; // % of protein in "allergen"

  // this one can be "tweaked" in scenario testing
  @Attr() amountExposed: string; // batch size exposed to hang up (crosscontact)

  @HasOne() allergen: Allergen;

  @HasOne() source: ProcessingSource;

  get label() {
    switch (this.dispersionType) {
      case ProcessingDispersionType.Particulate:
        return i18n.t('common.dispersionTypes.particulateLabel');
      case ProcessingDispersionType.Dispersible:
        return i18n.t('common.dispersionTypes.dispersibleLabel', {
          amount: this.calculatedPPM.toFixed(6),
        });
      default:
        return '';
    }
  }

  get calculatedAmountOfProtein() {
    try {
      return Big('1000')
        .times(this.quantityResidual)
        .times(Big(this.percentOfPrior).div('100'))
        .times(Big(this.percentResidual).div('100'));
    } catch (e) {
      return Number.NaN;
    }
  }

  get calculatedPPM() {
    try {
      // exit early to avoid div by 0 returning NaN
      if (
        Big(this.calculatedAmountOfProtein).eq('0') ||
        Big(this.amountExposed).eq('0')
      ) {
        return Big('0');
      }
      return Big(this.calculatedAmountOfProtein).div(this.amountExposed);
    } catch (e) {
      return Number.NaN;
    }
  }

  quantityResidualFromDesiredPPM(ppm: Big) {
    try {
      const amountOfProtein = ppm.times(this.amountExposed);
      return amountOfProtein
        .div('1000')
        .div(Big(this.percentOfPrior).div('100'))
        .div(Big(this.percentResidual).div('100'));
    } catch (e) {
      return Number.NaN;
    }
  }

  amountExposedFromDesiredPPM(ppm: Big) {
    try {
      return Big(this.calculatedAmountOfProtein).div(ppm);
    } catch (e) {
      return Number.NaN;
    }
  }
}
