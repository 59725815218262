













































import v8n from 'v8n';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Recipe } from '@/api';

import snackModule from '@/store/Snack';

import TagFilter from '@/components/common/TagFilter.vue';
import LegislationSelect from '@/components/common/LegislationSelect.vue';
import LibrarySelect from '@/components/common/LibrarySelect.vue';

@Component({
  components: {
    TagFilter,
    LegislationSelect,
    LibrarySelect,
  },
})
export default class RecipeYield extends Vue {
  @Prop({ required: true }) readonly item: Recipe;

  recipe = new Recipe(); // tmp value

  recipeOriginal = new Recipe();

  valid = false;

  isDirty = false;

  loading = false;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('recipes.validation.requiredPublish'),
  ];

  get validation() {
    let validation;
    if (this.isDirty) {
      validation = 'unsaved';
    } else if (!this.valid) {
      validation = 'invalid';
    } else {
      validation = 'complete';
    }
    return validation;
  }

  get formFields() {
    return [this.recipe.waterGain, this.recipe.waterGainAssumptions];
  }

  cancel() {
    this.recipe = this.recipeOriginal.dup();
  }

  async save() {
    try {
      this.loading = true;
      await this.recipe.save();
      this.isDirty = false;
      this.$emit('updated', this.recipe);
      snackModule.setSuccess(this.$t('recipes.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('recipes.alerts.notUpdated') as string,
        errors: e.response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('item', { immediate: true })
  async itemChanged() {
    this.recipe = this.item.dup();
    this.recipeOriginal = this.item.dup();
  }

  @Watch('validation', { immediate: true })
  validChanged() {
    this.$emit('validation', this.validation);
  }

  @Watch('formFields')
  dirtyChanged() {
    this.isDirty = this.recipe.isDirty();
  }
}
