import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ScenarioTester, { roundify } from '@/scenario-tester';
import i18n from '@/plugins/vue-i18n';
import dayjs from 'dayjs';
import Big from 'big.js';
import { Legislation } from '@/api';

// use require to get around ts bs
// use raw-loader to get the file contents
// eslint-disable-next-line
const logo = require('!!raw-loader!@/assets/images/vital.svg').default;

const primary = '#0079A5';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.tableLayouts = {
  resultsLayout: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hLineWidth(i: number, node: any) {
      if (i === 0 || i === node.table.body.length) {
        return 0;
      }
      return 0.5;
    },
    vLineWidth() {
      return 0;
    },
    hLineColor(i: number) {
      return i === 1 ? primary : '#ddd';
    },
    paddingTop() {
      return 6;
    },
    paddingBottom() {
      return 6;
    },
    paddingLeft() {
      return 6;
    },
    paddingRight() {
      return 6;
    },
  },
  intentionalFootnoteLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingTop: () => 6,
    paddingBottom: () => 6,
    paddingLeft: () => 6,
    paddingRight: () => 6,
    fillColor: () => '#D9DCF0',
  },
  AL2FootnoteLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingTop: () => 6,
    paddingBottom: () => 6,
    paddingLeft: () => 6,
    paddingRight: () => 6,
    fillColor: () => '#FDD9D7',
  },
  AL1FootnoteLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingTop: () => 6,
    paddingBottom: () => 6,
    paddingLeft: () => 6,
    paddingRight: () => 6,
    fillColor: () => '#DBEFDC',
  },
  unknownFootnoteLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingTop: () => 6,
    paddingBottom: () => 6,
    paddingLeft: () => 6,
    paddingRight: () => 6,
    fillColor: () => '#E4DDDA',
  },
};

function formatDate(val: string) {
  return dayjs(val).format('DD MMMM YYYY, h:mm:ss a');
}

function outcomeLabel(outcome: Outcome, ppm: string) {
  if (outcome.intentional) {
    return {
      text: i18n.t('common.dispersionTypes.intentionalLabel'),
      alignment: 'center',
      color: '#3f51b5',
    };
  }
  if (outcome.particulate) {
    return {
      text: i18n.t('common.dispersionTypes.particulateLabel'),
      alignment: 'center',
      color: '#F44336',
    };
  }
  if (outcome.dispersible) {
    return {
      text: i18n.t('common.ppmValue', {
        value: ppm,
      }),
      alignment: 'center',
    };
  }
  return { text: '–', alignment: 'center' };
}

export default class ReportBuilder {
  reportTitle(text: string) {
    this.content.push({ text, style: 'reportTitle' });
  }

  reportSubtitle(stack: (string | object)[]) {
    this.content.push({ stack, style: 'reportSubtitle' });
  }

  reportTable(tableBody: object[], tableWidths?: string[]) {
    this.content.push({
      layout: 'resultsLayout', // optional
      margin: [0, 0, 0, 6],
      table: {
        headerRows: 1,
        widths: tableWidths,
        body: tableBody,
      },
    });
  }

  basicFootnote(stack: object[], layoutName: string) {
    this.content.push({
      margin: [0, 0, 0, 6],
      layout: layoutName,
      table: {
        widths: ['*'],
        body: [
          [
            {
              stack,
            },
          ],
        ],
      },
    });
  }

  unknownFootnote(rows: ScenarioRow[]) {
    this.basicFootnote(
      [
        {
          text: i18n.t('scenarioTester.footnotes.unknown'),
        },
        {
          ul: rows.map(row => row.allergen.name),
          margin: [6, 3, 0, 0],
        },
      ],
      'unknownFootnoteLayout',
    );
  }

  summedAL2Footnote(rows: ScenarioRow[]) {
    this.basicFootnote(
      [
        {
          text: i18n.t('scenarioTester.footnotes.summedAL2'),
        },
        {
          ul: rows.map(row => row.allergen.name),
          margin: [6, 3, 0, 0],
        },
      ],
      'AL2FootnoteLayout',
    );
  }

  fullReportIntroduction() {
    this.reportTitle(
      `Detailed Summary Report from VITAL Calculator ${i18n.t('version')}`,
    );
    this.reportTitle('Disclaimer');
    this.reportSubtitle([
      'This document is intended to provide general information only for educative and illustrative purposes, and is not professional or technical advice. Seek professional advice about its contents to determine whether, and the extent to which, it applies or does not apply to your own circumstances. The document is provided on the basis that no liability of any kind, including in relation to negligence, will be accepted by the Allergen Bureau in relation to, or any use of, its contents in any circumstances.',
    ]);
    this.reportSubtitle([
      {
        text: [
          'This report has been produced using VITAL Online, a web-based version of the VITAL Program. A full understanding of the VITAL Program is required to implement the VITAL Program and its labelling outcomes. Any evaluation or investigation made, and any outcomes achieved using VITAL Online will only be of value if the risk review is done thoroughly by experienced personnel with knowledge of the ingredients and the manufacturing environment. Information about the VITAL Program can be accessed at ',
          {
            text: 'https://allergenbureau.net/vital/',
            link: 'https://allergenbureau.net/vital/',
          },
          '. For further details, see the end of this report.',
        ],
      },
    ]);
    return this;
  }

  allergenNotes() {
    this.reportTitle('Notes');
    this.reportSubtitle([
      { text: 'General', bold: true },
      {
        ul: [
          'Information about the VITAL® Program, including the Food Industry Guide to the Voluntary Incidental Trace Allergen Labelling (VITAL)® Program, can be accessed.',
          'It is important that the VITAL® Program is understood before commencing use of VITAL® Online. The information that needs to be considered in the risk review assessment can be found in the VITAL® Guide.',
          'Any evaluation or investigation made and any outcomes achieved through using VITAL® Online will only be of value if the risk review is done thoroughly by experienced personnel with knowledge of the ingredients and the manufacturing environment.',
        ],
        margin: [6, 3, 0, 0],
      },
    ]);
    this.reportSubtitle([
      { text: 'Exemptions from Mandatory Allergen Declaration', bold: true },
      {
        ul: [
          'Exemptions from the mandatory declaration for allergens are included in some legislation. It is important to understand the relevant regulatory framework and any requirements which may impact the labelling requirement. Care must be taken when using the outcomes from the VITAL® Program for the purposes of determining allergens which are exempt from mandatory allergen declaration.',
        ],
        margin: [6, 3, 0, 0],
      },
    ]);
    this.reportSubtitle([
      { text: 'Tree nuts', bold: true },
      {
        ul: [
          'There is insufficient information to provide a Reference Dose for individual tree nuts. The cross contact concentrations from tree nuts are added together to give a total concentration.',
        ],
        margin: [6, 3, 0, 0],
      },
    ]);
    this.reportSubtitle([
      { text: 'Cereals containinig gluten', bold: true },
      {
        ul: [
          'There is insufficient information to provide a Reference Dose for individual cereals containing gluten. The cross contact concentrations from cereals containing gluten are added together to provide a total concentration.',
        ],
        margin: [6, 3, 0, 0],
      },
    ]);
  }

  fullReportFooter() {
    this.reportSubtitle([
      {
        text: [
          'The following information about Action Levels, hierarchy rules and allergen categories may assist to understand ',
          {
            text: i18n.t('recipes.accordion.summaryAssessmentNoRecipeName'),
            italics: true,
          },
          '.',
        ],
      },
    ]);

    this.reportTitle('Action Levels');
    this.reportSubtitle([
      {
        text: [
          'Action Levels are calculated using the Reference Dose for the substance (allergen) and the Reference Amount and are reported in ',
          {
            text: i18n.t('recipes.accordion.summaryAssessmentNoRecipeName'),
            italics: true,
          },
          '. The Reference Dose is set by the Allergen Bureau ',
          { text: i18n.t('recipes.accordion.referenceDoses'), italics: true },
          ' – Please see this link for further information ',
          {
            text: 'http://allergenbureau.net/vital/vital-science',
            link: 'http://allergenbureau.net/vital/vital-science',
          },
          '. The Reference Amount is set by the user and is specific to each product.',
        ],
      },
    ]);

    this.actionLevelLegendSummary();
    this.labellingOutcomesSummary();
    this.allergenNotes();

    return this;
  }

  actionLevelLegendSummary() {
    this.reportTitle(i18n.t('scenarioTester.legend.title') as string);
    this.basicFootnote(
      [{ text: i18n.t('scenarioTester.legend.al1') }],
      'AL1FootnoteLayout',
    );
    this.basicFootnote(
      [{ text: i18n.t('scenarioTester.legend.al2') }],
      'AL2FootnoteLayout',
    );
    this.basicFootnote(
      [{ text: i18n.t('scenarioTester.legend.intentional') }],
      'intentionalFootnoteLayout',
    );
    this.reportSubtitle([]);
    return this;
  }

  labellingOutcomesSummary() {
    this.reportTitle('Labelling Outcomes');
    this.reportSubtitle([
      {
        text: [
          'The hierarchy rules are used to determine the Labelling Outcomes (as reported in ',
          { text: i18n.t('recipes.accordion.outcomes'), italics: true },
          ' and ',
          {
            text: i18n.t('recipes.accordion.summaryAssessmentNoRecipeName'),
            italics: true,
          },
          ') are reported below.',
        ],
      },
    ]);

    this.reportTable(
      [
        [
          { text: 'Allergen Status', style: 'colHeader' },
          {
            text: 'VITAL Online finished product labelling outcome',
            style: 'colHeader',
          },
        ],
        [
          'The allergen is present at Action Level 1',
          {
            text: i18n.t('common.labels.actionLevel1'),
            color: '#4CAF50',
          },
        ],
        [
          'The allergen is present at Action Level 2',
          { text: 'Action Level 2', color: '#F44336' },
        ],
        [
          'The same allergen is present at Action Level 1 and Action Level 2',
          { text: 'Action Level 2', color: '#F44336' },
        ],
        [
          'The allergen is present as cross contact in particulate form',
          { text: 'Action Level 2', color: '#F44336' },
        ],
        [
          'The same allergen is present at Action Level 1 and/or Action Level 2 and as cross contact in particulate form',
          { text: 'Action Level 2', color: '#F44336' },
        ],
        [
          'The allergen is intentionally added',
          {
            text: i18n.t('common.dispersionTypes.intentionalLabel'),
            color: '#3f51b5',
          },
        ],
        [
          'The same allergen is present at Action Level 1 and/or Action Level 2 and/or in particulate form and intentionally added',
          {
            text: i18n.t('common.dispersionTypes.intentionalLabel'),
            color: '#3f51b5',
          },
        ],
      ],
      ['*', '*'],
    );
  }

  pageBreak() {
    this.content.push({
      text: '',
      pageBreak: 'after',
    });
    return this;
  }

  content: object[] = [];

  scenario: ScenarioTester;

  sample: boolean;

  constructor(scenario: ScenarioTester, sample = false) {
    this.scenario = scenario;
    this.sample = sample;
  }

  open() {
    const dt = formatDate(new Date().toISOString());
    pdfMake
      .createPdf({
        pageSize: 'A4',
        pageMargins: [20, 50, 20, 30],
        header: {
          svg: logo,
          width: 60,
          margin: [20, 20, 0, 0],
          alignment: 'left',
        },
        watermark: { text: this.sample ? 'SAMPLE' : '', opacity: 0.2 },
        footer(currentPage: number, pageCount: number) {
          return {
            text: `Page ${currentPage} of ${pageCount} – Generated ${dt}`,
            alignment: 'center',
          };
        },
        content: this.content,
        defaultStyle: {
          fontSize: 7,
        },
        styles: {
          rowHeader: {
            alignment: 'right',
            bold: true,
            color: primary,
          },
          colHeader: {
            fontSize: 6,
            bold: true,
            color: '#444',
          },
          reportTitle: {
            fontSize: 10,
            bold: true,
            margin: [0, 0, 0, 12],
          },
          reportSubtitle: {
            fontSize: 8,
            margin: [0, 0, 0, 12],
          },
          childAllergen: {
            margin: [12, 0, 0, 0],
          },
          incomingChange: {
            color: 'green',
          },
          outgoingChange: {
            color: 'red',
          },
        },
      })
      .open();
  }

  assessmentSummary() {
    const rows = this.scenario.getRows();

    const intentional = rows.filter(
      row => !row.isGroup && row.labels.intentional,
    );
    const al2 = rows.filter(row => !row.isGroup && row.labels.actionLevel2);
    const al1 = rows.filter(row => !row.isGroup && row.labels.actionLevel1);
    const unknown = rows.filter(row => !row.isGroup && row.labels.unknown);

    const widths = ['auto', '*'];
    const body: object[] = [
      [
        {
          text: i18n.t('recipes.labels.assessmentOutcomes'),
          style: 'colHeader',
          alignment: 'right',
        },
        '',
      ],
      [
        {
          text: i18n.t('recipes.labels.name'),
          style: 'rowHeader',
        },
        this.scenario.recipe.name,
      ],
      [
        {
          text: i18n.t('recipes.labels.code'),
          style: 'rowHeader',
        },
        this.scenario.recipe.referenceCode,
      ],
      [
        {
          text: i18n.t('common.labels.published'),
          style: 'rowHeader',
        },
        formatDate(this.scenario.recipe.published),
      ],
      [
        {
          text: i18n.t('common.labels.legislation'),
          style: 'rowHeader',
        },
        this.scenario.legislation.name,
      ],
      [
        {
          text: i18n.t('common.labels.referenceAmount'),
          style: 'rowHeader',
        },
        [
          this.scenario.recipe.isComponent
            ? `${i18n.t('common.labels.isComponent')} (${i18n.t(
                'common.labels.isComponentTooltip',
              )})`
            : `${Big(this.scenario.recipe.referenceAmount).toString()} g`,
          this.scenario.recipe.referenceAmountAssumptions,
        ],
      ],
    ];
    if (!this.scenario.recipe.isComponent) {
      body.push(
        [
          {
            text: i18n.t('common.dispersionTypes.intentionalLabel'),
            style: 'rowHeader',
          },
          intentional.length
            ? intentional.map(
                item => item.allergen.alternativeLabel || item.allergen.name,
              )
            : { text: '–' },
        ],
        [
          {
            text: i18n.t('common.labels.actionLevel2'),
            style: 'rowHeader',
          },
          al2.length
            ? al2.map(
                item => item.allergen.alternativeLabel || item.allergen.name,
              )
            : { text: '–' },
        ],
        [
          {
            text: i18n.t('common.labels.actionLevel1'),
            style: 'rowHeader',
          },
          al1.length
            ? al1.map(
                item => item.allergen.alternativeLabel || item.allergen.name,
              )
            : { text: '–' },
        ],
      );
      if (unknown.length) {
        body.push([
          {
            text: i18n.t('common.labels.unknown'),
            style: 'rowHeader',
          },
          unknown.length
            ? unknown.map(
                item => item.allergen.alternativeLabel || item.allergen.name,
              )
            : { text: '–' },
        ]);
      }
    }

    this.reportTitle(i18n.t('recipes.accordion.outcomes') as string);
    this.reportTable(body, widths);

    return this;
  }

  private fullSummaryRow(
    row: ScenarioRow,
    isComponent: boolean,
    compare: 'in' | 'out' | 'none' | 'deleted' | 'added' = 'none',
  ) {
    const labels = [];
    if (row.labels.unknown) {
      labels.push({
        text: `${i18n.t('common.labels.unknown')}*`,
        color: '#795548',
      });
    }
    if (row.labels.intentional) {
      labels.push({
        text: i18n.t('common.dispersionTypes.intentionalLabel'),
        color: '#3f51b5',
      });
    }
    if (row.labels.actionLevel2) {
      const text = `${i18n.t('common.labels.actionLevel2')}${
        row.labels.requiresAL2Footnote ? '**' : ''
      }`;
      labels.push({ text, color: '#F44336' });
    }
    if (row.labels.actionLevel1) {
      labels.push({
        text: i18n.t('common.labels.actionLevel1'),
        color: '#4CAF50',
      });
    }
    if (!labels.length) {
      labels.push('–');
    }

    let { allergenName } = row;
    const allergenStyles: string[] = [];
    if (compare === 'in') {
      allergenName = `[A] ${allergenName}`;
      allergenStyles.push('incomingChange');
    } else if (compare === 'out') {
      allergenName = `[B] ${allergenName}`;
      allergenStyles.push('outgoingChange');
    } else if (compare === 'added') {
      allergenName = `${allergenName} [added]`;
      allergenStyles.push('incomingChange');
    } else if (compare === 'deleted') {
      allergenName = `${allergenName} [deleted]`;
      allergenStyles.push('outgoingChange');
    }

    if (row.isChild && compare !== 'deleted') {
      allergenStyles.push('childAllergen');
    }

    if (isComponent) {
      return [
        {
          text: allergenName,
          style: allergenStyles,
        },
        { text: row.outcome.intentional ? 'yes' : '–', alignment: 'center' },
        { text: row.outcome.particulate ? 'yes' : '–', alignment: 'center' },
        {
          text: row.outcome.dispersible
            ? i18n.t('common.ppmValue', { value: row.ppmAfterWaterGain })
            : '–',
          alignment: 'center',
          noWrap: true,
        },
      ];
    }

    return [
      {
        text: allergenName,
        style: allergenStyles,
      },
      {
        text:
          row.threshold === null
            ? '–'
            : i18n.t('common.ltPpmValue', {
                value: roundify(row.threshold),
              }),
        noWrap: true,
        alignment: 'center',
      },
      {
        text:
          row.threshold === null
            ? '–'
            : i18n.t('common.gtePpmValue', {
                value: roundify(row.threshold),
              }),
        noWrap: true,
        alignment: 'center',
      },
      { text: row.outcome.intentional ? 'yes' : '–', alignment: 'center' },
      { text: row.outcome.particulate ? 'yes' : '–', alignment: 'center' },
      {
        text: row.outcome.dispersible
          ? i18n.t('common.ppmValue', { value: row.ppmAfterWaterGain })
          : '–',
        alignment: 'center',
        noWrap: true,
      },
      {
        stack: labels,
        noWrap: true,
        alignment: 'center',
      },
    ];
  }

  private fullSummaryHeaderRow(isComponent: boolean) {
    if (isComponent) {
      return [
        { text: 'Substance', style: 'colHeader' },
        {
          text: i18n.t('common.dispersionTypes.intentionalLabel'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
        {
          text: i18n.t('common.dispersionTypes.particulate'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
        {
          text: i18n.t('common.dispersionTypes.readilyDispersible'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
      ];
    }
    return [
      { text: 'Substance', style: 'colHeader' },
      {
        text: i18n.t('common.labels.actionLevel1'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
      {
        text: i18n.t('common.labels.actionLevel2'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
      {
        text: i18n.t('common.dispersionTypes.intentionalLabel'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
      {
        text: i18n.t('common.dispersionTypes.particulate'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
      {
        text: i18n.t('common.dispersionTypes.readilyDispersible'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
      {
        text: i18n.t('common.labels.labellingOutcome'),
        style: 'colHeader',
        alignment: 'center',
        noWrap: true,
      },
    ];
  }

  fullSummary() {
    const { isComponent } = this.scenario.recipe;

    const rows = this.scenario.getRows();
    const widths = isComponent
      ? ['auto', '*', '*', '*']
      : ['auto', '*', '*', '*', '*', '*', '*'];

    const body = [
      this.fullSummaryHeaderRow(isComponent),
      ...rows.map(row => this.fullSummaryRow(row, isComponent)),
    ];

    this.reportTitle(
      i18n.t('recipes.accordion.summaryAssessment', {
        name: this.scenario.recipe.name,
        refCode: this.scenario.recipe.referenceCode,
      }) as string,
    );
    this.reportTable(body, widths);

    const unknown = rows.filter(row => row.labels.unknown);
    if (unknown.length) {
      this.unknownFootnote(unknown);
    }
    const summedAL2 = rows.filter(row => row.labels.requiresAL2Footnote);
    if (summedAL2.length) {
      this.summedAL2Footnote(summedAL2);
    }

    return this;
  }

  actionLevelGrid(legislation: Legislation, referenceAmount: Big) {
    const rows = ScenarioTester.actionLevelGrid(
      legislation,
      referenceAmount,
      this.sample,
    );

    const body = [
      [
        { text: 'Substance', style: 'colHeader' },
        {
          text: i18n.t('allergens.labels.referenceDose'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
        {
          text: i18n.t('common.labels.actionLevel1'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
        {
          text: i18n.t('common.labels.actionLevel2'),
          style: 'colHeader',
          alignment: 'center',
          noWrap: true,
        },
      ],
      ...rows.map(row => {
        return [
          {
            text: row.allergenName,
            style: row.isChild ? 'childAllergen' : '',
          },
          {
            text:
              row.allergen.referenceDose === null
                ? '–'
                : i18n.t('common.mgValue', {
                    value: roundify(row.allergen.referenceDose),
                  }),
            alignment: 'center',
            noWrap: true,
          },
          {
            text:
              row.threshold === null
                ? '–'
                : i18n.t('common.ltPpmValue', {
                    value: roundify(row.threshold),
                  }),
            noWrap: true,
            alignment: 'center',
          },
          {
            text:
              row.threshold === null
                ? '–'
                : i18n.t('common.gtePpmValue', {
                    value: roundify(row.threshold),
                  }),
            noWrap: true,
            alignment: 'center',
          },
        ];
      }),
    ];

    this.reportTitle(
      `${i18n.t('reports.labels.actionLevelGridReport') as string} ${i18n.t(
        'version',
      )}`,
    );
    this.reportSubtitle([
      {
        text: [
          { text: `${i18n.t('common.labels.referenceAmount')}: `, bold: true },
          { text: `${referenceAmount.toString()} g` },
        ],
      },
      {
        text: [
          { text: `${i18n.t('common.labels.legislation')}: `, bold: true },
          {
            text: `${legislation.name} (Effective from ${formatDate(
              legislation.effectiveFrom,
            )})`,
          },
        ],
      },
    ]);
    this.reportTable(body, ['auto', '*', '*', '*']);

    return this;
  }

  referenceDoseSummary() {
    const rows = this.scenario.getRows();
    const widths = ['*', 'auto'];
    const body = [
      [
        {
          text: i18n.t('common.labels.allergen'),
          style: 'colHeader',
        },
        {
          text: i18n.t('allergens.labels.referenceDose'),
          alignment: 'right',
          style: 'colHeader',
        },
      ],
      ...rows.map(row => {
        return [
          { text: row.allergenName, style: row.isChild ? 'childAllergen' : '' },
          {
            text:
              row.allergen.referenceDose === null
                ? '–'
                : i18n.t('common.mgValue', {
                    value: roundify(row.allergen.referenceDose),
                  }),
            alignment: 'right',
          },
        ];
      }),
    ];
    this.reportTitle(i18n.t('recipes.accordion.referenceDoses') as string);
    this.reportTable(body, widths);

    return this;
  }

  rawMaterialsSummary() {
    const widths = ['auto', 'auto', 'auto', '*'];
    const body = [
      [
        {
          text: i18n.t('common.labels.ingredient'),
          style: 'colHeader',
          noWrap: true,
        },
        {
          text: i18n.t('common.labels.referenceCode'),
          style: 'colHeader',
          noWrap: true,
          alignment: 'center',
        },
        {
          text: i18n.t('recipes.labels.amountInFormulation'),
          style: 'colHeader',
          noWrap: true,
          alignment: 'center',
        },
        {
          text: i18n.t('common.labels.assumptions'),
          style: 'colHeader',
          noWrap: true,
        },
      ],
      ...this.scenario.recipe.recipeIngredients.map(recipeIngredient => [
        recipeIngredient.ingredient.name,
        {
          text: recipeIngredient.ingredient.referenceCode,
          alignment: 'center',
        },
        {
          text: `${Big(recipeIngredient.percentage).toString()}%`,
          alignment: 'center',
        },
        recipeIngredient.ingredient.assumptions,
      ]),
    ];
    this.reportTitle(
      i18n.t('recipes.accordion.materialsFormulation') as string,
    );
    this.reportTable(body, widths);
    return this;
  }

  private constituentSummary(showIngredients: boolean, applyYield: boolean) {
    if (showIngredients) {
      this.reportTitle(
        applyYield
          ? (i18n.t('recipes.accordion.allergensWithYield') as string)
          : (i18n.t('recipes.accordion.allergensNoYield') as string),
      );
    } else {
      this.reportTitle(
        applyYield
          ? (i18n.t('recipes.accordion.processingAllergensWithYield') as string)
          : (i18n.t('recipes.accordion.processingAllergensNoYield') as string),
      );
    }

    const rows = this.scenario.getConstituentRows();
    const items = showIngredients
      ? this.scenario.recipe.recipeIngredients.map(recipeIngredient => ({
          text: recipeIngredient.ingredient.name,
          style: 'colHeader',
          alignment: 'center',
        }))
      : this.scenario.recipe.recipeProcessings.map(recipeProcessing => ({
          text: recipeProcessing.processing.name,
          style: 'colHeader',
          alignment: 'center',
        }));

    while (items.length) {
      const current = items.splice(0, 5);
      const headers = [
        {
          text: i18n.t('common.labels.allergen'),
          style: 'colHeader',
        },
        ...current,
      ];
      const body = [
        headers,
        ...rows.map(row => [
          {
            text: row.allergen.name,
            style: row.isChild ? 'childAllergen' : '',
          },
          ...(showIngredients
            ? row.ingredientOutcomes.splice(0, 5)
            : row.processingOutcomes.splice(0, 5)
          ).map(item => {
            return outcomeLabel(
              item.outcome,
              applyYield ? item.ppmAfterWaterGain : item.ppmBeforeWaterGain,
            );
          }),
        ]),
      ];
      this.reportTable(body, ['*', ...current.map(() => '*')]);

      if (items.length) {
        this.pageBreak();
        if (showIngredients) {
          this.reportTitle(
            applyYield
              ? (i18n.t('recipes.accordion.allergensWithYieldContd') as string)
              : (i18n.t('recipes.accordion.allergensNoYieldContd') as string),
          );
        } else {
          this.reportTitle(
            applyYield
              ? (i18n.t(
                  'recipes.accordion.processingAllergensWithYieldContd',
                ) as string)
              : (i18n.t(
                  'recipes.accordion.processingAllergensNoYieldContd',
                ) as string),
          );
        }
      }
    }

    return this;
  }

  ingredientAllergenSummary(applyYield: boolean) {
    return this.constituentSummary(true, applyYield);
  }

  processingAllergenSummary(applyYield: boolean) {
    return this.constituentSummary(false, applyYield);
  }

  processingAssumptionsSummary() {
    this.reportTitle(
      i18n.t('recipes.accordion.processingAssumptions') as string,
    );
    this.scenario.recipe.recipeProcessings.forEach(recipeProcessing => {
      const widths = ['auto', '*'];
      const body = [
        [
          {
            text: i18n.t('recipes.assumptionsForValue', {
              value: recipeProcessing.processing.name,
            }),
            style: 'colHeader',
          },
          '',
        ],
        ...recipeProcessing.processing.processingSources.map(source => [
          { text: source.name, style: 'rowHeader' },
          { text: source.assumptions || '–' },
        ]),
      ];
      this.reportTable(body, widths);
    });
    const body = [
      [
        {
          text: i18n.t('recipes.labels.overallAssumptions'),
          style: 'colHeader',
        },
      ],
      [{ text: this.scenario.recipe.noProcessingsAssumptions }],
    ];
    this.reportTable(body, ['*']);
    return this;
  }

  allAllergensSummary() {
    const rows = this.scenario.getConstituentRows();

    const headers = [
      { text: i18n.t('common.labels.substance'), style: 'colHeader' },
      {
        text: i18n.t('recipes.labels.totalIngredients'),
        style: 'colHeader',
        alignment: 'center',
      },
      {
        text: i18n.t('recipes.labels.totalProcessing'),
        style: 'colHeader',
        alignment: 'center',
      },
      {
        text: i18n.t('recipes.labels.total'),
        style: 'colHeader',
        alignment: 'center',
      },
      {
        text: i18n.t('recipes.labels.totalAfter'),
        style: 'colHeader',
        alignment: 'center',
      },
    ];

    const body = [
      headers,
      ...rows.map(row => [
        {
          text: row.allergen.name,
          style: row.isChild ? 'childAllergen' : '',
        },
        outcomeLabel(
          row.totalOutcomes.ingredientOutcome,
          row.totalOutcomes.ingredientOutcome.ppm,
        ),
        outcomeLabel(
          row.totalOutcomes.processingOutcome,
          row.totalOutcomes.processingOutcome.ppm,
        ),
        outcomeLabel(
          row.totalOutcomes.totalOutcome,
          row.totalOutcomes.totalOutcome.ppm,
        ),
        {
          text:
            !row.totalOutcomes.totalOutcome.intentional &&
            !row.totalOutcomes.totalOutcome.particulate &&
            row.totalOutcomes.totalOutcome.dispersible
              ? i18n.t('common.ppmValue', {
                  value: row.totalOutcomes.ppmAfterWaterGain,
                })
              : '–',
          alignment: 'center',
          noWrap: true,
        },
      ]),
    ];
    this.reportTitle(i18n.t('recipes.accordion.finalProduct') as string);
    this.reportTable(body, ['auto', '*', '*', '*', '*']);
    return this;
  }

  yieldSummmary() {
    const body = [
      [
        {
          text: i18n.t('recipes.accordion.concentrationDilution'),
          style: 'colHeader',
        },
        '',
      ],
      [
        { text: i18n.t('allergens.labels.concentration'), style: 'rowHeader' },
        {
          text: Big(this.scenario.recipe.waterGain).lt('100')
            ? `${this.scenario.recipe.waterGain}%`
            : '–',
        },
      ],
      [
        { text: i18n.t('common.labels.dilution'), style: 'rowHeader' },
        {
          text: Big(this.scenario.recipe.waterGain).gt('100')
            ? `${this.scenario.recipe.waterGain}%`
            : '–',
        },
      ],
      [
        { text: i18n.t('common.labels.assumptions'), style: 'rowHeader' },
        {
          text: this.scenario.recipe.waterGainAssumptions || '–',
        },
      ],
    ];
    this.reportTitle(
      i18n.t('recipes.accordion.concentrationDilution') as string,
    );
    this.reportTable(body, ['auto', '*']);
    return this;
  }

  comparisonSummary(oldScenario: ScenarioTester, showUnchangedRows = false) {
    const newRows = this.scenario.getRows();
    const oldRows = oldScenario.getRows();
    const isComponent =
      this.scenario.recipe.isComponent || oldScenario.recipe.isComponent;
    const comparison = ScenarioTester.compareRows(newRows, oldRows);
    const allEqual = comparison.filter(row => !row.rowsAreEqual).length === 0;
    const headerRow = this.fullSummaryHeaderRow(isComponent);
    const outputRows: object[] = [headerRow];
    comparison.forEach(({ revA, revB, rowsAreEqual }) => {
      if (!rowsAreEqual) {
        if (revA) {
          outputRows.push(
            this.fullSummaryRow(revA, isComponent, revB ? 'in' : 'added'),
          );
        }
        if (revB) {
          outputRows.push(
            this.fullSummaryRow(revB, isComponent, revA ? 'out' : 'deleted'),
          );
        }
      } else if (revA && showUnchangedRows) {
        outputRows.push(this.fullSummaryRow(revA, isComponent));
      }
    });
    this.reportTitle(
      i18n.t('recipes.accordion.comparisonReport', {
        name: this.scenario.recipe.name,
        refCode: this.scenario.recipe.referenceCode,
      }) as string,
    );
    this.reportSubtitle([
      {
        text: `Revision A: ${this.scenario.recipe
          .id as string} – Published ${dayjs(
          this.scenario.recipe.published,
        ).format('DD MMMM YYYY, h:mm:ss a')}`,
        style: 'incomingChange',
      },
      {
        text: `Revision B: ${oldScenario.recipe
          .id as string} – Published ${dayjs(
          oldScenario.recipe.published,
        ).format('DD MMMM YYYY, h:mm:ss a')}`,
        style: 'outgoingChange',
      },
    ]);
    if (allEqual) {
      if (isComponent) {
        this.reportTable(
          [
            headerRow,
            [{ text: 'No changes found', colSpan: 4, italics: true }],
          ],
          ['auto', '*', '*', '*'],
        );
      } else {
        this.reportTable(
          [
            headerRow,
            [{ text: 'No changes found', colSpan: 7, italics: true }],
          ],
          ['auto', '*', '*', '*', '*', '*', '*'],
        );
      }
    } else if (isComponent) {
      this.reportTable(outputRows, ['auto', '*', '*', '*']);
    } else {
      this.reportTable(outputRows, ['auto', '*', '*', '*', '*', '*', '*']);
    }
    return this;
  }
}
