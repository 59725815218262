








































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Slider extends Vue {
  elements: NodeListOf<Element>;

  index = -1;

  mounted() {
    this.index = 0;
    window.setInterval(() => {
      this.index = (this.index + 1) % (this.$el.children.length - 1);
    }, 10000);
  }
}
