












































import { Component, Vue } from 'vue-property-decorator';

import legislationModule from '@/store/Legislation';

import FilterItem from '@/components/dashboard/FilterItem.vue';

@Component({ components: { FilterItem } })
export default class RecipeFilters extends Vue {
  statusItems = [
    { label: this.$t('common.labels.published'), value: 'published' },
    { label: this.$t('common.labels.draft'), value: 'draft' },
  ];

  outdatedItems = [
    { label: this.$t('common.labels.outdated'), value: 'true' },
    { label: this.$t('common.labels.upToDate'), value: 'false' },
  ];

  archivedItems = [
    { label: this.$t('common.labels.archived'), value: 'true' },
    { label: this.$t('common.labels.notArchived'), value: 'false' },
  ];

  ingredientItems = [
    { label: this.$t('recipes.labels.notAvailable'), value: 'false' },
    { label: this.$t('recipes.labels.available'), value: 'true' },
    { label: this.$t('recipes.labels.availableAndUsed'), value: 'used' },
  ];

  get legislationItems() {
    return legislationModule.activeLegislationCores;
  }

  get archived() {
    return this.$route.query.archived === 'true';
  }

  set archived(v: boolean) {
    this.$router.push({
      query: {
        ...this.$route.query,
        archived: v.toString(),
      },
    });
  }
}
