
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Processing } from '@/api';

import RelAutocompleteAsync from '@/components/common/RelAutocompleteAsync.vue';

@Component({ components: { RelAutocompleteAsync } })
export default class LibrarySelect extends Vue {
  @Prop({ required: true }) readonly recipeId: string;

  @Prop({ type: Boolean, default: false }) readonly readonly: boolean;

  processing: Processing | null = null;

  get scopeFactory() {
    return () =>
      Processing.where({
        for_recipe: this.recipeId,
        is_archived: false,
      });
  }

  itemSelected(item: Processing) {
    this.$emit('select', item);
    this.$nextTick(() => {
      this.processing = null;
    });
  }
}
