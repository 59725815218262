





















































































































import v8n from 'v8n';
import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';
import { ProcessingSource } from '@/api';

import snackModule from '@/store/Snack';

import TabLayout from '@/components/common/TabLayout.vue';
import TabLayoutItem from '@/components/common/TabLayoutItem.vue';
import ProcessingAllergenList from '@/components/common/ProcessingAllergenList.vue';
import AlertTooltip from '@/components/common/AlertTooltip.vue';

@Component({
  components: {
    TabLayout,
    TabLayoutItem,
    ProcessingAllergenList,
    AlertTooltip,
  },
})
export default class InlineSourceEdit extends Vue {
  @VModel({ required: true }) dialog: boolean;

  @Prop({ required: true }) readonly legislationId: string;

  @Prop({ required: true }) readonly processingSource: ProcessingSource;

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  valid = false;

  allergenValid = 'incomplete';

  isDirty = false;

  loading = false;

  tabIndex = 0;

  get informationAlert() {
    if (!this.valid) {
      return {
        message: this.$t('common.tabs.missingInfo'),
        icon: 'mdi-alert-circle',
        color: 'error',
      };
    }
    if (this.isDirty) {
      return {
        message: this.$t('common.tabs.unsaved'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get allergenAlert() {
    if (this.allergenValid === 'incomplete') {
      return {
        message: this.$t('common.tabs.noAllergens'),
        icon: 'mdi-alert-circle',
        color: 'orange',
      };
    }
    return {
      message: this.$t('common.tabs.complete'),
      icon: 'mdi-check-circle',
      color: 'primary',
    };
  }

  get formFields() {
    return [this.processingSource.name, this.processingSource.assumptions];
  }

  get isNewSource() {
    return !this.processingSource || !this.processingSource.isPersisted;
  }

  async deleteSource() {
    try {
      await this.processingSource.destroy();
      snackModule.setSuccess(
        this.$t('processingSources.alerts.deleted') as string,
      );
      this.dialog = false;
    } catch (e) {
      snackModule.setError({
        text: this.$t('processingSources.alerts.notDeleted') as string,
        errors: e.response.errors,
      });
    }
  }

  async saveSource() {
    this.loading = true;
    try {
      await this.processingSource.save({ with: ['processing.id'] });
      snackModule.setSuccess(
        this.$t('processingSources.alerts.saved') as string,
      );
    } catch (e) {
      snackModule.setError({
        text: this.$t('processingSources.alerts.notSaved') as string,
        errors: e.response.errors,
      });
    } finally {
      this.checkIsDirty();
      this.loading = false;
    }
  }

  @Watch('formFields')
  checkIsDirty() {
    this.isDirty = this.processingSource.isDirty();
  }

  @Watch('dialog')
  dialogChanged() {
    this.tabIndex = 0;
  }
}
