

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipe, RecipeProcessing } from '@/api';

@Component({
  components: {},
})
export default class ProcessingAssumptionsSummary extends Vue {
  @Prop({ required: true }) readonly recipe: Recipe;

  @Prop({ required: true }) readonly recipeProcessings: RecipeProcessing[];
}
